import {
	DefaultStyledInputSwitchComponents,
	type InputBaseProps,
	type StyledInputSwitchComponents,
} from "@constellation-academy/framework-react-components";
import {
	InputSwitch as PrInputSwitch,
	type InputSwitchProps as PrInputSwitchProps,
} from "primereact/inputswitch";
import styled from "styled-components";

export const tkaAdminInputSwitchStyles: StyledInputSwitchComponents = {
	InputSwitch: {
		...DefaultStyledInputSwitchComponents.InputSwitch,
		StyledInputSwitch: styled(PrInputSwitch)<
			InputBaseProps & PrInputSwitchProps & { checked: boolean }
		>``,
	},
};
