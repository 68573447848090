import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { type matrixElementForm_EditMatrixElementMutation } from "@relay/matrixElementForm_EditMatrixElementMutation.graphql";
import { stripHtml } from "@util/html.utils";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import {
	EDIT_MATRIX_ELEMENT_MUTATION,
	MATRIX_ELEMENT_FRAGMENT,
} from "./matrix-element-form.graphql";
import {
	type MatrixElementFormFormState,
	type MatrixElementFormProps,
} from "./matrix-element-form.types";
import { ValidatedField } from "../../../../components/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";
import { MatrixFieldElement } from "../matrix-field-element";

export const MatrixElementForm = ({ matrixElementFragmentRef, onBack }: MatrixElementFormProps) => {
	const matrixElement = useFragment(MATRIX_ELEMENT_FRAGMENT, matrixElementFragmentRef);
	const [editMatrixElement, isEditingMatrixElement] =
		useMutation<matrixElementForm_EditMatrixElementMutation>(EDIT_MATRIX_ELEMENT_MUTATION);

	const dispatch = useTypedDispatch();
	const formId = "MatrixElementForm";
	const formik = useFormik<MatrixElementFormFormState>({
		initialValues: {
			title: stripHtml(matrixElement.title),
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Aufgabenstellung wird benötigt."),
		}),
		onSubmit: (values) => {
			editMatrixElement({
				variables: {
					input: {
						matrixElementId: matrixElement.id,
						newTitle: values.title,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<div className="m-4">
			<Card className="mb-4">
				<form onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<MatrixElementFormFormState, string>
						name={"title"}
						label={"Aufgabenstellung"}
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>
					<Button
						disabled={
							Object.entries(formik.touched).length === 0 || isEditingMatrixElement
						}
						type="submit"
						label="Aufgabenstellung Speichern"
						className="mt-2"
					/>
				</form>
			</Card>
			<Card>
				<MatrixFieldElement matrixElementFragmentRef={matrixElement} />
			</Card>
			<div className="p-fluid">
				<Button
					type="button"
					disabled={!formik.isValid}
					onClick={() => {
						onBack();
					}}
					label="Zurück"
					className="p-button-secondary mt-2"
				/>
			</div>
		</div>
	);
};
