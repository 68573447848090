import { graphql } from "react-relay";

export const CREATE_TEXT_ELEMENT_MUTATION = graphql`
	mutation createTextElementButton_CreateTextElementMutation($input: CreateTextElementV2Input!) {
		Admin {
			ElearningV2 {
				createTextElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;
