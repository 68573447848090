import { graphql } from "react-relay";

export const QUERY = graphql`
	query selectAccountGroups_Query($id: ID!) {
		Admin {
			UserInAccountGroupManagement {
				UserInAccountGroupsForAccount(accountId: $id) {
					id
					name
					permissions
				}
			}
		}
	}
`;
