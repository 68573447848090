/**
 * @generated SignedSource<<13b4a20cdd78c6269a90258973903085>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CountryCode = "DE";
export type accountEditBaseDataScreen_Query$variables = {
  id: string;
};
export type accountEditBaseDataScreen_Query$data = {
  readonly Admin: {
    readonly AccountBaseData: {
      readonly AccountBaseData: {
        readonly __typename: string;
        readonly accountData?: {
          readonly iban: string;
        } | null | undefined;
        readonly city: string | null | undefined;
        readonly countryCode: CountryCode | null | undefined;
        readonly houseNumber: string | null | undefined;
        readonly postalCode: string | null | undefined;
        readonly street: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"businessBaseDataForm_BusinessBaseDataFragment" | "privateBaseDataForm_PrivateBaseDataFragment">;
      };
    };
  };
};
export type accountEditBaseDataScreen_Query = {
  response: accountEditBaseDataScreen_Query$data;
  variables: accountEditBaseDataScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filterByAccountId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "houseNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iban",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountEditBaseDataScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBaseDataAdminSchema",
            "kind": "LinkedField",
            "name": "AccountBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "AccountBaseData",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountData",
                        "kind": "LinkedField",
                        "name": "accountData",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "BusinessBaseData",
                    "abstractKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "privateBaseDataForm_PrivateBaseDataFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "businessBaseDataForm_BusinessBaseDataFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountEditBaseDataScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBaseDataAdminSchema",
            "kind": "LinkedField",
            "name": "AccountBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "AccountBaseData",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountData",
                        "kind": "LinkedField",
                        "name": "accountData",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bic",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billingOffice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyLegalForm",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "invoiceEmail",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaxData",
                        "kind": "LinkedField",
                        "name": "taxData",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "taxNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "taxIdentificationNumber",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "BusinessBaseData",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "salutation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "type": "PrivateBaseData",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "58af6a4570c98b5932425fc83af5d219",
    "id": null,
    "metadata": {},
    "name": "accountEditBaseDataScreen_Query",
    "operationKind": "query",
    "text": "query accountEditBaseDataScreen_Query(\n  $id: ID!\n) {\n  Admin {\n    AccountBaseData {\n      AccountBaseData(filterByAccountId: $id) {\n        __typename\n        city\n        street\n        houseNumber\n        postalCode\n        countryCode\n        ... on BusinessBaseData {\n          accountData {\n            iban\n          }\n        }\n        ...privateBaseDataForm_PrivateBaseDataFragment\n        ...businessBaseDataForm_BusinessBaseDataFragment\n      }\n    }\n  }\n}\n\nfragment businessBaseDataForm_BusinessBaseDataFragment on BusinessBaseData {\n  __typename\n  companyName\n  billingOffice\n  companyLegalForm\n  invoiceEmail\n  phoneNumber\n  city\n  street\n  houseNumber\n  postalCode\n  countryCode\n  accountData {\n    bic\n    iban\n  }\n  taxData {\n    taxNumber\n    taxIdentificationNumber\n  }\n}\n\nfragment privateBaseDataForm_PrivateBaseDataFragment on PrivateBaseData {\n  __typename\n  salutation\n  title\n  firstName\n  lastName\n  phoneNumber\n  city\n  street\n  houseNumber\n  postalCode\n  countryCode\n}\n"
  }
};
})();

(node as any).hash = "21f95b6efb5a6fb0ec6dc3487b0c12a4";

export default node;
