/**
 * @generated SignedSource<<66369dc87c9bf268f6623998fc6129c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type reportsScreen_Query$variables = {
  filterByReferenceNumberOpt?: string | null | undefined;
  numItemsPerPage: number;
};
export type reportsScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"reportsTable_QueryFragment">;
};
export type reportsScreen_Query = {
  response: reportsScreen_Query$data;
  variables: reportsScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByReferenceNumberOpt"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "numItemsPerPage"
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "numItemsPerPage"
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "referenceNumber",
    "variableName": "filterByReferenceNumberOpt"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reportsScreen_Query",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "filterByReferenceNumberOpt",
            "variableName": "filterByReferenceNumberOpt"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "reportsTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reportsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportsAdminSchema",
            "kind": "LinkedField",
            "name": "Reports",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DSAReportConnection",
                "kind": "LinkedField",
                "name": "Reports",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DSAReportEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DSAReport",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "referenceNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAReportData",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "extra",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "problemType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reportType",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": [
                  "referenceNumber"
                ],
                "handle": "connection",
                "key": "ReportsTable_Reports",
                "kind": "LinkedHandle",
                "name": "Reports"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2ef278fec94eff72049612d3d132394b",
    "id": null,
    "metadata": {},
    "name": "reportsScreen_Query",
    "operationKind": "query",
    "text": "query reportsScreen_Query(\n  $filterByReferenceNumberOpt: String\n  $numItemsPerPage: Int!\n) {\n  ...reportsTable_QueryFragment_O14qD\n}\n\nfragment reportsTable_DSAReportInlineFragment on DSAReport {\n  id\n  createdAt\n  referenceNumber\n  data {\n    url\n    email\n    firstName\n    lastName\n    extra\n    problemType\n    reportType\n  }\n}\n\nfragment reportsTable_QueryFragment_O14qD on Query {\n  Admin {\n    Reports {\n      Reports(first: $numItemsPerPage, referenceNumber: $filterByReferenceNumberOpt) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            ...reportsTable_DSAReportInlineFragment\n            id\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4351983154b7fda623496e3bbccbd2c4";

export default node;
