import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { OverlayPanel } from "primereact/overlaypanel";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { Button } from "@components/button";
import {
	CREATE_RANDOM_DISCOUNT_CODES_MUTATION,
	DISCOUNT_ACTION_FRAGMENT,
} from "./discount-code-create-random.graphql";
import { DiscountCodeCreateRandomProps } from "./discount-code-create-random.props";
import { useDiscountCodeTableContext } from "../discount-code-table/discount-code-table.context";

export const DiscountCodeCreateRandom = ({
	discountActionFragmentRef,
}: DiscountCodeCreateRandomProps) => {
	const { connectionId } = useDiscountCodeTableContext();
	const discountAction = useFragment(DISCOUNT_ACTION_FRAGMENT, discountActionFragmentRef);
	const [createRandomCodes, isLoading] = useMutation(CREATE_RANDOM_DISCOUNT_CODES_MUTATION);

	const [amount, setAmount] = useState<number>(0);

	const overlayPanel = useRef<OverlayPanel>(null);
	const toast = useRef<Toast>(null);

	return (
		<>
			<Dialog
				header="Rabattcodes werden erstellt"
				visible={isLoading}
				closable={false}
				onHide={() => {}}
			>
				<div className="flex justify-content-center align-items-center">
					<ProgressSpinner />
				</div>
			</Dialog>
			<Toast ref={toast} />
			<Button
				label="Randomisiert"
				loading={isLoading}
				disabled={isLoading}
				onClick={(e) => overlayPanel.current?.toggle(e)}
			/>
			<OverlayPanel ref={overlayPanel}>
				<span className="p-float-label mb-2 mt-3">
					<InputNumber
						max={1000}
						min={1}
						id="nameOfCode"
						value={amount}
						onChange={(e) => setAmount(e.value || 0)}
					/>
					<label htmlFor="nameOfCode">Anzahl der Codes</label>
				</span>
				<Button
					label="Randomisierte Codes erstellen"
					loading={isLoading}
					disabled={isLoading}
					onClick={() => {
						createRandomCodes({
							variables: {
								input: {
									discountActionId: discountAction.id,
									number: amount,
								},
								connections: [connectionId],
							},
							onCompleted: () => {
								toast.current?.show({
									severity: "success",
									life: 3000,
									summary: "Codes erstellt",
									detail: "Randomisierte codes wurden erfolgreich erstellt",
								});
							},
						});
					}}
				/>
			</OverlayPanel>
		</>
	);
};
