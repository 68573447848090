import { graphql } from "react-relay";

export const QUERY = graphql`
	query orderEditScreen_OrderQuery($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Order {
				id
				customerDetails {
					...billingDetailsForm_Billing_CustomerDetailsFragment
				}
				...cartForm_OrderFragment
				...paymentDataForm_OrderFragment
				...invoiceDataForm_OrderFragment
			}
		}
		...orderHistoryTable_OrderHistoriesFragment
	}
`;
