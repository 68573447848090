import { graphql } from "react-relay";

export const QUERY = graphql`
	query accountEditBaseDataScreen_Query($id: ID!) {
		Admin {
			AccountBaseData {
				AccountBaseData(filterByAccountId: $id) {
					__typename
					city
					street
					houseNumber
					postalCode
					countryCode
					... on BusinessBaseData {
						accountData {
							iban
						}
					}
					...privateBaseDataForm_PrivateBaseDataFragment
					...businessBaseDataForm_BusinessBaseDataFragment
				}
			}
		}
	}
`;

export const EDIT_PRIVATE_BASE_DATA_MUTATION = graphql`
	mutation accountEditBaseDataScreen_EditPrivateBaseDataMutation(
		$accountId: ID!
		$baseData: PrivateBaseDataInput!
	) {
		Admin {
			AccountBaseData {
				editPrivateBaseData(input: { accountId: $accountId, baseData: $baseData }) {
					baseData {
						__typename
						...privateBaseDataForm_PrivateBaseDataFragment
					}
				}
			}
		}
	}
`;

export const EDIT_BUSINESS_BASE_DATA_MUTATION = graphql`
	mutation accountEditBaseDataScreen_EditBusinessBaseDataMutation(
		$accountId: ID!
		$baseData: BusinessBaseDataInput!
	) {
		Admin {
			AccountBaseData {
				editBusinessBaseData(input: { accountId: $accountId, baseData: $baseData }) {
					baseData {
						__typename
						...businessBaseDataForm_BusinessBaseDataFragment
					}
				}
			}
		}
	}
`;
