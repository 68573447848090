import { graphql } from "react-relay";

export const ORDER_HISTORY_FRAGMENT = graphql`
	fragment orderHistoryEvent_OrderHistoryFragment on HistoryEvent {
		kind
		... on InvoiceSentEvent {
			invoiceId
			invoiceNumber
		}
		# ... on CreditNoteSentEvent {
		# 	creditNoteId
		# 	creditNoteNumber
		# }
	}
`;
