/**
 * @generated SignedSource<<b84971c24436eca7c6f6dd1ce64b28bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditMultipleChoiceElementV2Input = {
  answerOptions: ReadonlyArray<AnswerOptionV2Input>;
  answeringType: string;
  answeringTypeText?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  imageIdOpt?: string | null | undefined;
  multipleChoiceElementId: string;
  question: string;
  title: string;
};
export type AnswerOptionV2Input = {
  answer: string;
  imageRef?: string | null | undefined;
  isCorrect: boolean;
};
export type multipleChoiceElementForm_EditMultipleChoiceElementMutation$variables = {
  input: EditMultipleChoiceElementV2Input;
};
export type multipleChoiceElementForm_EditMultipleChoiceElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editMultipleChoiceElement: {
        readonly multipleChoiceElement: {
          readonly " $fragmentSpreads": FragmentRefs<"multipleChoiceElementForm_MultipleChoiceElementV2Fragment">;
        };
      } | null | undefined;
    };
  };
};
export type multipleChoiceElementForm_EditMultipleChoiceElementMutation = {
  response: multipleChoiceElementForm_EditMultipleChoiceElementMutation$data;
  variables: multipleChoiceElementForm_EditMultipleChoiceElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "multipleChoiceElementForm_EditMultipleChoiceElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditMultipleChoiceElementV2Payload",
                "kind": "LinkedField",
                "name": "editMultipleChoiceElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleChoiceElementV2",
                    "kind": "LinkedField",
                    "name": "multipleChoiceElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "multipleChoiceElementForm_MultipleChoiceElementV2Fragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "multipleChoiceElementForm_EditMultipleChoiceElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditMultipleChoiceElementV2Payload",
                "kind": "LinkedField",
                "name": "editMultipleChoiceElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleChoiceElementV2",
                    "kind": "LinkedField",
                    "name": "multipleChoiceElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "question",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answeringType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answeringTypeText",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerOptionV2",
                        "kind": "LinkedField",
                        "name": "answerOptions",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "answer",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCorrect",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4abc3a5c647321d83a523ded94df57d6",
    "id": null,
    "metadata": {},
    "name": "multipleChoiceElementForm_EditMultipleChoiceElementMutation",
    "operationKind": "mutation",
    "text": "mutation multipleChoiceElementForm_EditMultipleChoiceElementMutation(\n  $input: EditMultipleChoiceElementV2Input!\n) {\n  Admin {\n    ElearningV2 {\n      editMultipleChoiceElement(input: $input) {\n        multipleChoiceElement {\n          ...multipleChoiceElementForm_MultipleChoiceElementV2Fragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment multipleChoiceElementForm_MultipleChoiceElementV2Fragment on MultipleChoiceElementV2 {\n  id\n  title\n  question\n  image {\n    id\n    name\n  }\n  answeringType\n  answeringTypeText\n  answerOptions {\n    answer\n    isCorrect\n    image {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cd316d08ee35efe66c66641928fd818";

export default node;
