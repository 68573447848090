/**
 * @generated SignedSource<<9865f8215163904b5fc6dcb76dcf85e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editTagInput = {
  clientMutationId?: string | null | undefined;
  data: TagDataInput;
  id: string;
};
export type TagDataInput = {
  isClickable: boolean;
  isTopic: boolean;
  name: string;
};
export type editAcademiesTagButton_EditTagMutation$variables = {
  input: editTagInput;
};
export type editAcademiesTagButton_EditTagMutation$data = {
  readonly Admin: {
    readonly AcademiesTag: {
      readonly editTag: {
        readonly tag: {
          readonly " $fragmentSpreads": FragmentRefs<"editAcademiesTagButton_AcademiesTagFragment">;
        };
      } | null | undefined;
    };
  };
};
export type editAcademiesTagButton_EditTagMutation = {
  response: editAcademiesTagButton_EditTagMutation$data;
  variables: editAcademiesTagButton_EditTagMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTopic",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isClickable",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAcademiesTagButton_EditTagMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AcademiesTagAdminMutationSchema",
            "kind": "LinkedField",
            "name": "AcademiesTag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "editTagPayload",
                "kind": "LinkedField",
                "name": "editTag",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "tag",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editAcademiesTagButton_AcademiesTagFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAcademiesTagButton_EditTagMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AcademiesTagAdminMutationSchema",
            "kind": "LinkedField",
            "name": "AcademiesTag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "editTagPayload",
                "kind": "LinkedField",
                "name": "editTag",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "tag",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isAcademiesTag"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AcademiesTagData",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "AcademiesRootTag",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AcademiesTagData",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "AcademiesChildTag",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4fb07cb1c8285950e6988bd4115650e7",
    "id": null,
    "metadata": {},
    "name": "editAcademiesTagButton_EditTagMutation",
    "operationKind": "mutation",
    "text": "mutation editAcademiesTagButton_EditTagMutation(\n  $input: editTagInput!\n) {\n  Admin {\n    AcademiesTag {\n      editTag(input: $input) {\n        tag {\n          __typename\n          ...editAcademiesTagButton_AcademiesTagFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editAcademiesTagButton_AcademiesTagFragment on AcademiesTag {\n  __isAcademiesTag: __typename\n  id\n  ... on AcademiesRootTag {\n    data {\n      name\n      isTopic\n      isClickable\n    }\n  }\n  ... on AcademiesChildTag {\n    data {\n      name\n      isClickable\n      isTopic\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0c7144bc067470dd2de91cfebce9971";

export default node;
