import { DefaultNumberFieldComponent } from "@constellation-academy/framework-react-components";
import { useFormik } from "formik";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Suspense, useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { ValidatedField } from "@components/ValidatedField";
import {
	SelectLicenseDefinitionField,
	SelectLicenseDefinitionFieldState,
} from "@features/license-definitions/select-license-definition-field";
import { licenseProductForm_EditLicenseProductMutation } from "@relay/licenseProductForm_EditLicenseProductMutation.graphql";
import { licenseProductForm_EditProductMutation } from "@relay/licenseProductForm_EditProductMutation.graphql";
import { licenseProductForm_LicenseProductFragment$key } from "@relay/licenseProductForm_LicenseProductFragment.graphql";
import { licenseProductForm_QueryFragment$key } from "@relay/licenseProductForm_QueryFragment.graphql";
import {
	QUERY_FRAGMENT,
	EDIT_PRODUCT_MUTATION,
	LICENSE_PRODUCT_FRAGMENT,
	EDIT_LICENSE_PRODUCT_MUTATION,
} from "@screens/product-edit/parts/license-product-form/license-product-form.graphql";
import {
	LicenseProductFormProps,
	LicenseProductFormState,
} from "@screens/product-edit/parts/license-product-form/license-product-form.types";
import { ProductForm } from "@screens/product-edit/parts/product-form/product-form.component";

export const LicenseProductForm = ({
	productFragmentRef,
	queryFragmentRef,
}: LicenseProductFormProps) => {
	const toast = useRef<Toast>(null);

	const query = useFragment<licenseProductForm_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const product = useFragment<licenseProductForm_LicenseProductFragment$key>(
		LICENSE_PRODUCT_FRAGMENT,
		productFragmentRef,
	);

	const [editProduct] =
		useMutation<licenseProductForm_EditProductMutation>(EDIT_PRODUCT_MUTATION);
	const [editLicenseProduct] = useMutation<licenseProductForm_EditLicenseProductMutation>(
		EDIT_LICENSE_PRODUCT_MUTATION,
	);

	const formik = useFormik<LicenseProductFormState>({
		enableReinitialize: true,
		initialValues: {
			title: product.title,
			isHidden: product.isHidden,
			isTaxFree: product.isTaxFree,
			netPrice: product.netPrice,
			licenseDefinition: product.data.licenseDefinition
				? {
						id: product.data.licenseDefinition.id,
						name: product.data.licenseDefinition.data.name,
				  }
				: undefined,
			licenseDefinitionAmount: product.data.licenseDefinitionAmount ?? 1,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Ein Titel wird benötigt."),
			netPrice: Yup.number().required("Ein Nettopreis wird benötigt."),
			isHidden: Yup.boolean(),
			licenseDefinition: Yup.object()
				.default(undefined)
				.required("Eine Lizenz wird benötigt."),
			licenseDefinitionAmount: Yup.number()
				.min(1, "Produkt muss mindestens eine Lizenz beinhalten")
				.typeError("Anzahl an Lizenzen muss eine Zahl sein"),
		}),
		onSubmit: (values: LicenseProductFormState, { setSubmitting }) => {
			editProduct({
				variables: {
					input: {
						productId: product.id,
						newNetPrice: values.netPrice,
						newTitle: values.title,
						newIsHidden: values.isHidden ?? true,
						newIsTaxFree: values.isTaxFree,
					},
				},
				onCompleted: () => {
					editLicenseProduct({
						variables: {
							input: {
								id: product.id,
								licenseDefinitionId: values.licenseDefinition!.id,
								licenseDefinitionAmount: values.licenseDefinitionAmount,
							},
						},
						onCompleted: () => {
							setSubmitting(false);
						},
					});
				},
			});
		},
	});

	return (
		<>
			<Toast ref={toast} />
			<Suspense fallback={<ProgressSpinner />}>
				<ProductForm formik={formik} handleSubmit={formik.handleSubmit}>
					<ValidatedField<LicenseProductFormState, SelectLicenseDefinitionFieldState>
						required
						name={"licenseDefinition"}
						label="Lizenzdefinition"
						placeholder="Lizenzdefinition auswählen..."
						formikConfig={formik}
						component={(renderConfig) => (
							<SelectLicenseDefinitionField
								{...renderConfig}
								queryFragmentRef={query}
							/>
						)}
					/>
					<ValidatedField<LicenseProductFormState, number>
						name={"licenseDefinitionAmount"}
						label="Anzahl an enthaltener Lizenzen"
						component={DefaultNumberFieldComponent}
						formikConfig={formik}
					/>
				</ProductForm>
			</Suspense>
		</>
	);
};
