import { type AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS as DNDCSS } from "@dnd-kit/utilities";
import { Tooltip } from "@constellation-academy/epic-ui";
import { useContext } from "react";
import { INDENTATION_WIDTH } from "@components/sortable-tree/sortable-tree.consts";
import { TreeSelectionContext } from "@screens/educational-offer/parts/TreeSelectionContext";

import {
	ChildCount,
	Collapse,
	Handle,
	Label,
	RemoveButton,
	TreeItem,
	TreeItemContent,
	Wrapper,
} from "./sortable-tree-item.styles";

type OwnProps = {
	id: string;
	depth: number;
	childCount?: number;
	collapsed?: boolean;
	dragable?: boolean;
	onClick?: () => void;
	onCollapse?: () => void;
	onRemove?: () => void;
	clone?: boolean;
	treeNode: any;
	nodeTemplate: (data: any) => {
		node: React.ReactNode;
		type?: "element" | undefined;
	};
};

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
	isSorting || wasDragging;

export const SortableTreeItem = ({
	id,
	childCount,
	collapsed,
	depth,
	dragable,
	onClick,
	onCollapse,
	onRemove,
	clone,
	treeNode,
	nodeTemplate,
}: OwnProps) => {
	const {
		isDragging,
		attributes,
		setDraggableNodeRef,
		setDroppableNodeRef,
		transform,
		listeners,
		transition,
	} = useSortable({
		id,
		animateLayoutChanges,
	});
	const { selectedNodeId } = useContext(TreeSelectionContext);

	const handleOnClick = () => {
		onClick?.();
	};

	const nodeTemplateConfig = nodeTemplate(treeNode);
	const indentationWidth =
		nodeTemplateConfig.type === "element"
			? depth * INDENTATION_WIDTH + 32
			: depth * INDENTATION_WIDTH;

	return (
		<Wrapper
			ref={setDroppableNodeRef}
			style={{
				paddingLeft: `${indentationWidth}px`,
			}}
			isDragging={isDragging}
		>
			<TreeItem
				className="TreeItem"
				ref={setDraggableNodeRef}
				style={{ transform: DNDCSS.Translate.toString(transform), transition }}
				isDragging={isDragging}
			>
				<TreeItemContent>
					{dragable && nodeTemplateConfig.type !== "element" && (
						<Handle {...attributes} {...listeners}>
							<i className="pi pi-ellipsis-v"></i>
						</Handle>
					)}
					{onCollapse && (
						<Collapse className={collapsed ? "collapsed" : ""} onClick={onCollapse}>
							<i className="pi pi-chevron-down"></i>
						</Collapse>
					)}
					<Label
						onClick={() => {
							if (nodeTemplateConfig.type !== "element") {
								handleOnClick();
							}
						}}
						active={selectedNodeId === treeNode.id}
					>
						{nodeTemplateConfig.node}
					</Label>
				</TreeItemContent>
				{!clone && onRemove && nodeTemplateConfig.type !== "element" && (
					<>
						<RemoveButton onClick={onRemove} id={`remove-${id}`}>
							<i className="pi pi-trash"></i>
						</RemoveButton>
						<Tooltip target={`#remove-${CSS.escape(id)}`} content="Ordner löschen" />
					</>
				)}
				{clone && childCount && childCount > 1 ? (
					<ChildCount>{childCount}</ChildCount>
				) : null}
			</TreeItem>
		</Wrapper>
	);
};
