import { graphql } from "react-relay";

export const SET_CONFIRM_BUTTON_MUTATION = graphql`
	mutation useMessagePageButtonMutations_SetConfirmButtonMutation(
		$input: SetConfirmButtonInPageInput!
		$connections: [ID!]!
	) {
		Admin {
			MessageDefinitions {
				setConfirmButtonInPage(input: $input) {
					messageDefinition @appendEdge(connections: $connections) {
						node {
							id
						}
					}
				}
			}
		}
	}
`;

export const SET_TEXT_COPY_BUTTON_MUTATION = graphql`
	mutation useMessagePageButtonMutations_SetTextCopyButtonMutation(
		$input: SetTextCopyButtonInPageInput!
		$connections: [ID!]!
	) {
		Admin {
			MessageDefinitions {
				setTextCopyButtonInPage(input: $input) {
					messageDefinition @appendEdge(connections: $connections) {
						node {
							id
						}
					}
				}
			}
		}
	}
`;

export const SET_LINK_BUTTON_MUTATION = graphql`
	mutation useMessagePageButtonMutations_SetLinkButtonMutation(
		$input: SetLinkButtonInPageInput!
		$connections: [ID!]!
	) {
		Admin {
			MessageDefinitions {
				setLinkButtonInPage(input: $input) {
					messageDefinition @appendEdge(connections: $connections) {
						node {
							id
						}
					}
				}
			}
		}
	}
`;
