import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment discountCodeTable_QueryFragment on Query
	@refetchable(queryName: "discountCodeTable_Refetch")
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }, id: { type: "ID!" }) {
		node(id: $id) {
			... on DiscountAction {
				...discountCodeEditButton_DiscountActionFragment
				...discountCodeTable_DiscountActionFragment
				discountCodes(first: $first, after: $after)
					@connection(key: "discountCodeTable_discountCodes") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							...discountCodeTable_DiscountCodeFragment
						}
					}
				}
			}
		}
	}
`;

export const DISCOUNT_CODE_FRAGMENT = graphql`
	fragment discountCodeTable_DiscountCodeFragment on DiscountCode @inline {
		id
		code
		createdAt
		numUsages
		...discountCodeDeleteButton_DiscountCodeFragment
		...discountCodeEditButton_DiscountCodeFragment
	}
`;
export const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment discountCodeTable_DiscountActionFragment on DiscountAction {
		id
	}
`;
