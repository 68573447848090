/**
 * @generated SignedSource<<a6c21d08b14c50e51917ff65b13aa928>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductTitleColumn_ProductFragment$data = {
  readonly title: string;
  readonly " $fragmentType": "ProductTitleColumn_ProductFragment";
};
export type ProductTitleColumn_ProductFragment$key = {
  readonly " $data"?: ProductTitleColumn_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductTitleColumn_ProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductTitleColumn_ProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "2f06913a49d68fbea6de753471658f65";

export default node;
