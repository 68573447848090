import { graphql } from "react-relay";

export const TRIGGER_RESEND_ACTIVATION_MUTATION = graphql`
	mutation resendActivationMailButton_TriggerResendActivationMutation(
		$input: TriggerResendActivationInput!
	) {
		Admin {
			Auth {
				triggerResendActivation(input: $input) {
					__typename
				}
			}
		}
	}
`;
