import { H1Span } from "@constellation-academy/epic-ui";
import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { messageDefinitions_MessageDefinitionsQuery } from "@relay/messageDefinitions_MessageDefinitionsQuery.graphql";
import {
	MessageDefinitionsContext,
	MessageDefinitionsContextProvider,
} from "@screens/message-definitions/message-definitions.context";
import { headerClass } from "@screens/message-definitions/message-definitions.styles";
import { MessageDefinitionsTable } from "@screens/message-definitions/parts/message-definitions-table";
import { CreateMessageDefinitionButton } from "@screens/message-definitions/parts/message-definitions-table/create-message-definition-button";
import { MessageDefinitionsTableFilters } from "@screens/message-definitions/parts/message-definitions-table/message-definitions-table-filters";
import { QUERY } from "./message-definitions.graphql";

export const MessageDefinitionsScreen = () => {
	const { filters } = useContext(MessageDefinitionsContext);

	const data = useLazyLoadQuery<messageDefinitions_MessageDefinitionsQuery>(
		QUERY,
		{
			filterByTextOpt: filters.filterByTextOpt,
		},
		{ fetchPolicy: "store-and-network" },
	);

	return (
		<MessageDefinitionsContextProvider>
			<BaseScreen>
				<div className={headerClass}>
					<H1Span>Nachrichten-Vorlagen</H1Span>
					<CreateMessageDefinitionButton />
				</div>
				<MessageDefinitionsTableFilters />
				<MessageDefinitionsTable messageDefinitionsFragmentRef={data} />
			</BaseScreen>
		</MessageDefinitionsContextProvider>
	);
};
