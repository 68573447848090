import { EpicIcons } from "@constellation-academy/epic-ui";
import { Dialog } from "primereact/dialog";
import { type DropdownChangeEvent } from "primereact/dropdown";
import React, { useState } from "react";
import { Button, ButtonVariant } from "@components/button";
import { P1Span } from "@themes/font-tags";
import { ConfigDropdown, type ConfigDropdownOptions } from "./ConfigDropdown";
import { CONFIGS_TRANSLATIONS } from "../../../translations/educational-offer";

export function ConfigItem<ConfigType, ConfigOptionType>(props: {
	configType: ConfigType;
	isPresentational: boolean;
	configOptions?: Array<ConfigDropdownOptions<ConfigOptionType>>;
	canEdit: boolean;
	editDialog?: (props: any) => React.ReactElement;
	onChange?: (e: DropdownChangeEvent) => void;
	onDelete?: () => void;
	isLoading?: boolean;
}) {
	const [displayMaximizable, setDisplayMaximizable] = useState(false);

	const showEditDialog = () => {
		setDisplayMaximizable(true);
	};

	const onHide = () => {
		setDisplayMaximizable(false);
	};

	// @ts-expect-error
	const configOption = props.configOptions?.find((e) => e.value === props.configType);

	return (
		<>
			<div className={"flex align-items-center gap-2"}>
				{props.isPresentational && configOption ? (
					<P1Span className="width-full flex-1 block">
						{CONFIGS_TRANSLATIONS[configOption.value]}
					</P1Span>
				) : (
					<ConfigDropdown
						configOptions={props.configOptions}
						onChange={props.onChange}
						isPresentational={props.isPresentational}
						dropdownIcon={props.isPresentational ? "" : "pi pi-chevron-down"}
						value={props.configType}
						isLoading={props.isLoading}
					/>
				)}

				{props.isPresentational && (
					<Button
						disabled={props.isLoading}
						variant={ButtonVariant.Error}
						icon={EpicIcons.TRASH}
						onClick={() => {
							props.onDelete!();
						}}
					/>
				)}

				{props.canEdit && (
					<Button
						icon={EpicIcons.PENCIL}
						onClick={() => {
							showEditDialog();
						}}
					/>
				)}
			</div>

			{props.canEdit && (
				<Dialog
					header="Bearbeiten"
					visible={displayMaximizable}
					maximizable
					modal
					style={{ width: "50vw" }}
					onHide={() => {
						onHide();
					}}
				>
					{props.editDialog!({ onBack: onHide })}
				</Dialog>
			)}
		</>
	);
}
