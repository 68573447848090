/**
 * @generated SignedSource<<fa45cb5b18bc75fecbdd6f04b995ef2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsageLimitationInput_Query$variables = {
  accountIds: ReadonlyArray<string>;
  productIds: ReadonlyArray<string>;
};
export type UsageLimitationInput_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountsSelectWithId_QueryFragment" | "ProductsSelect_QueryFragment">;
};
export type UsageLimitationInput_Query = {
  response: UsageLimitationInput_Query$data;
  variables: UsageLimitationInput_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "accountIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "inKinds",
    "value": [
      "License"
    ]
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsageLimitationInput_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "AccountsSelectWithId_QueryFragment"
      },
      {
        "args": [
          {
            "kind": "Variable",
            "name": "productIds",
            "variableName": "productIds"
          }
        ],
        "kind": "FragmentSpread",
        "name": "ProductsSelect_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsageLimitationInput_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AccountConnection",
                "kind": "LinkedField",
                "name": "SelectAccounts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "nameOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "AccountsSelectWithId_SelectAccounts",
                "kind": "LinkedHandle",
                "name": "SelectAccounts"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "ProductsConnection",
                "kind": "LinkedField",
                "name": "SearchProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v7/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": "SearchProducts(inKinds:[\"License\"])"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": [
                  "titleOpt",
                  "inKinds"
                ],
                "handle": "connection",
                "key": "ProductsSelect_SearchProducts",
                "kind": "LinkedHandle",
                "name": "SearchProducts"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "productIds"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cfb267c79493e0132d616ca4298197d5",
    "id": null,
    "metadata": {},
    "name": "UsageLimitationInput_Query",
    "operationKind": "query",
    "text": "query UsageLimitationInput_Query(\n  $accountIds: [ID!]!\n  $productIds: [ID!]!\n) {\n  ...AccountsSelectWithId_QueryFragment_3E5dtq\n  ...ProductsSelect_QueryFragment_35fFzS\n}\n\nfragment AccountsSelectWithId_QueryFragment_3E5dtq on Query {\n  Admin {\n    Auth {\n      SelectAccounts(ids: $accountIds) {\n        edges {\n          cursor\n          node {\n            id\n            name\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment ProductsSelect_QueryFragment_35fFzS on Query {\n  Admin {\n    Billing {\n      SearchProducts(inKinds: [License]) {\n        edges {\n          cursor\n          node {\n            id\n            title\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n  nodes(ids: $productIds) {\n    __typename\n    ... on Product {\n      id\n      title\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b83ed99646da4852103984761ff937b1";

export default node;
