import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	gap: "8",
	marginBottom: "8",
	alignSelf: "center",
});

export const headerWrapperClass = flex({
	direction: "row",
	justify: "between",
});
