import { graphql } from "react-relay";

export const QUERY = graphql`
	fragment selectAcademiesTagField_QueryFragment on Query
	@refetchable(queryName: "selectAcademiesTagField_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		name: { type: "String" }
		before: { type: "String" }
		last: { type: "Int" }
		isTopic: { type: "Boolean" }
		isClickable: { type: "Boolean" }
		isRoot: { type: "Boolean" }
	) {
		Admin {
			AcademiesTag {
				SelectTags(
					name: $name
					before: $before
					first: $first
					after: $after
					last: $last
					isTopic: $isTopic
					isClickable: $isClickable
					isRoot: $isRoot
				) @connection(key: "selectAcadamiesTagField_SelectTags") {
					edges {
						node {
							...selectAcademiesTagField_AcademiesTagFragment
						}
					}
				}
			}
		}
	}
`;

export const ACADEMIES_TAG_FRAGMENT = graphql`
	fragment selectAcademiesTagField_AcademiesTagFragment on AcademiesTag @inline {
		id
		data {
			name
			isClickable
			isTopic
		}
	}
`;
