import {
	DEFAULT_COMPONENTS_STYLING,
	DefaultIconFactory,
	DefaultStyledTooltipComponents,
	type TkComponentStyles,
} from "@constellation-academy/framework-react-components";
import { Button } from "primereact/button";
import styled from "styled-components";
import tw from "twin.macro";
import { tkaAdminDialogStyles } from "@corestyle/component-theme/dialog";
import { tkaAdminFieldStyles } from "@corestyle/component-theme/field";
import { TkaAdminFormStyles } from "@corestyle/component-theme/form";
import { tkaAdminInputCalendarStyles } from "@corestyle/component-theme/input-calendar";
import { tkaAdminInputChipsStyles } from "@corestyle/component-theme/input-colorpicker";
import { tkaAdminInputDropdownStyles } from "@corestyle/component-theme/input-dropdown";
import { tkaAdminInputMultiSelectStyles } from "@corestyle/component-theme/input-multiselect";
import { tkaAdminInputNumberStyles } from "@corestyle/component-theme/input-number";
import { tkaAdminInputPasswordStyles } from "@corestyle/component-theme/input-password";
import { tkaAdminInputSwitchStyles } from "@corestyle/component-theme/input-switch";
import { tkaAdminInputTextStyles } from "@corestyle/component-theme/input-text";
import { tkaAdminInputTextAreaStyles } from "@corestyle/component-theme/input-textarea";
import { tkaAdminTableStyles } from "@corestyle/component-theme/table";
import { HorizontalElementSpacingWrapper } from "./default-spacings.component";

export const TkaAdminTheme: TkComponentStyles = {
	...DEFAULT_COMPONENTS_STYLING,
	...tkaAdminFieldStyles,
	...TkaAdminFormStyles,
	...tkaAdminTableStyles,
	...tkaAdminDialogStyles,
	...tkaAdminInputCalendarStyles,
	...tkaAdminInputChipsStyles,
	...tkaAdminInputChipsStyles,
	...tkaAdminInputDropdownStyles,
	...tkaAdminInputMultiSelectStyles,
	...tkaAdminInputNumberStyles,
	...tkaAdminInputPasswordStyles,
	...tkaAdminInputSwitchStyles,
	...tkaAdminInputTextStyles,
	...tkaAdminInputTextAreaStyles,
	HorizontalElementSpacingWrapper,
	FormDialog: {
		...DEFAULT_COMPONENTS_STYLING.FormDialog,
		FormDialogButtonContainer: styled.div`
			display: flex;
			justify-content: flex-end;
		`,
		SaveText: "Speichern",
		CancelText: "Abbrechen",
	},
	DialogLogic: {
		...DEFAULT_COMPONENTS_STYLING.DialogLogic,
		ButtonContainer: styled.div`
			display: flex;
			justify-content: flex-end;
		`,
	},
	DeleteButton: {
		...DEFAULT_COMPONENTS_STYLING.DeleteButton,
		Icon: "pi pi-trash",
		Title: (selectedIdsLength, singularName, pluralName) =>
			selectedIdsLength === 1 ? `${singularName} löschen` : `${pluralName} löschen`,
		Content: (selectedIdsLength, singularName, pluralName) =>
			`Möchten Sie ${
				selectedIdsLength === 1 ? `diesen ${singularName}` : `diese ${pluralName}`
			} wirklich löschen? Dies kann nicht rückgängig gemacht werden.`,
	},
	Button: {
		StyledButton: styled(Button as any)`
			border-radius: 5px;
		` as any,
	},
	Pagination: {
		Label: "Mehr laden",
		PaginationContainer: tw.div`tw-flex tw-justify-center tw-items-center`,
	},
	Icon: {
		IconFactory: DefaultIconFactory,
	},
	Tooltip: {
		...DefaultStyledTooltipComponents.Tooltip,
	},
};
