/**
 * @generated SignedSource<<3db4a9f78b0be68494a011cb20a0cfad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment$data = {
  readonly id: string;
  readonly isDefault: boolean;
  readonly " $fragmentType": "deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment";
};
export type deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment$key = {
  readonly " $data"?: deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDefault",
      "storageKey": null
    }
  ],
  "type": "Vimeo_VimeoVideoSource",
  "abstractKey": null
};

(node as any).hash = "a53a2f7b535c805058b6e6f02d191c75";

export default node;
