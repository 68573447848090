/**
 * @generated SignedSource<<6a53f7f5b3ec764cbd2423c2895a79b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type discountCodeDownloadCsvButton_DiscountActionFragment$data = {
  readonly discountCodesCSV: string;
  readonly " $fragmentType": "discountCodeDownloadCsvButton_DiscountActionFragment";
};
export type discountCodeDownloadCsvButton_DiscountActionFragment$key = {
  readonly " $data"?: discountCodeDownloadCsvButton_DiscountActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"discountCodeDownloadCsvButton_DiscountActionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "discountCodeDownloadCsvButton_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountCodesCSV",
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};

(node as any).hash = "888d263709392f5380b2dea76d667ee6";

export default node;
