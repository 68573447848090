import { graphql, useLazyLoadQuery, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Paths } from "@routes/paths";
import { type FileIsUsedInNodeAttachmentsDialog_DeleteMutation } from "../../__generated__/FileIsUsedInNodeAttachmentsDialog_DeleteMutation.graphql";
import { type FileIsUsedInNodeAttachmentsDialog_Query } from "../../__generated__/FileIsUsedInNodeAttachmentsDialog_Query.graphql";

const QUERY = graphql`
	query FileIsUsedInNodeAttachmentsDialog_Query($filterByAttachmentId: ID) {
		Admin {
			Tree {
				GetRootNodes(filterByAttachmentId: $filterByAttachmentId) {
					edges {
						node {
							id
							structureDefinition {
								title
							}
							typeDefinition {
								definitionType
							}
							attachments {
								... on DefaultFileAttachment {
									file {
										id
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

const DELETE_MUTATION = graphql`
	mutation FileIsUsedInNodeAttachmentsDialog_DeleteMutation(
		$input: DeleteFileInput!
		$connections: [ID!]!
	) {
		Admin {
			Files {
				deleteFile(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

type OwnProps = {
	id: string;
	setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
	showDialog: boolean;
	connection: string;
};

export const FileIsUsedInNodeAttachmentsDialog = ({
	id,
	setShowDialog,
	showDialog,
	connection,
}: OwnProps) => {
	const query = useLazyLoadQuery<FileIsUsedInNodeAttachmentsDialog_Query>(
		QUERY,
		{ filterByAttachmentId: id },
		{ fetchPolicy: "network-only" },
	);

	const [deleteFile, isDeleting] =
		useMutation<FileIsUsedInNodeAttachmentsDialog_DeleteMutation>(DELETE_MUTATION);

	const nodes = query.Admin.Tree.GetRootNodes.edges?.map((l) => l?.node);

	const navigate = useNavigate();

	return (
		<div>
			{showDialog && (
				<DialogContainer
					header="Möchten Sie diese Datei wirklich löschen? Das kann nicht rückgängig gemacht werden."
					visible={showDialog}
					position="center"
					dismissableMask
					modal
					contentClassName="flex"
					onHide={() => {
						setShowDialog(false);
					}}
					footer={() => {
						return (
							<div className=" flex justify-content-end">
								<Button
									label="Zurück"
									className="mr-2 p-button-secondary"
									onClick={() => {
										setShowDialog(false);
									}}
								/>

								{nodes && nodes.length === 0 && (
									<Button
										disabled={isDeleting}
										type="button"
										onClick={() => {
											deleteFile({
												variables: {
													input: {
														ids: [id],
													},
													connections: [connection],
												},
											});

											setShowDialog(false);
										}}
										label={"Löschen"}
									/>
								)}
							</div>
						);
					}}
				>
					<div className="flex flex-column w-full">
						<div className=" flex flex-column">
							{nodes?.length ? (
								<div className="w-12 mb-4">
									<p className="mt-0">
										Diese Datei wird in diesen Module verwendet und kann nicht
										gelöscht werden:
									</p>

									<StyledDataTable
										value={nodes as any}
										stripedRows
										responsiveLayout="scroll"
									>
										<Column
											field="structureDefinition.title"
											headerStyle={{
												padding: 0,
											}}
											bodyStyle={{
												padding: 0,
											}}
										></Column>
										<Column
											field={"id"}
											align={"right"}
											body={(data) => (
												<Button
													className="ml-2"
													type="button"
													icon={"pi pi-folder-open"}
													onClick={() => {
														navigate(
															Paths.educationalOffer.withId(data.id)
																.overview.path,
														);
													}}
												/>
											)}
										></Column>
									</StyledDataTable>
								</div>
							) : (
								<p>Diese Datei wird in keinem Modul als Anhang verwendet</p>
							)}
						</div>
					</div>
				</DialogContainer>
			)}
		</div>
	);
};

const StyledDataTable = styled(DataTable)`
	thead {
		display: none;
	}
`;

const DialogContainer = styled(Dialog)`
	width: 30%;
`;
