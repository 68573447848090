/**
 * @generated SignedSource<<2c73d5c13fa319780f3d1c81327b0656>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DiscountTypeKind = "System" | "User";
export type UsageLimitationKind = "UsageLimited" | "usageUnlimited";
export type ValidUntilKind = "untilDateTime" | "untilForever";
import { FragmentRefs } from "relay-runtime";
export type discountActionForm_DiscountActionFragment$data = {
  readonly discountType: {
    readonly kind: DiscountTypeKind;
  };
  readonly id: string;
  readonly percent: number;
  readonly title: string;
  readonly usageLimitation: {
    readonly kind: UsageLimitationKind;
    readonly maxAmountOfUsages?: {
      readonly max?: number;
    };
    readonly maxAmountOfUsagesPerAccount?: {
      readonly max?: number;
    };
    readonly onlyForAccountIds?: ReadonlyArray<string>;
    readonly onlyForProductIds?: ReadonlyArray<string>;
  };
  readonly validUntil: {
    readonly dateTime?: string;
    readonly kind: ValidUntilKind;
  };
  readonly " $fragmentType": "discountActionForm_DiscountActionFragment";
};
export type discountActionForm_DiscountActionFragment$key = {
  readonly " $data"?: discountActionForm_DiscountActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"discountActionForm_DiscountActionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "max",
        "storageKey": null
      }
    ],
    "type": "AmountLimited",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "discountActionForm_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "validUntil",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dateTime",
              "storageKey": null
            }
          ],
          "type": "ValidUntilDateTime",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "discountType",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "usageLimitation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "maxAmountOfUsages",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "maxAmountOfUsagesPerAccount",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onlyForAccountIds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onlyForProductIds",
              "storageKey": null
            }
          ],
          "type": "UsageLimited",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};
})();

(node as any).hash = "a405e1b41a553596685cab4c954fa68a";

export default node;
