import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useFragment } from "react-relay";

import { PageItem } from "@features/pages-editor/pages-editor.types";

import { useTextWithPagesElementPageMutations } from "@hooks/use-text-with-pages-element-page-mutations";
import {
	textWithPagesElementPagesEditor_PagesFragment$data,
	textWithPagesElementPagesEditor_PagesFragment$key,
} from "@relay/textWithPagesElementPagesEditor_PagesFragment.graphql";
import { TextWithPagesElementPageFormState } from "@screens/educational-offer/parts/text-with-pages-element-page-form/text-with-pages-element-page-form.types";
import { TextWithPagesElementPageFormDialog } from "@screens/educational-offer/parts/text-with-pages-element-page-form-dialog";
import { TEXT_WITH_PAGES_ELEMENT_FRAGMENT } from "@screens/educational-offer/parts/text-with-pages-element-pages-editor/text-with-pages-element-pages-editor.graphql";
import { TextWithPagesElementPagesEditorProps } from "@screens/educational-offer/parts/text-with-pages-element-pages-editor/text-with-pages-element-pages-editor.types";

import { PagesEditor } from "@features/pages-editor";

export const TextWithPagesElementPagesEditor = ({
	textWithPagesElementFragmentRef,
}: TextWithPagesElementPagesEditorProps) => {
	const [isPageFormDialogVisible, setIsPageFormDialogVisible] = useState(false);
	const [selectedPage, setSelectedPage] = useState<
		textWithPagesElementPagesEditor_PagesFragment$data["pages"][number] | null
	>(null);

	const toast = useRef<Toast>(null);
	const element = useFragment<textWithPagesElementPagesEditor_PagesFragment$key>(
		TEXT_WITH_PAGES_ELEMENT_FRAGMENT,
		textWithPagesElementFragmentRef ?? null,
	);

	const pages = element?.pages.map((page) => page) || [];

	const { handleOnCreatePage, handleOnEditPage, handleDeletePage, handleRearrangePages } =
		useTextWithPagesElementPageMutations({
			textWithPagesElementId: element?.id,
			toast,
		});

	const formattedPages: Array<PageItem> = pages.map((page) => {
		return {
			title: page.internalTitle,
			...page,
		};
	});

	const handlePageFormOnSubmit = (pageValues: TextWithPagesElementPageFormState) => {
		selectedPage
			? handleOnEditPage(pageValues, selectedPage?.id)
			: handleOnCreatePage(pageValues);
		setIsPageFormDialogVisible(false);
		setSelectedPage(null);
	};

	const handleOnShowCreatePage = () => {
		setSelectedPage(null);
		setIsPageFormDialogVisible(true);
	};

	const handleOnShowEditPage = (pageId: string) => {
		const page = pages.find((page) => page.id === pageId);
		if (!page) {
			return;
		}
		setSelectedPage(page);
		setIsPageFormDialogVisible(true);
	};

	const handleOnHidePageFormDialog = () => {
		setSelectedPage(null);
		setIsPageFormDialogVisible(false);
	};

	return (
		<>
			<Toast ref={toast} />
			<TextWithPagesElementPageFormDialog
				isVisible={isPageFormDialogVisible}
				isCreating={selectedPage === null}
				textWithPagesElementPageFragmentRef={selectedPage}
				onSubmit={handlePageFormOnSubmit}
				onHide={handleOnHidePageFormDialog}
			/>
			<PagesEditor
				pages={formattedPages}
				onShowCreatePage={handleOnShowCreatePage}
				onShowEditPage={handleOnShowEditPage}
				onDeletePage={handleDeletePage}
				onRearrangePages={handleRearrangePages}
			/>
		</>
	);
};
