/**
 * @generated SignedSource<<5e0dbb6264b321c6759ec8aa71b73d96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Persolog_ClassType = "D" | "G" | "I" | "S";
import { FragmentRefs } from "relay-runtime";
export type persologElementQuestionForm_QuestionFragment$data = {
  readonly id: string;
  readonly questionClasses: ReadonlyArray<{
    readonly classType: Persolog_ClassType;
    readonly text: string;
    readonly " $fragmentSpreads": FragmentRefs<"persologElementQuestionClassForm_PersologQuestionClassFragment">;
  }>;
  readonly title: string;
  readonly " $fragmentType": "persologElementQuestionForm_QuestionFragment";
};
export type persologElementQuestionForm_QuestionFragment$key = {
  readonly " $data"?: persologElementQuestionForm_QuestionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"persologElementQuestionForm_QuestionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "persologElementQuestionForm_QuestionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Persolog_QuestionClass",
      "kind": "LinkedField",
      "name": "questionClasses",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "classType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "persologElementQuestionClassForm_PersologQuestionClassFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Persolog_Question",
  "abstractKey": null
};

(node as any).hash = "419ac496ba2acb1980b3e2373ebaec59";

export default node;
