import { E_LEARNING_I18N_KEY, E_LEARNING_I18N_MAP } from "./tree";

export const ELEMENT_TYPE_TRANSLATIONS: Record<string, string> = {
	text: "Text",
	file: E_LEARNING_I18N_MAP(E_LEARNING_I18N_KEY.file),
	video: "Video",
	multipleChoice: E_LEARNING_I18N_MAP(E_LEARNING_I18N_KEY.multipleChoice),
	podcast: "Podcast",
	markMistakes: "Fehler markieren",
	podcastWithTimestamp: "Podcast mit Zeitintervallen",
	matrix: "Fragen Matrix",
	enhancedText: "Erweiterter Text",
	order: "Reihenfolge",
	clozeText: "Lückentext",
	dynamicVocab: "Vokabeltrainer",
	textWithPages: "Text in Seiten",
	persolog: "Persolog",
	reminder: "Erinnerung",
};
