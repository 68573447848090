import { graphql } from "react-relay";

export const ordersPerPage = 20;

export const ORDERS_QUERY = graphql`
	query ordersScreen_Query($username: String, $email: String, $numItemsPerPage: Int!) {
		...ordersTable_OrdersFragment
			@arguments(username: $username, email: $email, first: $numItemsPerPage, after: null)
	}
`;
