import { type FormikProps, useFormik } from "formik";
import React, { useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { SelectMultiplePublishedRootField } from "@features/educational-offer/select-multiple-published-root-field";
import { colorWarning120Class } from "@themes/color-classes";
import { H1Span, P2Span } from "@themes/font-tags";
import { flexLicenseWarningClass } from "./flex-license-definition-form.styles";
import {
	FlexLicenseDefinitionFormSchema,
	type FlexLicenseDefinitionFormState,
} from "./flex-license-definition-form.types";
import { DefaultTextFieldComponent } from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";
import { type DefaultFormProps } from "../../../screens/offers/parts/common";
import { addEditedFormToEditedFormsArray } from "../../../store/slices/CoreSlice";
import { type SelectPublishedRootFieldState } from "../../educational-offer/select-published-root-field/select-published-root-field.types";

export const FlexLicenseDefinitionForm = React.forwardRef<
	FormikProps<FlexLicenseDefinitionFormState>,
	DefaultFormProps<FlexLicenseDefinitionFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<FlexLicenseDefinitionFormSchema>({
		initialValues: initialState ?? {
			name: "",
			includedNodeIds: [],
			excludedNodeIds: [],
		},
		enableReinitialize: true,
		validationSchema: toFormikValidationSchema(FlexLicenseDefinitionFormSchema),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));
	const formId = "EditFlexLicenseDefinitionForm";
	const dispatch = useDispatch();

	return (
		<form onSubmit={formik.handleSubmit} className={"p-fluid w-30rem"}>
			<ValidatedField<FlexLicenseDefinitionFormSchema, string>
				name={"name"}
				label={"Name"}
				placeholder="Namen eingeben..."
				component={DefaultTextFieldComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
			{(!formik.values.includedNodeIds || formik.values.includedNodeIds?.length === 0) && (
				<div className={flexLicenseWarningClass}>
					<H1Span className={colorWarning120Class}>Achtung</H1Span>
					<P2Span className={colorWarning120Class}>
						Wenn du keine Einschlüsse wählst, wird die Lizenzdefinition automatisch auf
						alle Weiterbildungen angewendet.
					</P2Span>
				</div>
			)}
			<ValidatedField<FlexLicenseDefinitionFormSchema, SelectPublishedRootFieldState>
				name={"includedNodeIds"}
				label={"Einschlüsse"}
				placeholder="Weiterbildung auswählen..."
				component={SelectMultiplePublishedRootField}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
			<ValidatedField<FlexLicenseDefinitionFormSchema, SelectPublishedRootFieldState>
				name={"excludedNodeIds"}
				label={"Ausschlüsse"}
				placeholder="Weiterbildung auswählen..."
				component={SelectMultiplePublishedRootField}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
		</form>
	);
});
FlexLicenseDefinitionForm.displayName = "FlexLicenseDefinitionForm";
