import { graphql } from "react-relay";

export const EDIT_EXTERNAL_LICENSE_MUTATION = graphql`
	mutation editExternalLicenseForm_EditExternalLicenseMutation(
		$input: EditExternalLicenseInput!
	) {
		Admin {
			Billing {
				editExternalLicense(input: $input) {
					externalLicense {
						id
						data {
							name
						}
						...editExternalLicenseForm_ExternalLicenseFragment
					}
				}
			}
		}
	}
`;

export const EXTERNAL_LICENSE_FRAGMENT = graphql`
	fragment editExternalLicenseForm_ExternalLicenseFragment on ExternalLicense {
		id
		data {
			name
			code
		}
		pool {
			id
			data {
				validNumDaysAfterIssuing
				usageInformation
			}
		}
		issuingInfo {
			hasBeenIssued
		}
	}
`;
