/**
 * @generated SignedSource<<581e2d6949955b174ac457769904bb41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type discountCodeTable_DiscountCodeFragment$data = {
  readonly code: string;
  readonly createdAt: string;
  readonly id: string;
  readonly numUsages: number;
  readonly " $fragmentSpreads": FragmentRefs<"discountCodeDeleteButton_DiscountCodeFragment" | "discountCodeEditButton_DiscountCodeFragment">;
  readonly " $fragmentType": "discountCodeTable_DiscountCodeFragment";
};
export type discountCodeTable_DiscountCodeFragment$key = {
  readonly " $data"?: discountCodeTable_DiscountCodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"discountCodeTable_DiscountCodeFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "discountCodeTable_DiscountCodeFragment"
};

(node as any).hash = "3cdda7bd8358268ae9cbd0297227ab90";

export default node;
