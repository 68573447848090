import { graphql } from "react-relay";

export const IN_APP_NOTIFICATION_FRAGMENT = graphql`
	fragment inAppNotificationForm_InAppNotificationFragment on MessageDefinition {
		inAppNotification {
			title
			content
		}
	}
`;
