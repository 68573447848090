/**
 * @generated SignedSource<<b4937e7719fe8d9077e3b0922e33d572>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AddExcludeTreeUnlockedDispatchConditionInput = {
  clientMutationId?: string | null | undefined;
  messageDispatchId: string;
  rootNodeIds: ReadonlyArray<string>;
};
export type useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation$variables = {
  input: AddExcludeTreeUnlockedDispatchConditionInput;
};
export type useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation$data = {
  readonly Admin: {
    readonly Message: {
      readonly addExcludeTreeUnlockedDispatchCondition: {
        readonly clientMutationId: string | null | undefined;
      } | null | undefined;
    };
  };
};
export type useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation = {
  response: useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation$data;
  variables: useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Message",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "AddExcludeTreeUnlockedDispatchConditionPayload",
            "kind": "LinkedField",
            "name": "addExcludeTreeUnlockedDispatchCondition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e47267ea98b35bea5e9899634c65a526",
    "id": null,
    "metadata": {},
    "name": "useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation",
    "operationKind": "mutation",
    "text": "mutation useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation(\n  $input: AddExcludeTreeUnlockedDispatchConditionInput!\n) {\n  Admin {\n    Message {\n      addExcludeTreeUnlockedDispatchCondition(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb01646e6208827958a924189201e653";

export default node;
