import { SelectComplexDataField } from "@components/select-complex-data-field";
import {
	SelectSingleAccountFieldProps,
	SelectSingleAccountFieldState,
} from "@features/accounts/select-single-account-field/select-single-account-field.types";
import { SearchableAccountTable } from "../searchable-account-table";

export const SelectSingleAccountField = ({ ...props }: SelectSingleAccountFieldProps) => {
	return (
		<SelectComplexDataField<SelectSingleAccountFieldState>
			valueField="name"
			valuePlaceholder="Keine Konto ausgewählt"
			actionButtonLabel="Konto auswählen"
			headerLabel="Wähle ein Konto aus"
			{...props}
		>
			<SearchableAccountTable selection={props.fieldValue} onChange={props.updateField} />
		</SelectComplexDataField>
	);
};
