/**
 * @generated SignedSource<<ffcdaeb7e9d50d0ff2f52bd4d04376a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type UnlockInfoKind = "Demo" | "ErrorOccurred" | "FullAccess" | "WallNotFound";
import { FragmentRefs } from "relay-runtime";
export type userRootNodeItem_LearnOpportunityV2Fragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly extension?: {
      readonly unlockInfo?: {
        readonly firstContentCoordinatesIndexPath?: ReadonlyArray<number>;
        readonly firstContentId?: string;
        readonly kind: UnlockInfoKind;
      };
    };
    readonly title: string;
  };
  readonly " $fragmentType": "userRootNodeItem_LearnOpportunityV2Fragment";
};
export type userRootNodeItem_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: userRootNodeItem_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userRootNodeItem_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userRootNodeItem_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "unlockInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "kind",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "firstContentId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "firstContentCoordinatesIndexPath",
                              "storageKey": null
                            }
                          ],
                          "type": "UnlockInfoDemo",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "PublishedRootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "e9b8e04d2b1462aa65f5b14dda7a6223";

export default node;
