import { graphql } from "react-relay";

export const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment discountCodeCreateRandom_DiscountActionFragment on DiscountAction {
		id
	}
`;

export const CREATE_RANDOM_DISCOUNT_CODES_MUTATION = graphql`
	mutation discountCodeCreateRandom_CreateMutation(
		$input: CreateRandomDiscountCodesInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createRandomDiscountCodes(input: $input) {
					connection {
						edges @prependEdge(connections: $connections) {
							node {
								...discountCodeTable_DiscountCodeFragment
							}
						}
					}
				}
			}
		}
	}
`;
