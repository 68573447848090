import { graphql } from "react-relay";

export const QUERY = graphql`
	query emailTemplateEdit_Query($id: ID!) {
		node(id: $id) {
			... on PersonalizedEmailTemplate {
				...emailTemplateEdit_EmailTemplateFragment
			}
		}
	}
`;

export const EMAIL_TEMPLATE_FRAGMENT = graphql`
	fragment emailTemplateEdit_EmailTemplateFragment on PersonalizedEmailTemplate {
		id
		template {
			key
			subject
			previewText
			body
			variables
		}
	}
`;

export const UPDATE_PERSONALIZED_EMAIL_TEMPLATE_MUTATION = graphql`
	mutation emailTemplateEdit_UpdatePersonalizedEmailTemplateMutation(
		$input: UpdatePersonalizedEmailTemplateInput!
	) {
		Admin {
			Email {
				updatePersonalizedEmailTemplate(input: $input) {
					data {
						node {
							...emailTemplateEdit_EmailTemplateFragment
						}
					}
				}
			}
		}
	}
`;
