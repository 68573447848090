import { graphql } from "react-relay";

export const PRIVATE_BILLING_DETAILS_FRAGMENT = graphql`
	fragment privateBillingDetailsForm_Billing_CustomerDetailsFragment on Billing_CustomerDetails {
		invoiceEmail
		houseNumber
		salutation
		postalCode
		firstName
		lastName
		country
		street
		city
		data {
			... on Billing_PrivateCustomerDetails {
				dateOfBirth
				invoiceAddress {
					firstName
					lastName
					postalCode
					city
					country
					houseNumber
					street
					companyDetails
					companyName
				}
			}
		}
	}
`;
