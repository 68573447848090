import { graphql } from "react-relay";

export const ADD_USER_IN_ACCOUNT_MUTATION = graphql`
	mutation registerUser_CreateUserInAccountMutation(
		$input: CreateUserInAccountInput!
		$connections: [ID!]!
	) {
		Admin {
			Auth {
				createUserInAccount(input: $input) {
					clientMutationId
					userInAccount @appendEdge(connections: $connections) {
						node {
							user {
								id
								name
								email
								activated
								groupAssociations {
									account {
										name
									}
									group {
										id
										name
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
