import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense } from "react";
import { BaseScreen } from "@components/base-screen";
import { GenerateCartLinkForm } from "./parts/generate-cart-link-form";

export const GenerateCartLinkScreen = () => {
	return (
		<BaseScreen title="Warenkorblink Generator">
			<Suspense fallback={<ProgressSpinner />}>
				<GenerateCartLinkForm />
			</Suspense>
		</BaseScreen>
	);
};
