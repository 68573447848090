/**
 * @generated SignedSource<<d44b43fbe8b30ab862a7922ac4681889>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountEditScreen_AccountGroupsQuery$variables = Record<PropertyKey, never>;
export type accountEditScreen_AccountGroupsQuery$data = {
  readonly Admin: {
    readonly Management: {
      readonly AccountGroups: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly name: string;
            readonly " $fragmentSpreads": FragmentRefs<"editAccountGroupsButton_AccountGroupFragment">;
          };
        } | null | undefined> | null | undefined;
      };
    };
  };
};
export type accountEditScreen_AccountGroupsQuery = {
  response: accountEditScreen_AccountGroupsQuery$data;
  variables: accountEditScreen_AccountGroupsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountEditScreen_AccountGroupsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountGroupsConnection",
                "kind": "LinkedField",
                "name": "AccountGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountGroupsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "editAccountGroupsButton_AccountGroupFragment",
                            "selections": [
                              {
                                "kind": "InlineDataFragmentSpread",
                                "name": "editAccountGroupsForm_AccountGroupFragment",
                                "selections": [
                                  (v1/*: any*/),
                                  (v0/*: any*/)
                                ],
                                "args": null,
                                "argumentDefinitions": []
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "accountEditScreen_AccountGroupsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountGroupsConnection",
                "kind": "LinkedField",
                "name": "AccountGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountGroupsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b6a302d31c3e9f2b6db0e14495a93505",
    "id": null,
    "metadata": {},
    "name": "accountEditScreen_AccountGroupsQuery",
    "operationKind": "query",
    "text": "query accountEditScreen_AccountGroupsQuery {\n  Admin {\n    Management {\n      AccountGroups {\n        edges {\n          node {\n            name\n            ...editAccountGroupsButton_AccountGroupFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment editAccountGroupsButton_AccountGroupFragment on AccountGroup {\n  ...editAccountGroupsForm_AccountGroupFragment\n}\n\nfragment editAccountGroupsForm_AccountGroupFragment on AccountGroup {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "68ed62288d9176e67ff634b59d717a9d";

export default node;
