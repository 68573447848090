import { DeleteButton } from "@constellation-academy/framework-react-components";
import { useMutation } from "react-relay";
import { type deleteLicenseButton_DeleteLicenseMutation } from "@relay/deleteLicenseButton_DeleteLicenseMutation.graphql";
import { DELETE_LICENSE_MUTATION } from "./delete-license-button.graphql";
import { type DeleteLicenseButtonProps } from "./delete-license-button.types";

export const DeleteLicenseButton = ({ selectedIds, connectionId }: DeleteLicenseButtonProps) => {
	const [deleteLicense, isDeletingLicense] =
		useMutation<deleteLicenseButton_DeleteLicenseMutation>(DELETE_LICENSE_MUTATION);

	const handleDoDelete = (selectedIds: string[]) => {
		deleteLicense({
			variables: {
				input: {
					ids: selectedIds,
				},
				connections: [connectionId],
			},
		});
	};
	return (
		<DeleteButton
			singularName={"Lizenz"}
			pluralName={"Lizenzen"}
			selectedIds={selectedIds}
			isDeleting={isDeletingLicense}
			doDelete={handleDoDelete}
			hideLabel
		/>
	);
};
