import { EpicIcons } from "@constellation-academy/epic-ui";
import React from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonVariant } from "@components/button";
import { type editCourseLearnableButton_UnpublishedLearnableFragment$key } from "@relay/editCourseLearnableButton_UnpublishedLearnableFragment.graphql";
import { Paths } from "@routes/paths";
import { UNPUBLISHED_LEARNABLE_FRAGMENT } from "./edit-course-learnable-button.graphql";
import { type EditCourseLearnableButtonProps } from "./edit-course-learnable-button.types";

export const EditCourseLearnableButton = ({
	learnableFragmentRef,
}: EditCourseLearnableButtonProps) => {
	const offer = useFragment<editCourseLearnableButton_UnpublishedLearnableFragment$key>(
		UNPUBLISHED_LEARNABLE_FRAGMENT,
		learnableFragmentRef,
	);
	const navigate = useNavigate();
	const handleOnClick = () => {
		if (!offer?.root?.id) return toast.error("Fehlende Root-ID");
		navigate(Paths.educationalOffer.withId(offer.root.id).path);
	};
	return (
		<Button
			variant={ButtonVariant.Default}
			icon={EpicIcons.PENCIL}
			tooltip={"Weiterbildung anpassen"}
			onClick={handleOnClick}
		/>
	);
};
