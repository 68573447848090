import { graphql } from "react-relay";

export const CREATE_MUTATION = graphql`
	mutation createCourseLearnableButton_AcademiesCreateBranchRootMutation(
		$input: CreateBranchRootInput! # $connections: [ID!]!
	) {
		Admin {
			Tree {
				createBranchRoot(input: $input) {
					createdRootNode {
						node {
							id
						}
					}
					tree {
						nodes {
							__typename
						}
					}
				}
			}
		}
	}
`;
