import { EpicIcons, FormDialogButton } from "@constellation-academy/epic-ui";
import { Toast } from "primereact/toast";
import React, { useContext, useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import { ButtonVariant } from "@components/button";
import { editVideoSourceButton_editVimeoVideoSourceMutation } from "@relay/editVideoSourceButton_editVimeoVideoSourceMutation.graphql";
import { editVideoSourceButton_Vimeo_VimeoVideoSourceFragment$key } from "@relay/editVideoSourceButton_Vimeo_VimeoVideoSourceFragment.graphql";
import { VideoSourcesScreenContext } from "@screens/video-sources/video-sources.context";
import {
	EDIT_VIMEO_VIDEO_SOURCE_MUTATION,
	VIMEO_VIMEO_VIDEO_SOURCE_FRAGMENT,
} from "./edit-video-source-button.graphql";
import { EditVideoSourceButtonProps } from "./edit-video-source-button.types";
import { VideoSourceForm } from "../video-source-form/video-source-form.component";
import { VideoSourceFormState } from "../video-source-form/video-source-form.types";

export const EditVideoSourceButton = ({ videoSourceFragmentRef }: EditVideoSourceButtonProps) => {
	const toast = useRef<Toast>(null);
	const { connectionId } = useContext(VideoSourcesScreenContext);

	const videoSource = useFragment<editVideoSourceButton_Vimeo_VimeoVideoSourceFragment$key>(
		VIMEO_VIMEO_VIDEO_SOURCE_FRAGMENT,
		videoSourceFragmentRef,
	);
	const [editVimeoVideoSource, isCreatingVimeoVideoSource] =
		useMutation<editVideoSourceButton_editVimeoVideoSourceMutation>(
			EDIT_VIMEO_VIDEO_SOURCE_MUTATION,
		);

	const handleVideoSourceFormOnSubmit = (onHide: () => void, values: VideoSourceFormState) => {
		editVimeoVideoSource({
			variables: {
				input: {
					id: videoSource.id,
					name: values.name,
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				onHide();
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Video-Quelle gespeichert",
					detail: "Die Video-Quelle wurde erfolgreich aktualisiert",
				});
			},
			onError: () => {
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Video-Quelle gespeichert",
					detail: "Die Video-Quelle wurde nicht aktualisiert",
				});
			},
		});
	};

	return (
		<>
			<Toast ref={toast}></Toast>
			<FormDialogButton<VideoSourceFormState>
				buttonVariant={ButtonVariant.Strong}
				buttonIcon={EpicIcons.PENCIL}
				dialogTitle="Daten zum Bearbeiten der Video-Quelle eingeben"
				disabled={isCreatingVimeoVideoSource}
			>
				{({ formikRef, onHide }) => {
					return (
						<VideoSourceForm
							videoSourceFragmentRef={videoSource}
							ref={formikRef}
							onSubmit={(values) => {
								handleVideoSourceFormOnSubmit(onHide, values);
							}}
						/>
					);
				}}
			</FormDialogButton>
		</>
	);
};
