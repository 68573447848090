import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useContext, useEffect } from "react";
import { useLazyLoadQuery, useMutation, usePaginationFragment } from "react-relay";
import { IdDisplayField } from "@components/id-display-field";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation } from "@relay/licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation.graphql";
import { type licenseDefinitionsTable_LicenseDefinitionsFragment$key } from "@relay/licenseDefinitionsTable_LicenseDefinitionsFragment.graphql";
import { type licenseDefinitionsTable_Query } from "@relay/licenseDefinitionsTable_Query.graphql";
import { type LicenseDefinitionsTable_Refetch } from "@relay/LicenseDefinitionsTable_Refetch.graphql";
import {
	DELETE_LICENSE_DEFINITION_MUTATION,
	LICENSE_DEFINITIONS_FRAGMENT,
	QUERY,
} from "./license-definitions-table.graphql";
import { DataTable } from "../../../components/data-table";
import { LicenseDefinitionsScreenContext } from "../../../screens/license-definitions/license-defintions.context";
import { EditFlexLicenseDefinitionButton } from "../edit-flex-license-definition-button";
import { LicenseDefinitionsTableFilter } from "../license-definitions-table-filter";

export const LicenseDefinitionsTable = () => {
	const { setConnectionId } = useContext(LicenseDefinitionsScreenContext);
	const data = useLazyLoadQuery<licenseDefinitionsTable_Query>(
		QUERY,
		{ first: 20 },
		{ fetchPolicy: "network-only" },
	);

	const {
		hasNext,
		loadNext,
		data: {
			Admin: {
				LicenseDefinition: { SearchLicenseDefinitions },
			},
		},
		refetch,
	} = usePaginationFragment<
		LicenseDefinitionsTable_Refetch,
		licenseDefinitionsTable_LicenseDefinitionsFragment$key
	>(LICENSE_DEFINITIONS_FRAGMENT, data);

	useEffect(() => {
		setConnectionId(SearchLicenseDefinitions.__id);
	}, [SearchLicenseDefinitions.__id]);

	const [deleteLicenseDefinition] =
		useMutation<licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation>(
			DELETE_LICENSE_DEFINITION_MUTATION,
		);

	const licenseDefinitions = SearchLicenseDefinitions.edges;

	const handleDeleteLicenseDefinitionOnClick = (id: string) => {
		confirmDialog({
			header: "Lizenzdefinition entfernen",
			message: "Bist du sicher, dass du dieses Lizenzdefinition entfernen möchtest?",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			accept: () => {
				deleteLicenseDefinition({
					variables: {
						input: {
							licenseDefinitionId: id,
						},
						connections: [SearchLicenseDefinitions.__id],
					},
				});
			},
		});
	};

	const handleLoadMore = () => {
		loadNext(20);
	};

	const canModify = useHasPermissions(["UserInAccountPermission_LicenseAdmin_Modify"]);

	return (
		<>
			<div className="mb-5">
				<DataTable
					emptyMessage={
						<div className="flex justify-content-center align-items-center">
							<div className="mr-2">Es wurden keine Lizenzdefinitionen gefunden.</div>
						</div>
					}
					header={
						<LicenseDefinitionsTableFilter
							refetch={(name?: string) =>
								refetch({
									nameMatchRegex: name,
								})
							}
						/>
					}
					className="mb-3"
					value={licenseDefinitions?.map((edge) => edge!.node!)}
				>
					{(Column) => (
						<>
							<Column
								header="ID"
								style={{ width: "5%" }}
								body={(licenseDefinition) => (
									<IdDisplayField id={licenseDefinition.id} />
								)}
							/>
							<Column header="Name" field="data.name" />
							{canModify && (
								<Column
									header="Aktionen"
									style={{ width: "10%" }}
									body={(licenseDefinition) => {
										return (
											<div>
												{licenseDefinition.data.kind === "Flex" && (
													<EditFlexLicenseDefinitionButton
														licenseDefinitionFragmentRef={
															licenseDefinition
														}
													/>
												)}

												<Button
													className="ml-2"
													onClick={() => {
														handleDeleteLicenseDefinitionOnClick(
															licenseDefinition.id,
														);
													}}
													icon={"pi pi-trash"}
												/>
											</div>
										);
									}}
								/>
							)}
						</>
					)}
				</DataTable>
				{hasNext && (
					<div className="flex justify-content-center align-items-center">
						<Button
							type="button"
							className="p-button-secondary"
							disabled={!hasNext}
							onClick={handleLoadMore}
						>
							Mehr
						</Button>
					</div>
				)}
				<ConfirmDialog />
			</div>
		</>
	);
};
