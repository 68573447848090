import { graphql } from "react-relay";

export const CREATE_REMINDER_ELEMENT_MUTATION = graphql`
	mutation createReminderElementButton_CreateReminderElementMutation(
		$input: CreateReminderElementInput!
	) {
		Admin {
			ElearningV2 {
				createReminderElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;
