/**
 * @generated SignedSource<<27d332115a0907a92a82552661505858>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AsyncElementTaskStatus = "Evaluated" | "Fresh";
export type EvaluateUploadAsyncElementTaskInput = {
  clientMutationId?: string | null | undefined;
  fileId: string;
  taskId: string;
  text?: string | null | undefined;
};
export type uploadTaskEditScreen_EvaluateUploadAsyncElementMutation$variables = {
  input: EvaluateUploadAsyncElementTaskInput;
};
export type uploadTaskEditScreen_EvaluateUploadAsyncElementMutation$data = {
  readonly Admin: {
    readonly AsyncContent: {
      readonly evaluateUploadAsyncElementTask: {
        readonly evaluatedTask: {
          readonly id: string;
          readonly status: AsyncElementTaskStatus;
        };
      } | null | undefined;
    };
  };
};
export type uploadTaskEditScreen_EvaluateUploadAsyncElementMutation = {
  response: uploadTaskEditScreen_EvaluateUploadAsyncElementMutation$data;
  variables: uploadTaskEditScreen_EvaluateUploadAsyncElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AsyncContentAdminMutationSchema",
        "kind": "LinkedField",
        "name": "AsyncContent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "EvaluateUploadAsyncElementTaskPayload",
            "kind": "LinkedField",
            "name": "evaluateUploadAsyncElementTask",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EvaluatedUploadAsyncElementTask",
                "kind": "LinkedField",
                "name": "evaluatedTask",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "uploadTaskEditScreen_EvaluateUploadAsyncElementMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "uploadTaskEditScreen_EvaluateUploadAsyncElementMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "78f65b1875f4c75dfff9021ab4af0a6a",
    "id": null,
    "metadata": {},
    "name": "uploadTaskEditScreen_EvaluateUploadAsyncElementMutation",
    "operationKind": "mutation",
    "text": "mutation uploadTaskEditScreen_EvaluateUploadAsyncElementMutation(\n  $input: EvaluateUploadAsyncElementTaskInput!\n) {\n  Admin {\n    AsyncContent {\n      evaluateUploadAsyncElementTask(input: $input) {\n        evaluatedTask {\n          id\n          status\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "37d283d2d2389c6f1ae22ffbb051fa3c";

export default node;
