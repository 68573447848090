/**
 * @generated SignedSource<<6334bb27680dd4a2e2c072d372bfeb3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messageDefinitionsTable_Refetch$variables = {
  after?: string | null | undefined;
  filterByTextOpt?: string | null | undefined;
  first?: number | null | undefined;
};
export type messageDefinitionsTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"messageDefinitionsTable_QueryFragment">;
};
export type messageDefinitionsTable_Refetch = {
  response: messageDefinitionsTable_Refetch$data;
  variables: messageDefinitionsTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByTextOpt"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "internalTitle",
    "variableName": "filterByTextOpt"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "messageDefinitionsTable_Refetch",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "filterByTextOpt",
            "variableName": "filterByTextOpt"
          },
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "messageDefinitionsTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "messageDefinitionsTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageDefinitionsAdminSchema",
            "kind": "LinkedField",
            "name": "MessageDefinitions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "MessageDefinitionsConnection",
                "kind": "LinkedField",
                "name": "MessageDefinitions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "showUntil",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "internalTitle"
                ],
                "handle": "connection",
                "key": "messageDefinitionsTable_MessageDefinitions",
                "kind": "LinkedHandle",
                "name": "MessageDefinitions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e57e29f026df1083722802d3f14fd9a8",
    "id": null,
    "metadata": {},
    "name": "messageDefinitionsTable_Refetch",
    "operationKind": "query",
    "text": "query messageDefinitionsTable_Refetch(\n  $after: String\n  $filterByTextOpt: String\n  $first: Int\n) {\n  ...messageDefinitionsTable_QueryFragment_3oVomQ\n}\n\nfragment createMessageDispatchButton_MessageDefinitionFragment on MessageDefinition {\n  id\n  internalTitle\n}\n\nfragment messageDefinitionsTable_MessageDefinitionFragment on MessageDefinition {\n  id\n  internalTitle\n  createdAt\n  showUntil\n  ...createMessageDispatchButton_MessageDefinitionFragment\n}\n\nfragment messageDefinitionsTable_QueryFragment_3oVomQ on Query {\n  Admin {\n    MessageDefinitions {\n      MessageDefinitions(first: $first, after: $after, internalTitle: $filterByTextOpt) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            ...messageDefinitionsTable_MessageDefinitionFragment\n            id\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc6c9dd0debe4c1a76d6993c8535cd87";

export default node;
