import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment messageDefinitionsTable_QueryFragment on Query
	@refetchable(queryName: "messageDefinitionsTable_Refetch")
	@argumentDefinitions(
		filterByTextOpt: { type: "String" }
		first: { type: "Int" }
		after: { type: "String" }
	) {
		Admin {
			MessageDefinitions {
				MessageDefinitions(first: $first, after: $after, internalTitle: $filterByTextOpt)
					@connection(key: "messageDefinitionsTable_MessageDefinitions") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							...messageDefinitionsTable_MessageDefinitionFragment
						}
					}
				}
			}
		}
	}
`;

export const MESSAGE_DEFINITION_FRAGMENT = graphql`
	fragment messageDefinitionsTable_MessageDefinitionFragment on MessageDefinition @inline {
		id
		internalTitle
		createdAt
		showUntil
		...createMessageDispatchButton_MessageDefinitionFragment
	}
`;
