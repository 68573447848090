import { graphql, useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { toast } from "react-toastify";
import { type StartPublishingButton_StartPublishingV2Mutation } from "../../../__generated__/StartPublishingButton_StartPublishingV2Mutation.graphql";
import { useDialogLogic } from "../../../hooks/use-dialog-logic/use-dialog-logic.hook";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../translations/tree";
import { EpicIcons } from "@constellation-academy/epic-ui";

const START_PUBLISHING_MUTATION = graphql`
	mutation StartPublishingButton_StartPublishingV2Mutation($input: StartPublishingV2Input!) {
		Admin {
			PublishingV2 {
				startPublishingV2(input: $input) {
					tree {
						...educationalOffer_TreeFragment
					}
				}
			}
		}
	}
`;

type OwnProps = {
	rootNodeId: string;
};

export const StartPublishingButton = ({ rootNodeId }: OwnProps) => {
	const [startPublishing, isStartingPublishing] =
		useMutation<StartPublishingButton_StartPublishingV2Mutation>(START_PUBLISHING_MUTATION);
	const { showDialog, dialogComponent } = useDialogLogic();

	return (
		<>
			{dialogComponent}
			<Button
				disabled={isStartingPublishing}
				icon={EpicIcons.SEND}
				variant={ButtonVariant.Strong}
				label={`${TREE_I18N_MAP(TREE_I18N_KEY.tree)} veröffentlichen`}
				onClick={() => {
					showDialog({
						title: `${TREE_I18N_MAP(TREE_I18N_KEY.tree)} veröffentlichen`,
						content: "Das Veröffentlichen kann nicht rückgängig gemacht werden",
						affirmativeText: "Veröffentlichen",
						negativeText: "Abbrechen",
						dialogCallback: (result) => {
							if (result === "Accept") {
								startPublishing({
									variables: {
										input: {
											rootNodeId,
										},
									},
									onCompleted: () => {
										window.location.reload();
									},
									onError: () => {
										toast.error(
											"Fehler beim Veröffentlichen, der Baum ist nicht valide.",
										);
									},
								});
							}
						},
					});
				}}
			/>
		</>
	);
};
