import { graphql } from "react-relay";
export const GENERATE_CART_LINK_MUTATION = graphql`
	mutation generateCartLinkForm_GenerateCartLinkMutation($input: GenerateAbsoluteCartLinkInput!) {
		Admin {
			Billing {
				generateAbsoluteCartLink(input: $input) {
					link
				}
			}
		}
	}
`;
