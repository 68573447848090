/**
 * @generated SignedSource<<0569aa7b913b3a298c989eacecc291a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditLevelDefinitionInput = {
  clientMutationId?: string | null | undefined;
  id: string;
  newImageOpt?: string | null | undefined;
  newShortDescription: string;
  newText: string;
  newTitle: string;
};
export type levelDefinitionForm_editLevelDefinitionMutation$variables = {
  input: EditLevelDefinitionInput;
};
export type levelDefinitionForm_editLevelDefinitionMutation$data = {
  readonly Admin: {
    readonly Gamification: {
      readonly editLevelDefinition: {
        readonly levelDefinition: {
          readonly " $fragmentSpreads": FragmentRefs<"levelDefinitionForm_LevelDefinitionFragment">;
        };
      } | null | undefined;
    };
  };
};
export type levelDefinitionForm_editLevelDefinitionMutation = {
  response: levelDefinitionForm_editLevelDefinitionMutation$data;
  variables: levelDefinitionForm_editLevelDefinitionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "levelDefinitionForm_editLevelDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GamificationAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Gamification",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditLevelDefinitionPayload",
                "kind": "LinkedField",
                "name": "editLevelDefinition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LevelDefinition",
                    "kind": "LinkedField",
                    "name": "levelDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "levelDefinitionForm_LevelDefinitionFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "levelDefinitionForm_editLevelDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GamificationAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Gamification",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditLevelDefinitionPayload",
                "kind": "LinkedField",
                "name": "editLevelDefinition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LevelDefinition",
                    "kind": "LinkedField",
                    "name": "levelDefinition",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortDescription",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "requiredPoints",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2bea706fd8210fc8efde235e1dd4d5f4",
    "id": null,
    "metadata": {},
    "name": "levelDefinitionForm_editLevelDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation levelDefinitionForm_editLevelDefinitionMutation(\n  $input: EditLevelDefinitionInput!\n) {\n  Admin {\n    Gamification {\n      editLevelDefinition(input: $input) {\n        levelDefinition {\n          ...levelDefinitionForm_LevelDefinitionFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment levelDefinitionForm_LevelDefinitionFragment on LevelDefinition {\n  id\n  title\n  shortDescription\n  text\n  requiredPoints\n  image {\n    id\n    name\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "2308da9def74f80dedbccb6a4ff1a8ae";

export default node;
