import { graphql } from "react-relay";

export const MESSAGE_DISPATCH_FRAGMENT = graphql`
	fragment dispatchConditionDropdown_MessageDispatchFragment on MessageDispatch
	@refetchable(queryName: "dispatchConditionDropdown_Refetch") {
		id
		includes {
			... on AccountDispatchCondition {
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on AllUserDispatchCondition {
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on UserDispatchCondition {
				userIds
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on TreeStartedDispatchCondition {
				rootNodeIds
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on TreeFinishedDispatchCondition {
				rootNodeIds
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on TreeUnlockedDispatchCondition {
				rootNodeIds
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on OptInDispatchCondition {
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
		}
		excludes {
			... on AccountDispatchCondition {
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on AllUserDispatchCondition {
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on UserDispatchCondition {
				userIds
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on TreeStartedDispatchCondition {
				rootNodeIds
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on TreeFinishedDispatchCondition {
				rootNodeIds
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on TreeUnlockedDispatchCondition {
				rootNodeIds
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
			... on OptInDispatchCondition {
				kind
				...removeDispatchConditionButton_DispatchConditionFragment
			}
		}
	}
`;
