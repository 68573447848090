import { graphql } from "react-relay";

export const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment discountCodeCreateSingle_DiscountActionFragment on DiscountAction {
		id
	}
`;

export const CREATE_MUTATION = graphql`
	mutation discountCodeCreateSingle_CreateMutation(
		$input: CreateDiscountCodeInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createDiscountCode(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...discountCodeTable_DiscountCodeFragment
						}
					}
				}
			}
		}
	}
`;
