/**
 * @generated SignedSource<<a80f98131e1b99dd9c8de559502bef44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type rewardEditor_Query$variables = Record<PropertyKey, never>;
export type rewardEditor_Query$data = {
  readonly Admin: {
    readonly Billing: {
      readonly GetExternalLicensePools: {
        readonly " $fragmentSpreads": FragmentRefs<"createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment">;
      };
    };
  };
};
export type rewardEditor_Query = {
  response: rewardEditor_Query$data;
  variables: rewardEditor_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "rewardEditor_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalLicensePoolsConnection",
                "kind": "LinkedField",
                "name": "GetExternalLicensePools",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "rewardEditor_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalLicensePoolsConnection",
                "kind": "LinkedField",
                "name": "GetExternalLicensePools",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensePoolsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalLicensePool",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "782e1f1337406810f2a8d6244d6c246b",
    "id": null,
    "metadata": {},
    "name": "rewardEditor_Query",
    "operationKind": "query",
    "text": "query rewardEditor_Query {\n  Admin {\n    Billing {\n      GetExternalLicensePools {\n        ...createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment\n      }\n    }\n  }\n}\n\nfragment createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment on ExternalLicensePoolsConnection {\n  edges {\n    node {\n      id\n    }\n  }\n}\n"
  }
};

(node as any).hash = "b5dfbeffcd1354ad53adca0d1ef45b51";

export default node;
