/**
 * @generated SignedSource<<56ecb141c994681df622caf4eff2dac6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateDiscountCodeInput = {
  clientMutationId?: string | null | undefined;
  code: string;
  discountActionId: string;
};
export type discountCodeCreateSingle_CreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateDiscountCodeInput;
};
export type discountCodeCreateSingle_CreateMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly createDiscountCode: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"discountCodeTable_DiscountCodeFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type discountCodeCreateSingle_CreateMutation = {
  response: discountCodeCreateSingle_CreateMutation$data;
  variables: discountCodeCreateSingle_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numUsages",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "discountCodeCreateSingle_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateDiscountCodePayload",
                "kind": "LinkedField",
                "name": "createDiscountCode",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "discountCodeTable_DiscountCodeFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "discountCodeDeleteButton_DiscountCodeFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "discountCodeEditButton_DiscountCodeFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "discountCodeCreateSingle_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateDiscountCodePayload",
                "kind": "LinkedField",
                "name": "createDiscountCode",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f8cb8a19b2ce59f240bc0f337a4057e",
    "id": null,
    "metadata": {},
    "name": "discountCodeCreateSingle_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation discountCodeCreateSingle_CreateMutation(\n  $input: CreateDiscountCodeInput!\n) {\n  Admin {\n    Billing {\n      createDiscountCode(input: $input) {\n        edge {\n          node {\n            ...discountCodeTable_DiscountCodeFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment discountCodeDeleteButton_DiscountCodeFragment on DiscountCode {\n  id\n}\n\nfragment discountCodeEditButton_DiscountCodeFragment on DiscountCode {\n  id\n}\n\nfragment discountCodeTable_DiscountCodeFragment on DiscountCode {\n  id\n  code\n  createdAt\n  numUsages\n  ...discountCodeDeleteButton_DiscountCodeFragment\n  ...discountCodeEditButton_DiscountCodeFragment\n}\n"
  }
};
})();

(node as any).hash = "703f5f784557a836424daa1d7b2e0cae";

export default node;
