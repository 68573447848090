import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment emailTemplatesTable_EmailTemplatesListFragment on Query
	@refetchable(queryName: "emailTemplatesTable_Refetch")
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
		Admin {
			Email {
				PersonalizedEmailTemplates(first: $first, after: $after)
					@connection(key: "emailTemplatesTable_PersonalizedEmailTemplates") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							...emailTemplatesTable_PersonalizedEmailTemplatesFragment
						}
					}
				}
			}
		}
		...createEmailTemplateButton_AvailableTemplatesFragment
	}
`;

export const PERSONALIZED_EMAIL_TEMPLATE_FRAGMENT = graphql`
	fragment emailTemplatesTable_PersonalizedEmailTemplatesFragment on PersonalizedEmailTemplate
	@inline {
		id
		template {
			key
			subject
		}
	}
`;
