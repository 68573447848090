import { type discountCodeCodesTableHeader_DiscountActionFragment$key } from "@relay/discountCodeCodesTableHeader_DiscountActionFragment.graphql";
import { useFragment } from "react-relay";
import { DISCOUNT_ACTION_FRAGMENT } from "./discount-code-codes-table-header.graphql";
import { DiscountCodeCodesTableHeaderProps } from "./discount-code-codes-table-header.props";
import { buttonWrapperClass } from "./discount-code-codes-table-header.styles";
import { DiscountCodeCreateRandom } from "../discount-code-create-random/discount-code-create-random.component";
import { DiscountCodeCreateSingle } from "../discount-code-create-single/discount-code-create-single.component";
import { DiscountCodeDownloadCsvButton } from "../discount-code-download-csv-button/discount-code-download-csv-button.component";

export const DiscountCodeCodesTableHeader = ({
	discountActionFragmentRef,
	canModifyDiscountCodes,
}: DiscountCodeCodesTableHeaderProps) => {
	const discountAction = useFragment<discountCodeCodesTableHeader_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);

	return (
		<div className={buttonWrapperClass}>
			<DiscountCodeDownloadCsvButton discountActionFragmentRef={discountAction} />
			{canModifyDiscountCodes && (
				<>
					<DiscountCodeCreateRandom discountActionFragmentRef={discountAction} />
					<DiscountCodeCreateSingle discountActionFragmentRef={discountAction} />
				</>
			)}
		</div>
	);
};
