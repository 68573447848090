import React, { useEffect } from "react";
import {
	readInlineData,
	usePaginationFragment,
	usePreloadedQuery,
	useQueryLoader,
} from "react-relay";
import { searchableAccountTable_AccountFragment$key } from "@relay/searchableAccountTable_AccountFragment.graphql";
import { type searchableAccountTable_Query } from "@relay/searchableAccountTable_Query.graphql";
import { type searchableAccountTable_QueryFragment$key } from "@relay/searchableAccountTable_QueryFragment.graphql";
import { type searchableAccountTable_QueryRefetch } from "@relay/searchableAccountTable_QueryRefetch.graphql";
import { ACCOUNT_FRAGMENT, QUERY, QUERY_FRAGMENT } from "./searchable-account-table.graphql";
import {
	type SearchableAccountTableSelection,
	type SearchableAccountTableProps,
	type SearchableAccountTableWithPreloadedQueryProps,
} from "./searchable-account-table.types";
import { type DataTableSelectionChangeParams } from "../../../components/data-table/data-table.interface";
import { SearchableTable } from "../../../components/searchable-table/searchable-table.component";

export const SearchableAccountTableWithPreloadedQuery = ({
	selection,
	onChange,
	selectionMode = "single",
	queryRef,
}: SearchableAccountTableWithPreloadedQueryProps) => {
	const query = usePreloadedQuery<searchableAccountTable_Query>(QUERY, queryRef);

	const {
		data: {
			Admin: {
				Auth: { SelectAccounts },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		searchableAccountTable_QueryRefetch,
		searchableAccountTable_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};

	const handleOnSearch = (search: string) => {
		refetch({ nameOpt: search });
	};

	useEffect(() => {
		refetch({});
	}, []);

	const handleOnSelectionChange = (event: DataTableSelectionChangeParams<any>) => {
		onChange?.(event.value);
	};

	const values =
		SelectAccounts.edges
			?.filter((e) => !!e?.node)
			.map((e) =>
				readInlineData<searchableAccountTable_AccountFragment$key>(
					ACCOUNT_FRAGMENT,
					e!.node,
				),
			)
			.map((node) => ({ id: node.id, name: node.name })) ?? [];

	return (
		<SearchableTable<SearchableAccountTableSelection>
			selectionMode={selectionMode}
			selection={selection}
			dataKey="id"
			value={values}
			metaKeySelection={false}
			hasPrevious={hasPrevious}
			hasNext={hasNext}
			loadPrevious={handleLoadPrevious}
			loadNext={handleLoadNext}
			onSearch={handleOnSearch}
			onSelectionChange={handleOnSelectionChange}
		>
			{(Column) => (
				<>
					<Column header="Name" field="name" />
				</>
			)}
		</SearchableTable>
	);
};

export const SearchableAccountTable = ({ ...props }: SearchableAccountTableProps) => {
	const [queryReference, loadQuery] = useQueryLoader<searchableAccountTable_Query>(QUERY);

	useEffect(() => {
		loadQuery({}, { fetchPolicy: "store-and-network" });
	}, [loadQuery]);

	return queryReference ? (
		<SearchableAccountTableWithPreloadedQuery {...props} queryRef={queryReference} />
	) : null;
};
