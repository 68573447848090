import { Button } from "@components/button";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { DISCOUNT_ACTION_FRAGMENT, CREATE_MUTATION } from "./discount-code-create-single.graphql";
import { DiscountCodeCreateSingleProps } from "./discount-code-create-single.props";
import { inputWrapperClass, labelClass } from "./discount-code-create-single.styles";
import { useDiscountCodeTableContext } from "../discount-code-table/discount-code-table.context";

export const DiscountCodeCreateSingle = ({
	discountActionFragmentRef,
}: DiscountCodeCreateSingleProps) => {
	const discountAction = useFragment(DISCOUNT_ACTION_FRAGMENT, discountActionFragmentRef);
	const [createSingleCode, isLoading] = useMutation(CREATE_MUTATION);
	const { connectionId } = useDiscountCodeTableContext();

	const [codeName, setCodeName] = useState<string>("Neu");

	const overlayPanel = useRef<OverlayPanel>(null);
	const toast = useRef<Toast>(null);

	const handleOnCreateClick = () => {
		createSingleCode({
			variables: {
				input: {
					code: codeName,
					discountActionId: discountAction.id,
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Code erstellt",
					detail: "Discountcode wurde erfolgreich erstellt",
				});
			},
		});
	};

	return (
		<>
			<Toast ref={toast} />
			<Button
				label="Neu"
				loading={isLoading}
				disabled={isLoading}
				onClick={(e) => overlayPanel.current?.toggle(e)}
			/>
			<OverlayPanel ref={overlayPanel}>
				<span className={inputWrapperClass}>
					<label className={labelClass} htmlFor="nameOfCode">
						Code
					</label>
					<InputText
						id="nameOfCode"
						value={codeName}
						onChange={(e) => setCodeName(e.target.value)}
					/>
				</span>
				<Button
					label="Code erstellen"
					loading={isLoading}
					disabled={isLoading}
					onClick={handleOnCreateClick}
				/>
			</OverlayPanel>
		</>
	);
};
