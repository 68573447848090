import { graphql } from "react-relay";

export const PAGE_BUTTON_FRAGMENT = graphql`
	fragment pageButtonForm_ButtonFragment on ImageWithButtonPage {
		button {
			kind
			... on ConfirmButton {
				text
			}
			... on TextCopyButton {
				text
			}
			... on LinkButton {
				text
				url
			}
		}
	}
`;
