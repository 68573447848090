/**
 * @generated SignedSource<<42b9b8b7b3cfba4a7cd73ce9f074d885>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteCourseOrMicroLearningLearnableInput = {
  clientMutationId?: string | null | undefined;
  rootId: string;
};
export type deleteCourseLearnableButton_DeleteTreeMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteCourseOrMicroLearningLearnableInput;
};
export type deleteCourseLearnableButton_DeleteTreeMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly deleteCourseOrMicroLearningLearnable: {
        readonly deletedCourseOrMicroLearningLearnableId: string;
      } | null | undefined;
    };
  };
};
export type deleteCourseLearnableButton_DeleteTreeMutation = {
  response: deleteCourseLearnableButton_DeleteTreeMutation$data;
  variables: deleteCourseLearnableButton_DeleteTreeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedCourseOrMicroLearningLearnableId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteCourseLearnableButton_DeleteTreeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteCourseOrMicroLearningLearnablePayload",
                "kind": "LinkedField",
                "name": "deleteCourseOrMicroLearningLearnable",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteCourseLearnableButton_DeleteTreeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteCourseOrMicroLearningLearnablePayload",
                "kind": "LinkedField",
                "name": "deleteCourseOrMicroLearningLearnable",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedCourseOrMicroLearningLearnableId",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c19db1b877c08abf332fa1553fd250af",
    "id": null,
    "metadata": {},
    "name": "deleteCourseLearnableButton_DeleteTreeMutation",
    "operationKind": "mutation",
    "text": "mutation deleteCourseLearnableButton_DeleteTreeMutation(\n  $input: DeleteCourseOrMicroLearningLearnableInput!\n) {\n  Admin {\n    Tree {\n      deleteCourseOrMicroLearningLearnable(input: $input) {\n        deletedCourseOrMicroLearningLearnableId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "132c930718634fb3427288eb1fbd961c";

export default node;
