import {
	DefaultStyledInputDropdownComponents,
	type InputBaseProps,
	type StyledInputDropdownComponents,
} from "@constellation-academy/framework-react-components";
import { Dropdown as PrDropdown, type DropdownProps as PrDropdownProps } from "primereact/dropdown";
import styled, { createGlobalStyle } from "styled-components";
import tw from "twin.macro";

export const tkaAdminInputDropdownStyles: StyledInputDropdownComponents = {
	InputDropdown: {
		...DefaultStyledInputDropdownComponents.InputDropdown,
		StyledDropdown: styled(PrDropdown)<PrDropdownProps & InputBaseProps>`
			${tw`tw-flex tw-items-center tw-w-full`}
		`,
		DropdownValueIconWrapper: styled.div<{
			hasLabel: boolean;
		}>``,
		DropdownGlobalStyles: createGlobalStyle`
		`,
		DropdownValueTemplate: tw.div``,
	},
};
