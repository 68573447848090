import { Button, Dialog, ButtonType } from "@constellation-academy/epic-ui";
import { ButtonVariant } from "@components/button";
import { error100, shade0 } from "@themes/colors";
import { buttonsWrapperClass } from "./confirm-dialog.styles";
import { type ConfirmWithTextDialogProps } from "./confirm-dialog.types";

// TODO: Add translations
export const ConfirmDialog = ({
	showDialog = true,
	setShowDialog,
	title,
	content,
	cancelButtonText = "Abbrechen",
	confirmButtonText = "Ja",
	onCancel,
	onConfirm,
}: ConfirmWithTextDialogProps) => {
	const handleClose = () => {
		onCancel?.();
		setShowDialog?.(false);
	};

	const handleConfirm = () => {
		onConfirm?.();
		setShowDialog?.(false);
	};

	return (
		<Dialog
			visible={showDialog}
			onHide={handleClose}
			title={title}
			footerNode={
				<div className={buttonsWrapperClass}>
					<Button
						onClick={handleClose}
						type={ButtonType.Button}
						label={cancelButtonText}
					/>
					<Button
						type={ButtonType.Button}
						variant={ButtonVariant.Error}
						backgroundColor={error100}
						onClick={handleConfirm}
						contentColor={shade0}
						label={confirmButtonText}
					/>
				</div>
			}
		>
			{content}
		</Dialog>
	);
};
