import { Button } from "@constellation-academy/epic-ui";
import { DefaultTextFieldComponent, Form } from "@constellation-academy/framework-react-components";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { type SendEmailFormProps, type SendEmailFormState } from "./send-email-form.types";
import { ValidatedField } from "../../../../components/ValidatedField";

export const SendEmailForm = ({ onSubmit }: SendEmailFormProps) => {
	const formik = useFormik<SendEmailFormState>({
		initialValues: {},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Bitte geben Sie eine gültige E-Mail ein")
				.required("Das Feld E-Mail wird benötigt."),
		}),
		onSubmit,
	});

	const handleOnClick = () => {
		formik.handleSubmit();
	};

	return (
		<Form onSubmit={formik.handleSubmit}>
			<ValidatedField<SendEmailFormState, string>
				name={"email"}
				label={"E-Mail"}
				required
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<Button label={"Senden"} disabled={!formik.isValid} onClick={handleOnClick} />
		</Form>
	);
};
