/**
 * @generated SignedSource<<b41904d9c7ea447e61fe3f85f72a1e01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_AfterDateTime" | "VisibilityTree_Hide" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyBusinessAccount" | "VisibilityTree_OnlyIfTreeState" | "VisibilityTree_OnlyPermissionsImpl";
import { FragmentRefs } from "relay-runtime";
export type visibilityTreeConfigEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly visibilityConfigs?: ReadonlyArray<{
      readonly configType: VisibilityTreeConfigType;
      readonly dateTime?: string;
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment" | "NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AfterDateTimeVisibilityConfigForm_TreeNodeFragment" | "NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment">;
  readonly " $fragmentType": "visibilityTreeConfigEditor_TreeNodeFragment";
};
export type visibilityTreeConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: visibilityTreeConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"visibilityTreeConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dateTime",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "visibilityTreeConfigEditor_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "visibilityConfigs",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v1/*: any*/),
                  "type": "NotAfterDateTimeVisibilityTreeConfig",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v1/*: any*/),
                  "type": "AfterDateTimeVisibilityTreeConfig",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AfterDateTimeVisibilityConfigForm_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "d05f687ac7ed36916aae3648b2e28334";

export default node;
