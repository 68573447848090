import { type PathParams } from "@constellation-academy/epic-ui";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type orderEditScreen_OrderQuery } from "@relay/orderEditScreen_OrderQuery.graphql";
import { type OrdersPath } from "@screens/orders";
import { QUERY } from "./order-edit.graphql";
import { BillingDetailsForm } from "./parts/billing-details-form/billing-details-form.component";
import { CartForm } from "./parts/cart-form/cart-form.component";
import { InvoiceDataForm } from "./parts/invoice-data-form/invoice-data-form.component";
import { NoAccess } from "../../components/no-access";
import { OrderHistoryTable } from "../../features/orders/order-history-table/order-history-table.component";
import { PaymentDataForm } from "../../features/orders/payment-data-form/payment-data-form.component";

export const OrderEditScreen = () => {
	const { orderId } = useParams<PathParams<typeof OrdersPath>>();

	const query = useLazyLoadQuery<orderEditScreen_OrderQuery>(QUERY, {
		id: orderId!,
		skip: !orderId,
	});

	const canReadAndModify = useHasPermissions([
		"UserInAccountPermission_OrderAdmin_Read",
		"UserInAccountPermission_OrderAdmin_Modify",
	]);

	if (!canReadAndModify) return <NoAccess />;

	return query.node ? (
		<Suspense fallback={<ProgressSpinner />}>
			<div className="p-4">
				<Card>
					<h2 className="mb-3 text-center">[Bestellung] {query.node?.id}</h2>
					<Accordion activeIndex={0}>
						<AccordionTab header="Kundeninformationen" tabIndex={0}>
							{query.node?.customerDetails && (
								<BillingDetailsForm
									billingDetailsFragmentRef={query.node?.customerDetails}
								/>
							)}
						</AccordionTab>
						<AccordionTab header="Warenkorb" tabIndex={1}>
							<CartForm orderFragmentRef={query.node} />
						</AccordionTab>
						<AccordionTab header="Zahlungsdaten" tabIndex={2}>
							<PaymentDataForm orderFragmentRef={query.node} />
						</AccordionTab>
						<AccordionTab header="Rechnungsdaten" tabIndex={3}>
							<InvoiceDataForm orderFragmentRef={query.node} />
						</AccordionTab>
						<AccordionTab header="Verlauf" tabIndex={4}>
							<OrderHistoryTable orderHistoriesFragmentRef={query} />
						</AccordionTab>
					</Accordion>
				</Card>
			</div>
		</Suspense>
	) : (
		<h4>Fehler beim Laden der Bestellung</h4>
	);
};
