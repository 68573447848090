import { graphql } from "react-relay";

export const LIMITED_CART_DISCOUNTS_FRAGMENT = graphql`
	fragment limitedCartDiscountTable_LimitedCartDiscountsFragment on Query
	@refetchable(queryName: "limitedCartDiscountTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String" }
		title: { type: "String" }
	) {
		Admin {
			Billing {
				LimitedCartDiscounts(first: $first, after: $after, title: $title)
					@connection(key: "limitedCartDiscountTable_LimitedCartDiscounts") {
					__id
					edges {
						node {
							...limitedCartDiscountTable_LimitedCartDiscountFragment
						}
					}
				}
			}
		}
	}
`;

export const LIMITED_CART_DISCOUNT_FRAGMENT = graphql`
	fragment limitedCartDiscountTable_LimitedCartDiscountFragment on LimitedCartDiscount @inline {
		id
		title
		createdAt
		productSelectionConfig {
			... on PercentageProductSelectionConfig {
				value
			}
		}
		conditions {
			kind
		}
	}
`;
