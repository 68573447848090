import { graphql } from "react-relay";

export const ACCOUNT_FRAGMENT = graphql`
	fragment editAccountGroupsButton_AccountFragment on Account {
		...editAccountGroupsForm_AccountFragment
	}
`;

export const ACCOUNT_GROUP_FRAGMENT = graphql`
	fragment editAccountGroupsButton_AccountGroupFragment on AccountGroup @inline {
		...editAccountGroupsForm_AccountGroupFragment
	}
`;
