/**
 * @generated SignedSource<<d2806937af4255db9b3e33531f8a1c47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddExternalLicenseRewardInput = {
  clientMutationId?: string | null | undefined;
  contentId: string;
  data: ExternalLicenseRewardDataInput;
};
export type ExternalLicenseRewardDataInput = {
  pool: string;
};
export type createExternalLicenseRewardButton_AddExternalLicenseRewardMutation$variables = {
  input: AddExternalLicenseRewardInput;
};
export type createExternalLicenseRewardButton_AddExternalLicenseRewardMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly addExternalLicenseReward: {
        readonly content: {
          readonly " $fragmentSpreads": FragmentRefs<"rewardEditor_TreeNodeFragment">;
        };
      } | null | undefined;
    };
  };
};
export type createExternalLicenseRewardButton_AddExternalLicenseRewardMutation = {
  response: createExternalLicenseRewardButton_AddExternalLicenseRewardMutation$data;
  variables: createExternalLicenseRewardButton_AddExternalLicenseRewardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createExternalLicenseRewardButton_AddExternalLicenseRewardMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddExternalLicenseRewardPayload",
                "kind": "LinkedField",
                "name": "addExternalLicenseReward",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "rewardEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createExternalLicenseRewardButton_AddExternalLicenseRewardMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddExternalLicenseRewardPayload",
                "kind": "LinkedField",
                "name": "addExternalLicenseReward",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "rewards",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isReward"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ExternalLicenseRewardData",
                                        "kind": "LinkedField",
                                        "name": "data",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ExternalLicensePool",
                                            "kind": "LinkedField",
                                            "name": "pool",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ExternalLicensePoolData",
                                                "kind": "LinkedField",
                                                "name": "data",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              },
                                              (v2/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "superId",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "ExternalLicenseReward",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ContentTypeDefinition",
                            "abstractKey": "__isContentTypeDefinition"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "rewards",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ELearningContentTypeDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e0d47e030aac7730f5db91e7fe63a32",
    "id": null,
    "metadata": {},
    "name": "createExternalLicenseRewardButton_AddExternalLicenseRewardMutation",
    "operationKind": "mutation",
    "text": "mutation createExternalLicenseRewardButton_AddExternalLicenseRewardMutation(\n  $input: AddExternalLicenseRewardInput!\n) {\n  Admin {\n    Tree {\n      addExternalLicenseReward(input: $input) {\n        content {\n          ...rewardEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment createExternalLicenseRewardButton_TreeNodeFragment on TreeNode {\n  id\n  typeDefinition {\n    __typename\n    ... on ELearningContentTypeDefinition {\n      rewards {\n        __typename\n        id\n        kind\n      }\n    }\n  }\n}\n\nfragment createIhkCertificateRewardButton_TreeNodeFragment on TreeNode {\n  id\n  typeDefinition {\n    __typename\n    ... on ELearningContentTypeDefinition {\n      rewards {\n        __typename\n        id\n        kind\n      }\n    }\n  }\n}\n\nfragment externalLicenseRewardForm_ExternalLicenseRewardFragment on ExternalLicenseReward {\n  ... on Reward {\n    __isReward: __typename\n    id\n  }\n  superId\n  data {\n    pool {\n      id\n      data {\n        name\n      }\n    }\n  }\n}\n\nfragment rewardEditor_TreeNodeFragment on TreeNode {\n  id\n  typeDefinition {\n    __typename\n    ... on ContentTypeDefinition {\n      __isContentTypeDefinition: __typename\n      rewards {\n        __typename\n        id\n        kind\n        ... on ExternalLicenseReward {\n          data {\n            pool {\n              data {\n                name\n              }\n              id\n            }\n          }\n        }\n        ...rewardForm_RewardFragment\n      }\n    }\n  }\n  ...rewardForm_TreeNodeFragment\n  ...createExternalLicenseRewardButton_TreeNodeFragment\n  ...createIhkCertificateRewardButton_TreeNodeFragment\n}\n\nfragment rewardForm_RewardFragment on Reward {\n  __isReward: __typename\n  id\n  kind\n  ... on ExternalLicenseReward {\n    ...externalLicenseRewardForm_ExternalLicenseRewardFragment\n  }\n}\n\nfragment rewardForm_TreeNodeFragment on TreeNode {\n  id\n}\n"
  }
};
})();

(node as any).hash = "b8e3b4afd1655606e54901907ffe8f0a";

export default node;
