/**
 * @generated SignedSource<<867c05894e5ed9d9ddc64e9d66aff501>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type LearnableKind = "Course";
import { FragmentRefs } from "relay-runtime";
export type editCourseLearnableButton_UnpublishedLearnableFragment$data = {
  readonly id: string;
  readonly kind?: LearnableKind;
  readonly root?: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "editCourseLearnableButton_UnpublishedLearnableFragment";
};
export type editCourseLearnableButton_UnpublishedLearnableFragment$key = {
  readonly " $data"?: editCourseLearnableButton_UnpublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editCourseLearnableButton_UnpublishedLearnableFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editCourseLearnableButton_UnpublishedLearnableFragment",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TreeNode",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "UnpublishedCourseLearnable",
      "abstractKey": null
    }
  ],
  "type": "UnpublishedLearnable",
  "abstractKey": "__isUnpublishedLearnable"
};
})();

(node as any).hash = "f3a9d72779485b0fc9cec0304cf550bb";

export default node;
