/**
 * @generated SignedSource<<aefb11f1d9dd9bf91803268abcd0f475>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usersInAccountTable_UserInAccountConnectionFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly groups: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly user: {
        readonly activated: boolean;
        readonly email: string;
        readonly id: string;
        readonly name: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"editUserInAccountGroup_UserInAccountFragment">;
    };
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "usersInAccountTable_UserInAccountConnectionFragment";
};
export type usersInAccountTable_UserInAccountConnectionFragment$key = {
  readonly " $data"?: usersInAccountTable_UserInAccountConnectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"usersInAccountTable_UserInAccountConnectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usersInAccountTable_UserInAccountConnectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInAccountEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserInAccount",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserInAccountGroup",
              "kind": "LinkedField",
              "name": "groups",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "activated",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "editUserInAccountGroup_UserInAccountFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserInAccountConnection",
  "abstractKey": null
};
})();

(node as any).hash = "d76ca23cb78b56f902af296d4cebd85a";

export default node;
