/**
 * @generated SignedSource<<5b8bb268f57536448d98d7cc728e1f35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type productsTable_ProductsConnectionFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly " $fragmentSpreads": FragmentRefs<"productsTable_LicenseProductFragment">;
    };
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "productsTable_ProductsConnectionFragment";
};
export type productsTable_ProductsConnectionFragment$key = {
  readonly " $data"?: productsTable_ProductsConnectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"productsTable_ProductsConnectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "productsTable_ProductsConnectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "productsTable_LicenseProductFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductsConnection",
  "abstractKey": null
};

(node as any).hash = "f38b5f7bfc9363f57c66af542ee63d02";

export default node;
