/**
 * @generated SignedSource<<2da9f1451bfa9e5d01bd6ef50c14311f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PublishingStatus = "acknowledged" | "failed" | "inprogress" | "succeeded";
import { FragmentRefs } from "relay-runtime";
export type educationalOfferTree_PublishingV2Fragment$data = {
  readonly status: PublishingStatus;
  readonly " $fragmentType": "educationalOfferTree_PublishingV2Fragment";
};
export type educationalOfferTree_PublishingV2Fragment$key = {
  readonly " $data"?: educationalOfferTree_PublishingV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"educationalOfferTree_PublishingV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "educationalOfferTree_PublishingV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "PublishingV2",
  "abstractKey": null
};

(node as any).hash = "8c46116e632d0129709a743df1e4f0db";

export default node;
