/**
 * @generated SignedSource<<409315b8e5115c1463996c56fabe3bc2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AfterDateTimeVisibilityConfigForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "AfterDateTimeVisibilityConfigForm_TreeNodeFragment";
};
export type AfterDateTimeVisibilityConfigForm_TreeNodeFragment$key = {
  readonly " $data"?: AfterDateTimeVisibilityConfigForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AfterDateTimeVisibilityConfigForm_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AfterDateTimeVisibilityConfigForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "70696243cd4dde7e7cb9a5545b30ba63";

export default node;
