/**
 * @generated SignedSource<<5183eb5660bf25ddd196aeeb9eff3944>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DispatchConditionKind = "AccountDispatchCondition" | "AllUserDispatchCondition" | "OptInDispatchCondition" | "TreeFinishedDispatchCondition" | "TreeStartedDispatchCondition" | "TreeUnlockedDispatchCondition" | "UserDispatchCondition";
import { FragmentRefs } from "relay-runtime";
export type dispatchExclusions_MessageDispatchFragment$data = {
  readonly excludes: ReadonlyArray<{
    readonly kind?: DispatchConditionKind;
    readonly rootNodeIds?: ReadonlyArray<string>;
    readonly userIds?: ReadonlyArray<string>;
    readonly " $fragmentSpreads": FragmentRefs<"removeDispatchConditionButton_DispatchConditionFragment">;
  }>;
  readonly id: string;
  readonly includes: ReadonlyArray<{
    readonly kind?: DispatchConditionKind;
    readonly rootNodeIds?: ReadonlyArray<string>;
    readonly userIds?: ReadonlyArray<string>;
    readonly " $fragmentSpreads": FragmentRefs<"removeDispatchConditionButton_DispatchConditionFragment">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"dispatchConditionDropdown_MessageDispatchFragment" | "dispatchConditionsPreview_MessageDispatchFragment">;
  readonly " $fragmentType": "dispatchExclusions_MessageDispatchFragment";
};
export type dispatchExclusions_MessageDispatchFragment$key = {
  readonly " $data"?: dispatchExclusions_MessageDispatchFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"dispatchExclusions_MessageDispatchFragment">;
};

import dispatchExclusions_Refetch_graphql from './dispatchExclusions_Refetch.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "removeDispatchConditionButton_DispatchConditionFragment"
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rootNodeIds",
    "storageKey": null
  },
  (v0/*: any*/),
  (v1/*: any*/)
],
v4 = [
  {
    "kind": "InlineFragment",
    "selections": (v2/*: any*/),
    "type": "AccountDispatchCondition",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": (v2/*: any*/),
    "type": "AllUserDispatchCondition",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userIds",
        "storageKey": null
      },
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "type": "UserDispatchCondition",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": (v3/*: any*/),
    "type": "TreeStartedDispatchCondition",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": (v3/*: any*/),
    "type": "TreeFinishedDispatchCondition",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": (v3/*: any*/),
    "type": "TreeUnlockedDispatchCondition",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": (v2/*: any*/),
    "type": "OptInDispatchCondition",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": dispatchExclusions_Refetch_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "dispatchExclusions_MessageDispatchFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "includes",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "excludes",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "dispatchConditionDropdown_MessageDispatchFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "dispatchConditionsPreview_MessageDispatchFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MessageDispatch",
  "abstractKey": null
};
})();

(node as any).hash = "abc69e9f2ad21652553cfbd58144669c";

export default node;
