import { graphql } from "react-relay";
export const QUERY = graphql`
	query selectDiscountCodeField_Query {
		...selectDiscountCodeField_QueryFragment
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment selectDiscountCodeField_QueryFragment on Query
	@refetchable(queryName: "selectDiscountCodeField_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		code: { type: "String" }
	) {
		Admin {
			Billing {
				searchDiscountCodes(first: $first, after: $after, code: $code)
					@connection(key: "selectDiscountCodeField_searchDiscountCodes") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
						}
					}
					...minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment
				}
			}
		}
	}
`;
