import { FormDialogButton } from "@constellation-academy/framework-react-components";
import { type FormikProps } from "formik";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type editFlexLicenseDefinitionButton_editFlexLicenseDefinitionMutation } from "@relay/editFlexLicenseDefinitionButton_editFlexLicenseDefinitionMutation.graphql";
import { type editFlexLicenseDefinitionButton_LicenseDefinitionFragment$key } from "@relay/editFlexLicenseDefinitionButton_LicenseDefinitionFragment.graphql";
import {
	EDIT_FLEX_LICENSE_DEFINITION_MUTATION,
	LICENSE_DEFINITION_FRAGMENT,
} from "./edit-flex-license-definition-button.graphql";
import { type EditFlexLicenseDefinitionButtonProps } from "./edit-flex-license-definition-button.types";
import { FlexLicenseDefinitionForm } from "../flex-license-definition-form";
import { type FlexLicenseDefinitionFormState } from "../flex-license-definition-form/flex-license-definition-form.types";

export const EditFlexLicenseDefinitionButton = ({
	licenseDefinitionFragmentRef,
}: EditFlexLicenseDefinitionButtonProps) => {
	const licenseDefinition =
		useFragment<editFlexLicenseDefinitionButton_LicenseDefinitionFragment$key>(
			LICENSE_DEFINITION_FRAGMENT,
			licenseDefinitionFragmentRef,
		);
	const [edit, isEditing] =
		useMutation<editFlexLicenseDefinitionButton_editFlexLicenseDefinitionMutation>(
			EDIT_FLEX_LICENSE_DEFINITION_MUTATION,
		);

	const createHandleOnSubmit = (
		ref: React.MutableRefObject<FormikProps<FlexLicenseDefinitionFormState> | null>,
		onHide: () => void,
		values: FlexLicenseDefinitionFormState,
	) => {
		edit({
			variables: {
				input: {
					licenseDefinitionId: licenseDefinition.id,
					data: {
						name: values.name,
						includedNodeIds: values.includedNodeIds?.map((node) => node.id) ?? [],
						excludedNodeIds: values.excludedNodeIds?.map((node) => node.id) ?? [],
					},
				},
			},
			onCompleted: () => {
				ref.current?.setSubmitting(false);
				onHide();
				toast.success("Lizenzdefinition berarbeitet.");
			},
			onError: () => {
				ref.current?.setSubmitting(false);
				toast.error("Fehler beim bearbeiten der Lizenzdefinition.");
			},
		});
	};

	return (
		<FormDialogButton<FlexLicenseDefinitionFormState>
			disabled={isEditing}
			buttonContent={{
				icon: "pi pi-pencil",
			}}
			title={"Lizenzdefinition bearbeiten"}
		>
			{(ref, onHide) => {
				return (
					<FlexLicenseDefinitionForm
						initialState={{
							name: licenseDefinition?.data?.name ?? "",
							includedNodeIds:
								licenseDefinition?.data?.includedNodeIds?.map((node) => ({
									id: node.rootId,
									title: node.title,
								})) ?? [],
							excludedNodeIds:
								licenseDefinition?.data?.excludedNodeIds?.map((node) => ({
									id: node.rootId,
									title: node.title,
								})) ?? [],
						}}
						ref={ref}
						onSubmit={(values) => {
							createHandleOnSubmit(ref, onHide, values);
						}}
					/>
				);
			}}
		</FormDialogButton>
	);
};
