import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment userRootNodes_QueryFragment on Query
	@argumentDefinitions(userId: { type: "ID!" }, name: { type: "String" })
	@refetchable(queryName: "userRootNodes_RefetchQuery") {
		Admin {
			LearnV2 {
				RootNodesWithTreeStatesForUser(userId: $userId, name: $name) {
					rootNode {
						id
					}
					...userRootNodeItem_RootNodesWithTreeStateTypeFragment
				}
			}
		}
	}
`;
