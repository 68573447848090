/**
 * @generated SignedSource<<e2514da37508a2a5d36dab79527dd28b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type selectUserField_EditorsQuery$variables = {
  nameOrEmail?: string | null | undefined;
};
export type selectUserField_EditorsQuery$data = {
  readonly Admin: {
    readonly Auth: {
      readonly SelectUsers: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly activated: boolean;
            readonly email: string;
            readonly id: string;
            readonly name: string;
          };
        } | null | undefined> | null | undefined;
      };
    };
  };
};
export type selectUserField_EditorsQuery = {
  response: selectUserField_EditorsQuery$data;
  variables: selectUserField_EditorsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nameOrEmail"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthAdminSchema",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filterByNameOrEmail",
                "variableName": "nameOrEmail"
              }
            ],
            "concreteType": "UserConnection",
            "kind": "LinkedField",
            "name": "SelectUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "activated",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectUserField_EditorsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectUserField_EditorsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "41d68fca6542d24dbf3c26154de81737",
    "id": null,
    "metadata": {},
    "name": "selectUserField_EditorsQuery",
    "operationKind": "query",
    "text": "query selectUserField_EditorsQuery(\n  $nameOrEmail: String\n) {\n  Admin {\n    Auth {\n      SelectUsers(filterByNameOrEmail: $nameOrEmail) {\n        edges {\n          node {\n            id\n            name\n            email\n            activated\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "91130060e81067082011d00bc95ecf93";

export default node;
