/**
 * @generated SignedSource<<77480f20fdb10713715f181922ebfcdf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PublishingStatus = "acknowledged" | "failed" | "inprogress" | "succeeded";
import { FragmentRefs } from "relay-runtime";
export type educationalOffer_PublishingV2Fragment$data = {
  readonly status: PublishingStatus;
  readonly " $fragmentSpreads": FragmentRefs<"educationalOfferPublishing_PublishingV2Fragment" | "educationalOfferTree_PublishingV2Fragment">;
  readonly " $fragmentType": "educationalOffer_PublishingV2Fragment";
};
export type educationalOffer_PublishingV2Fragment$key = {
  readonly " $data"?: educationalOffer_PublishingV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"educationalOffer_PublishingV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "educationalOffer_PublishingV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "educationalOfferTree_PublishingV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "educationalOfferPublishing_PublishingV2Fragment"
    }
  ],
  "type": "PublishingV2",
  "abstractKey": null
};

(node as any).hash = "0782cf1ded827453dc506d74a353fbb7";

export default node;
