import { graphql } from "react-relay";

export const CREATE_BRANCH_CHILD_MUTATION = graphql`
	mutation addNodeButton_CreateBranchChildMutation($input: CreateBranchChildInput!) {
		Admin {
			Tree {
				createBranchChild(input: $input) {
					createdChildNode {
						id
					}
					tree {
						...educationalOffer_TreeFragment
					}
				}
			}
		}
	}
`;

export const CREATE_CONTENT_CHILD_MUTATION = graphql`
	mutation addNodeButton_CreateContentChildMutation($input: CreateContentChildInput!) {
		Admin {
			Tree {
				createContentChild(input: $input) {
					createdChildNode {
						id
					}
					tree {
						...educationalOffer_TreeFragment
					}
				}
			}
		}
	}
`;

export const CREATE_UPLOAD_ASYNC_ELEMENT_MUTATION = graphql`
	mutation addNodeButton_CreateUploadAsyncElementMutation(
		$input: CreateUploadAsyncElementInput!
	) {
		Admin {
			AsyncContent {
				createUploadAsyncElement(input: $input) {
					asyncContent {
						id
					}
				}
			}
		}
	}
`;
