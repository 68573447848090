/**
 * @generated SignedSource<<8c4f125dac7e1db5b472ffeed7b63f5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ResetLearnProgressInput = {
  clientMutationId?: string | null | undefined;
  rootId: string;
  userId: string;
};
export type resetLearnProgressButton_ResetLearnProgressMutation$variables = {
  input: ResetLearnProgressInput;
};
export type resetLearnProgressButton_ResetLearnProgressMutation$data = {
  readonly Admin: {
    readonly LearnProgress: {
      readonly resetLearnProgress: {
        readonly clientMutationId: string | null | undefined;
      } | null | undefined;
    };
  };
};
export type resetLearnProgressButton_ResetLearnProgressMutation = {
  response: resetLearnProgressButton_ResetLearnProgressMutation$data;
  variables: resetLearnProgressButton_ResetLearnProgressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnProgressAdminMutationSchema",
        "kind": "LinkedField",
        "name": "LearnProgress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "ResetLearnProgressPayload",
            "kind": "LinkedField",
            "name": "resetLearnProgress",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "resetLearnProgressButton_ResetLearnProgressMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "resetLearnProgressButton_ResetLearnProgressMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8e8b737b3f2aca6f9a0e4665323a9e73",
    "id": null,
    "metadata": {},
    "name": "resetLearnProgressButton_ResetLearnProgressMutation",
    "operationKind": "mutation",
    "text": "mutation resetLearnProgressButton_ResetLearnProgressMutation(\n  $input: ResetLearnProgressInput!\n) {\n  Admin {\n    LearnProgress {\n      resetLearnProgress(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8196ed011571ae91a68bd5992f09186b";

export default node;
