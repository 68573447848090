import { ButtonType, EpicIcons } from "@constellation-academy/epic-ui";
import * as Sentry from "@sentry/react";
import { memo, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { Button } from "@components/button";
import { ConfirmDialog } from "@components/confirm-dialog";
import type { createTreeStateButton_CreateTreeStateForUserMutation } from "@relay/createTreeStateButton_CreateTreeStateForUserMutation.graphql";
import type { createTreeStateButton_LearnOpportunityV2Fragment$key } from "@relay/createTreeStateButton_LearnOpportunityV2Fragment.graphql";
import {
	LEARN_OPPORTUNITY_V2_FRAGMENT,
	CREATE_TREE_STATE_FOR_USER_MUTATION,
} from "./create-tree-state-button.graphql";
import type { CreateTreeStateForUserInput } from "./create-tree-state-button.types";

const CreateTreeStateButtonComponent = ({
	learnOpportunityV2FragmentRef,
	userId,
	onCompleted,
	onError,
}: CreateTreeStateForUserInput) => {
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);

	const rootNode = useFragment<createTreeStateButton_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef ?? null,
	);

	const [createTreeState, isCreatingTreeState] =
		useMutation<createTreeStateButton_CreateTreeStateForUserMutation>(
			CREATE_TREE_STATE_FOR_USER_MUTATION,
		);

	const handleCreateTreeStateOnClick = () => {
		setShowConfirmDialog(true);
	};

	const handleCreateTreeStateOnCancel = () => {
		setShowConfirmDialog(false);
	};

	const handleCreateTreeStateOnConfirm = () => {
		if (!rootNode) {
			toast.error("Fehler beim Wiederherstellen der Paywall! RootNode nicht gefunden.");
			Sentry.captureException(
				new Error(
					"createTreeStateButton_CreateTreeStateForUserMutation: RootNode not found",
				),
				{
					extra: {
						userId,
						rootNode,
					},
				},
			);
			return;
		}

		createTreeState({
			variables: {
				input: { rootId: rootNode.id, userId },
			},
			onCompleted: () => {
				toast.success("Kurs erfolgreich freigeschaltet!");
				onCompleted?.();
			},
			onError: () => {
				toast.error("Fehler beim Freischalten des Kurses!");
				onError?.();
			},
		});
	};

	return (
		<>
			<Button
				type={ButtonType.Button}
				icon={EpicIcons.UNLOCK}
				label="Kurs freischalten"
				onClick={handleCreateTreeStateOnClick}
				disabled={isCreatingTreeState}
				loading={isCreatingTreeState}
			/>
			<ConfirmDialog
				title="Kurs freischalten"
				content={`Möchtest du den Kurs "${
					rootNode?.structureDefinition.title ?? "Unbekannter Kurs"
				}" wirklich freischalten?`}
				confirmButtonText="Ja, Kurs freischalten"
				onConfirm={handleCreateTreeStateOnConfirm}
				onCancel={handleCreateTreeStateOnCancel}
				showDialog={showConfirmDialog}
			/>
		</>
	);
};

export const CreateTreeStateButton = memo(CreateTreeStateButtonComponent);
