import { EpicIcons } from "@constellation-academy/epic-ui";
import React from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/button";
import { Paths } from "@routes/paths";
import {
	DISCOUNT_ACTION_FRAGMENT,
	DISCOUNT_CODE_FRAGMENT,
} from "./discount-code-edit-button.graphql";
import { DiscountCodeEditButtonProps } from "./discount-code-edit-button.props";

export const DiscountCodeEditButton = ({
	discountActionFragmentRef,
	discountCodeFragmentRef,
}: DiscountCodeEditButtonProps) => {
	const navigate = useNavigate();

	const discountActionResult = useFragment(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef ?? null,
	);
	const discountActionId = discountActionResult?.id ?? null;

	const discountCodeResult = useFragment(DISCOUNT_CODE_FRAGMENT, discountCodeFragmentRef ?? null);
	const codeId = discountCodeResult?.id ?? null;

	const handleOnClick = () => {
		if (!discountActionId || !codeId) {
			return;
		}
		navigate(
			Paths.discountActions.withId(discountActionId).edit.discountCode.withId(codeId).path,
		);
	};
	return <Button icon={EpicIcons.PENCIL} tooltip="Aktion editieren" onClick={handleOnClick} />;
};
