import { Button, EpicIcons } from "@constellation-academy/epic-ui";
import { useMutation } from "react-relay";
import { type createFileElementButton_CreateFileElementMutation } from "@relay/createFileElementButton_CreateFileElementMutation.graphql";
import { CREATE_FILE_ELEMENT_MUTATION } from "./create-file-element-button.graphql";
import { type CreateFileElementButtonProps } from "./create-file-element-button.types";
import { E_LEARNING_I18N_MAP, E_LEARNING_I18N_KEY } from "../../../../translations/tree";

export const CreateFileElementButton = ({
	eLearningContentNodeId,
}: CreateFileElementButtonProps) => {
	const [createFileElement, isCreatingTextElement] =
		useMutation<createFileElementButton_CreateFileElementMutation>(
			CREATE_FILE_ELEMENT_MUTATION,
		);

	return (
		<Button
			disabled={isCreatingTextElement}
			tooltip={`${E_LEARNING_I18N_MAP(E_LEARNING_I18N_KEY.fileElement)} anlegen`}
			icon={EpicIcons.FILE}
			onClick={() => {
				createFileElement({
					variables: {
						input: {
							eLearningContentNodeId,
							title: "Neues Element",
							fileId: undefined,
						},
					},
				});
			}}
		/>
	);
};
