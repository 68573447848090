import { Button, EpicIcons, PathParams } from "@constellation-academy/epic-ui";
import React from "react";

import { useFragment, useMutation } from "react-relay";
import { useParams } from "react-router-dom";
import { removeDispatchConditionButton_DispatchConditionFragment$key } from "@relay/removeDispatchConditionButton_DispatchConditionFragment.graphql";
import { removeDispatchConditionButton_RemoveExcludeDispatchConditionMutation } from "@relay/removeDispatchConditionButton_RemoveExcludeDispatchConditionMutation.graphql";
import { removeDispatchConditionButton_RemoveIncludeDispatchConditionMutation } from "@relay/removeDispatchConditionButton_RemoveIncludeDispatchConditionMutation.graphql";

import {
	DISPATCH_CONDITION_FRAGMENT,
	REMOVE_EXCLUDE_DISPATCH_CONDITION_MUTATION,
	REMOVE_INCLUDE_DISPATCH_CONDITION_MUTATION,
} from "@screens/message-dispatch-edit/parts/remove-dispatch-condition-button/remove-dispatch-condition-button.graphql";
import { RemoveDispatchConditionButtonProps } from "@screens/message-dispatch-edit/parts/remove-dispatch-condition-button/remove-dispatch-condition-button.types";
import { MessageDispatchesPath } from "@screens/message-dispatches";

export const RemoveDispatchConditionButton = ({
	isInclusion,
	dispatchConditionFragmentRef,
	onCompleted,
}: RemoveDispatchConditionButtonProps) => {
	const { messageDispatchId } = useParams<PathParams<typeof MessageDispatchesPath>>();

	const dispatchCondition =
		useFragment<removeDispatchConditionButton_DispatchConditionFragment$key>(
			DISPATCH_CONDITION_FRAGMENT,
			dispatchConditionFragmentRef ?? null,
		);

	const [removeIncludeDispatchCondition] =
		useMutation<removeDispatchConditionButton_RemoveIncludeDispatchConditionMutation>(
			REMOVE_INCLUDE_DISPATCH_CONDITION_MUTATION,
		);
	const [removeExcludeDispatchCondition] =
		useMutation<removeDispatchConditionButton_RemoveExcludeDispatchConditionMutation>(
			REMOVE_EXCLUDE_DISPATCH_CONDITION_MUTATION,
		);

	const handleRemoveOnClick = () => {
		if (!messageDispatchId || !dispatchCondition) return;
		if (isInclusion) {
			removeIncludeDispatchCondition({
				variables: {
					input: {
						dispatchConditionId: dispatchCondition.id,
						messageDispatchId: messageDispatchId,
					},
				},
				onCompleted: onCompleted,
			});
		} else {
			removeExcludeDispatchCondition({
				variables: {
					input: {
						dispatchConditionId: dispatchCondition.id,
						messageDispatchId: messageDispatchId,
					},
				},
				onCompleted: onCompleted,
			});
		}
	};

	return <Button icon={EpicIcons.TRASH} onClick={handleRemoveOnClick} />;
};
