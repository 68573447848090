import { Button, EpicIcons } from "@constellation-academy/epic-ui";
import { graphql, useMutation } from "react-relay";
import React from "react";
import { type CreateOrderElementButtonProps } from "./create-order-element-button.interface";
import { type CreateOrderElementButton_CreateOrderElementMutation } from "../../../../__generated__/CreateOrderElementButton_CreateOrderElementMutation.graphql";

const CREATE_ORDER_ELEMENT_MUTATION = graphql`
	mutation CreateOrderElementButton_CreateOrderElementMutation($input: CreateOrderElementInput!) {
		Admin {
			ElearningV2 {
				createOrderElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const CreateOrderElementButton = ({ eLearningContentId }: CreateOrderElementButtonProps) => {
	const [createOrderElement, isAddingOrderElement] =
		useMutation<CreateOrderElementButton_CreateOrderElementMutation>(
			CREATE_ORDER_ELEMENT_MUTATION,
		);

	return (
		<Button
			disabled={isAddingOrderElement}
			tooltip={`Reihenfolge-Baustein anlegen`}
			icon={EpicIcons.SORT_AMOUNT_DOWN}
			onClick={() => {
				createOrderElement({
					variables: {
						input: {
							title: "Neues Element",
							eLearningContentNodeId: eLearningContentId,
						},
					},
				});
			}}
		/>
	);
};
