/**
 * @generated SignedSource<<a9ed31bdf0748aec19a8c97bfcc8691f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CreatePersonalizedEmailTemplateInput = {
  clientMutationId?: string | null | undefined;
  data: PersonalizedEmailTemplateInput;
};
export type PersonalizedEmailTemplateInput = {
  body: string;
  key: string;
  previewText: string;
  subject: string;
  variables: ReadonlyArray<string>;
};
export type createEmailTemplateButton_CreateMutation$variables = {
  input: CreatePersonalizedEmailTemplateInput;
};
export type createEmailTemplateButton_CreateMutation$data = {
  readonly Admin: {
    readonly Email: {
      readonly createPersonalizedEmailTemplate: {
        readonly data: {
          readonly node: {
            readonly id: string;
          };
        };
      } | null | undefined;
    };
  };
};
export type createEmailTemplateButton_CreateMutation = {
  response: createEmailTemplateButton_CreateMutation$data;
  variables: createEmailTemplateButton_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Email",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "CreatePersonalizedEmailTemplatePayload",
            "kind": "LinkedField",
            "name": "createPersonalizedEmailTemplate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonalizedEmailTemplatesEdge",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonalizedEmailTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createEmailTemplateButton_CreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createEmailTemplateButton_CreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "482cc932e42ca08f21c684f8c30587ef",
    "id": null,
    "metadata": {},
    "name": "createEmailTemplateButton_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation createEmailTemplateButton_CreateMutation(\n  $input: CreatePersonalizedEmailTemplateInput!\n) {\n  Admin {\n    Email {\n      createPersonalizedEmailTemplate(input: $input) {\n        data {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f8535a6511e74580f2224a4454321e8";

export default node;
