import { ButtonType, EpicIcons } from "@constellation-academy/epic-ui";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { Button } from "@components/button";
import {
	DefaultTextFieldComponent,
	DefaultYesNoFieldComponent,
} from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { ProductsTableFiltersState } from "@screens/products/parts/products-table-filters/products-table-filters.types";
import { ProductsScreenContext } from "@screens/products/products.context";
import { headerWrapperClass, wrapperClass } from "@screens/products/products.styles";

export const ProductsTableFilters = () => {
	const { setFilters } = useContext(ProductsScreenContext);

	const initialValues: ProductsTableFiltersState = {
		isHiddenOpt: undefined,
		titleOpt: undefined,
	};

	const formik = useFormik<ProductsTableFiltersState>({
		initialValues,
		onSubmit: (values: ProductsTableFiltersState, { setSubmitting }) => {
			setFilters(values);
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		formik.handleReset(initialValues);
		formik.handleSubmit();
	};

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<div className={headerWrapperClass}>
				<div className={wrapperClass}>
					<ValidatedField<ProductsTableFiltersState, boolean>
						name="isHiddenOpt"
						label="Ist Versteckt"
						className="mr-2"
						component={DefaultYesNoFieldComponent}
						formikConfig={formik}
					/>
					<ValidatedField<ProductsTableFiltersState, string>
						name="titleOpt"
						label="Name des Produkts"
						className="mr-2"
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>
				</div>
				<div className={wrapperClass}>
					<Button
						disabled={false}
						type={ButtonType.Reset}
						onClick={clearForm}
						icon={EpicIcons.TIMES}
					/>
					<Button disabled={false} type={ButtonType.Submit} icon={EpicIcons.SEARCH} />
				</div>
			</div>
		</form>
	);
};
