import { Dialog } from "@constellation-academy/epic-ui";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { type FileSelectionFieldProps, type FileV2 } from "./file-selection-field.types";
import { type RenderConfig } from "../../../components/ValidatedField";
import { withSuspense } from "../../../components/withSuspense";
import { type FileTypeOption } from "../file-filters/file-filters.const";
import { FilesTable } from "../files-table";

const FileSelectionFieldComponent = ({
	name,
	selectedFile,
	setSelectedFile,
	onChange,
	fileTypeOptions,
	disabled,
	canUploadFiles,
	canDeleteFiles,
	filterByFileTypes,
}: FileSelectionFieldProps) => {
	const [selection, setSelection] = useState<FileV2 | undefined>(selectedFile);
	const [isModalOpen, setModalOpen] = useState<boolean>(false);

	return (
		<div className="flex">
			<InputText
				onClick={() => {
					setModalOpen(true);
				}}
				className="mr-2 flex-grow-1 w-auto"
				name={name}
				disabled={true}
				value={selection?.name ?? "Bitte Datei auswählen"}
			/>
			{selection?.url && (
				<a target="_blank" rel="noopener noreferrer" href={selection?.url}>
					<Button
						className="w-auto p-button-secondary p-2 mr-2"
						type={"button"}
						disabled={selection?.id === undefined}
						label={""}
						icon="pi pi-download"
					/>
				</a>
			)}
			<Button
				className="w-auto p-button-secondary p-2"
				type={"button"}
				disabled={selection?.id === undefined || disabled}
				label={""}
				icon="pi pi-times"
				onClick={() => {
					setSelection(undefined);
					setSelectedFile({ id: "", name: "" });
				}}
			/>
			<Button
				className="w-auto ml-2"
				type={"button"}
				disabled={disabled}
				onClick={() => {
					setModalOpen(true);
				}}
			>
				Datei auswählen
			</Button>

			<Dialog
				title="Datei auswählen"
				onHide={() => {
					setModalOpen(false);
				}}
				footerNode={
					<div>
						<Button
							label={"Auswählen"}
							icon="pi pi-check"
							type="button"
							onClick={() => {
								setSelectedFile(selection);
								setModalOpen(false);
								onChange?.();
							}}
							autoFocus
						/>
					</div>
				}
				visible={isModalOpen}
			>
				<FilesTable
					selectionSettings={{
						selection,
						onSelectionChange: (e) => {
							setSelection(e.value);
						},
						fileTypeOptions: filterByFileTypes
							? filterByFileTypes.map((e) => ({ value: e, label: e }))
							: fileTypeOptions,
					}}
					canUploadFiles={canUploadFiles}
					canDeleteFiles={canDeleteFiles}
				/>
			</Dialog>
		</div>
	);
};

export const DefaultFileSelectionField = (canUploadFiles: boolean, canDeleteFiles: boolean) => {
	const Component = ({ fieldName, fieldValue, updateField, onChange }: RenderConfig<FileV2>) => {
		return (
			<FileSelectionField
				name={fieldName}
				selectedFile={fieldValue}
				setSelectedFile={updateField}
				onChange={onChange}
				canUploadFiles={canUploadFiles}
				canDeleteFiles={canDeleteFiles}
			/>
		);
	};
	Component.DisplayName = "DefaultFileSelectionFieldV2 - Inner";
	return Component;
};

export const LimitedFileSelectionField = (
	filterByFileTypes: string[],
	canUploadFiles: boolean,
	canDeleteFiles: boolean,
) => {
	const Component = ({
		fieldName,
		fieldValue,
		updateField,
		onChange,
		disabled,
	}: RenderConfig<FileV2>) => {
		return (
			<FileSelectionField
				name={fieldName}
				selectedFile={fieldValue}
				setSelectedFile={updateField}
				filterByFileTypes={filterByFileTypes}
				onChange={onChange}
				disabled={disabled}
				canUploadFiles={canUploadFiles}
				canDeleteFiles={canDeleteFiles}
			/>
		);
	};
	Component.displayName = "LimitedFileSelectionField - Inner";
	return Component;
};

export const LimitedTypeOptionsFileSelectionField = (
	fileTypeOptions: FileTypeOption[],
	canUploadFiles: boolean,
	canDeleteFiles: boolean,
) => {
	const Component = ({
		fieldName,
		fieldValue,
		updateField,
		onChange,
		disabled,
	}: RenderConfig<FileV2>) => {
		return (
			<FileSelectionField
				name={fieldName}
				selectedFile={fieldValue}
				setSelectedFile={updateField}
				fileTypeOptions={fileTypeOptions}
				onChange={onChange}
				disabled={disabled}
				canUploadFiles={canUploadFiles}
				canDeleteFiles={canDeleteFiles}
			/>
		);
	};
	Component.displayName = "LimitedTypeOptionsFileSelectionFieldV2 - Inner";
	return Component;
};

export const FileSelectionField = withSuspense(FileSelectionFieldComponent);
