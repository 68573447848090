/**
 * @generated SignedSource<<4f6636c6f1fe50240ea3e7e20d809b18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditFileElementV2Input = {
  clientMutationId?: string | null | undefined;
  fileElementId: string;
  fileId?: string | null | undefined;
  title: string;
};
export type fileElementForm_EditFileElementMutation$variables = {
  input: EditFileElementV2Input;
};
export type fileElementForm_EditFileElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editFileElement: {
        readonly fileElement: {
          readonly " $fragmentSpreads": FragmentRefs<"fileElementForm_FileElementV2Fragment">;
        };
      } | null | undefined;
    };
  };
};
export type fileElementForm_EditFileElementMutation = {
  response: fileElementForm_EditFileElementMutation$data;
  variables: fileElementForm_EditFileElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fileElementForm_EditFileElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditFileElementV2Payload",
                "kind": "LinkedField",
                "name": "editFileElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FileElementV2",
                    "kind": "LinkedField",
                    "name": "fileElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "fileElementForm_FileElementV2Fragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fileElementForm_EditFileElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditFileElementV2Payload",
                "kind": "LinkedField",
                "name": "editFileElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FileElementV2",
                    "kind": "LinkedField",
                    "name": "fileElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "file",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b65f39ab4f0bb905c3e3e00c3d60d55d",
    "id": null,
    "metadata": {},
    "name": "fileElementForm_EditFileElementMutation",
    "operationKind": "mutation",
    "text": "mutation fileElementForm_EditFileElementMutation(\n  $input: EditFileElementV2Input!\n) {\n  Admin {\n    ElearningV2 {\n      editFileElement(input: $input) {\n        fileElement {\n          ...fileElementForm_FileElementV2Fragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment fileElementForm_FileElementV2Fragment on FileElementV2 {\n  id\n  title\n  file {\n    id\n    name\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "0b8138e25c9b320fa841707dfbca1f73";

export default node;
