import { Icon, Tooltip, EpicIcons } from "@constellation-academy/epic-ui";
import { useState } from "react";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { ConfirmDialog } from "@components/confirm-dialog";
import { formatDateTime } from "@components/DateTimeDisplay";
import { NumberInputDialog } from "@components/number-input-dialog";
import { userRootNodeItemTreeItem_AssignPointsToUserMutation } from "@relay/userRootNodeItemTreeItem_AssignPointsToUserMutation.graphql";
import type { userRootNodeItemTreeItem_SetTreeStateHeadMutation } from "@relay/userRootNodeItemTreeItem_SetTreeStateHeadMutation.graphql";
import { colorSuccess100Class, colorWarning100Class } from "@themes/color-classes";
import { P2Span, P3Span } from "@themes/font-tags";
import {
	ASSIGN_POINTS_TO_USER_MUTATION,
	SET_TREE_STATE_HEAD_MUTATION,
} from "./user-root-node-item-tree-item.graphql";
import {
	assignPointsButtonClass,
	setCurrentHeadContentButtonClass,
	treeItemClass,
	treeItemModificationsClass,
	treeItemTitleClass,
} from "./user-root-node-item-tree-item.styles";
import { UserRootNodeItemTreeItemProps } from "./user-root-node-item-tree-item.types";
import { treeWrapperClass } from "../user-root-node-item-tree/user-root-node-item-tree.styles";
import { learnOpportunityV2IdToTreeNodeId } from "../user-root-node-item-tree/user-root-node-item-tree.utils";

export const UserRootNodeItemTreeItem = ({
	rootId,
	userId,
	item,
	hasPaywall,
	currentHeadContentId,
	submissions,
	gamificationPoints,
	depth = 0,
}: UserRootNodeItemTreeItemProps) => {
	const [expanded, setExpanded] = useState(depth === 0);
	const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
	const [assignPointsDialogVisible, setAssignPointsDialogVisible] = useState(false);
	const isPaywallBarrier = hasPaywall && item.isPaywall;
	const isCurrentTreeState = item.data?.id === currentHeadContentId;
	const hasModifications = isPaywallBarrier || isCurrentTreeState;
	const isContentNode = item.data?.typeDefinition.definitionType === "content";
	const submission = submissions?.find(
		(s) => learnOpportunityV2IdToTreeNodeId(s.learnOpportunity?.id ?? "") === item.data?.id,
	);
	const tooltipId = `tooltip-${item.id.replace("=", "")}`;

	const isContentBeforePaywall = !isPaywallBarrier && !item.isAfterPaywall && isContentNode;
	const isContentWithSubmissionBeforePaywall = isContentBeforePaywall && Boolean(submission);
	const isContentNotCurrentBeforePaywall = depth > 1 && !isCurrentTreeState;

	const [setTreeStateHead] = useMutation<userRootNodeItemTreeItem_SetTreeStateHeadMutation>(
		SET_TREE_STATE_HEAD_MUTATION,
	);
	const [assignPointsToUser] = useMutation<userRootNodeItemTreeItem_AssignPointsToUserMutation>(
		ASSIGN_POINTS_TO_USER_MUTATION,
	);

	const handleSetNodeAsHeadContentOnClick = () => {
		if (item.isCurrentHeadOrAfterCurrentHead) {
			handleSetNodeAsHeadContentOnConfirm();
			return;
		}
		setConfirmationDialogVisible(true);
	};

	const handleSetNodeAsHeadContentOnCancel = () => {
		setConfirmationDialogVisible(false);
	};

	const handleSetNodeAsHeadContentOnConfirm = () => {
		setTreeStateHead({
			variables: {
				input: {
					rootId,
					userId,
					newHeadContentId: item.id,
				},
			},
		});
		setConfirmationDialogVisible(false);
	};

	const handleAssignPointsOnCancel = () => {
		setAssignPointsDialogVisible(false);
	};

	const handleAssignPointsOnConfirm = (points: number) => {
		if (!item.data?.id) {
			return;
		}
		setAssignPointsDialogVisible(false);
		assignPointsToUser({
			variables: {
				input: {
					userId,
					rootId,
					amount: points,
					contentId: item.data.id,
				},
			},
			onCompleted: () => {
				toast.success(
					"Punkte erfolgreich gesetzt. Änderungen werden erst nach einem Neuladen der Seite sichtbar.",
				);
			},
			onError: () => {
				toast.error("Fehler beim Setzen der Punkte");
			},
		});
	};

	return (
		<>
			<ConfirmDialog
				title="Position vor die aktuellen Position setzen"
				content={
					<div>
						<P2Span>
							Möchtest du wirklich die Position vor die aktuelle Position setzen?
						</P2Span>
						<br />
						<P2Span>Dies kann unerwünschte Nebeneffekte auslösen:</P2Span>
						<ul>
							<li>
								<P3Span>
									- Der Nutzer setzt nach Abschluss der neuen Position die
									Lektion/das Modul an der alten Position fort.
								</P3Span>
							</li>
						</ul>
					</div>
				}
				onCancel={handleSetNodeAsHeadContentOnCancel}
				onConfirm={handleSetNodeAsHeadContentOnConfirm}
				showDialog={confirmationDialogVisible}
			/>
			<NumberInputDialog
				title="Punkte vergeben"
				content={
					<P2Span>
						Wie viele Punkte sollen gesetzt werden? Dies überschreibt die aktuelle
						Punkteanzahl! <br />
						Maximal {item.receivableGamificationPoints} Punkte.
					</P2Span>
				}
				maxValue={item.receivableGamificationPoints}
				onCancel={handleAssignPointsOnCancel}
				onConfirm={handleAssignPointsOnConfirm}
				showDialog={assignPointsDialogVisible}
			/>
			<div
				className={treeItemClass({
					hasIndentation: depth > 0,
				})}
			>
				<div
					className={treeItemTitleClass({
						isPaywallBarrier,
						isCurrentTreeState,
						isContentNode,
					})}
				>
					{!isContentNode && (
						<button
							type="button"
							onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
						>
							<Icon icon={expanded ? EpicIcons.ANGLE_DOWN : EpicIcons.ANGLE_RIGHT} />
						</button>
					)}
					{hasModifications && (
						<div className={treeItemModificationsClass}>
							{isPaywallBarrier && (
								<P3Span className={colorWarning100Class}>Paywall</P3Span>
							)}
							{isCurrentTreeState && (
								<P3Span className={colorSuccess100Class}>Aktuell</P3Span>
							)}
						</div>
					)}
					<P2Span>{item.data?.structureDefinition?.title}</P2Span>
					{isContentWithSubmissionBeforePaywall && (
						<div>
							<Tooltip
								target={`#${tooltipId}`}
								content={`Erstes Update: ${formatDateTime(
									submission?.startedAt ?? "",
								)}
Letztes Update: ${formatDateTime(submission?.lastUpdated ?? "")}`}
							/>
							<div id={tooltipId}>
								<Icon icon={EpicIcons.INFO_CIRCLE} />
							</div>
						</div>
					)}
					{isContentNode &&
						item.receivableGamificationPoints &&
						item.receivableGamificationPoints > 0 && (
							<P3Span>
								{item.earnedGamificationPoints ?? 0}/
								{item.receivableGamificationPoints}
							</P3Span>
						)}
					{isContentNode && (
						<button
							type="button"
							onClick={() => setAssignPointsDialogVisible(true)}
							className={assignPointsButtonClass}
						>
							<P3Span>Punkte setzen</P3Span>
						</button>
					)}
					{isContentNotCurrentBeforePaywall && (
						<button
							type="button"
							onClick={handleSetNodeAsHeadContentOnClick}
							className={setCurrentHeadContentButtonClass}
						>
							<P3Span>Als aktuelle Position setzen</P3Span>
						</button>
					)}
				</div>
				{expanded && item.children.length > 0 && (
					<div className={treeWrapperClass}>
						{item.children.map((child) => (
							<UserRootNodeItemTreeItem
								key={child.id}
								rootId={rootId}
								userId={userId}
								item={child}
								hasPaywall={hasPaywall}
								currentHeadContentId={currentHeadContentId}
								submissions={submissions}
								gamificationPoints={gamificationPoints}
								depth={depth + 1}
							/>
						))}
					</div>
				)}
			</div>
		</>
	);
};
