import { RelayPageDataTable } from "@constellation-academy/epic-ui";
import React, { useContext } from "react";
import { DateTimeDisplay } from "@components/DateTimeDisplay";
import { messageDefinitionsTable_MessageDefinitionFragment$key } from "@relay/messageDefinitionsTable_MessageDefinitionFragment.graphql";
import { messageDefinitionsTable_QueryFragment$key } from "@relay/messageDefinitionsTable_QueryFragment.graphql";
import { messageDefinitionsTable_Refetch } from "@relay/messageDefinitionsTable_Refetch.graphql";
import { MessageDefinitionsContext } from "@screens/message-definitions/message-definitions.context";
import { CreateMessageDispatchButton } from "@screens/message-definitions/parts/message-definitions-table/create-message-dispatch-button";
import { DeleteMessageDefinitionButton } from "@screens/message-definitions/parts/message-definitions-table/delete-message-definition-button";
import { EditMessageDefinitionButton } from "@screens/message-definitions/parts/message-definitions-table/edit-message-definition-button";
import { ROWS_COUNT } from "./message-definitions-table.const";
import { MESSAGE_DEFINITION_FRAGMENT, QUERY_FRAGMENT } from "./message-definitions-table.graphql";
import { buttonWrapperClass } from "./message-definitions-table.styles";
import { MessageDefinitionsTableProps } from "./message-definitions-table.types";

export const MessageDefinitionsTable = ({
	messageDefinitionsFragmentRef,
}: MessageDefinitionsTableProps) => {
	const { setConnectionId, filters } = useContext(MessageDefinitionsContext);

	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};

	return (
		<RelayPageDataTable<
			messageDefinitionsTable_Refetch,
			messageDefinitionsTable_QueryFragment$key,
			messageDefinitionsTable_MessageDefinitionFragment$key
		>
			queryFragment={QUERY_FRAGMENT}
			parentFragmentRef={messageDefinitionsFragmentRef}
			nodeInlineFragment={MESSAGE_DEFINITION_FRAGMENT}
			extractConnection={(data) => data.Admin.MessageDefinitions.MessageDefinitions}
			onConnectionIdChange={handleOnConnectionIdChange}
			rowsCount={ROWS_COUNT}
			filters={filters}
			emptyMessage={"Keine Nachrichten-Vorlagen"}
		>
			{(Column) => (
				<>
					<Column header="Interner Titel" body={(item) => item.internalTitle} />
					<Column
						header="Erstellt am"
						body={(item) => <DateTimeDisplay value={item.createdAt} hideTimezone />}
					/>
					<Column
						header="Anzeigen bis"
						body={(item) => <DateTimeDisplay value={item.showUntil} hideTimezone />}
					/>
					<Column
						header="Aktionen"
						style={{ width: "20%" }}
						body={(item) => (
							<div className={buttonWrapperClass}>
								<CreateMessageDispatchButton messageDefinitionFragmentRef={item} />
								<EditMessageDefinitionButton messageDefinitionId={item.id} />
								<DeleteMessageDefinitionButton messageDefinitionId={item.id} />
							</div>
						)}
					/>
				</>
			)}
		</RelayPageDataTable>
	);
};
