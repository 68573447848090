import { EpicIcons } from "@constellation-academy/epic-ui";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariant } from "@components/button";
import { Paths } from "@routes/paths";
import { ButtonWrapper } from "./edit-email-template-button.styles";
import { type EditEmailTemplateButtonProps } from "./edit-email-template-button.types";

export const EditEmailTemplateButton = ({
	personalizedEmailTemplateId,
}: EditEmailTemplateButtonProps) => {
	const navigate = useNavigate();
	const handleOnEditClick = (id: string) => {
		navigate(Paths.emailTemplates.withId(id).edit.path);
	};

	return (
		<ButtonWrapper>
			<Button
				icon={EpicIcons.FILE_EDIT}
				variant={ButtonVariant.Strong}
				onClick={() => {
					handleOnEditClick(personalizedEmailTemplateId);
				}}
			/>
		</ButtonWrapper>
	);
};
