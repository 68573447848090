/**
 * @generated SignedSource<<3640510f6ba7f946aa2ae07c3f58ce09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SetUserInAccountGroupsInput = {
  accountId: string;
  clientMutationId?: string | null | undefined;
  groupIds: ReadonlyArray<string>;
  userId: string;
};
export type editUserInAccountGroup_SetUserInAccountGroupsMutation$variables = {
  input: SetUserInAccountGroupsInput;
};
export type editUserInAccountGroup_SetUserInAccountGroupsMutation$data = {
  readonly Admin: {
    readonly Auth: {
      readonly setUserInAccountGroups: {
        readonly userInAccount: {
          readonly node: {
            readonly groups: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
            }>;
            readonly user: {
              readonly activated: boolean;
              readonly email: string;
              readonly id: string;
              readonly name: string;
            };
          };
        };
      } | null | undefined;
    };
  };
};
export type editUserInAccountGroup_SetUserInAccountGroupsMutation = {
  response: editUserInAccountGroup_SetUserInAccountGroupsMutation$data;
  variables: editUserInAccountGroup_SetUserInAccountGroupsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserInAccountGroup",
  "kind": "LinkedField",
  "name": "groups",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activated",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editUserInAccountGroup_SetUserInAccountGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetUserInAccountGroupsPayload",
                "kind": "LinkedField",
                "name": "setUserInAccountGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountEdge",
                    "kind": "LinkedField",
                    "name": "userInAccount",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editUserInAccountGroup_SetUserInAccountGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetUserInAccountGroupsPayload",
                "kind": "LinkedField",
                "name": "setUserInAccountGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountEdge",
                    "kind": "LinkedField",
                    "name": "userInAccount",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0525b2d7f1fdd709ef3b24b45879926d",
    "id": null,
    "metadata": {},
    "name": "editUserInAccountGroup_SetUserInAccountGroupsMutation",
    "operationKind": "mutation",
    "text": "mutation editUserInAccountGroup_SetUserInAccountGroupsMutation(\n  $input: SetUserInAccountGroupsInput!\n) {\n  Admin {\n    Auth {\n      setUserInAccountGroups(input: $input) {\n        userInAccount {\n          node {\n            groups {\n              id\n              name\n            }\n            user {\n              id\n              name\n              email\n              activated\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b2df318966f1d789d994b676dec611e";

export default node;
