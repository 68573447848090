import { z } from "zod";

export const FlexLicenseDefinitionFormSchema = z.object({
	name: z.string().min(1),
	includedNodeIds: z
		.array(
			z.object({
				id: z.string(),
				title: z.string(),
			}),
		)
		.optional(),
	excludedNodeIds: z
		.array(
			z.object({
				id: z.string(),
				title: z.string(),
			}),
		)
		.optional(),
});

export type FlexLicenseDefinitionFormSchema = z.infer<typeof FlexLicenseDefinitionFormSchema>;
export type FlexLicenseDefinitionFormState = FlexLicenseDefinitionFormSchema;
