import { graphql } from "react-relay";

export const TREE_NODE_FRAGMENT = graphql`
	fragment rewardForm_TreeNodeFragment on TreeNode {
		id
	}
`;

export const REWARD_CONFIGURATION_FRAGMENT = graphql`
	fragment rewardForm_RewardFragment on Reward {
		id
		kind
		... on ExternalLicenseReward {
			...externalLicenseRewardForm_ExternalLicenseRewardFragment
		}
	}
`;
