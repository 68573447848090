import { type PathParams } from "@constellation-academy/epic-ui";
import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { BaseScreen } from "@components/base-screen";
import { Button } from "@components/button";
import { DataTable } from "@components/data-table";
import { formatDateTime } from "@components/DateTimeDisplay";
import { type reportDetails_Query } from "@relay/reportDetails_Query.graphql";
import { Paths } from "@routes/paths";
import { QUERY } from "@screens/report-details/report-details.grapqhl";
import { HeaderWrapper } from "@screens/report-details/report-details.styles";
import {
	getProblemTypeTranslations,
	getReportTypeTranslations,
} from "@screens/report-details/report-details.utils";
import { type ReportsPath } from "@screens/reports/reports.paths";

export const ReportDetailsScreen = () => {
	const navigate = useNavigate();
	const { reportId } = useParams<PathParams<typeof ReportsPath>>();
	const { node } = useLazyLoadQuery<reportDetails_Query>(QUERY, {
		reportId: reportId!,
		skip: !reportId,
	});
	if (!reportId) {
		navigate(Paths.reports.path);
		return;
	}

	const anonymousFallback = "Anonym";
	const notProvidedFallback = "-";
	const rows: Array<{ label: string; value: string }> = [
		{
			label: "Refernznummmer",
			value: node?.referenceNumber ?? notProvidedFallback,
		},
		{
			label: "Erstellt am",
			value: node?.createdAt ? formatDateTime(node?.createdAt) : notProvidedFallback,
		},
		{
			label: "Vorname",
			value: node?.data?.firstName ?? anonymousFallback,
		},
		{
			label: "Nachname",
			value: node?.data?.lastName ?? anonymousFallback,
		},
		{
			label: "E-Mail",
			value: node?.data?.email ?? anonymousFallback,
		},
		{
			label: "Meldungsart",
			value: getReportTypeTranslations(node?.data?.reportType) || notProvidedFallback,
		},
		{
			label: "Problemart",
			value: getProblemTypeTranslations(node?.data?.problemType) || notProvidedFallback,
		},
		{
			label: "URL",
			value: node?.data?.url ?? "",
		},
		{
			label: "Extra",
			value: node?.data?.extra ?? notProvidedFallback,
		},
	];
	const handleOnClick = () => {
		navigate(-1);
	};
	return (
		<BaseScreen>
			<DataTable
				emptyMessage={
					<div className="flex justify-content-center align-items-center">
						<div className="mr-2">Es wurden keine Meldungen gefunden.</div>
					</div>
				}
				header={
					<HeaderWrapper>
						<h1>Meldung mit Referenznummer [{node?.referenceNumber}]</h1>
						<Button label={"Zurück"} onClick={handleOnClick} />
					</HeaderWrapper>
				}
				className="mb-3"
				value={rows}
			>
				{(Column) => (
					<>
						<Column header="" field="label" />
						<Column
							header=""
							body={(row) => {
								return match(row.label)
									.with("E-Mail", () =>
										row.value === notProvidedFallback ? (
											notProvidedFallback
										) : (
											<a href={`mailto:${row.value}`}>{row.value}</a>
										),
									)
									.with("URL", () =>
										row.value ? (
											<a href={row.value} target={"_blank"} rel="noreferrer">
												{row.value}
											</a>
										) : (
											<div>{notProvidedFallback}</div>
										),
									)
									.otherwise(() => row.value);
							}}
						/>
					</>
				)}
			</DataTable>
		</BaseScreen>
	);
};
