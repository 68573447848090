import { Button, EpicIcons } from "@constellation-academy/epic-ui";
import { OrderList } from "primereact/orderlist";
import React from "react";

import { CreatePageButton } from "@features/pages-editor/create-page-button";
import { EditPageButton } from "@features/pages-editor/edit-page-button";
import {
	headerClass,
	orderListActionsClass,
	orderListItemClass,
} from "@features/pages-editor/pages-editor.styles";
import { PageItem, PagesEditorProps } from "@features/pages-editor/pages-editor.types";
import { useDialogLogic } from "@hooks/use-dialog-logic";
import { H1Span } from "@themes/font-tags";

export const PagesEditor = ({
	onDeletePage,
	onShowEditPage,
	onShowCreatePage,
	onRearrangePages,
	pages,
	title = "Seiten",
	createLabel = "Seite hinzufügen",
}: PagesEditorProps) => {
	const { showDialog, dialogComponent } = useDialogLogic();

	const deletePageWithConfirmation = (pageId: string) => {
		showDialog({
			title: "Seite löschen",
			content:
				"Soll diese Seite wirklich gelöscht werden? Das kann nicht rückgängig gemacht werden.",
			dialogCallback: (result) => {
				if (result === "Accept") {
					onDeletePage(pageId);
				}
			},
		});
	};

	return (
		<>
			{dialogComponent}
			<div className={headerClass}>
				<H1Span>{title}</H1Span>
				<CreatePageButton
					onCreatePage={onShowCreatePage}
					createPageButtonLabel={createLabel}
				/>
			</div>
			<OrderList
				value={pages}
				dragdrop={true}
				onChange={(event) =>
					onRearrangePages(event.value.map((item: { id: string }) => item.id))
				}
				itemTemplate={(item: PageItem) => {
					return (
						<div key={item.id} className={orderListItemClass}>
							{item.title}

							<div className={orderListActionsClass}>
								{item && (
									<EditPageButton onEditPage={() => onShowEditPage(item.id)} />
								)}
								<Button
									disabled={pages?.length === 1}
									icon={EpicIcons.TRASH}
									onClick={() => deletePageWithConfirmation(item.id)}
								/>
							</div>
						</div>
					);
				}}
			/>
		</>
	);
};
