/**
 * @generated SignedSource<<5a05383482d2e4e28c99c81cda89e2cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MarkMistakeElementForm_MarkMistakeElementFragment$data = {
  readonly id: string;
  readonly text: string;
  readonly title: string;
  readonly " $fragmentType": "MarkMistakeElementForm_MarkMistakeElementFragment";
};
export type MarkMistakeElementForm_MarkMistakeElementFragment$key = {
  readonly " $data"?: MarkMistakeElementForm_MarkMistakeElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MarkMistakeElementForm_MarkMistakeElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MarkMistakeElementForm_MarkMistakeElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "type": "MarkMistakesElement",
  "abstractKey": null
};

(node as any).hash = "b21768a0c508b4622b36b7310d002140";

export default node;
