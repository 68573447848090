import { ButtonType } from "@constellation-academy/epic-ui";
import { useFormik } from "formik";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonVariant } from "@components/button";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { FileSelectionField, type FileV2 } from "@features/files/file-selection-field";
import { useHasPermissions } from "@hooks/use-has-permissions";
import type { levelDefinitionForm_editLevelDefinitionMutation } from "@relay/levelDefinitionForm_editLevelDefinitionMutation.graphql";
import type { levelDefinitionForm_LevelDefinitionFragment$key } from "@relay/levelDefinitionForm_LevelDefinitionFragment.graphql";
import {
	EDIT_LEVEL_DEFINITION_MUTATION,
	LEVEL_DEFINITION_FRAGMENT,
} from "./level-definition-form.graphql";
import { buttonWrapperClass } from "./level-definition-form.styles";
import {
	LevelDefintionFormSchema,
	type LevelDefinitionFormProps,
	type LevelDefinitionFormState,
} from "./level-definition-form.types";

export const LevelDefintionForm = ({
	levelDefinitionFragmentRef,
	onSubmit,
}: LevelDefinitionFormProps) => {
	const navigate = useNavigate();

	const levelDefintion = useFragment<levelDefinitionForm_LevelDefinitionFragment$key>(
		LEVEL_DEFINITION_FRAGMENT,
		levelDefinitionFragmentRef,
	);

	const [editLevelDefinition] = useMutation<levelDefinitionForm_editLevelDefinitionMutation>(
		EDIT_LEVEL_DEFINITION_MUTATION,
	);

	const formik = useFormik<LevelDefinitionFormState>({
		initialValues: {
			title: levelDefintion.title,
			shortDescription: levelDefintion.shortDescription,
			text: levelDefintion.text,
			image: levelDefintion.image,
		},
		validationSchema: LevelDefintionFormSchema,
		onSubmit: (values, { setSubmitting }) => {
			let imageId: string | null | undefined = values.image?.id;
			if (imageId === "") {
				imageId = null;
			}

			editLevelDefinition({
				variables: {
					input: {
						id: levelDefintion.id,
						newTitle: values.title,
						newShortDescription: values.shortDescription,
						newText: values.text,
						newImageOpt: imageId,
					},
				},
				onCompleted: () => {
					toast.success("Level-Definition erfolgreich bearbeitet!");
				},
				onError: () => {
					toast.error(
						"Level-Definiton konnte leider nicht bearbeitet werden, bitte versuche es erneut",
					);
				},
			});
			onSubmit?.(values);
			setSubmitting(false);
		},
	});

	const handleBackOnClick = () => {
		navigate(-1);
	};

	const canUpload = useHasPermissions(["UserInAccountPermission_LevelDefinitionAdmin_Modify"]);
	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	return (
		<form onSubmit={formik.handleSubmit}>
			<ValidatedField<LevelDefinitionFormState, string>
				name="title"
				label="Titel"
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<LevelDefinitionFormState, string>
				name="shortDescription"
				label="Kurzbeschreibung"
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<LevelDefinitionFormState, string>
				name="text"
				label="Text"
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<LevelDefinitionFormState, FileV2>
				name="image"
				label="Bild"
				formikConfig={formik}
				component={({ fieldName, fieldValue, updateField, onChange }) => {
					return (
						<FileSelectionField
							name={fieldName}
							selectedFile={fieldValue}
							setSelectedFile={updateField}
							filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
							onChange={onChange}
							canUploadFiles={canUpload}
							canDeleteFiles={canDelete}
						/>
					);
				}}
			/>
			<div className={buttonWrapperClass}>
				<Button
					type={ButtonType.Button}
					variant={ButtonVariant.Default}
					onClick={handleBackOnClick}
					label="Zurück"
					stretch
				/>
				<Button
					type={ButtonType.Submit}
					variant={ButtonVariant.Strong}
					label="Speichern"
					stretch
				/>
			</div>
		</form>
	);
};
