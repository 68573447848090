/**
 * @generated SignedSource<<52630f0260ff024f2285c1a0a16acb4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editRootExtensionForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectAcademiesTagField_QueryFragment">;
  readonly " $fragmentType": "editRootExtensionForm_QueryFragment";
};
export type editRootExtensionForm_QueryFragment$key = {
  readonly " $data"?: editRootExtensionForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editRootExtensionForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editRootExtensionForm_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "selectAcademiesTagField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "19fc243ff011cac7f35448fa238403c8";

export default node;
