/**
 * @generated SignedSource<<7a39b5aaa74bcd8418047b9688e3ae08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectAcademiesTagField_Refetch$variables = {
  after?: string | null | undefined;
  before?: string | null | undefined;
  first?: number | null | undefined;
  isClickable?: boolean | null | undefined;
  isRoot?: boolean | null | undefined;
  isTopic?: boolean | null | undefined;
  last?: number | null | undefined;
  name?: string | null | undefined;
};
export type selectAcademiesTagField_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectAcademiesTagField_QueryFragment">;
};
export type selectAcademiesTagField_Refetch = {
  response: selectAcademiesTagField_Refetch$data;
  variables: selectAcademiesTagField_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isClickable"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isRoot"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isTopic"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isClickable",
    "variableName": "isClickable"
  },
  {
    "kind": "Variable",
    "name": "isRoot",
    "variableName": "isRoot"
  },
  {
    "kind": "Variable",
    "name": "isTopic",
    "variableName": "isTopic"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectAcademiesTagField_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "selectAcademiesTagField_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectAcademiesTagField_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AcademiesTagAdminSchema",
            "kind": "LinkedField",
            "name": "AcademiesTag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AcademiesTagsConnection",
                "kind": "LinkedField",
                "name": "SelectTags",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AcademiesTagsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isAcademiesTag"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AcademiesTagData",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isClickable",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isTopic",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "name",
                  "isTopic",
                  "isClickable",
                  "isRoot"
                ],
                "handle": "connection",
                "key": "selectAcadamiesTagField_SelectTags",
                "kind": "LinkedHandle",
                "name": "SelectTags"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "58a9a5b4032a418fde28cb9f9042affc",
    "id": null,
    "metadata": {},
    "name": "selectAcademiesTagField_Refetch",
    "operationKind": "query",
    "text": "query selectAcademiesTagField_Refetch(\n  $after: String\n  $before: String\n  $first: Int = 20\n  $isClickable: Boolean\n  $isRoot: Boolean\n  $isTopic: Boolean\n  $last: Int\n  $name: String\n) {\n  ...selectAcademiesTagField_QueryFragment_13DgXL\n}\n\nfragment selectAcademiesTagField_AcademiesTagFragment on AcademiesTag {\n  __isAcademiesTag: __typename\n  id\n  data {\n    name\n    isClickable\n    isTopic\n  }\n}\n\nfragment selectAcademiesTagField_QueryFragment_13DgXL on Query {\n  Admin {\n    AcademiesTag {\n      SelectTags(name: $name, before: $before, first: $first, after: $after, last: $last, isTopic: $isTopic, isClickable: $isClickable, isRoot: $isRoot) {\n        edges {\n          node {\n            __typename\n            ...selectAcademiesTagField_AcademiesTagFragment\n            id\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9f33021b8fd95b39f1b4662f74e9f60d";

export default node;
