/**
 * @generated SignedSource<<80e20f3bf2b0eb98384186a7bf579238>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type ContentKind = "Async" | "ELearning";
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "persolog" | "persologEvaluation" | "persologQuestion" | "podcast" | "podcastWithTimestamp" | "reminder" | "text" | "textWithPages" | "video";
export type StructureType = "child" | "root";
export type TypeDefinitionType = "branch" | "content";
import { FragmentRefs } from "relay-runtime";
export type userRootNodeItemTree_TreeNodeInlineFragment$data = {
  readonly hasBeenPublishedOnce: boolean;
  readonly id: string;
  readonly parentId: string | null | undefined;
  readonly structureDefinition: {
    readonly coordinates: {
      readonly parentRef: string | null | undefined;
    };
    readonly definitionType: StructureType;
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly childRefs?: ReadonlyArray<string>;
    readonly contentKind?: ContentKind;
    readonly definitionType: TypeDefinitionType;
    readonly elements?: ReadonlyArray<{
      readonly elementType: ElementTypeV2;
      readonly extension: {
        readonly points?: number;
      };
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ElementNode_ElementV2Fragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"Node_TreeNodeFragment">;
  readonly " $fragmentType": "userRootNodeItemTree_TreeNodeInlineFragment";
};
export type userRootNodeItemTree_TreeNodeInlineFragment$key = {
  readonly " $data"?: userRootNodeItemTree_TreeNodeInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userRootNodeItemTree_TreeNodeInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "userRootNodeItemTree_TreeNodeInlineFragment"
};

(node as any).hash = "2d72f7322766c79e9b8aae56fd0eb40f";

export default node;
