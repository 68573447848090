import { graphql } from "react-relay";

export const TREE_NODE_FRAGMENT = graphql`
	fragment editNodeForm_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			title
			... on RootStructureDefinition {
				extension {
					... on RootExtensionImpl {
						diploma
						tags {
							id
						}
						learnGoalsOpt
						product {
							id
						}
						trailerOpt
					}
				}
			}
		}
		description
		shortDescription
		instructors {
			superId
		}
		imageId
		image {
			id
			name
			url
		}
	}
`;

export const EDIT_NODE_CORE_MUTATION = graphql`
	mutation editNodeForm_EditNodeCoreMutation($input: EditNodeCoreInput!) {
		Admin {
			Tree {
				editNodeCore(input: $input) {
					editedNode {
						id
						...editNodeForm_TreeNodeFragment
						...Node_TreeNodeFragment
					}
				}
			}
		}
	}
`;
