import { graphql } from "react-relay";

export const TREE_NODE_FRAGMENT = graphql`
	fragment asyncContentEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on AsyncContentTypeDefinition {
				element {
					id
					title
					kind
					...asyncElementForm_AsyncElementFragment
				}
			}
		}
	}
`;

export const CREATE_UPLOAD_ASYNC_ELEMENT_MUTATION = graphql`
	mutation asyncContentEditor_CreateUploadAsyncElementMutation(
		$input: CreateUploadAsyncElementInput!
	) {
		Admin {
			AsyncContent {
				createUploadAsyncElement(input: $input) {
					asyncContent {
						...asyncContentEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
