import { stack } from "@styled-system/patterns";

export const flexLicenseWarningClass = stack({
	backgroundColor: "warning.20",
	padding: "16",
	borderRadius: "8",
	border: "1px solid",
	borderColor: "warning.100",
	gap: "4",
	marginBottom: "16",
});
