import { forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import { isValid as isIBANValid } from "iban";
import * as Yup from "yup";
import { QUERY_FRAGMENT } from "./business-base-data-form.graphql";
import type {
	BusinessBaseDataFormState,
	BusinessBaseDataProps,
	BusinessBaseDataRef,
} from "./business-base-data-form.types";
import {
	ColSpan2,
	ColSpan4,
	InputGroupWrapper,
	ShortInputsRow,
	Wrapper,
} from "./business-base-data-form.styles";
import { useFormik } from "formik";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { CountryCode } from "@relay/accountEditBaseDataScreen_EditBusinessBaseDataMutation.graphql";
import { ResidenceDropdown } from "@components/residence-dropdown";
import { Divider } from "primereact/divider";
import { InputMask } from "@components/input-mask";
import { type BusinessType, BusinessTypeDropdown } from "@components/business-type-dropdown";

export const BusinessBaseDataForm = forwardRef<BusinessBaseDataRef, BusinessBaseDataProps>(
	function BusinessBaseDataForm({ baseDataFragmentRef, onSubmit }, ref) {
		const query = useFragment(QUERY_FRAGMENT, baseDataFragmentRef ?? null);

		const form = useFormik<BusinessBaseDataFormState>({
			initialValues: {
				companyName: query?.companyName ?? "",
				billingOffice: query?.billingOffice ?? "",
				companyLegalForm: query?.companyLegalForm ?? undefined,
				invoiceEmail: query?.invoiceEmail ?? "",
				street: query?.street ?? "",
				houseNumber: query?.houseNumber ?? "",
				postalCode: query?.postalCode ?? "",
				city: query?.city ?? "",
				countryCode: query?.countryCode ?? "DE",
				phoneNumber: query?.phoneNumber ?? "",
				taxNumber: query?.taxData?.taxNumber ?? "",
				taxIdentificationNumber: query?.taxData?.taxIdentificationNumber ?? "",
				iban: query?.accountData?.iban ?? "",
				bic: query?.accountData?.bic ?? "",
			},
			validateOnChange: false,
			validateOnBlur: false,
			validationSchema: Yup.object().shape({
				companyName: Yup.string().required("Bitte gib den Name des Unternehmens ein."),
				billingOffice: Yup.string().required("Bitt gib eine Rechnungsstelle ein."),
				companyLegalForm: Yup.string().required("Bitte wähle eine Rechtsform aus."),
				invoiceEmail: Yup.string()
					.email("Bitte gib eine gültige E-Mail Adresse ein.")
					.required("Bitte gib eine E-Mail Adresse ein."),
				street: Yup.string().required("Bitte gib eine Straße ein."),
				houseNumber: Yup.string().required("Bitte gib eine Hausnummer ein."),
				city: Yup.string().required("Bitte gib einen Ort ein."),
				postalCode: Yup.string()
					.length(5, "Bitte gib eine gültige Postleitzahl ein")
					.matches(/^[0-9]{5}/, "Bitte gib eine gültige Postleitzahl ein")
					.required("Bitte gib eine Postleitzahl ein."),
				phoneNumber: Yup.string().optional(),
				countryCode: Yup.string().required("Bitte wähle ein Land aus."),
				iban: Yup.string()
					.test("test-card-number", "Bitte gib eine gültige IBAN ein.", (value) =>
						isIBANValid(value ?? ""),
					)
					.required("Bitte gib eine IBAN ein."),
				bic: Yup.string(),
				taxNumber: Yup.string().required("Bitte gib eine Steuernummer ein."),
				taxIdentificationNumber: Yup.string().required(
					"Bitte gib eine Umsatzsteuer-Identifikationsnummer ein.",
				),
			}),
			onSubmit: (values) => {
				onSubmit?.(values);
			},
		});

		useImperativeHandle(ref, () => ({
			submit: form.submitForm,
			validate: () => form.validateForm().then((errors) => !errors),
		}));

		return (
			<Wrapper>
				<InputGroupWrapper>
					<ValidatedField<BusinessBaseDataFormState, string>
						formikConfig={form}
						name="companyName"
						label="Name des Unternehmens (inkl. Geschäftsform)*"
						placeholder="z.B. Muster Unicorn GmbH ..."
						component={DefaultTextFieldComponent}
					/>
					<ValidatedField<BusinessBaseDataFormState, string>
						formikConfig={form}
						name="billingOffice"
						label="Rechnungsstelle oder Empfänger*"
						placeholder="z.B. IT-Department ..."
						component={DefaultTextFieldComponent}
					/>
					<ValidatedField<BusinessBaseDataFormState, BusinessType>
						formikConfig={form}
						name="companyLegalForm"
						label="Rechtsform*"
						placeholder="z.B. GmbH, GbR, OHG ..."
						component={BusinessTypeDropdown}
					/>
					<ValidatedField<BusinessBaseDataFormState, string>
						formikConfig={form}
						name="invoiceEmail"
						label="E-Mail für Rechnung*"
						placeholder="..."
						component={DefaultTextFieldComponent}
					/>
					<ShortInputsRow>
						<ColSpan4>
							<ValidatedField<BusinessBaseDataFormState, string>
								formikConfig={form}
								name="street"
								label="Straße*"
								placeholder="..."
								component={DefaultTextFieldComponent}
							/>
						</ColSpan4>
						<ColSpan2>
							<ValidatedField<BusinessBaseDataFormState, string>
								formikConfig={form}
								name="houseNumber"
								label="Hausnummer*"
								placeholder="..."
								component={DefaultTextFieldComponent}
							/>
						</ColSpan2>
					</ShortInputsRow>
					<ShortInputsRow>
						<ColSpan4>
							<ValidatedField<BusinessBaseDataFormState, string>
								formikConfig={form}
								name="city"
								label="Ort/Stadt*"
								placeholder="..."
								component={DefaultTextFieldComponent}
							/>
						</ColSpan4>
						<ColSpan2>
							<ValidatedField<BusinessBaseDataFormState, string>
								formikConfig={form}
								name="postalCode"
								label="PLZ*"
								placeholder="..."
								component={DefaultTextFieldComponent}
							/>
						</ColSpan2>
					</ShortInputsRow>
					<ValidatedField<BusinessBaseDataFormState, string>
						formikConfig={form}
						name="phoneNumber"
						label="Telefonnummer"
						placeholder="z.B. +49 174 00 00 000 ..."
						component={DefaultTextFieldComponent}
					/>
					<ValidatedField<BusinessBaseDataFormState, CountryCode>
						formikConfig={form}
						name="countryCode"
						label="Steuerpflichtiges Land"
						component={ResidenceDropdown}
					/>
				</InputGroupWrapper>
				<Divider />
				<h2 className="m-0">Steuerdaten</h2>
				<InputGroupWrapper>
					<InputMask<BusinessBaseDataFormState>
						formikConfig={form}
						name="taxNumber"
						label={"Steuernummer (St.-Nr.)*"}
						placeholder={"z.B. 12/345/67890 ..."}
						mask={"99/999/9999?*"}
					/>
					<InputMask<BusinessBaseDataFormState>
						formikConfig={form}
						name="taxIdentificationNumber"
						label={"Umsatzsteuer-Identifikationsnummer (USt-IdNr.)*"}
						placeholder={"z.B. DE123456789 ..."}
						mask={"DE999999999"}
					/>
				</InputGroupWrapper>
				<Divider />
				<h2 className="m-0">Kontodaten</h2>
				<InputGroupWrapper>
					<ValidatedField<BusinessBaseDataFormState, string>
						formikConfig={form}
						name="iban"
						label="IBAN*"
						placeholder={"z.B. DE89 3704 0044 0532 0130 00 ..."}
						component={DefaultTextFieldComponent}
					/>
					<ValidatedField<BusinessBaseDataFormState, string>
						formikConfig={form}
						name="bic"
						label="BIC/SWIFT-Code"
						placeholder={"z.B. COBADEFFXXX ..."}
						component={DefaultTextFieldComponent}
					/>
				</InputGroupWrapper>
			</Wrapper>
		);
	},
);
