import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment levelDefinitionTable_QueryFragment on Query
	@refetchable(queryName: "levelDefinitionTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		last: { type: "Int" }
		before: { type: "String" }
		filterByTitle: { type: "String" }
	) {
		Admin {
			Gamification {
				LevelDefinitions(
					first: $first
					after: $after
					last: $last
					before: $before
					filterByTitle: $filterByTitle
				) @connection(key: "levelDefinitionTable_LevelDefinitions") {
					__id
					edges {
						node {
							id
							...levelDefinitionTable_LevelDefinitionInlineFragment
						}
					}
					pageInfo {
						startCursor
						endCursor
						hasNextPage
						hasPreviousPage
					}
				}
			}
		}
	}
`;

export const LEVEL_DEFINITION_INLINE_FRAGMENT = graphql`
	fragment levelDefinitionTable_LevelDefinitionInlineFragment on LevelDefinition @inline {
		id
		title
		shortDescription
		requiredPoints
		...editLevelDefinitionButton_LevelDefinitionFragment
	}
`;
