/**
 * @generated SignedSource<<61d9fb845ffbf0343ef6a1d76b99e692>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type levelDefinitionTable_Refetch$variables = {
  after?: string | null | undefined;
  before?: string | null | undefined;
  filterByTitle?: string | null | undefined;
  first?: number | null | undefined;
  last?: number | null | undefined;
};
export type levelDefinitionTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"levelDefinitionTable_QueryFragment">;
};
export type levelDefinitionTable_Refetch = {
  response: levelDefinitionTable_Refetch$data;
  variables: levelDefinitionTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByTitle"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "filterByTitle",
    "variableName": "filterByTitle"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "levelDefinitionTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "levelDefinitionTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "levelDefinitionTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GamificationAdminSchema",
            "kind": "LinkedField",
            "name": "Gamification",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "LevelDefinitionsConnection",
                "kind": "LinkedField",
                "name": "LevelDefinitions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LevelDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LevelDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortDescription",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "requiredPoints",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "filterByTitle"
                ],
                "handle": "connection",
                "key": "levelDefinitionTable_LevelDefinitions",
                "kind": "LinkedHandle",
                "name": "LevelDefinitions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "96bd27ec46707745098f8747653f4aa2",
    "id": null,
    "metadata": {},
    "name": "levelDefinitionTable_Refetch",
    "operationKind": "query",
    "text": "query levelDefinitionTable_Refetch(\n  $after: String\n  $before: String\n  $filterByTitle: String\n  $first: Int = 20\n  $last: Int\n) {\n  ...levelDefinitionTable_QueryFragment_3kXPa5\n}\n\nfragment editLevelDefinitionButton_LevelDefinitionFragment on LevelDefinition {\n  id\n}\n\nfragment levelDefinitionTable_LevelDefinitionInlineFragment on LevelDefinition {\n  id\n  title\n  shortDescription\n  requiredPoints\n  ...editLevelDefinitionButton_LevelDefinitionFragment\n}\n\nfragment levelDefinitionTable_QueryFragment_3kXPa5 on Query {\n  Admin {\n    Gamification {\n      LevelDefinitions(first: $first, after: $after, last: $last, before: $before, filterByTitle: $filterByTitle) {\n        edges {\n          node {\n            id\n            ...levelDefinitionTable_LevelDefinitionInlineFragment\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          startCursor\n          endCursor\n          hasNextPage\n          hasPreviousPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "78f96ed5e4e6373f4567a3254870dd18";

export default node;
