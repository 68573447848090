import { graphql } from "react-relay";

export const CREATE_MATRIX_ELEMENT_MUTATION = graphql`
	mutation createMatrixElementButton_CreateMatrixElementMutation(
		$input: CreateMatrixElementInput!
	) {
		Admin {
			ElearningV2 {
				createMatrixElement(input: $input) {
					eLearningContent {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;
