import { graphql } from "react-relay";

export const USER_IN_ACCOUNT_CONNECTION_FRAGMENT = graphql`
	fragment usersInAccountTable_UserInAccountConnectionFragment on UserInAccountConnection {
		edges {
			node {
				groups {
					id
					name
				}
				user {
					id
					name
					email
					activated
				}
				...editUserInAccountGroup_UserInAccountFragment
			}
		}
	}
`;

export const REMOVE_USER_FROM_ACCOUNT_MUTATION = graphql`
	mutation usersInAccountTable_RemoveUserFromAccountMutation(
		$input: RemoveUserFromAccountInput!
		$connections: [ID!]!
	) {
		Admin {
			Auth {
				removeUserFromAccount(input: $input) {
					removedUserId @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
