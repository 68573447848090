import { graphql } from "react-relay";

export const CREATE_FILE_ELEMENT_MUTATION = graphql`
	mutation createFileElementButton_CreateFileElementMutation($input: CreateFileElementV2Input!) {
		Admin {
			ElearningV2 {
				createFileElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;
