import { Button } from "@constellation-academy/epic-ui";
import React from "react";

import { CreatePageButtonProps } from "@features/pages-editor/create-page-button/create-page-button.types";

export const CreatePageButton = ({
	createPageButtonLabel,
	onCreatePage,
}: CreatePageButtonProps) => {
	return <Button label={createPageButtonLabel} onClick={onCreatePage} />;
};
