import { ProgressSpinner } from "primereact/progressspinner";
import React, { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { BaseScreen } from "@components/base-screen";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type externalLicensePoolScreen_ExternalLicensePoolQuery } from "@relay/externalLicensePoolScreen_ExternalLicensePoolQuery.graphql";
import {
	type externalLicensePoolScreen_ExternalLicensesQuery,
	type externalLicensePoolScreen_ExternalLicensesQuery$data,
} from "@relay/externalLicensePoolScreen_ExternalLicensesQuery.graphql";
import { externalLicensesPerPage } from "./external-license-pool.constants";
import {
	EXTERNAL_LICENSE_POOL_QUERY,
	EXTERNAL_LICENSES_QUERY,
} from "./external-license-pool.graphql";
import { ExternalLicensesTable } from "./parts/external-licenses-table/external-licenses-table.component";
import { NoAccess } from "../../components/no-access";

export const ExternalLicensePoolScreen = () => {
	const { [`externalLicensePoolId`]: externalLicensePoolId } = useParams();

	const query = useLazyLoadQuery<externalLicensePoolScreen_ExternalLicensePoolQuery>(
		EXTERNAL_LICENSE_POOL_QUERY,
		{
			id: externalLicensePoolId!,
			skip: !externalLicensePoolId,
		},
	);

	const externalLicenses: externalLicensePoolScreen_ExternalLicensesQuery$data =
		useLazyLoadQuery<externalLicensePoolScreen_ExternalLicensesQuery>(EXTERNAL_LICENSES_QUERY, {
			numItemsPerPage: externalLicensesPerPage,
			externalLicensePoolId: externalLicensePoolId!,
		});

	const canRead = useHasPermissions(["UserInAccountPermission_ExternalLicenseAdmin_Read"]);

	if (!canRead) return <NoAccess />;

	if (!query.node) {
		return <h4>Fehler beim Laden des Pools</h4>;
	}

	return (
		<BaseScreen title="Externer Lizenz-Pool">
			<Suspense fallback={<ProgressSpinner />}>
				<ExternalLicensesTable
					externalLicensePoolId={externalLicensePoolId!}
					externalLicensesFragmentRef={externalLicenses}
					externalLicensesPerPage={externalLicensesPerPage}
				/>
			</Suspense>
		</BaseScreen>
	);
};
