/**
 * @generated SignedSource<<419cdcfdf60319a9a1389123c4aab59f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Billing_CompanyType = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "PartmbH" | "Stiftung" | "Ug" | "Unknown";
export type Billing_Salutation = "Divers" | "Mr" | "Mrs" | "Unknown";
import { FragmentRefs } from "relay-runtime";
export type businessBillingDetailsForm_Billing_CustomerDetailsFragment$data = {
  readonly city: string;
  readonly country: string;
  readonly data: {
    readonly company?: string;
    readonly companyDetails?: string | null | undefined;
    readonly companyType?: Billing_CompanyType;
  };
  readonly firstName: string;
  readonly houseNumber: string;
  readonly invoiceEmail: string;
  readonly lastName: string;
  readonly postalCode: string;
  readonly salutation: Billing_Salutation;
  readonly street: string;
  readonly " $fragmentType": "businessBillingDetailsForm_Billing_CustomerDetailsFragment";
};
export type businessBillingDetailsForm_Billing_CustomerDetailsFragment$key = {
  readonly " $data"?: businessBillingDetailsForm_Billing_CustomerDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"businessBillingDetailsForm_Billing_CustomerDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "businessBillingDetailsForm_Billing_CustomerDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "houseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyDetails",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "company",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyType",
              "storageKey": null
            }
          ],
          "type": "Billing_BusinessCustomerDetails",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Billing_CustomerDetails",
  "abstractKey": null
};

(node as any).hash = "06f55010f4cbf954ac2c75059ac23e20";

export default node;
