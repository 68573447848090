import { graphql } from "react-relay";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment reenablePaywallButton_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		structureDefinition {
			title
		}
	}
`;

export const REENABLE_PAYWALL_MUTATION = graphql`
	mutation reenablePaywallButton_ReenablePaywallMutation($input: ReenablePaywallInput!) {
		Admin {
			LearnProgress {
				reenablePaywall(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
