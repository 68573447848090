import { graphql } from "react-relay";

export const ADD_COLUMN_TO_MATRIX_ELEMENT_MUTATION = graphql`
	mutation addColumnToMatrixElementButton_AddColumnToMatrixElementMutation(
		$input: AddColumnToMatrixElementInput!
	) {
		Admin {
			ElearningV2 {
				addColumnToMatrixElement(input: $input) {
					matrixElement {
						...matrixElementForm_MatrixElementFragment
					}
				}
			}
		}
	}
`;
