import { graphql } from "react-relay";

export const MATRIX_ELEMENT_FRAGMENT = graphql`
	fragment matrixElementForm_MatrixElementFragment on MatrixElement {
		id
		title
		...matrixFieldElement_MatrixElementFragment
	}
`;

export const EDIT_MATRIX_ELEMENT_MUTATION = graphql`
	mutation matrixElementForm_EditMatrixElementMutation($input: EditMatrixElementInput!) {
		Admin {
			ElearningV2 {
				editMatrixElement(input: $input) {
					MatrixElement {
						...matrixElementForm_MatrixElementFragment
					}
				}
			}
		}
	}
`;
