import { graphql } from "react-relay";

export const TEXT_WITH_PAGES_PAGE_FRAGMENT = graphql`
	fragment textWithPagesElementPageForm_TextWithPagesPageFragment on TextWithPagesPage {
		internalTitle
		data {
			kind
			... on TextWithPagesTextPageData {
				text
			}
			... on TextWithPagesImagePageData {
				image {
					id
					name
				}
			}
		}
	}
`;
