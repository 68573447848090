import { graphql } from "react-relay";

export const LICENSE_DEFINITION_FRAGMENT = graphql`
	fragment editFlexLicenseDefinitionButton_LicenseDefinitionFragment on LicenseDefinition {
		id
		data {
			... on FlexLicenseDefinitionData {
				name
				includedNodeIds {
					rootId
					title
				}
				excludedNodeIds {
					rootId
					title
				}
			}
		}
	}
`;

export const EDIT_FLEX_LICENSE_DEFINITION_MUTATION = graphql`
	mutation editFlexLicenseDefinitionButton_editFlexLicenseDefinitionMutation(
		$input: EditFlexLicenseDefinitionAdminMutationInput!
	) {
		Admin {
			LicenseDefinition {
				editFlexLicenseDefinitionAdminMutation(input: $input) {
					licenseDefinition {
						...editFlexLicenseDefinitionButton_LicenseDefinitionFragment
					}
				}
			}
		}
	}
`;
