import { graphql } from "react-relay";

export const EXTERNAL_LICENSE_POOLS_FRAGMENT = graphql`
	fragment externalLicensePoolsTable_ExternalLicensePoolsFragment on Query
	@refetchable(queryName: "externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch")
	@argumentDefinitions(
		name: { type: "String" }
		first: { type: "Int!" }
		after: { type: "String" }
	) {
		Admin {
			Billing {
				GetExternalLicensePools(nameOpt: $name, first: $first, after: $after)
					@connection(key: "ExternalLicensePoolsTable_GetExternalLicensePools") {
					__id
					edges {
						node {
							...externalLicensePoolsTable_ExternalLicensePoolFragment
						}
					}
				}
			}
		}
	}
`;

export const EXTERNAL_LICENSE_POOL_FRAGMENT = graphql`
	fragment externalLicensePoolsTable_ExternalLicensePoolFragment on ExternalLicensePool @inline {
		canBeDeleted
		id
		data {
			name
			usageInformation
			validNumDaysAfterIssuing
			link
		}
	}
`;

export const CREATE_EXTERNAL_LICENSE_POOL_MUTATION = graphql`
	mutation externalLicensePoolsTable_CreateExternalLicensePoolMutation(
		$input: CreateExternalLicensePoolInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createExternalLicensePool(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							...externalLicensePoolsTable_ExternalLicensePoolFragment
						}
					}
				}
			}
		}
	}
`;
