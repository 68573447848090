/**
 * @generated SignedSource<<0203f83e6121f080e700103a12c7f9e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type externalLicenseRewardForm_Query$variables = Record<PropertyKey, never>;
export type externalLicenseRewardForm_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectExternalLicensePoolField_QueryFragment">;
};
export type externalLicenseRewardForm_Query = {
  response: externalLicenseRewardForm_Query$data;
  variables: externalLicenseRewardForm_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "externalLicenseRewardForm_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "selectExternalLicensePoolField_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "externalLicenseRewardForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "ExternalLicensePoolsConnection",
                "kind": "LinkedField",
                "name": "GetExternalLicensePools",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensePoolsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalLicensePool",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalLicensePoolData",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": "GetExternalLicensePools(first:20)"
              },
              {
                "alias": null,
                "args": (v0/*: any*/),
                "filters": [
                  "nameOpt"
                ],
                "handle": "connection",
                "key": "selectExternalLicenseField_GetExternalLicensePools",
                "kind": "LinkedHandle",
                "name": "GetExternalLicensePools"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee83515044a78079cb0bcf0226783962",
    "id": null,
    "metadata": {},
    "name": "externalLicenseRewardForm_Query",
    "operationKind": "query",
    "text": "query externalLicenseRewardForm_Query {\n  ...selectExternalLicensePoolField_QueryFragment\n}\n\nfragment selectExternalLicensePoolField_ExternalLicensePoolFragment on ExternalLicensePool {\n  id\n  data {\n    name\n  }\n}\n\nfragment selectExternalLicensePoolField_QueryFragment on Query {\n  Admin {\n    Billing {\n      GetExternalLicensePools(first: 20) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            ...selectExternalLicensePoolField_ExternalLicensePoolFragment\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dba7f2f545ec22be6dbdf5b1d7b060cf";

export default node;
