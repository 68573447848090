import { Dialog } from "@constellation-academy/epic-ui";
import { useFragment } from "react-relay";

import { persologElementQuestionFormDialog_PersologElementFragment$key } from "@relay/persologElementQuestionFormDialog_PersologElementFragment.graphql";
import { persologElementQuestionFormDialog_QuestionFragment$key } from "@relay/persologElementQuestionFormDialog_QuestionFragment.graphql";
import { PersologElementQuestionForm } from "@screens/educational-offer/parts/persolog-element-question-form";
import {
	PERSOLOG_ELEMENT_FRAGMENT,
	QUESTION_FRAGMENT,
} from "@screens/educational-offer/parts/persolog-element-question-form-dialog/persolog-element-question-form-dialog.graphql";
import { PersologElementQuestionFormDialogProps } from "@screens/educational-offer/parts/persolog-element-question-form-dialog/persolog-element-question-form-dialog.types";

export const PersologElementQuestionFormDialog = ({
	persologElementFragmentRef,
	questionFragmentRef,
	onHide,
	onSubmit,
	isVisible,
}: PersologElementQuestionFormDialogProps) => {
	const persologElement =
		useFragment<persologElementQuestionFormDialog_PersologElementFragment$key>(
			PERSOLOG_ELEMENT_FRAGMENT,
			persologElementFragmentRef,
		);
	const question = useFragment<persologElementQuestionFormDialog_QuestionFragment$key>(
		QUESTION_FRAGMENT,
		questionFragmentRef ?? null,
	);

	const dialogTitle = question ? `Frage ${question.title} bearbeiten` : "Frage erstellen";

	if (!persologElement) return;

	return (
		<Dialog visible={isVisible} onHide={onHide} title={dialogTitle}>
			<PersologElementQuestionForm
				persologElementFragmentRef={persologElement}
				questionFragmentRef={question}
				onSubmit={onSubmit}
			/>
		</Dialog>
	);
};
