import {
	DefaultStyledInputPasswordComponents,
	type InputBaseProps,
	type StyledInputPasswordComponents,
} from "@constellation-academy/framework-react-components";
import { Password as PrPassword, type PasswordProps as PrPasswordProps } from "primereact/password";
import styled, { createGlobalStyle } from "styled-components";
import tw from "twin.macro";
import { DefaultInputStyles } from "@corestyle/component-theme/common/input.styles";

export const tkaAdminInputPasswordStyles: StyledInputPasswordComponents = {
	InputPassword: {
		...DefaultStyledInputPasswordComponents.InputPassword,
		StyledInputPassword: styled(PrPassword)<InputBaseProps & PrPasswordProps>`
			${tw`tw-w-full`}
			input {
				${DefaultInputStyles()};
			}
		`,
		GlobalPasswordPanelStyles: createGlobalStyle`
		`,
	},
};
