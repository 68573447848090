/**
 * @generated SignedSource<<ef5dae1e8db0f8a91aca9378a6dd883b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveQuestionFromPersologElementInput = {
  clientMutationId?: string | null | undefined;
  elementId: string;
  questionId: string;
};
export type persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation$variables = {
  input: RemoveQuestionFromPersologElementInput;
};
export type persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly removeQuestionFromPersologElement: {
        readonly persologElement: {
          readonly " $fragmentSpreads": FragmentRefs<"persologElementForm_PersologElementFragment">;
        };
      } | null | undefined;
    };
  };
};
export type persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation = {
  response: persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation$data;
  variables: persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "classType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveQuestionFromPersologElementPayload",
                "kind": "LinkedField",
                "name": "removeQuestionFromPersologElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Persolog_PersologElement",
                    "kind": "LinkedField",
                    "name": "persologElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "persologElementForm_PersologElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveQuestionFromPersologElementPayload",
                "kind": "LinkedField",
                "name": "removeQuestionFromPersologElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Persolog_PersologElement",
                    "kind": "LinkedField",
                    "name": "persologElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Persolog_Class",
                        "kind": "LinkedField",
                        "name": "classes",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Persolog_Question",
                        "kind": "LinkedField",
                        "name": "questions",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Persolog_QuestionClass",
                            "kind": "LinkedField",
                            "name": "questionClasses",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "896086354c31171d7f880a208668bc39",
    "id": null,
    "metadata": {},
    "name": "persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation",
    "operationKind": "mutation",
    "text": "mutation persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation(\n  $input: RemoveQuestionFromPersologElementInput!\n) {\n  Admin {\n    ElearningV2 {\n      removeQuestionFromPersologElement(input: $input) {\n        persologElement {\n          ...persologElementForm_PersologElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment persologClassForm_PersologClassFragment on Persolog_Class {\n  title\n  text\n  classType\n  image {\n    id\n    name\n  }\n}\n\nfragment persologElementForm_PersologElementFragment on Persolog_PersologElement {\n  id\n  title\n  classes {\n    title\n    text\n    classType\n    image {\n      id\n      name\n    }\n    ...persologClassForm_PersologClassFragment\n  }\n  questions {\n    id\n  }\n  ...persologElementQuestionsEditor_QuestionsFragment\n}\n\nfragment persologElementQuestionClassForm_PersologQuestionClassFragment on Persolog_QuestionClass {\n  text\n  classType\n}\n\nfragment persologElementQuestionFormDialog_PersologElementFragment on Persolog_PersologElement {\n  ...persologElementQuestionForm_PersologElementFragment\n}\n\nfragment persologElementQuestionFormDialog_QuestionFragment on Persolog_Question {\n  title\n  ...persologElementQuestionForm_QuestionFragment\n}\n\nfragment persologElementQuestionForm_PersologElementFragment on Persolog_PersologElement {\n  classes {\n    classType\n    title\n  }\n}\n\nfragment persologElementQuestionForm_QuestionFragment on Persolog_Question {\n  id\n  title\n  questionClasses {\n    text\n    classType\n    ...persologElementQuestionClassForm_PersologQuestionClassFragment\n  }\n}\n\nfragment persologElementQuestionsEditor_QuestionsFragment on Persolog_PersologElement {\n  id\n  ...persologElementQuestionFormDialog_PersologElementFragment\n  questions {\n    id\n    title\n    ...persologElementQuestionFormDialog_QuestionFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d54efcd150f73e56f638eb9c6c413f3";

export default node;
