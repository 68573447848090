/**
 * @generated SignedSource<<578364c71ec6c89462aa21654c14a470>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountsForUsersTable_UserFragment$data = {
  readonly groupAssociations: ReadonlyArray<{
    readonly account: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly group: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
  }>;
  readonly id: string;
  readonly " $fragmentType": "accountsForUsersTable_UserFragment";
};
export type accountsForUsersTable_UserFragment$key = {
  readonly " $data"?: accountsForUsersTable_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"accountsForUsersTable_UserFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "accountsForUsersTable_UserFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserGroupAssociation",
      "kind": "LinkedField",
      "name": "groupAssociations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserInAccountGroup",
          "kind": "LinkedField",
          "name": "group",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "094fcf47b93f00fd3cfc7b9f1f694980";

export default node;
