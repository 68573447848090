/**
 * @generated SignedSource<<6a9c33be157f537413f2a5d14a2a79a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type desktopNotificationForm_DesktopNotificationFragment$data = {
  readonly desktopNotification: {
    readonly content: string;
    readonly icon: {
      readonly id: string;
      readonly name: string;
    };
    readonly title: string;
    readonly url: string;
  } | null | undefined;
  readonly " $fragmentType": "desktopNotificationForm_DesktopNotificationFragment";
};
export type desktopNotificationForm_DesktopNotificationFragment$key = {
  readonly " $data"?: desktopNotificationForm_DesktopNotificationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"desktopNotificationForm_DesktopNotificationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "desktopNotificationForm_DesktopNotificationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DesktopNotification",
      "kind": "LinkedField",
      "name": "desktopNotification",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "icon",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MessageDefinition",
  "abstractKey": null
};

(node as any).hash = "545472cbd311cdbdd598fdfaca6c8019";

export default node;
