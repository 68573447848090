import { Button } from "primereact/button";
import { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { BaseScreen } from "@components/base-screen";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { Paths } from "@routes/paths";
import { AccountsTable } from "./parts/accounts-table";
import { NoAccess } from "../../components/no-access";

export const AccountsScreen = () => {
	const navigate = useNavigate();

	const handleCreateAccountOnClick = () => {
		navigate(Paths.accounts.withId("new").edit.path);
	};

	const canModify = useHasPermissions(["UserInAccountPermission_AuthAdmin_Modify"]);
	const canRead = useHasPermissions(["UserInAccountPermission_AuthAdmin_Read"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen title="Konten">
			{canModify && <Button label="Konto erstellen" onClick={handleCreateAccountOnClick} />}
			<Suspense fallback={<div>Lade...</div>}>
				<AccountsTable />
			</Suspense>
		</BaseScreen>
	);
};
