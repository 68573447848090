import { Dialog, EpicIcons } from "@constellation-academy/epic-ui";
import { useMemo, useState } from "react";
import { Button } from "@components/button";
import { ControllsWrapper, SelectedControllsWrapper } from "./select-complex-data-field.styles";
import { type FieldData, type SelectComplexDataFieldProps } from "./select-complex-data-field.type";
import { getDeepValue } from "./select-complex-data-field.util";

export const SelectComplexDataField = <T extends FieldData>({
	updateField,
	fieldValue,
	disabled,
	placeholder,
	valueField,
	valueBody,
	body,
	disableClear,
	actionButtonLabel,
	headerLabel,
	hidePreviewOnDialog,
	children,
}: SelectComplexDataFieldProps<T>) => {
	const [isModalOpen, setModalOpen] = useState<boolean>(false);

	const handleDialogOnHide = () => {
		setModalOpen(false);
	};

	const handleSelectRootOnClick = () => {
		setModalOpen(true);
	};

	const handleClearOnClick = () => {
		updateField(undefined);
	};

	const handleReadyOnClick = () => {
		setModalOpen(false);
	};

	const value = useMemo(() => {
		if (valueBody) {
			return valueBody(fieldValue);
		}
		if (Array.isArray(fieldValue)) {
			return `${fieldValue.length} ausgewählt`;
		}
		if (valueField) {
			const valueLabel = getDeepValue(valueField, fieldValue);
			return valueLabel ?? placeholder ?? "Keine Auswahl";
		}
	}, [valueBody, valueField, fieldValue, placeholder]);
	return (
		<>
			<ControllsWrapper>
				{body ? (
					body(fieldValue)
				) : (
					<>
						<div className="flex-1 flex bg-gray-100 border-gray-300 border-1 border-round  px-3 py-2">
							{value as string}
						</div>
						{disableClear !== true && (
							<Button
								disabled={!fieldValue || disabled}
								icon={EpicIcons.TIMES}
								onClick={handleClearOnClick}
							/>
						)}
						<Button
							disabled={disabled}
							label={actionButtonLabel ?? "Auswählen"}
							onClick={handleSelectRootOnClick}
						/>
					</>
				)}
			</ControllsWrapper>
			<Dialog
				title={headerLabel ?? "Auswählen"}
				onHide={handleDialogOnHide}
				visible={isModalOpen}
			>
				{hidePreviewOnDialog !== true && (
					<>
						<span>Deine Auswahl:</span>
						<SelectedControllsWrapper>
							<div className="flex-grow-1 flex bg-gray-100 border-gray-300 border-1 border-round  px-3 py-2">
								{value as string}
							</div>
							{disableClear !== true && (
								<Button
									// className="w-auto p-button-secondary p-2 ml-2"
									disabled={!fieldValue || disabled}
									icon={EpicIcons.TIMES}
									onClick={handleClearOnClick}
								/>
							)}
						</SelectedControllsWrapper>
					</>
				)}
				{children}
				<div className="flex flex-column align-items-end mt-3">
					<Button label={"Fertig"} onClick={handleReadyOnClick} minWidthRem={10} />
				</div>
			</Dialog>
		</>
	);
};
