import { graphql } from "react-relay";

export const QUERY = graphql`
	query searchablePublishedRootTable_Query {
		...searchablePublishedRootTable_QueryFragment
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment searchablePublishedRootTable_QueryFragment on Query
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		titleMatchRegex: { type: "String" }
	)
	@refetchable(queryName: "searchablePublishedRootTable_QueryRefetch") {
		Admin {
			LearnV2 {
				SearchLearnOpportunityV2(
					first: $first
					after: $after
					titleMatchRegex: $titleMatchRegex
				) @connection(key: "searchablePublishedRootTable_SearchLearnOpportunityV2") {
					edges {
						node {
							...searchablePublishedRootTable_LearnOpportunityV2Fragment
						}
					}
				}
			}
		}
	}
`;

export const LEARN_OPPORTUNITY_V2 = graphql`
	fragment searchablePublishedRootTable_LearnOpportunityV2Fragment on LearnOpportunityV2 @inline {
		id
		structureDefinition {
			title
		}
	}
`;
