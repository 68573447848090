import { graphql, useFragment } from "react-relay";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import {
	type AttachmentKind,
	type DefaultFileAttachmentForm_AttachmentV2Fragment$key,
} from "@relay/DefaultFileAttachmentForm_AttachmentV2Fragment.graphql";
import { ValidatedField } from "../../../components/ValidatedField";
import {
	DefaultFileSelectionField,
	type FileV2,
} from "../../../features/files/file-selection-field";
import {
	resetArrayOfEditedForms,
	addEditedFormToEditedFormsArray,
} from "../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../store/store.redux";

const ATTACHMENT_FRAGMENT = graphql`
	fragment DefaultFileAttachmentForm_AttachmentV2Fragment on AttachmentV2 {
		... on DefaultFileAttachment {
			file {
				id
				name
			}
			title
			description
		}
		kind
	}
`;

export type AttachmentInterface = {
	file?: FileV2;
	attachmentType?: AttachmentKind;
	description?: string;
	title?: string;
};

type OwnProps = {
	attachmentFragmentRef?: DefaultFileAttachmentForm_AttachmentV2Fragment$key | null;
	onBack: (values?: AttachmentInterface) => void;
	setCurrentEditingAttachment?: React.Dispatch<React.SetStateAction<any>> | undefined;
	lockButton?: boolean;
	canUploadFiles: boolean;
	canDeleteFiles: boolean;
};

export const DefaultFileAttachmentForm = ({
	attachmentFragmentRef,
	onBack,
	setCurrentEditingAttachment,
	lockButton,
	canUploadFiles,
	canDeleteFiles,
}: OwnProps) => {
	const attachment = useFragment<DefaultFileAttachmentForm_AttachmentV2Fragment$key>(
		ATTACHMENT_FRAGMENT,
		attachmentFragmentRef ?? null,
	);
	const dispatch = useTypedDispatch();
	const formId = "DefaultFileAttachmentForm";

	const formik = useFormik<AttachmentInterface>({
		initialValues: {
			file: attachment?.file as FileV2,
			title: attachment?.title ?? undefined,
			description: attachment?.description ?? undefined,
			attachmentType: attachment?.kind ?? undefined,
		},
		validationSchema: Yup.object().shape({
			file: Yup.object().required("Das Feld Datei wird benötigt."),
			title: Yup.string().required("Das Feld Titel wird benötigt."),
		}),
		onSubmit: (values: AttachmentInterface, { setSubmitting, setTouched }) => {
			dispatch(resetArrayOfEditedForms());
			if (setCurrentEditingAttachment) {
				setCurrentEditingAttachment(undefined);
			}
			setTouched({});
			setSubmitting(false);
			onBack(values);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<AttachmentInterface, FileV2>
				name={"file"}
				label={"Datei"}
				required={true}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultFileSelectionField(canUploadFiles, canDeleteFiles)}
				formikConfig={formik}
			/>
			<ValidatedField<AttachmentInterface, string>
				name={"title"}
				label={"Titel"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<AttachmentInterface, string>
				name={"description"}
				label={"Beschreibung"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<Button
				disabled={Object.entries(formik.touched).length === 0 || lockButton}
				type="submit"
				label="Anhängen"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};
