import { useContext } from "react";
import { useMutation } from "react-relay";
import {
	SET_CONFIRM_BUTTON_MUTATION,
	SET_LINK_BUTTON_MUTATION,
	SET_TEXT_COPY_BUTTON_MUTATION,
} from "@hooks/use-message-page-button-mutations/use-message-page-button-mutations.graphql";
import { useMessagePageButtonMutationsProps } from "@hooks/use-message-page-button-mutations/use-message-page-button-mutations.types";
import { useMessagePageButtonMutations_SetConfirmButtonMutation } from "@relay/useMessagePageButtonMutations_SetConfirmButtonMutation.graphql";
import { useMessagePageButtonMutations_SetLinkButtonMutation } from "@relay/useMessagePageButtonMutations_SetLinkButtonMutation.graphql";
import { useMessagePageButtonMutations_SetTextCopyButtonMutation } from "@relay/useMessagePageButtonMutations_SetTextCopyButtonMutation.graphql";
import { PageButtonFormState } from "@screens/message-definition-edit/parts/page-button-form/page-button-form.types";
import { MessageDefinitionsContext } from "@screens/message-definitions/message-definitions.context";

export const useMessagePageButtonMutations = ({
	messageDefinitionId,
}: useMessagePageButtonMutationsProps) => {
	const { connectionId } = useContext(MessageDefinitionsContext);

	const [setConfirmButton] = useMutation<useMessagePageButtonMutations_SetConfirmButtonMutation>(
		SET_CONFIRM_BUTTON_MUTATION,
	);
	const [setTextCopyButton] =
		useMutation<useMessagePageButtonMutations_SetTextCopyButtonMutation>(
			SET_TEXT_COPY_BUTTON_MUTATION,
		);
	const [setLinkButton] =
		useMutation<useMessagePageButtonMutations_SetLinkButtonMutation>(SET_LINK_BUTTON_MUTATION);

	const executeButtonMutation = (
		buttonValues: PageButtonFormState,
		pageId: string,
		onCompleted: () => void,
		onError: () => void,
	) => {
		if (!messageDefinitionId || !pageId) return;
		switch (buttonValues.kind) {
			case "confirm":
				setConfirmButton({
					variables: {
						input: {
							messageDefinitionId: messageDefinitionId,
							pageId: pageId,
							text: buttonValues.text ?? "",
						},
						connections: [connectionId],
					},
					onCompleted,
					onError,
				});
				break;
			case "link":
				setLinkButton({
					variables: {
						input: {
							messageDefinitionId: messageDefinitionId,
							pageId: pageId,
							text: buttonValues.text ?? "",
							url: buttonValues.url ?? "",
						},
						connections: [connectionId],
					},
					onCompleted,
					onError,
				});
				break;
			case "textCopy":
				setTextCopyButton({
					variables: {
						input: {
							messageDefinitionId: messageDefinitionId,
							pageId: pageId,
							text: buttonValues.text ?? "",
						},
						connections: [connectionId],
					},
					onCompleted,
					onError,
				});
				break;
			default:
				break;
		}
	};

	return { executeButtonMutation };
};
