import { graphql, useFragment, useMutation } from "react-relay";
import { Card } from "primereact/card";
import { AttachmentItem } from "./AttachmentItem";
import { AttachmentPreview } from "./AttachmentPreview";
import { CreateDefaultFileAttachmentButton } from "./CreateDefaultFileAttachmentButton";
import { DefaultFileAttachmentForm } from "./DefaultFileAttachmentForm";
import { type AttachmentEditor_EditDefaultFileAttachmentMutation } from "../../../__generated__/AttachmentEditor_EditDefaultFileAttachmentMutation.graphql";
import { type AttachmentEditor_RemoveAttachmentV2Mutation } from "../../../__generated__/AttachmentEditor_RemoveAttachmentV2Mutation.graphql";
import {
	type AttachmentEditor_TreeNodeFragment$key,
	type AttachmentKind,
} from "../../../__generated__/AttachmentEditor_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment AttachmentEditor_TreeNodeFragment on TreeNode {
		id
		attachments {
			kind
			... on DefaultFileAttachment {
				id
				file {
					id
					name
				}
				title
				description
				kind
			}
			...DefaultFileAttachmentForm_AttachmentV2Fragment
		}
	}
`;

const EDIT_DEFAULT_FILE_ATTACHMENT_MUTATION = graphql`
	mutation AttachmentEditor_EditDefaultFileAttachmentMutation(
		$input: EditDefaultFileAttachmentInput!
	) {
		Admin {
			Tree {
				EditDefaultFileAttachment(input: $input) {
					node {
						attachments {
							...DefaultFileAttachmentForm_AttachmentV2Fragment
						}
					}
				}
			}
		}
	}
`;

const REMOVE_ATTACHMENT_MUTATION = graphql`
	mutation AttachmentEditor_RemoveAttachmentV2Mutation($input: RemoveAttachmentInput!) {
		Admin {
			Tree {
				removeAttachment(input: $input) {
					node {
						...AttachmentEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export type AttachmentGraphqlInterface<AttachmentKind> = {
	id: string;
	title: string;
	kind: AttachmentKind;
};

type OwnProps = {
	treeNodeFragmentRef: AttachmentEditor_TreeNodeFragment$key;
	canUploadFiles: boolean;
	canDeleteFiles: boolean;
};

export const AttachmentEditor = ({
	treeNodeFragmentRef,
	canUploadFiles,
	canDeleteFiles,
}: OwnProps) => {
	const node = useFragment<AttachmentEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const [editDefaultFileAttachment] =
		useMutation<AttachmentEditor_EditDefaultFileAttachmentMutation>(
			EDIT_DEFAULT_FILE_ATTACHMENT_MUTATION,
		);

	const [removeAttachment, isRemovingAttachment] =
		useMutation<AttachmentEditor_RemoveAttachmentV2Mutation>(REMOVE_ATTACHMENT_MUTATION);

	return (
		<Card className="mb-2">
			<h2>Anhänge</h2>
			<div>
				<div className="p-buttonset mb-2 flex">
					<CreateDefaultFileAttachmentButton
						contentNodeId={node.id}
						canUploadFiles={canUploadFiles}
						canDeleteFiles={canDeleteFiles}
					/>
				</div>
				<AttachmentPreview<AttachmentGraphqlInterface<AttachmentKind>>
					selectedAttachment={node.attachments as any[]}
					template={(attachment: AttachmentGraphqlInterface<AttachmentKind>) => {
						return (
							<>
								<AttachmentItem
									attachmentTitle={attachment.title}
									isLoading={isRemovingAttachment}
									isPresentational
									canEdit
									editDialog={() => {
										return (
											<>
												{attachment.kind === "DefaultFile" && (
													<DefaultFileAttachmentForm
														attachmentFragmentRef={attachment as any}
														onBack={(values) =>
															values?.file &&
															editDefaultFileAttachment({
																variables: {
																	input: {
																		fileId: values?.file.id,
																		attachmentId: attachment.id,
																		nodeId: node.id,
																		titleOpt: values?.title,
																		descriptionOpt:
																			values?.description,
																	},
																},
															})
														}
														canUploadFiles={canUploadFiles}
														canDeleteFiles={canDeleteFiles}
													/>
												)}
											</>
										);
									}}
									onDelete={() =>
										removeAttachment({
											variables: {
												input: {
													nodeId: node.id,
													attachmentId: attachment.id,
												},
											},
										})
									}
								></AttachmentItem>
							</>
						);
					}}
				/>
			</div>
		</Card>
	);
};
