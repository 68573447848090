import { FormDialogButton } from "@constellation-academy/epic-ui";
import { Toast } from "primereact/toast";
import React, { useContext, useRef } from "react";
import { useMutation } from "react-relay";
import { createVideoSourceButton_CreateVimeoVideoSourceMutation } from "@relay/createVideoSourceButton_CreateVimeoVideoSourceMutation.graphql";
import { VideoSourcesScreenContext } from "@screens/video-sources/video-sources.context";
import { CREATE_VIMEO_VIDEO_SOURCE_MUTATION } from "./create-video-source-button.graphql";
import { VideoSourceForm } from "../video-source-form/video-source-form.component";
import { VideoSourceFormState } from "../video-source-form/video-source-form.types";

export const CreateVideoSourceButton = () => {
	const toast = useRef<Toast>(null);
	const { connectionId } = useContext(VideoSourcesScreenContext);

	const [createVimeoVideoSource, isCreatingVimeoVideoSource] =
		useMutation<createVideoSourceButton_CreateVimeoVideoSourceMutation>(
			CREATE_VIMEO_VIDEO_SOURCE_MUTATION,
		);

	const handleVideoSourceFormOnSubmit = (onHide: () => void, values: VideoSourceFormState) => {
		createVimeoVideoSource({
			variables: {
				input: {
					name: values.name,
					accessToken: values.accessToken!,
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				onHide();
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Video-Quelle gespeichert",
					detail: "Die Video-Quelle wurde erfolgreich erstellt",
				});
			},
			onError: () => {
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Video-Quelle gespeichert",
					detail: "Die Video-Quelle wurde nicht erstellt",
				});
			},
		});
	};

	return (
		<>
			<Toast ref={toast}></Toast>
			<FormDialogButton<VideoSourceFormState>
				buttonLabel="Video-Quelle erstellen"
				dialogTitle="Daten zum Erstellen der Video-Quelle eingeben"
				disabled={isCreatingVimeoVideoSource}
			>
				{({ formikRef, onHide }) => {
					return (
						<VideoSourceForm
							ref={formikRef}
							isCreating
							onSubmit={(values) => {
								handleVideoSourceFormOnSubmit(onHide, values);
							}}
						/>
					);
				}}
			</FormDialogButton>
		</>
	);
};
