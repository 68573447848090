/**
 * @generated SignedSource<<da00b56ae47445ff6f79c97ed0ae1abe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAcademiesTagButton_AcademiesTagFragment$data = {
  readonly data?: {
    readonly isClickable: boolean;
    readonly isTopic: boolean;
    readonly name: string;
  };
  readonly id: string;
  readonly " $fragmentType": "editAcademiesTagButton_AcademiesTagFragment";
};
export type editAcademiesTagButton_AcademiesTagFragment$key = {
  readonly " $data"?: editAcademiesTagButton_AcademiesTagFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAcademiesTagButton_AcademiesTagFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTopic",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isClickable",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editAcademiesTagButton_AcademiesTagFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AcademiesTagData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "AcademiesRootTag",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AcademiesTagData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "AcademiesChildTag",
      "abstractKey": null
    }
  ],
  "type": "AcademiesTag",
  "abstractKey": "__isAcademiesTag"
};
})();

(node as any).hash = "e6bc73342067d4115762fd4df9407017";

export default node;
