/**
 * @generated SignedSource<<2dd811ba5c4a4976399a95a2bf983862>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AsyncElementTaskStatus = "Evaluated" | "Fresh";
import { FragmentRefs } from "relay-runtime";
export type uploadTaskEditScreen_AsyncElementTaskFragment$data = {
  readonly evaluation?: {
    readonly file: {
      readonly id: string;
      readonly name: string;
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly text: string | null | undefined;
  };
  readonly file?: {
    readonly id: string;
    readonly name: string;
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly status: AsyncElementTaskStatus;
  readonly " $fragmentType": "uploadTaskEditScreen_AsyncElementTaskFragment";
};
export type uploadTaskEditScreen_AsyncElementTaskFragment$key = {
  readonly " $data"?: uploadTaskEditScreen_AsyncElementTaskFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"uploadTaskEditScreen_AsyncElementTaskFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "file",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "uploadTaskEditScreen_AsyncElementTaskFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/)
      ],
      "type": "FreshUploadAsyncElementTask",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UploadEvaluation",
          "kind": "LinkedField",
          "name": "evaluation",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "EvaluatedUploadAsyncElementTask",
      "abstractKey": null
    }
  ],
  "type": "AsyncElementTask",
  "abstractKey": "__isAsyncElementTask"
};
})();

(node as any).hash = "e17af77076a69dd0e73585cb16c435e5";

export default node;
