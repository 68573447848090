import React from "react";
import { graphql, useFragment } from "react-relay";
import { ProductTitleColumn_ProductFragment$key } from "@relay/ProductTitleColumn_ProductFragment.graphql";

const PRODUCT_FRAGMENT = graphql`
	fragment ProductTitleColumn_ProductFragment on Product {
		title
	}
`;

interface OwnProps {
	productFragmentRef: ProductTitleColumn_ProductFragment$key;
}

export const ProductTitleColumn = ({ productFragmentRef }: OwnProps) => {
	const product = useFragment<ProductTitleColumn_ProductFragment$key>(
		PRODUCT_FRAGMENT,
		productFragmentRef,
	);

	return <div>{product.title}</div>;
};
