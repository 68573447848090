/**
 * @generated SignedSource<<cc1d3772727b128895ffbf76fcd5c718>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type createVideoElementButton_GetDefaultVimeoVideoSourceQuery$variables = Record<PropertyKey, never>;
export type createVideoElementButton_GetDefaultVimeoVideoSourceQuery$data = {
  readonly Admin: {
    readonly VimeoVideoSource: {
      readonly GetDefaultVimeoVideoSource: {
        readonly id: string;
      } | null | undefined;
    };
  };
};
export type createVideoElementButton_GetDefaultVimeoVideoSourceQuery = {
  response: createVideoElementButton_GetDefaultVimeoVideoSourceQuery$data;
  variables: createVideoElementButton_GetDefaultVimeoVideoSourceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VimeoVideoSourceAdminSchema",
        "kind": "LinkedField",
        "name": "VimeoVideoSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Vimeo_VimeoVideoSource",
            "kind": "LinkedField",
            "name": "GetDefaultVimeoVideoSource",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "createVideoElementButton_GetDefaultVimeoVideoSourceQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "createVideoElementButton_GetDefaultVimeoVideoSourceQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6cf6e6097214ad65ee37f5876b2134b4",
    "id": null,
    "metadata": {},
    "name": "createVideoElementButton_GetDefaultVimeoVideoSourceQuery",
    "operationKind": "query",
    "text": "query createVideoElementButton_GetDefaultVimeoVideoSourceQuery {\n  Admin {\n    VimeoVideoSource {\n      GetDefaultVimeoVideoSource {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2dd9d74bad60e33664118bbbcdf2f817";

export default node;
