/**
 * @generated SignedSource<<86e935d334eac61ab1b7c209f7620c89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Billing_Salutation = "Divers" | "Mr" | "Mrs" | "Unknown";
import { FragmentRefs } from "relay-runtime";
export type privateBillingDetailsForm_Billing_CustomerDetailsFragment$data = {
  readonly city: string;
  readonly country: string;
  readonly data: {
    readonly dateOfBirth?: string;
    readonly invoiceAddress?: {
      readonly city: string;
      readonly companyDetails: string | null | undefined;
      readonly companyName: string;
      readonly country: string;
      readonly firstName: string;
      readonly houseNumber: string;
      readonly lastName: string;
      readonly postalCode: string;
      readonly street: string;
    } | null | undefined;
  };
  readonly firstName: string;
  readonly houseNumber: string;
  readonly invoiceEmail: string;
  readonly lastName: string;
  readonly postalCode: string;
  readonly salutation: Billing_Salutation;
  readonly street: string;
  readonly " $fragmentType": "privateBillingDetailsForm_Billing_CustomerDetailsFragment";
};
export type privateBillingDetailsForm_Billing_CustomerDetailsFragment$key = {
  readonly " $data"?: privateBillingDetailsForm_Billing_CustomerDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"privateBillingDetailsForm_Billing_CustomerDetailsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "houseNumber",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "privateBillingDetailsForm_Billing_CustomerDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceEmail",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dateOfBirth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Billing_InvoiceAddress",
              "kind": "LinkedField",
              "name": "invoiceAddress",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                (v1/*: any*/),
                (v6/*: any*/),
                (v4/*: any*/),
                (v0/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyDetails",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Billing_PrivateCustomerDetails",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Billing_CustomerDetails",
  "abstractKey": null
};
})();

(node as any).hash = "1918fc3fadc4ac6a8ccef44308e27091";

export default node;
