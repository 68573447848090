import { Button } from "primereact/button";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { type accountsForUsersTable_UserFragment$key } from "@relay/accountsForUsersTable_UserFragment.graphql";
import { Paths } from "@routes/paths";
import { USER_FRAGMENT } from "./accounts-for-users-table.graphql";
import { type AccountsForUserTableProps } from "./accounts-for-users-table.types";
import { convertUserInAccountGroupName } from "./accounts-for-users-table.util";
import { DataTable } from "../../../components/data-table";

export const AccountsForUserTable = ({ hasNext, loadNext, userRef }: AccountsForUserTableProps) => {
	const { groupAssociations } = useFragment<accountsForUsersTable_UserFragment$key>(
		USER_FRAGMENT,
		userRef,
	);

	const navigate = useNavigate();

	const handleOnViewAccountOnClick = (accountId: string) => {
		navigate(Paths.accounts.withId(accountId).edit.path);
	};

	return (
		<div className="mb-5">
			<DataTable
				emptyMessage={
					<div className="flex justify-content-center align-items-center">
						<div className="mr-2">Es wurden keine Konten gefunden.</div>
					</div>
				}
				className="mb-3"
				value={groupAssociations?.map((edge) => ({
					id: edge.account?.id,
					name: edge.account?.name,
					group: edge.group,
				}))}
			>
				{(Column) => (
					<>
						<Column header="Name" field="name" />
						<Column
							header="Gruppe"
							body={(row) => {
								if (!row.group) return null;
								return (
									<div key={row.group.id} className="flex">
										<div>{convertUserInAccountGroupName(row.group.name)}</div>
									</div>
								);
							}}
						/>
						<Column
							header="Aktionen"
							body={(row) => {
								return (
									<div>
										<Button
											tooltip="Zum Konto navigieren"
											onClick={() => {
												if (!row.id) return;
												handleOnViewAccountOnClick(row.id);
											}}
											icon={"pi pi-eye"}
										/>
									</div>
								);
							}}
						/>
					</>
				)}
			</DataTable>
			{hasNext && (
				<div className="flex justify-content-center align-items-center">
					<Button
						type="button"
						className="p-button-secondary"
						disabled={!hasNext}
						onClick={loadNext}
					>
						Load more
					</Button>
				</div>
			)}
		</div>
	);
};
