/**
 * @generated SignedSource<<918479de1be65ca0a7ddbdef27ddd5a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type matrixElementForm_MatrixElementFragment$data = {
  readonly id: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"matrixFieldElement_MatrixElementFragment">;
  readonly " $fragmentType": "matrixElementForm_MatrixElementFragment";
};
export type matrixElementForm_MatrixElementFragment$key = {
  readonly " $data"?: matrixElementForm_MatrixElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"matrixElementForm_MatrixElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "matrixElementForm_MatrixElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "matrixFieldElement_MatrixElementFragment"
    }
  ],
  "type": "MatrixElement",
  "abstractKey": null
};

(node as any).hash = "a985b2030a5cc425c49efd25e5f53b0c";

export default node;
