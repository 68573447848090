/**
 * @generated SignedSource<<cf563060d67f9b53f847c41845a84149>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type FlowELearningContentConfigType = "FlowELearningContent_DirectFeedback" | "FlowELearningContent_SimplyContinue";
import { FragmentRefs } from "relay-runtime";
export type flowAndCurrentElementContentConfigEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly flowConfig?: {
      readonly configType: FlowELearningContentConfigType;
      readonly id: string;
      readonly minNumTriesTillShowAnswer?: number;
      readonly " $fragmentSpreads": FragmentRefs<"setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment">;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"setDirectFeedbackFlowConfigForm_TreeNodeFragment">;
  readonly " $fragmentType": "flowAndCurrentElementContentConfigEditor_TreeNodeFragment";
};
export type flowAndCurrentElementContentConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: flowAndCurrentElementContentConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"flowAndCurrentElementContentConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "flowAndCurrentElementContentConfigEditor_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "flowConfig",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "minNumTriesTillShowAnswer",
                      "storageKey": null
                    }
                  ],
                  "type": "DirectFeedbackFlowELearningContentConfig",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "setDirectFeedbackFlowConfigForm_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "912030000d0d9becff3082b622be2e7f";

export default node;
