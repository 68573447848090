import { graphql } from "react-relay";

export const AVAILABLE_EMAIL_TEMPLATES_FRAGMENT = graphql`
	fragment createEmailTemplateButton_AvailableTemplatesFragment on Query {
		Admin {
			Email {
				AvailableSystemTemplates {
					key
					previewText
					subject
					body
					variables
				}
			}
		}
	}
`;

export const CREATE_EMAIL_TEMPLATE_MUTATION = graphql`
	mutation createEmailTemplateButton_CreateMutation(
		$input: CreatePersonalizedEmailTemplateInput!
	) {
		Admin {
			Email {
				createPersonalizedEmailTemplate(input: $input) {
					data {
						node {
							id
						}
					}
				}
			}
		}
	}
`;
