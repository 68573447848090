import { graphql } from "react-relay";

export const SET_TREE_STATE_HEAD_MUTATION = graphql`
	mutation userRootNodeItemTreeItem_SetTreeStateHeadMutation($input: SetTreeStateHeadInput!) {
		Admin {
			LearnV2 {
				setTreeStateHead(input: $input) {
					treeState {
						...userRootNodeItem_TreeStateFragment
					}
				}
			}
		}
	}
`;

export const ASSIGN_POINTS_TO_USER_MUTATION = graphql`
	mutation userRootNodeItemTreeItem_AssignPointsToUserMutation($input: AssignPointsToUserInput!) {
		Admin {
			GamificationPoints {
				assignPointsToUser(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
