import { graphql } from "react-relay";

export const QUERY = graphql`
	query selectProductField_Query {
		...selectProductField_QueryFragment
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment selectProductField_QueryFragment on Query
	@refetchable(queryName: "SelectProductField_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		titleOpt: { type: "String" }
		isHiddenOpt: { type: "Boolean" }
		inKinds: { type: "[ProductKind!]", defaultValue: [License] }
	) {
		Admin {
			Billing {
				SearchProducts(
					first: $first
					after: $after
					titleOpt: $titleOpt
					isHiddenOpt: $isHiddenOpt
					inKinds: $inKinds
				) @connection(key: "SelectProductField_SearchProducts") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
						}
					}
					...productsTable_ProductsConnectionFragment
				}
			}
		}
	}
`;
