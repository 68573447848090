import { graphql, useFragment, useMutation } from "react-relay";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import * as Yup from "yup";
import { type MarkMistakeElementForm_EditMarkMistakeElementMutation } from "@relay/MarkMistakeElementForm_EditMarkMistakeElementMutation.graphql";
import { type MarkMistakeElementForm_MarkMistakeElementFragment$key } from "@relay/MarkMistakeElementForm_MarkMistakeElementFragment.graphql";
import {
	DefaultTextAreaComponent,
	DefaultTextFieldComponent,
} from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../store/store.redux";

const MARK_MISTAKE_ELEMENT_FRAGMENT = graphql`
	fragment MarkMistakeElementForm_MarkMistakeElementFragment on MarkMistakesElement {
		id
		title
		text
	}
`;

const EDIT_MARK_MISTAKE_ELEMENT_MUTATION = graphql`
	mutation MarkMistakeElementForm_EditMarkMistakeElementMutation(
		$input: EditMarkMistakesElementInput!
	) {
		Admin {
			ElearningV2 {
				editMarkMistakesElement(input: $input) {
					markMistakesElement {
						...MarkMistakeElementForm_MarkMistakeElementFragment
					}
				}
			}
		}
	}
`;

type FormState = {
	id: string;
	title: string;
	text: string;
};

type OwnProps = {
	markMistakeElementFragmentRef: MarkMistakeElementForm_MarkMistakeElementFragment$key;
	onBack: () => void;
};

export const MarkMistakeElementForm = ({ markMistakeElementFragmentRef, onBack }: OwnProps) => {
	const markMistakeElement = useFragment<MarkMistakeElementForm_MarkMistakeElementFragment$key>(
		MARK_MISTAKE_ELEMENT_FRAGMENT,
		markMistakeElementFragmentRef,
	);

	const [editMarkMistakeElement, isEditingMarkMistakeElement] =
		useMutation<MarkMistakeElementForm_EditMarkMistakeElementMutation>(
			EDIT_MARK_MISTAKE_ELEMENT_MUTATION,
		);

	const formId = "MarkMistakeElementForm";
	const dispatch = useTypedDispatch();

	const formik = useFormik<FormState>({
		initialValues: {
			id: markMistakeElement.id,
			text: markMistakeElement.text || "",
			title: markMistakeElement.title || "",
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Titel wird benötigt."),
			text: Yup.string().required("Das Feld Text wird benötigt."),
		}),
		onSubmit: (values, { setSubmitting }) => {
			editMarkMistakeElement({
				variables: {
					input: {
						markMistakesElementId: values.id,
						newTitle: values.title,
						newText: values.text,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
			setSubmitting(false);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"title"}
				label={"Titel"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldValue, updateField, fieldName, isValid }) => {
					return (
						<DefaultTextFieldComponent
							fieldName={fieldName + "-string"}
							disabled={isEditingMarkMistakeElement}
							isValid={isValid}
							fieldValue={fieldValue}
							required
							updateField={(value) => {
								updateField(value);
								dispatch(addEditedFormToEditedFormsArray({ form: formId }));
							}}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, string>
				name={"text"}
				label={"Text"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldValue, updateField, fieldName, isValid }) => {
					return (
						<DefaultTextAreaComponent
							fieldName={fieldName + "-string"}
							disabled={isEditingMarkMistakeElement}
							isValid={isValid}
							required
							fieldValue={fieldValue}
							updateField={(value) => {
								updateField(value);
								dispatch(addEditedFormToEditedFormsArray({ form: formId }));
							}}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<Button
				disabled={isEditingMarkMistakeElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};
