import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = css({
	paddingY: "20",
});

export const treeTitleClass = flex({
	flex: "1",
	marginY: "4",
	hyphens: "auto",
	alignItems: "center",
	maxWidth: "full",
	wordBreak: "break-word",
});

export const treeToolbarClass = flex({
	flex: "1",
	marginTop: "8",
	marginBottom: "4",
	gap: "8",
	alignItems: "center",
	justify: "space-between",
	width: "full",
});

export const dividerClass = css({
	border: "1px solid",
	borderColor: "shade.20",
	backgroundColor: "transparent",
	marginBottom: "8",
});
