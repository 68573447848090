import { graphql } from "react-relay";

export const VIMEO_VIMEO_VIDEO_SOURCE_FRAGMENT = graphql`
	fragment editVideoSourceButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {
		id
		...videoSourceForm_Vimeo_VimeoVideoSourceFragment
	}
`;

export const EDIT_VIMEO_VIDEO_SOURCE_MUTATION = graphql`
	mutation editVideoSourceButton_editVimeoVideoSourceMutation(
		$input: EditVimeoVideoSourceInput!
		$connections: [ID!]!
	) {
		Admin {
			VimeoVideoSource {
				editVimeoVideoSource(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...videoSourcesTable_Vimeo_VimeoVideoSourceFragment
						}
					}
				}
			}
		}
	}
`;
