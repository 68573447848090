/**
 * @generated SignedSource<<0c67c4ad4e46d7bfb5571666626bcc40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type StartContentConfigType = "StartContent_CanAfterStartingTree" | "StartContent_CanIfInTreeFlow" | "StartContent_CanIfUnlocked" | "StartContent_CanNot";
import { FragmentRefs } from "relay-runtime";
export type startContentConfigEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly startConfigs?: ReadonlyArray<{
      readonly configType: StartContentConfigType;
      readonly id: string;
    }>;
  };
  readonly " $fragmentType": "startContentConfigEditor_TreeNodeFragment";
};
export type startContentConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: startContentConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"startContentConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "startConfigs",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "configType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "startContentConfigEditor_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "ELearningContentTypeDefinition",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "AsyncContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "ce063d057e8452fa135e5e7c3dca339e";

export default node;
