import { EpicIcons } from "@constellation-academy/epic-ui";
import * as Sentry from "@sentry/react";
import { memo, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { Button, ButtonVariant } from "@components/button";
import { ConfirmDialog } from "@components/confirm-dialog";
import type { unlockTreeButton_LearnOpportunityV2Fragment$key } from "@relay/unlockTreeButton_LearnOpportunityV2Fragment.graphql";
import type { unlockTreeButton_UnlockTreeByAdminMutation } from "@relay/unlockTreeButton_UnlockTreeByAdminMutation.graphql";
import {
	LEARN_OPPORTUNITY_V2_FRAGMENT,
	UNLOCK_TREE_BY_ADMIN_MUTATION,
} from "./unlock-tree-button.graphql";
import type { UnlockTreeButtonProps } from "./unlock-tree-button.types";

const UnlockTreeButtonComponent = ({
	learnOpportunityV2FragmentRef,
	userId,
	onCompleted,
	onError,
}: UnlockTreeButtonProps) => {
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);

	const rootNode = useFragment<unlockTreeButton_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef ?? null,
	);

	const [unlockTree, isUnlockingTree] = useMutation<unlockTreeButton_UnlockTreeByAdminMutation>(
		UNLOCK_TREE_BY_ADMIN_MUTATION,
	);

	const handleUnlockTreeOnClick = () => {
		setShowConfirmDialog(true);
	};

	const handleUnlockTreeOnCancel = () => {
		setShowConfirmDialog(false);
	};

	const handleUnlockTreeOnConfirm = () => {
		if (!rootNode) {
			toast.error("Fehler beim entfernen der Paywall! RootNode nicht gefunden.");
			Sentry.captureException(
				new Error("unlockTreeButton_UnlockTreeByAdminMutation: RootNode not found"),
				{
					extra: {
						userId,
						rootNode,
					},
				},
			);
			return;
		}

		unlockTree({
			variables: {
				input: { rootId: rootNode.id, userId },
			},
			onCompleted: () => {
				toast.success("Paywall erfolgreich entfernt!");
				onCompleted?.();
			},
			onError: () => {
				toast.error("Fehler beim entfernen der Paywall!");
				onError?.();
			},
		});
	};

	return (
		<>
			<Button
				label="Paywall entfernen"
				icon={EpicIcons.DOLLAR}
				variant={ButtonVariant.Strong}
				onClick={handleUnlockTreeOnClick}
				disabled={isUnlockingTree}
				loading={isUnlockingTree}
			/>
			<ConfirmDialog
				title="Paywall entfernen"
				content={`Möchtest du die Paywall für den Kurs "${
					rootNode?.structureDefinition.title ?? "Unbekannter Kurs"
				}" wirklich entfernen?`}
				confirmButtonText="Ja, Paywall entfernen"
				onConfirm={handleUnlockTreeOnConfirm}
				onCancel={handleUnlockTreeOnCancel}
				showDialog={showConfirmDialog}
			/>
		</>
	);
};

export const UnlockTreeButton = memo(UnlockTreeButtonComponent);
