import { graphql } from "react-relay";

export const DESKTOP_NOTIFICATION_FRAGMENT = graphql`
	fragment desktopNotificationForm_DesktopNotificationFragment on MessageDefinition {
		desktopNotification {
			title
			content
			url
			icon {
				id
				name
			}
		}
	}
`;
