import { graphql } from "react-relay";

export const QUERY = graphql`
	query selectUserFieldDialog_Query($userId: ID!, $skip: Boolean!) {
		node(id: $userId) @skip(if: $skip) {
			... on User {
				name
				email
			}
		}
	}
`;
