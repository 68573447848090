/**
 * @generated SignedSource<<f58da31f356995ad928d2a65fd6f2fe7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type LimitedCartDiscountConditionKindEnum = "ProductSelection";
import { FragmentRefs } from "relay-runtime";
export type limitedCartDiscountTable_LimitedCartDiscountFragment$data = {
  readonly conditions: ReadonlyArray<{
    readonly kind: LimitedCartDiscountConditionKindEnum;
  }>;
  readonly createdAt: string;
  readonly id: string;
  readonly productSelectionConfig: {
    readonly value?: number;
  };
  readonly title: string;
  readonly " $fragmentType": "limitedCartDiscountTable_LimitedCartDiscountFragment";
};
export type limitedCartDiscountTable_LimitedCartDiscountFragment$key = {
  readonly " $data"?: limitedCartDiscountTable_LimitedCartDiscountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"limitedCartDiscountTable_LimitedCartDiscountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "limitedCartDiscountTable_LimitedCartDiscountFragment"
};

(node as any).hash = "3072eadac660f632f84f74fb5e77a941";

export default node;
