/**
 * @generated SignedSource<<36f932ebf656b0f63a8db1c58f2ae03c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ordersScreen_Query$variables = {
  email?: string | null | undefined;
  numItemsPerPage: number;
  username?: string | null | undefined;
};
export type ordersScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ordersTable_OrdersFragment">;
};
export type ordersScreen_Query = {
  response: ordersScreen_Query$data;
  variables: ordersScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numItemsPerPage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v3 = [
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "numItemsPerPage"
  },
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paymentIntentId",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ordersScreen_Query",
    "selections": [
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "ordersTable_OrdersFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ordersScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "OrdersConnection",
                "kind": "LinkedField",
                "name": "Orders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrdersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Order",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "selectedPaymentMethod",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paymentMethodType",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MonthlyOption",
                                    "kind": "LinkedField",
                                    "name": "chosenOption",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Price",
                                        "kind": "LinkedField",
                                        "name": "monthlyRate",
                                        "plural": false,
                                        "selections": (v5/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "MonthlyPaymentMethod",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Cart",
                            "kind": "LinkedField",
                            "name": "cart",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Totals",
                                "kind": "LinkedField",
                                "name": "totals",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Price",
                                    "kind": "LinkedField",
                                    "name": "includingAllDiscounts",
                                    "plural": false,
                                    "selections": (v5/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "invoiceData",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isInvoiceData"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "invoiceId",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "invoiceNumber",
                                    "storageKey": null
                                  }
                                ],
                                "type": "CreatedInvoiceData",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "paymentData",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paymentMethod",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dataType",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v6/*: any*/),
                                "type": "StripePaymentProviderData",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v6/*: any*/),
                                "type": "StripeSepaPaymentProviderData",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "username",
                  "email",
                  "accountId",
                  "userId"
                ],
                "handle": "connection",
                "key": "OrdersTable_Orders",
                "kind": "LinkedHandle",
                "name": "Orders"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f1e346ae94c327c62b2cb8bf31d812f",
    "id": null,
    "metadata": {},
    "name": "ordersScreen_Query",
    "operationKind": "query",
    "text": "query ordersScreen_Query(\n  $username: String\n  $email: String\n  $numItemsPerPage: Int!\n) {\n  ...ordersTable_OrdersFragment_4cmgOX\n}\n\nfragment invoiceProviderLink_InvoiceDataFragment on InvoiceData {\n  __isInvoiceData: __typename\n  ... on CreatedInvoiceData {\n    invoiceId\n    invoiceNumber\n  }\n}\n\nfragment orderPriceDisplay_OrderFragment on Order {\n  status\n  cart {\n    totals {\n      includingAllDiscounts {\n        netPrice\n      }\n    }\n  }\n  selectedPaymentMethod {\n    __typename\n    ... on MonthlyPaymentMethod {\n      chosenOption {\n        monthlyRate {\n          netPrice\n        }\n      }\n    }\n  }\n  invoiceData {\n    __typename\n    ...invoiceProviderLink_InvoiceDataFragment\n  }\n}\n\nfragment ordersTable_OrderFragment on Order {\n  id\n  createdAt\n  status\n  selectedPaymentMethod {\n    __typename\n    kind\n    paymentMethodType\n  }\n  ...orderPriceDisplay_OrderFragment\n  ...paymentProviderLink_OrderFragment\n}\n\nfragment ordersTable_OrdersFragment_4cmgOX on Query {\n  Admin {\n    Billing {\n      Orders(first: $numItemsPerPage, username: $username, email: $email) {\n        edges {\n          node {\n            ...ordersTable_OrderFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment paymentProviderLink_OrderFragment on Order {\n  paymentData {\n    __typename\n    paymentMethod\n    dataType\n    ... on StripePaymentProviderData {\n      paymentIntentId\n    }\n    ... on StripeSepaPaymentProviderData {\n      paymentIntentId\n    }\n  }\n  status\n}\n"
  }
};
})();

(node as any).hash = "9c35ccdac6a3b648a75c514bffd81ede";

export default node;
