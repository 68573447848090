import { graphql } from "react-relay";

export const INVOICE_DATA_FRAGMENT = graphql`
	fragment invoiceProviderLink_InvoiceDataFragment on InvoiceData {
		... on CreatedInvoiceData {
			invoiceId
			invoiceNumber
		}
	}
`;
