/**
 * @generated SignedSource<<2a8c2c595d1eb4110cfe92ca3148313b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectDiscountCodeField_Refetch$variables = {
  after?: string | null | undefined;
  code?: string | null | undefined;
  first?: number | null | undefined;
};
export type selectDiscountCodeField_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectDiscountCodeField_QueryFragment">;
};
export type selectDiscountCodeField_Refetch = {
  response: selectDiscountCodeField_Refetch$data;
  variables: selectDiscountCodeField_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "code",
    "variableName": "code"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectDiscountCodeField_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "selectDiscountCodeField_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectDiscountCodeField_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "DiscountCodesV2Connection",
                "kind": "LinkedField",
                "name": "searchDiscountCodes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "code"
                ],
                "handle": "connection",
                "key": "selectDiscountCodeField_searchDiscountCodes",
                "kind": "LinkedHandle",
                "name": "searchDiscountCodes"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0525ad4067aa13028c4f78e41fd01920",
    "id": null,
    "metadata": {},
    "name": "selectDiscountCodeField_Refetch",
    "operationKind": "query",
    "text": "query selectDiscountCodeField_Refetch(\n  $after: String\n  $code: String\n  $first: Int = 20\n) {\n  ...selectDiscountCodeField_QueryFragment_2231ah\n}\n\nfragment minimizedDiscountCodeTable_DiscountCodeFragment on DiscountCode {\n  id\n  code\n}\n\nfragment minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment on DiscountCodesV2Connection {\n  edges {\n    node {\n      ...minimizedDiscountCodeTable_DiscountCodeFragment\n      id\n    }\n  }\n}\n\nfragment selectDiscountCodeField_QueryFragment_2231ah on Query {\n  Admin {\n    Billing {\n      searchDiscountCodes(first: $first, after: $after, code: $code) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            __typename\n          }\n          cursor\n        }\n        ...minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "70b56234a484e1fc5ee2209b1f2283d7";

export default node;
