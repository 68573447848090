import { graphql } from "react-relay";

export const DELETE_PERSONALIZED_EMAIL_TEMPLATE_MUTATION = graphql`
	mutation deleteEmailTemplateButton_DeletePersonalizedEmailTemplateMutation(
		$input: DeletePersonalizedEmailTemplateInput!
		$connections: [ID!]!
	) {
		Admin {
			Email {
				deletePersonalizedEmailTemplate(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
