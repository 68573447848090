/**
 * @generated SignedSource<<cd4dca4f7725a167cdfb02b944512679>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CreateBranchRootInput = {
  clientMutationId?: string | null | undefined;
  title: string;
};
export type createCourseLearnableButton_AcademiesCreateBranchRootMutation$variables = {
  input: CreateBranchRootInput;
};
export type createCourseLearnableButton_AcademiesCreateBranchRootMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly createBranchRoot: {
        readonly createdRootNode: {
          readonly node: {
            readonly id: string;
          };
        };
        readonly tree: {
          readonly nodes: ReadonlyArray<{
            readonly __typename: "TreeNode";
          }>;
        };
      } | null | undefined;
    };
  };
};
export type createCourseLearnableButton_AcademiesCreateBranchRootMutation = {
  response: createCourseLearnableButton_AcademiesCreateBranchRootMutation$data;
  variables: createCourseLearnableButton_AcademiesCreateBranchRootMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "TreeNodesEdge",
  "kind": "LinkedField",
  "name": "createdRootNode",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TreeNode",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createCourseLearnableButton_AcademiesCreateBranchRootMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateBranchRootPayload",
                "kind": "LinkedField",
                "name": "createBranchRoot",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tree",
                    "kind": "LinkedField",
                    "name": "tree",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TreeNode",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createCourseLearnableButton_AcademiesCreateBranchRootMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateBranchRootPayload",
                "kind": "LinkedField",
                "name": "createBranchRoot",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tree",
                    "kind": "LinkedField",
                    "name": "tree",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TreeNode",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "66061db5d51496463c931516d6743ac2",
    "id": null,
    "metadata": {},
    "name": "createCourseLearnableButton_AcademiesCreateBranchRootMutation",
    "operationKind": "mutation",
    "text": "mutation createCourseLearnableButton_AcademiesCreateBranchRootMutation(\n  $input: CreateBranchRootInput!\n) {\n  Admin {\n    Tree {\n      createBranchRoot(input: $input) {\n        createdRootNode {\n          node {\n            id\n          }\n        }\n        tree {\n          nodes {\n            __typename\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "12a04ba13d682e8815dee082edcd20b2";

export default node;
