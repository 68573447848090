/**
 * @generated SignedSource<<6c31485ae99a8f2de853ce00096a1cb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type discountCodeCreateRandom_DiscountActionFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "discountCodeCreateRandom_DiscountActionFragment";
};
export type discountCodeCreateRandom_DiscountActionFragment$key = {
  readonly " $data"?: discountCodeCreateRandom_DiscountActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"discountCodeCreateRandom_DiscountActionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "discountCodeCreateRandom_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};

(node as any).hash = "608a0a040e226dc41334bdd70d36c3ca";

export default node;
