import { graphql } from "react-relay";

export const reportsPerPage = 20;

export const QUERY = graphql`
	query reportsScreen_Query($filterByReferenceNumberOpt: String, $numItemsPerPage: Int!) {
		...reportsTable_QueryFragment
			@arguments(
				filterByReferenceNumberOpt: $filterByReferenceNumberOpt
				first: $numItemsPerPage
				after: null
			)
	}
`;
