import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { NoAccess } from "@components/no-access";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type emailTemplates_EmailTemplatesQuery } from "@relay/emailTemplates_EmailTemplatesQuery.graphql";
import { QUERY } from "./email-templates.graphql";
import { EmailTemplatesTable } from "./parts/email-templates-table";

export const EmailTemplatesScreen = () => {
	const data = useLazyLoadQuery<emailTemplates_EmailTemplatesQuery>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	const canRead = useHasPermissions(["UserInAccountPermission_Email_Templates"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen title="E-Mail-Vorlagen">
			<EmailTemplatesTable EmailTemplatesFragmentRef={data} />
		</BaseScreen>
	);
};
