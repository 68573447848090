import { graphql, readInlineData, usePaginationFragment } from "react-relay";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext } from "react";
import { type DiscountCodeUsageTable_DiscountCodeFragment$key } from "@relay/DiscountCodeUsageTable_DiscountCodeFragment.graphql";
import { type DiscountCodeUsageTable_Refetch } from "@relay/DiscountCodeUsageTable_Refetch.graphql";
import { DiscountCodeUsageTableHeader } from "./DiscountCodeUsageTableHeader";
import { UsageIdColumn } from "./UsageIdColumn";
import { UsageInvoiceColumn } from "./UsageInvoiceColumn";
import { UsageOrderColumn } from "./UsageOrderColumn";
import { UsageUsedAtColumn } from "./UsageUsedAtColumn";
import {
	type DiscountUsageFilters,
	DiscountUsageSearchContext,
} from "./DiscountUsageSearchContext";
import { useDebounceFilters } from "../../../hooks/useDebounceFilters";

const QUERY_FRAGMENT = graphql`
	fragment DiscountCodeUsageTable_DiscountCodeFragment on Query
	@refetchable(queryName: "DiscountCodeUsageTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int!" }
		after: { type: "String" }
		filterByUsedAtFrom: { type: "ZonedDateTIme" }
		filterByUsedToFrom: { type: "ZonedDateTIme" }
		id: { type: "ID!" }
	) {
		node(id: $id) {
			... on DiscountCode {
				searchUsages(
					after: $after
					first: $first
					filterByUsedAtFrom: $filterByUsedAtFrom
					filterByUsedToFrom: $filterByUsedToFrom
				) {
					usages(after: $after, first: $first)
						@connection(key: "DiscountCodeUsageTable_usages") {
						edges {
							node {
								...DiscountCodeUsageTable_DiscountCodeUsageFragment
							}
						}
					}
					...DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment
				}
			}
		}
	}
`;

const DISCOUNT_CODE_USAGE_FRAGMENT = graphql`
	fragment DiscountCodeUsageTable_DiscountCodeUsageFragment on DiscountCodeUsage @inline {
		...UsageUsedAtColumn_DiscountCodeUsageFragment
		...UsageOrderColumn_DiscountCodeUsageFragment
		...UsageIdColumn_DiscountCodeUsageFragment
		...UsageInvoiceColumn_DiscountCodeUsageFragment
	}
`;

type OwnProps = {
	query: DiscountCodeUsageTable_DiscountCodeFragment$key;
};

export const DiscountCodeUsageTable = ({ query }: OwnProps) => {
	const { filters } = useContext(DiscountUsageSearchContext);

	const {
		data: code,
		hasNext,
		loadNext,
		refetch,
	} = usePaginationFragment<
		DiscountCodeUsageTable_Refetch,
		DiscountCodeUsageTable_DiscountCodeFragment$key
	>(QUERY_FRAGMENT, query);

	useDebounceFilters<DiscountUsageFilters>(filters, refetch);

	return (
		<>
			<DataTable
				emptyMessage="Keine Verwendungen"
				className="mt-3"
				header={() => (
					<DiscountCodeUsageTableHeader
						searchDiscountUsageResultFragmentRef={code.node?.searchUsages!}
					/>
				)}
				value={
					code.node?.searchUsages?.usages?.edges
						?.filter((e: any) => !!e?.node)
						.map((e: any) => e!.node)
						.map((item: any) => readInlineData(DISCOUNT_CODE_USAGE_FRAGMENT, item)) ||
					[]
				}
			>
				<Column
					header="ID"
					body={(item) => <UsageIdColumn discountCodeUsageFragmentRef={item} />}
				/>
				<Column
					header="Genutzt am"
					body={(item) => <UsageUsedAtColumn discountCodeUsageFragmentRef={item} />}
				/>
				<Column
					header="Rechnung"
					body={(item) => <UsageInvoiceColumn discountCodeUsageFragmentRef={item} />}
				/>
				<Column
					header="Bestellung"
					body={(item) => <UsageOrderColumn discountCodeUsageFragmentRef={item} />}
				/>
			</DataTable>
			<div className="flex justify-content-center align-items-center">
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					label="Weiter"
					onClick={(_) => loadNext(20)}
				/>
			</div>
		</>
	);
};
