/**
 * @generated SignedSource<<842c6dfe60fde3d13d9ce18dfc6bf064>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MoveOrderItemsInput = {
  clientMutationId?: string | null | undefined;
  orderElementId: string;
  orderItemIds: ReadonlyArray<string>;
};
export type OrderElementForm_MoveOrderItemMutation$variables = {
  input: MoveOrderItemsInput;
};
export type OrderElementForm_MoveOrderItemMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly moveOrderItems: {
        readonly orderElement: {
          readonly " $fragmentSpreads": FragmentRefs<"OrderElementForm_OrderElementFragment">;
        };
      } | null | undefined;
    };
  };
};
export type OrderElementForm_MoveOrderItemMutation = {
  response: OrderElementForm_MoveOrderItemMutation$data;
  variables: OrderElementForm_MoveOrderItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderElementForm_MoveOrderItemMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "MoveOrderItemsPayload",
                "kind": "LinkedField",
                "name": "moveOrderItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderElement",
                    "kind": "LinkedField",
                    "name": "orderElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OrderElementForm_OrderElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrderElementForm_MoveOrderItemMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "MoveOrderItemsPayload",
                "kind": "LinkedField",
                "name": "moveOrderItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderElement",
                    "kind": "LinkedField",
                    "name": "orderElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answeringTypeText",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderItem",
                        "kind": "LinkedField",
                        "name": "orderItems",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "480776d2754ef3dd4b4ab924ebcf66af",
    "id": null,
    "metadata": {},
    "name": "OrderElementForm_MoveOrderItemMutation",
    "operationKind": "mutation",
    "text": "mutation OrderElementForm_MoveOrderItemMutation(\n  $input: MoveOrderItemsInput!\n) {\n  Admin {\n    ElearningV2 {\n      moveOrderItems(input: $input) {\n        orderElement {\n          ...OrderElementForm_OrderElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment EditOrderItemButton_OrderItemFragment on OrderItem {\n  id\n  text\n}\n\nfragment OrderElementForm_OrderElementFragment on OrderElement {\n  id\n  title\n  answeringTypeText\n  orderItems {\n    id\n    ...OrderItemForm_OrderItemFragment\n  }\n}\n\nfragment OrderItemForm_OrderItemFragment on OrderItem {\n  id\n  text\n  ...EditOrderItemButton_OrderItemFragment\n  ...RemoveOrderItemButton_OrderItemFragment\n}\n\nfragment RemoveOrderItemButton_OrderItemFragment on OrderItem {\n  id\n}\n"
  }
};
})();

(node as any).hash = "e3a7730942ac5a46bc961fce1e5cb6a0";

export default node;
