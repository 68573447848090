import { graphql } from "react-relay";

export const QUERY = graphql`
	query educationalOffer_Query($input: ID!) {
		Admin {
			Tree {
				Tree(rootId: $input) {
					...educationalOffer_TreeFragment
				}
			}
			PublishingV2 {
				ActivePublishing(rootId: $input) {
					...educationalOffer_PublishingV2Fragment
				}
			}
		}
	}
`;

export const TREE_FRAGMENT = graphql`
	fragment educationalOffer_TreeFragment on Tree {
		...educationalOfferTree_TreeFragment
	}
`;

export const PUBLISHINGV2_FRAGMENT = graphql`
	fragment educationalOffer_PublishingV2Fragment on PublishingV2 {
		status
		...educationalOfferTree_PublishingV2Fragment
		...educationalOfferPublishing_PublishingV2Fragment
	}
`;

export const SUBSCRIPTION = graphql`
	subscription educationalOffer_Subscription {
		subscribeToPublishingUpdatesV2 {
			tree {
				id
				...educationalOffer_TreeFragment
			}
			publishing {
				...educationalOfferPublishing_PublishingV2Fragment
			}
		}
	}
`;
