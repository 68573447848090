import { graphql } from "react-relay";

export const ORDER_FRAGMENT = graphql`
	fragment paymentProviderLink_OrderFragment on Order {
		paymentData {
			paymentMethod
			dataType
			... on StripePaymentProviderData {
				paymentIntentId
			}
			... on StripeSepaPaymentProviderData {
				paymentIntentId
			}
		}
		status
	}
`;
