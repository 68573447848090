import { Button, EpicIcons } from "@constellation-academy/epic-ui";
import { graphql, useMutation } from "react-relay";
import React from "react";
import { type CreateClozeTextElementButton_CreateClozeTextElementMutation } from "@relay/CreateClozeTextElementButton_CreateClozeTextElementMutation.graphql";

const CREATE_CLOZE_TEXT_ELEMENT_MUTATION = graphql`
	mutation CreateClozeTextElementButton_CreateClozeTextElementMutation(
		$input: CreateClozeTextElementV2Input!
	) {
		Admin {
			ElearningV2 {
				createClozeTextElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type OwnProps = {
	eLearningContentNodeId: string;
};

export const CreateClozeTextElementButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [CreateClozeTextElement, isCreatingClozeTextElement] =
		useMutation<CreateClozeTextElementButton_CreateClozeTextElementMutation>(
			CREATE_CLOZE_TEXT_ELEMENT_MUTATION,
		);
	return (
		<Button
			disabled={isCreatingClozeTextElement}
			tooltip={`Lückentext-Baustein anlegen`}
			icon={EpicIcons.WINDOW_MINIMIZE}
			onClick={() => {
				CreateClozeTextElement({
					variables: {
						input: {
							title: "Neues Element",
							text: "",
							additionalWords: [],
							eLearningContentNodeId,
						},
					},
				});
			}}
		/>
	);
};
