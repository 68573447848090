import { graphql } from "react-relay";

export const ASYNC_ELEMENT_FRAGMENT = graphql`
	fragment asyncElementForm_AsyncElementFragment on AsyncElement {
		id
		title
		kind
		...uploadAsyncElementForm_UploadAsyncElementFragment
	}
`;
