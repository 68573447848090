import { graphql } from "react-relay";

export const ORDER_FRAGMENT = graphql`
	fragment cartForm_OrderFragment on Order {
		cart {
			selection {
				selectedProducts {
					amount
					product {
						title
					}
				}
				selectedDiscountCodes
			}
		}
	}
`;
