/**
 * @generated SignedSource<<49ee842b7b5e2cb396b344b347023d9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditVimeoVideoSourceInput = {
  clientMutationId?: string | null | undefined;
  id: string;
  name: string;
};
export type editVideoSourceButton_editVimeoVideoSourceMutation$variables = {
  connections: ReadonlyArray<string>;
  input: EditVimeoVideoSourceInput;
};
export type editVideoSourceButton_editVimeoVideoSourceMutation$data = {
  readonly Admin: {
    readonly VimeoVideoSource: {
      readonly editVimeoVideoSource: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"videoSourcesTable_Vimeo_VimeoVideoSourceFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type editVideoSourceButton_editVimeoVideoSourceMutation = {
  response: editVideoSourceButton_editVimeoVideoSourceMutation$data;
  variables: editVideoSourceButton_editVimeoVideoSourceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDefault",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessToken",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editVideoSourceButton_editVimeoVideoSourceMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VimeoVideoSourceAdminMutationSchema",
            "kind": "LinkedField",
            "name": "VimeoVideoSource",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "EditVimeoVideoSourcePayload",
                "kind": "LinkedField",
                "name": "editVimeoVideoSource",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Vimeo_VimeoVideoSourceEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Vimeo_VimeoVideoSource",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "videoSourcesTable_Vimeo_VimeoVideoSourceFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "editVideoSourceButton_editVimeoVideoSourceMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VimeoVideoSourceAdminMutationSchema",
            "kind": "LinkedField",
            "name": "VimeoVideoSource",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "EditVimeoVideoSourcePayload",
                "kind": "LinkedField",
                "name": "editVimeoVideoSource",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Vimeo_VimeoVideoSourceEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Vimeo_VimeoVideoSource",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56e10b183c842ea8bafbc7b670fcd666",
    "id": null,
    "metadata": {},
    "name": "editVideoSourceButton_editVimeoVideoSourceMutation",
    "operationKind": "mutation",
    "text": "mutation editVideoSourceButton_editVimeoVideoSourceMutation(\n  $input: EditVimeoVideoSourceInput!\n) {\n  Admin {\n    VimeoVideoSource {\n      editVimeoVideoSource(input: $input) {\n        edge {\n          node {\n            ...videoSourcesTable_Vimeo_VimeoVideoSourceFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  isDefault\n}\n\nfragment editVideoSourceButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  ...videoSourceForm_Vimeo_VimeoVideoSourceFragment\n}\n\nfragment setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  isDefault\n}\n\nfragment videoSourceForm_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  name\n}\n\nfragment videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  ...deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment\n  ...setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment\n  ...editVideoSourceButton_Vimeo_VimeoVideoSourceFragment\n}\n\nfragment videoSourcesTable_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  name\n  isDefault\n  accessToken\n  ...videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment\n}\n"
  }
};
})();

(node as any).hash = "b8ae6fab0808e5d00e322c84fec035aa";

export default node;
