import { graphql } from "react-relay";

export const CREATE_DISCOUNT_ACTION_MUTATION = graphql`
	mutation createPercentageDiscountActionButton_CreateDiscountActionMutation(
		$input: CreateDiscountActionInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createDiscountAction(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...DiscountActionsTable_DiscountActionFragment
						}
					}
				}
			}
		}
	}
`;
