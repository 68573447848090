import { graphql } from "react-relay";

export const VIMEO_VIMEO_VIDEO_SOURCE_FRAGMENT = graphql`
	fragment deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {
		id
		isDefault
	}
`;

export const DELETE_VIMEO_VIDEO_SOURCE_MUTATION = graphql`
	mutation deleteVideoSourceButton_DeleteVimeoVideoSourceMutation(
		$input: DeleteVimeoVideoSourceInput!
		$connections: [ID!]!
	) {
		Admin {
			VimeoVideoSource {
				deleteVimeoVideoSource(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
