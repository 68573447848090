/**
 * @generated SignedSource<<af6a68fd7e9575c6c4238ddeb50972ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AddIncludeTreeFinishedDispatchConditionInput = {
  clientMutationId?: string | null | undefined;
  messageDispatchId: string;
  rootNodeIds: ReadonlyArray<string>;
};
export type useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation$variables = {
  input: AddIncludeTreeFinishedDispatchConditionInput;
};
export type useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation$data = {
  readonly Admin: {
    readonly Message: {
      readonly addIncludeTreeFinishedDispatchCondition: {
        readonly clientMutationId: string | null | undefined;
      } | null | undefined;
    };
  };
};
export type useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation = {
  response: useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation$data;
  variables: useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Message",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "AddIncludeTreeFinishedDispatchConditionPayload",
            "kind": "LinkedField",
            "name": "addIncludeTreeFinishedDispatchCondition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e76bdcf5f293a6c2b7aeeaca4df72225",
    "id": null,
    "metadata": {},
    "name": "useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation",
    "operationKind": "mutation",
    "text": "mutation useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation(\n  $input: AddIncludeTreeFinishedDispatchConditionInput!\n) {\n  Admin {\n    Message {\n      addIncludeTreeFinishedDispatchCondition(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc1e6647508d0f2f147d514882bab0f9";

export default node;
