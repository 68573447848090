import { graphql } from "react-relay";

export const DELETE_DISCOUNT_CODE_MUTATION = graphql`
	mutation discountCodeDeleteButton_DeleteMutation(
		$input: DeleteDiscountCodeInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				deleteDiscountCode(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
					clientMutationId
				}
			}
		}
	}
`;
export const DISCOUNT_CODE_FRAGMENT = graphql`
	fragment discountCodeDeleteButton_DiscountCodeFragment on DiscountCode {
		id
	}
`;
