import { USER_GROUP_NAME } from "./accounts-for-users-table.consts";

export const convertUserInAccountGroupName = (name: string) => {
	switch (name) {
		case "Owner":
			return "Besitzer";
		case "Editor":
			return "Redakteur";
		case USER_GROUP_NAME:
			return "";
		default:
			return name;
	}
};
