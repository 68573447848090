import { RelayPageDataTable } from "@constellation-academy/epic-ui";
import React, { useState } from "react";
import { usePaginationFragment } from "react-relay";
import { EMAIL_TEMPLATE_KEYS } from "@translations/email";
import { type emailTemplatesTable_EmailTemplatesListFragment$key } from "@relay/emailTemplatesTable_EmailTemplatesListFragment.graphql";
import { type emailTemplatesTable_PersonalizedEmailTemplatesFragment$key } from "@relay/emailTemplatesTable_PersonalizedEmailTemplatesFragment.graphql";
import { type emailTemplatesTable_Refetch } from "@relay/emailTemplatesTable_Refetch.graphql";
import { ROWS_COUNT } from "./email-templates-table.const";
import {
	QUERY_FRAGMENT,
	PERSONALIZED_EMAIL_TEMPLATE_FRAGMENT,
} from "./email-templates-table.graphql";
import { ButtonWrapper } from "./email-templates-table.styles";
import { type EmailTemplatesTableProps } from "./email-templates-table.types";
import { CreateEmailTemplateButton } from "../create-email-template-button";
import { DeleteEmailTemplateButton } from "../delete-email-template-button";
import { EditEmailTemplateButton } from "../edit-email-template-button";
import { SendEmailButton } from "../send-email-button";

export const EmailTemplatesTable = ({ EmailTemplatesFragmentRef }: EmailTemplatesTableProps) => {
	const [connectionId, setConnectionId] = useState<string>("");

	const { data } = usePaginationFragment<
		emailTemplatesTable_Refetch,
		emailTemplatesTable_EmailTemplatesListFragment$key
	>(QUERY_FRAGMENT, EmailTemplatesFragmentRef);

	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};

	return (
		<>
			<CreateEmailTemplateButton availableTemplatesFragmentRef={data} />
			<RelayPageDataTable<
				emailTemplatesTable_Refetch,
				emailTemplatesTable_EmailTemplatesListFragment$key,
				emailTemplatesTable_PersonalizedEmailTemplatesFragment$key
			>
				queryFragment={QUERY_FRAGMENT}
				parentFragmentRef={EmailTemplatesFragmentRef}
				nodeInlineFragment={PERSONALIZED_EMAIL_TEMPLATE_FRAGMENT}
				extractConnection={(data) => data.Admin.Email.PersonalizedEmailTemplates}
				onConnectionIdChange={handleOnConnectionIdChange}
				rowsCount={ROWS_COUNT}
				emptyMessage={"Keine Vorlagen"}
			>
				{(Column) => (
					<>
						<Column
							header="Schlüssel"
							body={(item) => EMAIL_TEMPLATE_KEYS[item.template.key]}
						/>
						<Column header="Betreff" body={(item) => item.template.subject} />
						<Column
							header="Aktionen"
							style={{ width: "20%" }}
							body={(item) => (
								<ButtonWrapper>
									<EditEmailTemplateButton
										personalizedEmailTemplateId={item.id}
									/>
									<SendEmailButton personalizedEmailTemplateId={item.id} />
									<DeleteEmailTemplateButton
										personalizedEmailTemplateId={item.id}
										connectionId={connectionId}
									/>
								</ButtonWrapper>
							)}
						/>
					</>
				)}
			</RelayPageDataTable>
		</>
	);
};
