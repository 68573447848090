import { graphql } from "react-relay";

export const DELETE_TAG_MUTATION = graphql`
	mutation deleteAcademiesTagButton_DeleteTagMutation(
		$input: deleteTagInput!
		$connections: [ID!]!
	) {
		Admin {
			AcademiesTag {
				deleteTag(input: $input) {
					deletedId @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
