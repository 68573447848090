import { graphql } from "react-relay";

export const PERMISSION_QUERY = graphql`
	query permissionBasedNavigation_Query {
		Viewer {
			Auth {
				currentUser {
					permissionsInAccount
					user {
						name
					}
					accounts {
						id
						name
					}
				}
			}
		}
	}
`;
