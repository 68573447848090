import { Button, ButtonType } from "@constellation-academy/epic-ui";
import { DefaultSwitchComponent } from "@constellation-academy/framework-react-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "@components/ValidatedField";
import {
	SelectNewsletterOptInFormProps,
	SelectNewsletterOptInFormState,
} from "@features/newsletter/select-newsletter-opt-in-form.types";

export const SelectNewsletterOptInForm = ({
	onOptInSelected,
	disabled,
}: SelectNewsletterOptInFormProps) => {
	const formik = useFormik<SelectNewsletterOptInFormState>({
		initialValues: {},
		validationSchema: Yup.object().shape({
			optIn: Yup.boolean(),
		}),
		onSubmit: (data, { setSubmitting }) => {
			onOptInSelected(data.optIn!);
			setSubmitting(false);
		},
	});

	const label = formik.values.optIn ? "Newsletter abonniert" : "Newsletter nicht abonniert";

	return (
		<form onSubmit={formik.handleSubmit} className="w-12 p-fluid mb-5">
			<ValidatedField<SelectNewsletterOptInFormState, boolean>
				className="mb-4"
				name={"optIn"}
				label={label}
				formikConfig={formik}
				component={DefaultSwitchComponent}
			/>
			<Button
				disabled={!formik.isValid || disabled}
				label="Auswahl speichern"
				type={ButtonType.Submit}
			/>
		</form>
	);
};
