import { Button, ButtonType } from "@constellation-academy/epic-ui";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { ProductSelectionForm } from "@features/products/product-selection-form";
import { type AddProductSelectionButtonProps } from "./add-product-selection-button.interface";

export const AddProductSelectionButton = ({ onSubmit }: AddProductSelectionButtonProps) => {
	const [showDialog, setIsShowingDialog] = useState(false);

	const handleOnClick = () => {
		setIsShowingDialog(true);
	};
	const handleOnHide = () => {
		setIsShowingDialog(false);
	};
	const handleOnBack = () => {
		setIsShowingDialog(false);
	};

	return (
		<div>
			<div className="flex w-auto align-items-center">
				<Button
					label="Produkt auswählen"
					type={ButtonType.Button}
					onClick={handleOnClick}
				/>
			</div>
			<Dialog
				header={<div>Produkt Bedingung</div>}
				visible={showDialog}
				onHide={handleOnHide}
			>
				<ProductSelectionForm
					onBack={handleOnBack}
					onSubmit={onSubmit}
					initialValues={{
						product: {
							title: "",
							id: "",
						},
						amount: 0,
					}}
				/>
			</Dialog>
		</div>
	);
};
