/**
 * @generated SignedSource<<1a734af170c1cd7f34c53eeb5ed95e20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type LearnableKind = "Course";
import { FragmentRefs } from "relay-runtime";
export type deleteCourseLearnableButton_UnpublishedLearnableFragment$data = {
  readonly id: string;
  readonly kind: LearnableKind;
  readonly root?: {
    readonly id: string;
    readonly structureDefinition: {
      readonly title: string;
    };
  } | null | undefined;
  readonly " $fragmentType": "deleteCourseLearnableButton_UnpublishedLearnableFragment";
};
export type deleteCourseLearnableButton_UnpublishedLearnableFragment$key = {
  readonly " $data"?: deleteCourseLearnableButton_UnpublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"deleteCourseLearnableButton_UnpublishedLearnableFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "deleteCourseLearnableButton_UnpublishedLearnableFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TreeNode",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "structureDefinition",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "UnpublishedCourseLearnable",
      "abstractKey": null
    }
  ],
  "type": "UnpublishedLearnable",
  "abstractKey": "__isUnpublishedLearnable"
};
})();

(node as any).hash = "f5902f8609cad5aac7e3e1dfbb22c828";

export default node;
