/**
 * @generated SignedSource<<2d0dd811f721a3f857323fa0fb75f111>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licenseProductForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectLicenseDefinitionField_QueryFragment">;
  readonly " $fragmentType": "licenseProductForm_QueryFragment";
};
export type licenseProductForm_QueryFragment$key = {
  readonly " $data"?: licenseProductForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licenseProductForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "licenseProductForm_QueryFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "dataKinds",
          "value": [
            "Flex"
          ]
        }
      ],
      "kind": "FragmentSpread",
      "name": "selectLicenseDefinitionField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0bc675f0d063a3a0f20c3bc925579da3";

export default node;
