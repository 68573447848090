/**
 * @generated SignedSource<<ea1d17aded96b03e3d3da51636de54a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type discountCodeEditButton_DiscountActionFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "discountCodeEditButton_DiscountActionFragment";
};
export type discountCodeEditButton_DiscountActionFragment$key = {
  readonly " $data"?: discountCodeEditButton_DiscountActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"discountCodeEditButton_DiscountActionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "discountCodeEditButton_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};

(node as any).hash = "3c909b932d7912bbee92e03530055eac";

export default node;
