import { graphql } from "react-relay";

export const ORDER_FRAGMENT = graphql`
	fragment invoiceDataForm_OrderFragment on Order {
		invoiceData {
			... on CreatedInvoiceData {
				invoiceNumber
				invoiceId
				invoiceFile {
					url
				}
			}
		}
	}
`;
