import { graphql, useFragment } from "react-relay";
import React from "react";
import { type TreeConfigEditor_TreeNodeFragment$key } from "@relay/TreeConfigEditor_TreeNodeFragment.graphql";
import { VisibilityTreeConfigEditor } from "@screens/educational-offer/parts/visibility-tree-config-editor/visibility-tree-config-editor.component";
import { StartTreeConfigEditor } from "./StartTreeConfigEditor";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../translations/tree";

const TREE_NODE_FRAGMENT = graphql`
	fragment TreeConfigEditor_TreeNodeFragment on TreeNode {
		...StartTreeConfigEditor_TreeNodeFragment
		...visibilityTreeConfigEditor_TreeNodeFragment
	}
`;

type OwnProps = {
	treeNodeFragmentRef: TreeConfigEditor_TreeNodeFragment$key;
};

export const TreeConfigEditor = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNodeFragment = useFragment<TreeConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	return (
		<>
			<h2>Auf {TREE_I18N_MAP(TREE_I18N_KEY.treeLevel)}</h2>
			<StartTreeConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			<VisibilityTreeConfigEditor treeNodeFragmentRef={treeNodeFragment} />
		</>
	);
};
