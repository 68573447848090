/**
 * @generated SignedSource<<d8bfb8a66a6e4293bc8879cf3218cdff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editLevelDefinitionButton_LevelDefinitionFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "editLevelDefinitionButton_LevelDefinitionFragment";
};
export type editLevelDefinitionButton_LevelDefinitionFragment$key = {
  readonly " $data"?: editLevelDefinitionButton_LevelDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editLevelDefinitionButton_LevelDefinitionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editLevelDefinitionButton_LevelDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "LevelDefinition",
  "abstractKey": null
};

(node as any).hash = "085bc9a0901208ad23e9cb02bf6a06cd";

export default node;
