/**
 * @generated SignedSource<<4d8fabe4fb02764e21ef173318c6db23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TextWithPagesPageDataKind = "ImagePageData" | "TextPageData";
import { FragmentRefs } from "relay-runtime";
export type textWithPagesElementPagesEditor_PagesFragment$data = {
  readonly id: string;
  readonly pages: ReadonlyArray<{
    readonly data: {
      readonly kind: TextWithPagesPageDataKind;
    };
    readonly id: string;
    readonly internalTitle: string;
    readonly " $fragmentSpreads": FragmentRefs<"textWithPagesElementPageFormDialog_PageFragment">;
  }>;
  readonly " $fragmentType": "textWithPagesElementPagesEditor_PagesFragment";
};
export type textWithPagesElementPagesEditor_PagesFragment$key = {
  readonly " $data"?: textWithPagesElementPagesEditor_PagesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"textWithPagesElementPagesEditor_PagesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "textWithPagesElementPagesEditor_PagesFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TextWithPagesPage",
      "kind": "LinkedField",
      "name": "pages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "textWithPagesElementPageFormDialog_PageFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TextWithPagesElement",
  "abstractKey": null
};
})();

(node as any).hash = "9ab1af5587248c88e21d55567cbafbea";

export default node;
