/**
 * @generated SignedSource<<1d13746ab3a2dee00988c19356d43f96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "persolog" | "persologEvaluation" | "persologQuestion" | "podcast" | "podcastWithTimestamp" | "reminder" | "text" | "textWithPages" | "video";
import { FragmentRefs } from "relay-runtime";
export type ElementNode_ElementV2Fragment$data = {
  readonly elementType: ElementTypeV2;
  readonly id: string;
  readonly question?: string;
  readonly title: string;
  readonly " $fragmentType": "ElementNode_ElementV2Fragment";
};
export type ElementNode_ElementV2Fragment$key = {
  readonly " $data"?: ElementNode_ElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElementNode_ElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElementNode_ElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "question",
          "storageKey": null
        }
      ],
      "type": "MultipleChoiceElementV2",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    }
  ],
  "type": "ElementV2",
  "abstractKey": "__isElementV2"
};

(node as any).hash = "f35c13496dde92a639f098b32a60cb6a";

export default node;
