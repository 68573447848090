import { graphql } from "react-relay";

export const ISSUE_EXTERNAL_LICENSE_TO_USER_MUTATION = graphql`
	mutation issueExternalLicenseToUserButton_IssueExternalLicenseMutation(
		$input: IssueExternalLicenseInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				issueExternalLicense(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							issuingInfo {
								hasBeenIssued
							}
							...externalLicensesTable_ExternalLicenseFragment
						}
					}
				}
			}
		}
	}
`;
