import { graphql } from "react-relay";

export const REMINDER_ELEMENT_FRAGMENT = graphql`
	fragment reminderElementForm_ReminderElementFragment on ReminderElement {
		id
		title
		text
	}
`;

export const EDIT_REMINDER_ELEMENT_MUTATION = graphql`
	mutation reminderElementForm_EditReminderElementMutation($input: EditReminderElementInput!) {
		Admin {
			ElearningV2 {
				editReminderElement(input: $input) {
					reminderElement {
						...reminderElementForm_ReminderElementFragment
					}
				}
			}
		}
	}
`;
