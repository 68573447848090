import { graphql } from "react-relay";

export const ROOT_NODES_WITH_TREE_STATE_TYPE_FRAGMENT = graphql`
	fragment userRootNodeItem_RootNodesWithTreeStateTypeFragment on RootNodesWithTreeStateType {
		rootNode {
			...userRootNodeItem_LearnOpportunityV2Fragment
			...reenablePaywallButton_LearnOpportunityV2Fragment
			...resetLearnProgressButton_LearnOpportunityV2Fragment
			...createTreeStateButton_LearnOpportunityV2Fragment
			...unlockTreeButton_LearnOpportunityV2Fragment
		}
		treeState {
			...userRootNodeItem_TreeStateFragment
		}
	}
`;

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment userRootNodeItem_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on PublishedRootExtensionImpl {
						unlockInfo {
							kind
							... on UnlockInfoDemo {
								firstContentId
								firstContentCoordinatesIndexPath
								kind
							}
						}
					}
				}
			}
		}
	}
`;

export const TREE_STATE_FRAGMENT = graphql`
	fragment userRootNodeItem_TreeStateFragment on TreeState {
		id
		definition {
			status
			... on FinishedTreeStateDefinition {
				status
			}
			... on StartedTreeStateDefinition {
				status
				headContentNode {
					id
				}
			}
		}
		user {
			name
		}
		startedAt
		progressPercentage
		rootNode {
			parentId
			structureDefinition {
				coordinates {
					parentRef
				}
				definitionType
				title
			}
			typeDefinition {
				definitionType
				... on BranchTypeDefinition {
					childRefs
				}
				... on ContentTypeDefinition {
					contentKind
					... on ELearningContentTypeDefinition {
						elements {
							id
							elementType
							...ElementNode_ElementV2Fragment
						}
					}
				}
			}
		}
		extension {
			... on TkaTreeStateExtension {
				treeUnlockedState {
					data {
						kind
					}
					...treeUnlockedStateDisplay_TreeUnlockedStateFragment
				}
				submissions {
					id
					startedAt
					lastUpdated
					learnOpportunity {
						id
					}
				}
				gamificationPoints {
					id
					contentId
					num
				}
			}
		}
	}
`;
