import { SelectComplexDataField } from "@components/select-complex-data-field";
import {
	SelectMultipleAccountsFieldProps,
	SelectMultipleAccountsFieldState,
} from "./select-multiple-accounts-field.types";
import { SearchableAccountTable } from "../searchable-account-table";

export const SelectMultipleAccountsField = ({ ...props }: SelectMultipleAccountsFieldProps) => {
	return (
		<SelectComplexDataField<SelectMultipleAccountsFieldState>
			valuePlaceholder="Keine Konten ausgewählt"
			actionButtonLabel="Konten auswählen"
			headerLabel="Wähle mindestens ein Konto aus"
			{...props}
		>
			<SearchableAccountTable
				selection={props.fieldValue}
				onChange={props.updateField}
				selectionMode="multiple"
			/>
		</SelectComplexDataField>
	);
};
