import { graphql } from "react-relay";

export const QUERY = graphql`
	query discountActionsScreen_Query(
		$after: String
		$before: String
		$filterByDiscountTypeKind: DiscountTypeKindInput
		$filterByActionTitle: String
		$filterByCode: String
		$first: Int
		$last: Int
	) {
		...DiscountActionsTable_QueryFragment
			@arguments(
				first: $first
				last: $last
				before: $before
				after: $after
				filterByDiscountTypeKind: $filterByDiscountTypeKind
				filterByActionTitle: $filterByActionTitle
				filterByCode: $filterByCode
			)
	}
`;
