/**
 * @generated SignedSource<<239e99c3ca72273d08b223049559024e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type discountCodeEditButton_DiscountCodeFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "discountCodeEditButton_DiscountCodeFragment";
};
export type discountCodeEditButton_DiscountCodeFragment$key = {
  readonly " $data"?: discountCodeEditButton_DiscountCodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"discountCodeEditButton_DiscountCodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "discountCodeEditButton_DiscountCodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DiscountCode",
  "abstractKey": null
};

(node as any).hash = "449cf5bb09d280458e700645dc998b25";

export default node;
