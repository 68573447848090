import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import * as Yup from "yup";
import {
	type SelectDiscountCodeFieldDialogProps,
	type SelectDiscountCodeFieldDialogState,
} from "./select-discount-code-field-dialog.interface";
import { ValidatedField } from "../../../components/ValidatedField";
import { SelectDiscountCodeField } from "../select-discount-code-field";
import { type DiscountCode } from "../select-discount-code-field/select-discount-code-field.interface";
import { Button, ButtonType, EpicIcons } from "@constellation-academy/epic-ui";

export const SelectDiscountCodeFieldDialog = ({
	updateField,
	fieldValue: selectedDiscountCode,
	onChange,
	disabled,
}: SelectDiscountCodeFieldDialogProps) => {
	const [isModalOpen, setModalOpen] = useState<boolean>(false);

	const formik = useFormik<SelectDiscountCodeFieldDialogState>({
		initialValues: {
			discountCode: selectedDiscountCode,
		},
		validationSchema: Yup.object().shape({}),
		onSubmit: (values) => {
			updateField({
				code: values.discountCode?.code,
			});
			setModalOpen(false);
			onChange?.();
		},
	});

	const handleDialogOnHide = () => {
		setModalOpen(false);
	};

	const handleSelectProductOnClick = () => {
		setModalOpen(true);
	};

	return (
		<>
			<div className="flex w-auto align-items-center">
				<Button
					type={ButtonType.Button}
					disabled={disabled}
					onClick={handleSelectProductOnClick}
					label="Discount Code auswählen"
				/>
			</div>
			<Dialog
				header="Discount Code auswählen"
				onHide={handleDialogOnHide}
				className="w-10"
				visible={isModalOpen}
			>
				<form onSubmit={formik.handleSubmit}>
					<ValidatedField<SelectDiscountCodeFieldDialogState, DiscountCode>
						className="mb-4"
						name={"discountCode"}
						label={"Wähle ein Discount-Code aus"}
						formikConfig={{
							...formik,
							errors: { ...formik.errors, discountCode: undefined },
						}}
						component={SelectDiscountCodeField}
					/>
					<Button type={ButtonType.Submit} icon={EpicIcons.CHECK} label="Auswählen" />
				</form>
			</Dialog>
		</>
	);
};
