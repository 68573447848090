/**
 * @generated SignedSource<<4a4d2063d5441749abf490baaa6cd717>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_AfterDateTime" | "VisibilityTree_Hide" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyBusinessAccount" | "VisibilityTree_OnlyIfTreeState" | "VisibilityTree_OnlyPermissionsImpl";
import { FragmentRefs } from "relay-runtime";
export type NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$data = {
  readonly configType: VisibilityTreeConfigType;
  readonly dateTime: string;
  readonly id: string;
  readonly " $fragmentType": "NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment";
};
export type NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key = {
  readonly " $data"?: NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    }
  ],
  "type": "NotAfterDateTimeVisibilityTreeConfig",
  "abstractKey": null
};

(node as any).hash = "d944e48e07cc84cd6d2cca97c4d5efb2";

export default node;
