import { Button, Dialog, ButtonType } from "@constellation-academy/epic-ui";
import { useState } from "react";
import { ButtonVariant } from "@components/button";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { error100, shade0 } from "@themes/colors";
import { buttonsWrapperClass, numberInputWrapperClass } from "./number-input-dialog.styles";
import { type NumberInputDialogProps } from "./number-input-dialog.types";

// TODO: Add translations
export const NumberInputDialog = ({
	showDialog = true,
	setShowDialog,
	title,
	content,
	cancelButtonText = "Abbrechen",
	confirmButtonText = "Ja",
	onCancel,
	onConfirm,
	initialValue = 0,
	maxValue,
}: NumberInputDialogProps) => {
	const [points, setPoints] = useState(initialValue);
	const [isValid, setIsValid] = useState(true);

	const handleOnChange = (newValue: string | undefined) => {
		if (newValue === undefined || newValue === "") {
			setPoints(0);
		} else {
			const parsedValue = Number.parseInt(newValue, 10);
			if (Number.isNaN(parsedValue)) {
				setIsValid(false);
			} else {
				if (maxValue && parsedValue > maxValue) {
					setIsValid(false);
				} else {
					setIsValid(true);
					setPoints(parsedValue);
				}
			}
		}
	};

	const handleClose = () => {
		onCancel?.();
		setShowDialog?.(false);
	};

	const handleConfirm = () => {
		onConfirm?.(points);
		setShowDialog?.(false);
	};

	return (
		<Dialog
			visible={showDialog}
			onHide={handleClose}
			title={title}
			footerNode={
				<div className={buttonsWrapperClass}>
					<Button
						onClick={handleClose}
						type={ButtonType.Button}
						label={cancelButtonText}
					/>
					<Button
						type={ButtonType.Button}
						variant={ButtonVariant.Success}
						backgroundColor={error100}
						onClick={handleConfirm}
						contentColor={shade0}
						label={confirmButtonText}
					/>
				</div>
			}
		>
			{content}
			<h3>Anzahl der Punkte</h3>
			<div className={numberInputWrapperClass}>
				<DefaultTextFieldComponent
					fieldName="points"
					fieldValue={points.toString()}
					updateField={handleOnChange}
					isValid={isValid}
					disabled={false}
					required
				/>
			</div>
		</Dialog>
	);
};
