import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { readInlineData, usePaginationFragment } from "react-relay";
import { DateTimeDisplay } from "@components/datetime-display/datetime-display.component";
import { Pagination } from "@components/Pagination";
import { type ordersTable_OrderFragment$key } from "@relay/ordersTable_OrderFragment.graphql";
import { type ordersTable_OrdersFragment$key } from "@relay/ordersTable_OrdersFragment.graphql";
import { type ordersTable_OrdersFragmentRefetch } from "@relay/ordersTable_OrdersFragmentRefetch.graphql";
import { PaymentMethodType } from "@translations/PaymentMethodType";
import { PaymentStatus } from "@translations/PaymentStatus";
import { ORDER_FRAGMENT, ORDERS_FRAGMENT } from "./orders-table.graphql";
import { type OrdersTableProps } from "./orders-table.interface";
import { EditOrderButton } from "../edit-order-button/edit-order-button.component";
import { OrderPriceDisplay } from "../order-price-display/order-price-display.component";
import { OrdersTableFilters } from "../OrdersTableFilters";
import { PaymentProviderLink } from "../payment-provider-link/payment-provider-link.component";
import { IdDisplayField } from "@components/id-display-field";

export const OrdersTable = ({
	ordersFragmentRef,
	ordersPerPage,
	enableFilter,
	canModifyOrders,
}: OrdersTableProps) => {
	const {
		data: orders,
		hasNext,
		loadNext,
		refetch,
	} = usePaginationFragment<ordersTable_OrdersFragmentRefetch, ordersTable_OrdersFragment$key>(
		ORDERS_FRAGMENT,
		ordersFragmentRef,
	);

	const ordersConnection = orders.Admin.Billing.Orders.edges?.map((edge) =>
		readInlineData<ordersTable_OrderFragment$key>(ORDER_FRAGMENT, edge?.node!),
	);

	return (
		<>
			<DataTable
				responsiveLayout="stack"
				emptyMessage={"Keine Bestellungen"}
				className="p-mb-2"
				header={
					enableFilter && (
						<OrdersTableFilters
							refetch={(username?: string, email?: string) =>
								refetch({
									username,
									email,
									first: ordersPerPage,
								})
							}
						/>
					)
				}
				value={ordersConnection}
			>
				<Column header={"ID"} body={(order) => <IdDisplayField id={order.id} />} />
				<Column
					header={"Erstellungsdatum"}
					body={(order) => <DateTimeDisplay value={order.createdAt} hideTimezone />}
				/>
				<Column
					header={"Status"}
					body={(order) => <div>{PaymentStatus[order.status]}</div>}
				/>
				<Column
					header={"Preis"}
					body={(order) => <OrderPriceDisplay orderFragmentRef={order} />}
				/>
				<Column
					header={"Zahlungsdienstleister"}
					body={(order) => <PaymentProviderLink orderFragmentRef={order} />}
				/>
				<Column
					header={"Zahlweise"}
					body={(order) => {
						if (!order.selectedPaymentMethod?.selectedPaymentMethodType)
							return <div>N/A</div>;

						return <div>{PaymentMethodType[order.selectedPaymentMethod?.kind]}</div>;
					}}
				/>
				{canModifyOrders && (
					<Column
						header="Aktionen"
						style={{ width: "20%" }}
						body={(order) => <EditOrderButton orderId={order.id} />}
					/>
				)}
			</DataTable>
			<Pagination hasNext={hasNext} loadNext={() => loadNext(ordersPerPage)} />
		</>
	);
};
