/**
 * @generated SignedSource<<5b5b76f75acb0dc19fbeb0fd2d9bedcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userRootNodeItem_RootNodesWithTreeStateTypeFragment$data = {
  readonly rootNode: {
    readonly " $fragmentSpreads": FragmentRefs<"createTreeStateButton_LearnOpportunityV2Fragment" | "reenablePaywallButton_LearnOpportunityV2Fragment" | "resetLearnProgressButton_LearnOpportunityV2Fragment" | "unlockTreeButton_LearnOpportunityV2Fragment" | "userRootNodeItem_LearnOpportunityV2Fragment">;
  } | null | undefined;
  readonly treeState: {
    readonly " $fragmentSpreads": FragmentRefs<"userRootNodeItem_TreeStateFragment">;
  } | null | undefined;
  readonly " $fragmentType": "userRootNodeItem_RootNodesWithTreeStateTypeFragment";
};
export type userRootNodeItem_RootNodesWithTreeStateTypeFragment$key = {
  readonly " $data"?: userRootNodeItem_RootNodesWithTreeStateTypeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userRootNodeItem_RootNodesWithTreeStateTypeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userRootNodeItem_RootNodesWithTreeStateTypeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "rootNode",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "userRootNodeItem_LearnOpportunityV2Fragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "reenablePaywallButton_LearnOpportunityV2Fragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "resetLearnProgressButton_LearnOpportunityV2Fragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "createTreeStateButton_LearnOpportunityV2Fragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "unlockTreeButton_LearnOpportunityV2Fragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TreeState",
      "kind": "LinkedField",
      "name": "treeState",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "userRootNodeItem_TreeStateFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RootNodesWithTreeStateType",
  "abstractKey": null
};

(node as any).hash = "6f17a1764dba0ddc9bfb7cd4b6a25f09";

export default node;
