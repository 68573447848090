import { grid } from "@styled-system/patterns";

export const wrapperClass = grid({
	gridTemplateColumns: "1",
	backgroundColor: "var(--surface-50)",
	lg: {
		gridTemplateColumns: "[500px minmax(0,1fr)]",
	},
	xl: {
		gridTemplateColumns: "[600px minmax(0,1fr)]",
	},
});
