import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const buttonsWrapperClass = flex({
	gap: "8",
	justifyContent: "flex-end",
	width: "full",
});

export const numberInputWrapperClass = css({
	width: "full",
	"& input": {
		width: "full",
	},
});
