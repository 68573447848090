/**
 * @generated SignedSource<<67849ac26ec86c55347b33a02225b369>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editMessageDispatchButton_MessageDispatchFragment$data = {
  readonly dispatchedAt: string | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "editMessageDispatchButton_MessageDispatchFragment";
};
export type editMessageDispatchButton_MessageDispatchFragment$key = {
  readonly " $data"?: editMessageDispatchButton_MessageDispatchFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editMessageDispatchButton_MessageDispatchFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editMessageDispatchButton_MessageDispatchFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dispatchedAt",
      "storageKey": null
    }
  ],
  "type": "MessageDispatch",
  "abstractKey": null
};

(node as any).hash = "b511b8ed6af4f7b218129bb725ffda6e";

export default node;
