import { graphql } from "react-relay";

export const PAGES_FRAGMENT = graphql`
	fragment messagePagesEditor_PagesFragment on MessageDefinition
	@refetchable(queryName: "messagePagesEditor_Refetch") {
		pages {
			id
			internalTitle
			data {
				kind
			}
			...messagePageFormDialog_PageFragment
		}
	}
`;
