import { Path } from "@constellation-academy/epic-ui";
import { AcademiesTagsPath } from "@screens/academies-tags/academies-tags.paths";
import { AccountsPath } from "@screens/accounts/accounts.paths";
import { DiscountActionsPath } from "@screens/discount-actions/discount-actions.paths";
import { EducationalOfferPath } from "@screens/educational-offer/educational-offer.paths";
import { EmailTemplatesPath } from "@screens/email-templates/email-templates.paths";
import { ExternalLicensesPath } from "@screens/external-license-edit/external-license-edit.paths";
import { ExternalLicensePoolsPath } from "@screens/external-license-pools/external-license-pools.paths";
import { FilesPath } from "@screens/files/files.paths";
import { GenerateCartLinkPath } from "@screens/generate-cart-link/generate-cart-link.paths";
import { InstructorsPath } from "@screens/instructors/instructors.paths";
import { LevelDefinitionPath } from "@screens/level-definition/level-definition.paths";
import { LicenseDefinitionsPath } from "@screens/license-definitions/license-definitions.paths";
import { LicensesPath } from "@screens/licenses/licenses.paths";
import { LimitedCartDiscountsPath } from "@screens/limited-cart-discounts/limited-cart-discounts.paths";
import { MessageDefinitionsPath } from "@screens/message-definitions/message-definitions.paths";
import { MessageDispatchesPath } from "@screens/message-dispatches/message-dispatches.paths";
import { OffersPath } from "@screens/offers/offers.paths";
import { OrdersPath } from "@screens/orders/orders.paths";
import { ProductsPath } from "@screens/products/products.paths";
import { ReportsPath } from "@screens/reports/reports.paths";
import { SettingsPath } from "@screens/settings/settings.paths";
import { StagingPath } from "@screens/staging/staging.paths";
import { TasksPath } from "@screens/tasks/tasks.paths";
import { UsersPath } from "@screens/users/users.paths";
import { VideoSourcesPaths } from "@screens/video-sources/video-sources.paths";

/**
 * import files directly and not over the index file to avoid circular dependencies
 */

class PathIndex extends Path<typeof PathIndex> {
	static readonly childPaths = [
		OffersPath,
		EducationalOfferPath,
		FilesPath,
		UsersPath,
		TasksPath,
		StagingPath,
		SettingsPath,
		LicensesPath,
		InstructorsPath,
		LevelDefinitionPath,
		EmailTemplatesPath,
		AccountsPath,
		AcademiesTagsPath,
		OrdersPath,
		ProductsPath,
		LimitedCartDiscountsPath,
		GenerateCartLinkPath,
		DiscountActionsPath,
		LicenseDefinitionsPath,
		ExternalLicensePoolsPath,
		ExternalLicensesPath,
		ReportsPath,
		MessageDispatchesPath,
		MessageDefinitionsPath,
		VideoSourcesPaths,
	];
}

export const Paths = new PathIndex();
