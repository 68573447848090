import { graphql } from "react-relay";

export const PERSOLOG_QUESTION_FRAGMENT = graphql`
	fragment persologElementQuestionClassForm_PersologQuestionFragment on Persolog_Question {
		title
	}
`;

export const PERSOLOG_QUESTION_CLASS_FRAGMENT = graphql`
	fragment persologElementQuestionClassForm_PersologQuestionClassFragment on Persolog_QuestionClass {
		text
		classType
	}
`;
