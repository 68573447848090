/**
 * @generated SignedSource<<593b9f6abd450f43a7fdfd019d22f005>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_CanNot";
import { FragmentRefs } from "relay-runtime";
export type BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment$data = {
  readonly configType: RestartIfFailedContentConfigType;
  readonly id: string;
  readonly maximumTries: number;
  readonly " $fragmentType": "BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment";
};
export type BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment$key = {
  readonly " $data"?: BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maximumTries",
      "storageKey": null
    }
  ],
  "type": "BlockPermanentlyRestartIfFailedContentConfig",
  "abstractKey": null
};

(node as any).hash = "58100d722b731904ffa915e98aa2eaef";

export default node;
