/**
 * @generated SignedSource<<8a34403718fe033f40f2b84b6196e492>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditEnhancedTextElementInput = {
  clientMutationId?: string | null | undefined;
  enhancedTextElementId: string;
  readMore?: string | null | undefined;
  readMoreButtonText?: string | null | undefined;
  text: string;
  title: string;
};
export type enhancedTextElementForm_EditEnhancedTextElementMutation$variables = {
  input: EditEnhancedTextElementInput;
};
export type enhancedTextElementForm_EditEnhancedTextElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editEnhancedTextElement: {
        readonly enhancedTextElement: {
          readonly " $fragmentSpreads": FragmentRefs<"enhancedTextElementForm_EnhancedTextElementFragment">;
        };
      } | null | undefined;
    };
  };
};
export type enhancedTextElementForm_EditEnhancedTextElementMutation = {
  response: enhancedTextElementForm_EditEnhancedTextElementMutation$data;
  variables: enhancedTextElementForm_EditEnhancedTextElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "enhancedTextElementForm_EditEnhancedTextElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditEnhancedTextElementPayload",
                "kind": "LinkedField",
                "name": "editEnhancedTextElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EnhancedTextElement",
                    "kind": "LinkedField",
                    "name": "enhancedTextElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "enhancedTextElementForm_EnhancedTextElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "enhancedTextElementForm_EditEnhancedTextElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditEnhancedTextElementPayload",
                "kind": "LinkedField",
                "name": "editEnhancedTextElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EnhancedTextElement",
                    "kind": "LinkedField",
                    "name": "enhancedTextElement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "readMore",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "readMoreButtonText",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "034ed6d15b3fdfeda28ace410f13076b",
    "id": null,
    "metadata": {},
    "name": "enhancedTextElementForm_EditEnhancedTextElementMutation",
    "operationKind": "mutation",
    "text": "mutation enhancedTextElementForm_EditEnhancedTextElementMutation(\n  $input: EditEnhancedTextElementInput!\n) {\n  Admin {\n    ElearningV2 {\n      editEnhancedTextElement(input: $input) {\n        enhancedTextElement {\n          ...enhancedTextElementForm_EnhancedTextElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment enhancedTextElementForm_EnhancedTextElementFragment on EnhancedTextElement {\n  id\n  title\n  text\n  readMore\n  readMoreButtonText\n}\n"
  }
};
})();

(node as any).hash = "b7929be918b17251ba631f7c9424ba95";

export default node;
