/**
 * @generated SignedSource<<64ca346f80ed406672f27d00b17c0b79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licenseDefinitionsTable_Query$variables = {
  first?: number | null | undefined;
};
export type licenseDefinitionsTable_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"licenseDefinitionsTable_LicenseDefinitionsFragment">;
};
export type licenseDefinitionsTable_Query = {
  response: licenseDefinitionsTable_Query$data;
  variables: licenseDefinitionsTable_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "dataKinds",
    "value": [
      "Flex"
    ]
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rootId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "licenseDefinitionsTable_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "licenseDefinitionsTable_LicenseDefinitionsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "licenseDefinitionsTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "LicenseDefinitionsConnection",
                "kind": "LinkedField",
                "name": "SearchLicenseDefinitions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LicenseDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicenseDefinitionRootInfo",
                                    "kind": "LinkedField",
                                    "name": "includedNodeIds",
                                    "plural": true,
                                    "selections": (v3/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicenseDefinitionRootInfo",
                                    "kind": "LinkedField",
                                    "name": "excludedNodeIds",
                                    "plural": true,
                                    "selections": (v3/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "type": "FlexLicenseDefinitionData",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "nameMatchRegex",
                  "dataKinds"
                ],
                "handle": "connection",
                "key": "LicenseDefinitionsTable_SearchLicenseDefinitions",
                "kind": "LinkedHandle",
                "name": "SearchLicenseDefinitions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "15b3d75dd6e5767b72dcf5e4fc33b7d2",
    "id": null,
    "metadata": {},
    "name": "licenseDefinitionsTable_Query",
    "operationKind": "query",
    "text": "query licenseDefinitionsTable_Query(\n  $first: Int\n) {\n  ...licenseDefinitionsTable_LicenseDefinitionsFragment_bPxaz\n}\n\nfragment editFlexLicenseDefinitionButton_LicenseDefinitionFragment on LicenseDefinition {\n  id\n  data {\n    __typename\n    ... on FlexLicenseDefinitionData {\n      name\n      includedNodeIds {\n        rootId\n        title\n      }\n      excludedNodeIds {\n        rootId\n        title\n      }\n    }\n  }\n}\n\nfragment licenseDefinitionsTable_LicenseDefinitionsFragment_bPxaz on Query {\n  Admin {\n    LicenseDefinition {\n      SearchLicenseDefinitions(first: $first, dataKinds: [Flex]) {\n        edges {\n          node {\n            id\n            data {\n              __typename\n              name\n              kind\n            }\n            ...editFlexLicenseDefinitionButton_LicenseDefinitionFragment\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e2ebbfa0aae61075a26cf36d84f6388";

export default node;
