import { graphql } from "react-relay";

export const CREATE_FLEX_LICENSE_DEFINITION_MUTATION = graphql`
	mutation createFlexLicenseDefinitionButton_CreateFlexLicenseDefinitionMutation(
		$input: CreateFlexLicenseDefinitionInput!
		$connections: [ID!]!
	) {
		Admin {
			LicenseDefinition {
				createFlexLicenseDefinition(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							data {
								name
								kind
							}
							...editFlexLicenseDefinitionButton_LicenseDefinitionFragment
						}
					}
				}
			}
		}
	}
`;
