import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment selectExternalLicensePoolField_QueryFragment on Query
	@refetchable(queryName: "selectExternalLicensePoolField_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		nameMatchRegex: { type: "String" }
	) {
		Admin {
			Billing {
				GetExternalLicensePools(first: $first, after: $after, nameOpt: $nameMatchRegex)
					@connection(key: "selectExternalLicenseField_GetExternalLicensePools") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							...selectExternalLicensePoolField_ExternalLicensePoolFragment
						}
					}
				}
			}
		}
	}
`;

export const EXTERNAL_LICENSE_POOL_FRAGMENT = graphql`
	fragment selectExternalLicensePoolField_ExternalLicensePoolFragment on ExternalLicensePool
	@inline {
		id
		data {
			name
		}
	}
`;
