import { graphql } from "react-relay";

export const DELETE_EXTERNAL_LICENSE_POOL_MUTATION = graphql`
	mutation deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation(
		$input: DeleteExternalLicensePoolInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				deleteExternalLicensePool(input: $input) {
					clientMutationId
					id @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
