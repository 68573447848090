/**
 * @generated SignedSource<<47b6a0e23eafa576fb47f62d62440e73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment";
};
export type editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment$key = {
  readonly " $data"?: editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "AnswerMatrixElementCell",
  "abstractKey": null
};

(node as any).hash = "2268b630e7ec51993f0a60e73aea860f";

export default node;
