/**
 * @generated SignedSource<<4ac20934b24cdc094f289765365baadf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type limitedCartDiscountEditScreen_LimitedCartDiscountQuery$variables = {
  id: string;
  skip: boolean;
};
export type limitedCartDiscountEditScreen_LimitedCartDiscountQuery$data = {
  readonly node?: {
    readonly title?: string;
    readonly " $fragmentSpreads": FragmentRefs<"limitedCartDiscountForm_LimitedCartDiscountFragment">;
  } | null | undefined;
};
export type limitedCartDiscountEditScreen_LimitedCartDiscountQuery = {
  response: limitedCartDiscountEditScreen_LimitedCartDiscountQuery$data;
  variables: limitedCartDiscountEditScreen_LimitedCartDiscountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "limitedCartDiscountEditScreen_LimitedCartDiscountQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "limitedCartDiscountForm_LimitedCartDiscountFragment"
                  }
                ],
                "type": "LimitedCartDiscount",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "limitedCartDiscountEditScreen_LimitedCartDiscountQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "conditions",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "productId",
                            "storageKey": null
                          }
                        ],
                        "type": "ProductSelectionCondition",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "productSelectionConfig",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "type": "PercentageProductSelectionConfig",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LimitedCartDiscount",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "f6b72428923592a5b8126add5819c93e",
    "id": null,
    "metadata": {},
    "name": "limitedCartDiscountEditScreen_LimitedCartDiscountQuery",
    "operationKind": "query",
    "text": "query limitedCartDiscountEditScreen_LimitedCartDiscountQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on LimitedCartDiscount {\n      title\n      ...limitedCartDiscountForm_LimitedCartDiscountFragment\n    }\n    id\n  }\n}\n\nfragment limitedCartDiscountForm_LimitedCartDiscountFragment on LimitedCartDiscount {\n  id\n  title\n  conditions {\n    __typename\n    kind\n    ... on ProductSelectionCondition {\n      product {\n        title\n        id\n      }\n      id\n      amount\n      productId\n      ...limitedCartDiscountForm_ProductSelectionConditionFragment\n    }\n    id\n  }\n  productSelectionConfig {\n    __typename\n    ... on PercentageProductSelectionConfig {\n      value\n    }\n  }\n}\n\nfragment limitedCartDiscountForm_ProductSelectionConditionFragment on ProductSelectionCondition {\n  id\n  amount\n  productId\n  product {\n    title\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "90db13f88aec5293329a15419e03cbd8";

export default node;
