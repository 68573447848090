/**
 * @generated SignedSource<<e105fbb42cd8c9aa565041a9990b4081>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CreateUploadAsyncElementInput = {
  asyncContentId: string;
  clientMutationId?: string | null | undefined;
  title: string;
};
export type addNodeButton_CreateUploadAsyncElementMutation$variables = {
  input: CreateUploadAsyncElementInput;
};
export type addNodeButton_CreateUploadAsyncElementMutation$data = {
  readonly Admin: {
    readonly AsyncContent: {
      readonly createUploadAsyncElement: {
        readonly asyncContent: {
          readonly id: string;
        };
      } | null | undefined;
    };
  };
};
export type addNodeButton_CreateUploadAsyncElementMutation = {
  response: addNodeButton_CreateUploadAsyncElementMutation$data;
  variables: addNodeButton_CreateUploadAsyncElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AsyncContentAdminMutationSchema",
        "kind": "LinkedField",
        "name": "AsyncContent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "CreateUploadAsyncElementPayload",
            "kind": "LinkedField",
            "name": "createUploadAsyncElement",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TreeNode",
                "kind": "LinkedField",
                "name": "asyncContent",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addNodeButton_CreateUploadAsyncElementMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addNodeButton_CreateUploadAsyncElementMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f1e532e532ab2daad1f8d580b42e504d",
    "id": null,
    "metadata": {},
    "name": "addNodeButton_CreateUploadAsyncElementMutation",
    "operationKind": "mutation",
    "text": "mutation addNodeButton_CreateUploadAsyncElementMutation(\n  $input: CreateUploadAsyncElementInput!\n) {\n  Admin {\n    AsyncContent {\n      createUploadAsyncElement(input: $input) {\n        asyncContent {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a455c12439a4ff4500493db2c91ed3bd";

export default node;
