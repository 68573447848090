import { Button, EpicIcons } from "@constellation-academy/epic-ui";
import { useMutation } from "react-relay";

import { createTextElementButton_CreateTextElementMutation } from "@relay/createTextElementButton_CreateTextElementMutation.graphql";
import { CREATE_TEXT_ELEMENT_MUTATION } from "@screens/educational-offer/parts/create-text-element-button/create-text-element-button.graphql";
import { type CreateTextElementButtonProps } from "./create-text-element-button.types";

export const CreateTextElementButton = ({
	eLearningContentNodeId,
}: CreateTextElementButtonProps) => {
	const [createTextElement, isCreatingTextElement] =
		useMutation<createTextElementButton_CreateTextElementMutation>(
			CREATE_TEXT_ELEMENT_MUTATION,
		);

	return (
		<Button
			disabled={isCreatingTextElement}
			tooltip={`Text-Baustein anlegen`}
			icon={EpicIcons.LIST}
			onClick={() => {
				createTextElement({
					variables: {
						input: {
							title: "Neues Text-Element",
							text: "Neuer Text",
							eLearningContentNodeId: eLearningContentNodeId,
						},
					},
				});
			}}
		/>
	);
};
