import { graphql } from "react-relay";

export const CREATE_LIMITED_CART_DISCOUNT_MUTATION = graphql`
	mutation createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation(
		$input: CreateLimitedCartDiscountInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createLimitedCartDiscount(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...limitedCartDiscountTable_LimitedCartDiscountFragment
						}
					}
				}
			}
		}
	}
`;
