/**
 * @generated SignedSource<<bcdbc7856ae7ff6a72a199ce343d8da5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type externalLicensePoolsTable_ExternalLicensePoolFragment$data = {
  readonly canBeDeleted: boolean;
  readonly data: {
    readonly link: string | null | undefined;
    readonly name: string;
    readonly usageInformation: string;
    readonly validNumDaysAfterIssuing: number;
  };
  readonly id: string;
  readonly " $fragmentType": "externalLicensePoolsTable_ExternalLicensePoolFragment";
};
export type externalLicensePoolsTable_ExternalLicensePoolFragment$key = {
  readonly " $data"?: externalLicensePoolsTable_ExternalLicensePoolFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"externalLicensePoolsTable_ExternalLicensePoolFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "externalLicensePoolsTable_ExternalLicensePoolFragment"
};

(node as any).hash = "0e980dfc7d2561cfcaea0dae694c0aae";

export default node;
