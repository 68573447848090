import { EpicIcons, Button } from "@constellation-academy/epic-ui";
import { Toast } from "primereact/toast";
import React, { useContext, useRef } from "react";
import { useMutation } from "react-relay";
import { ButtonVariant } from "@components/button";
import { useDialogLogic } from "@hooks/use-dialog-logic";

import { deleteMessageDefinitionButton_DeleteMessageDefinitionMutation } from "@relay/deleteMessageDefinitionButton_DeleteMessageDefinitionMutation.graphql";
import { MessageDefinitionsContext } from "@screens/message-definitions/message-definitions.context";
import { DELETE_MESSAGE_DEFINITION_MUTATION } from "@screens/message-definitions/parts/message-definitions-table/delete-message-definition-button/delete-message-definition-button.graphql";
import { DeleteMessageDefinitionButtonProps } from "@screens/message-definitions/parts/message-definitions-table/delete-message-definition-button/delete-message-definition-button.types";

export const DeleteMessageDefinitionButton = ({
	messageDefinitionId,
}: DeleteMessageDefinitionButtonProps) => {
	const { connectionId } = useContext(MessageDefinitionsContext);
	const toast = useRef<Toast>(null);

	const { showDialog, dialogComponent } = useDialogLogic();
	const [deleteMessageDefinition, isDeletingMessageDefinition] =
		useMutation<deleteMessageDefinitionButton_DeleteMessageDefinitionMutation>(
			DELETE_MESSAGE_DEFINITION_MUTATION,
		);
	const handleOnDeleteClick = () => {
		showDialog({
			title: "E-Mail Vorlage löschen",
			content:
				"Möchten Sie diese Nachrichten-Vorlage wirklich löschen? Das kann nicht rückgängig gemacht werden.",
			dialogCallback: (result) => {
				if (result === "Accept") {
					deleteMessageDefinition({
						variables: {
							input: {
								messageDefinitionId: messageDefinitionId,
							},
							connections: [connectionId],
						},
						onCompleted: () => {
							toast.current?.show({
								severity: "success",
								life: 3000,
								summary: "Nachrichten-Vorlage gelöscht",
								detail: "Nachrichten-Vorlage wurde gelöscht",
							});
						},
						onError: () => {
							toast.current?.show({
								severity: "error",
								life: 3000,
								summary: "Nachrichten-Vorlage nicht gelöscht",
								detail: "Nachrichten-Vorlage konnte nicht gelöscht werden",
							});
						},
					});
				}
			},
		});
	};

	return (
		<>
			<Toast ref={toast}></Toast>
			{dialogComponent}
			<Button
				disabled={isDeletingMessageDefinition}
				icon={EpicIcons.TRASH}
				variant={ButtonVariant.Error}
				onClick={handleOnDeleteClick}
			/>
		</>
	);
};
