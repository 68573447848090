/**
 * @generated SignedSource<<e599e149776475e30298bc76a51a32c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SendMessageInput = {
  clientMutationId?: string | null | undefined;
  messageDispatchId: string;
  sentAtOpt?: string | null | undefined;
};
export type messageDispatchForm_SendMessageDispatchMutation$variables = {
  input: SendMessageInput;
};
export type messageDispatchForm_SendMessageDispatchMutation$data = {
  readonly Admin: {
    readonly Message: {
      readonly sendMessage: {
        readonly clientMutationId: string | null | undefined;
      } | null | undefined;
    };
  };
};
export type messageDispatchForm_SendMessageDispatchMutation = {
  response: messageDispatchForm_SendMessageDispatchMutation$data;
  variables: messageDispatchForm_SendMessageDispatchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Message",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "SendMessagePayload",
            "kind": "LinkedField",
            "name": "sendMessage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "messageDispatchForm_SendMessageDispatchMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "messageDispatchForm_SendMessageDispatchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ab14df308eed63d2b232c8088ebf5ac",
    "id": null,
    "metadata": {},
    "name": "messageDispatchForm_SendMessageDispatchMutation",
    "operationKind": "mutation",
    "text": "mutation messageDispatchForm_SendMessageDispatchMutation(\n  $input: SendMessageInput!\n) {\n  Admin {\n    Message {\n      sendMessage(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "78cdc4db3f887738f4daed07708a25c5";

export default node;
