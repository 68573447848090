import { EpicIcons } from "@constellation-academy/epic-ui";
import * as Sentry from "@sentry/react";
import { memo, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { Button, ButtonVariant } from "@components/button";
import { ConfirmDialog } from "@components/confirm-dialog";
import type { reenablePaywallButton_LearnOpportunityV2Fragment$key } from "@relay/reenablePaywallButton_LearnOpportunityV2Fragment.graphql";
import type { reenablePaywallButton_ReenablePaywallMutation } from "@relay/reenablePaywallButton_ReenablePaywallMutation.graphql";
import {
	LEARN_OPPORTUNITY_V2_FRAGMENT,
	REENABLE_PAYWALL_MUTATION,
} from "./reenable-paywall-button.graphql";
import type { ReenablePaywallButtonProps } from "./reenable-paywall-button.types";

const ReenablePaywallButtonComponent = ({
	learnOpportunityV2FragmentRef,
	userId,
	onCompleted,
	onError,
}: ReenablePaywallButtonProps) => {
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);

	const rootNode = useFragment<reenablePaywallButton_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef ?? null,
	);

	const [reenablePaywall, isReenablingPaywall] =
		useMutation<reenablePaywallButton_ReenablePaywallMutation>(REENABLE_PAYWALL_MUTATION);

	const handleReenablePaywallOnClick = () => {
		setShowConfirmDialog(true);
	};

	const handleReenablePaywallOnCancel = () => {
		setShowConfirmDialog(false);
	};

	const handleReenablePaywallOnConfirm = () => {
		if (!rootNode) {
			toast.error("Fehler beim Wiederherstellen der Paywall! RootNode nicht gefunden.");
			Sentry.captureException(
				new Error("reenablePaywallButton_ReenablePaywallMutation: RootNode not found"),
				{
					extra: {
						userId,
						rootNode,
					},
				},
			);
			return;
		}

		reenablePaywall({
			variables: {
				input: { rootId: rootNode.id, userId },
			},
			onCompleted: () => {
				toast.success("Paywall erfolgreich wiederhergestellt!");
				onCompleted?.();
			},
			onError: () => {
				toast.error("Fehler beim Wiederherstellen der Paywall!");
				onError?.();
			},
		});
	};

	return (
		<>
			<Button
				label="Paywall einsetzen"
				icon={EpicIcons.BAN}
				variant={ButtonVariant.Warning}
				onClick={handleReenablePaywallOnClick}
				disabled={isReenablingPaywall}
				loading={isReenablingPaywall}
			/>
			<ConfirmDialog
				title="Paywall wiederherstellen"
				content={
					<div>
						Möchtest du die Paywall für den Kurs "
						{rootNode?.structureDefinition.title ?? "Unbekannter Kurs"}" wirklich
						wiederherstellen?
					</div>
				}
				confirmButtonText="Ja, Paywall wiederherstellen"
				onConfirm={handleReenablePaywallOnConfirm}
				onCancel={handleReenablePaywallOnCancel}
				showDialog={showConfirmDialog}
			/>
		</>
	);
};

export const ReenablePaywallButton = memo(ReenablePaywallButtonComponent);
