import { graphql } from "react-relay";

export const QUERY = graphql`
	query discountActionEditScreen_Query($id: ID!) {
		node(id: $id) {
			... on DiscountAction {
				title
				...discountActionForm_DiscountActionFragment
				...discountCodeCodesTableHeader_DiscountActionFragment
			}
		}
		...discountCodeTable_QueryFragment @arguments(id: $id)
	}
`;
