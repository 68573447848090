/**
 * @generated SignedSource<<a23d14cf65d35b3bd233acdc0f300f9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Billing_CompanyType = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "PartmbH" | "Stiftung" | "Ug" | "Unknown";
export type CountryCode = "DE";
import { FragmentRefs } from "relay-runtime";
export type businessBaseDataForm_BusinessBaseDataFragment$data = {
  readonly __typename: "BusinessBaseData";
  readonly accountData: {
    readonly bic: string | null | undefined;
    readonly iban: string;
  } | null | undefined;
  readonly billingOffice: string | null | undefined;
  readonly city: string | null | undefined;
  readonly companyLegalForm: Billing_CompanyType | null | undefined;
  readonly companyName: string | null | undefined;
  readonly countryCode: CountryCode | null | undefined;
  readonly houseNumber: string | null | undefined;
  readonly invoiceEmail: string | null | undefined;
  readonly phoneNumber: string | null | undefined;
  readonly postalCode: string | null | undefined;
  readonly street: string | null | undefined;
  readonly taxData: {
    readonly taxIdentificationNumber: string;
    readonly taxNumber: string;
  } | null | undefined;
  readonly " $fragmentType": "businessBaseDataForm_BusinessBaseDataFragment";
};
export type businessBaseDataForm_BusinessBaseDataFragment$key = {
  readonly " $data"?: businessBaseDataForm_BusinessBaseDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"businessBaseDataForm_BusinessBaseDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "businessBaseDataForm_BusinessBaseDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingOffice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyLegalForm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "houseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countryCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountData",
      "kind": "LinkedField",
      "name": "accountData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bic",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "iban",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxData",
      "kind": "LinkedField",
      "name": "taxData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxIdentificationNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BusinessBaseData",
  "abstractKey": null
};

(node as any).hash = "e14484fec51a933d77cdc5a5e4346770";

export default node;
