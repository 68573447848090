import { graphql } from "react-relay";

export const CREATE_PERSOLOG_ELEMENT_MUTATION = graphql`
	mutation createPersologElementButton_CreatePersologElementMutation(
		$input: CreatePersologElementInput!
	) {
		Admin {
			ElearningV2 {
				createPersologElement(input: $input) {
					eLearningContent {
						id
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;
