/**
 * @generated SignedSource<<f17da5ebe3b2205e1693f2b181a74c12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messageDispatchesTable_MessageDispatchFragment$data = {
  readonly createdAt: string;
  readonly createdBy: {
    readonly name: string;
  };
  readonly dispatchedAt: string | null | undefined;
  readonly id: string;
  readonly messageDefinition: {
    readonly internalTitle: string;
  };
  readonly sendAt: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"deleteMessageDispatchButton_MessageDispatchFragment" | "editMessageDispatchButton_MessageDispatchFragment">;
  readonly " $fragmentType": "messageDispatchesTable_MessageDispatchFragment";
};
export type messageDispatchesTable_MessageDispatchFragment$key = {
  readonly " $data"?: messageDispatchesTable_MessageDispatchFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messageDispatchesTable_MessageDispatchFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "messageDispatchesTable_MessageDispatchFragment"
};

(node as any).hash = "3c54d66cb8c77162f18fae12d8ab4923";

export default node;
