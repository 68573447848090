import { RelayPageDataTable } from "@constellation-academy/epic-ui";
import React, { useContext } from "react";
import { CopyTableIdButton } from "@components/copy-table-id-button";
import { DateTimeDisplay } from "@components/datetime-display/datetime-display.component";
import { type reportsTable_DSAReportInlineFragment$key } from "@relay/reportsTable_DSAReportInlineFragment.graphql";
import { type reportsTable_QueryFragment$key } from "@relay/reportsTable_QueryFragment.graphql";
import { type reportsTable_QueryFragmentRefetch } from "@relay/reportsTable_QueryFragmentRefetch.graphql";
import {
	DSA_REPORT_INLINE_FRAGMENT,
	QUERY_FRAGMENT,
} from "@screens/reports/parts/reports-table/reports-table.graphql";
import { type ReportsTableProps } from "@screens/reports/parts/reports-table/reports-table.types";
import { ViewReportButton } from "@screens/reports/parts/view-report-button";
import { ReportsContext } from "@screens/reports/reports.context";

export const ReportsTable = ({ reportsFragmentRef, canRead }: ReportsTableProps) => {
	const { filters } = useContext(ReportsContext);

	const handleOnConnectionIdChange = () => {};
	return (
		<>
			<RelayPageDataTable<
				reportsTable_QueryFragmentRefetch,
				reportsTable_QueryFragment$key,
				reportsTable_DSAReportInlineFragment$key
			>
				queryFragment={QUERY_FRAGMENT}
				parentFragmentRef={reportsFragmentRef}
				nodeInlineFragment={DSA_REPORT_INLINE_FRAGMENT}
				extractConnection={(d) => d.Admin.Reports.Reports}
				rowsCount={20}
				filters={filters}
				onConnectionIdChange={handleOnConnectionIdChange}
				emptyMessage={"Keine Reports"}
			>
				{(Column) => (
					<>
						<Column
							header={"ID"}
							body={(report) => (
								<CopyTableIdButton id={report.id}>
									<b>ID</b>
								</CopyTableIdButton>
							)}
						/>
						<Column
							header={"Referenznummer"}
							body={(report) => (
								<CopyTableIdButton id={report.referenceNumber}>
									{report.referenceNumber}
								</CopyTableIdButton>
							)}
						/>
						<Column
							header={"Erstellungsdatum"}
							body={(report) => (
								<DateTimeDisplay value={report.createdAt} hideTimezone />
							)}
						/>

						{canRead && (
							<Column
								header="Aktionen"
								style={{ width: "20%" }}
								body={(report) => <ViewReportButton reportId={report.id} />}
							/>
						)}
					</>
				)}
			</RelayPageDataTable>
		</>
	);
};
