/**
 * @generated SignedSource<<b7293d231b2a034b0684470417950e04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateLicenseProductInput = {
  clientMutationId?: string | null | undefined;
  title: string;
};
export type createLicenseProductButton_CreateLicenseProductMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateLicenseProductInput;
};
export type createLicenseProductButton_CreateLicenseProductMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly createLicenseProduct: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"productsTable_ProductFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type createLicenseProductButton_CreateLicenseProductMutation = {
  response: createLicenseProductButton_CreateLicenseProductMutation$data;
  variables: createLicenseProductButton_CreateLicenseProductMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createLicenseProductButton_CreateLicenseProductMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateLicenseProductPayload",
                "kind": "LinkedField",
                "name": "createLicenseProduct",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "productsTable_ProductFragment",
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProductIdColumn_ProductFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProductTitleColumn_ProductFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProductIsHiddenColumn_ProductFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "productPriceColumn_ProductFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProductActionColumn_ProductFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createLicenseProductButton_CreateLicenseProductMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateLicenseProductPayload",
                "kind": "LinkedField",
                "name": "createLicenseProduct",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isHidden",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "netPrice",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b264f8269656e5a90d6306e8ae8b3f3a",
    "id": null,
    "metadata": {},
    "name": "createLicenseProductButton_CreateLicenseProductMutation",
    "operationKind": "mutation",
    "text": "mutation createLicenseProductButton_CreateLicenseProductMutation(\n  $input: CreateLicenseProductInput!\n) {\n  Admin {\n    Billing {\n      createLicenseProduct(input: $input) {\n        edge {\n          node {\n            ...productsTable_ProductFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ProductActionColumn_ProductFragment on Product {\n  id\n}\n\nfragment ProductIdColumn_ProductFragment on Product {\n  id\n}\n\nfragment ProductIsHiddenColumn_ProductFragment on Product {\n  isHidden\n}\n\nfragment ProductTitleColumn_ProductFragment on Product {\n  title\n}\n\nfragment productPriceColumn_ProductFragment on Product {\n  netPrice\n}\n\nfragment productsTable_ProductFragment on Product {\n  ...ProductIdColumn_ProductFragment\n  ...ProductTitleColumn_ProductFragment\n  ...ProductIsHiddenColumn_ProductFragment\n  ...productPriceColumn_ProductFragment\n  ...ProductActionColumn_ProductFragment\n}\n"
  }
};
})();

(node as any).hash = "828c4c9f842ea72e0515dcba45d6f6c6";

export default node;
