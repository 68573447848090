/**
 * @generated SignedSource<<e9306809d0dcf596b702a51eaa909403>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetVimeoVideoSourceAsDefaultInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation$variables = {
  input: SetVimeoVideoSourceAsDefaultInput;
};
export type setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation$data = {
  readonly Admin: {
    readonly VimeoVideoSource: {
      readonly setVimeoVideoSourceAsDefault: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"videoSourcesTable_Vimeo_VimeoVideoSourceFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation = {
  response: setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation$data;
  variables: setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDefault",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessToken",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VimeoVideoSourceAdminMutationSchema",
            "kind": "LinkedField",
            "name": "VimeoVideoSource",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetVimeoVideoSourceAsDefaultPayload",
                "kind": "LinkedField",
                "name": "setVimeoVideoSourceAsDefault",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Vimeo_VimeoVideoSourceEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Vimeo_VimeoVideoSource",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "videoSourcesTable_Vimeo_VimeoVideoSourceFragment",
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VimeoVideoSourceAdminMutationSchema",
            "kind": "LinkedField",
            "name": "VimeoVideoSource",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetVimeoVideoSourceAsDefaultPayload",
                "kind": "LinkedField",
                "name": "setVimeoVideoSourceAsDefault",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Vimeo_VimeoVideoSourceEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Vimeo_VimeoVideoSource",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3206ee114502ee3cf9c97e11d71a998",
    "id": null,
    "metadata": {},
    "name": "setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation",
    "operationKind": "mutation",
    "text": "mutation setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation(\n  $input: SetVimeoVideoSourceAsDefaultInput!\n) {\n  Admin {\n    VimeoVideoSource {\n      setVimeoVideoSourceAsDefault(input: $input) {\n        edge {\n          node {\n            ...videoSourcesTable_Vimeo_VimeoVideoSourceFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  isDefault\n}\n\nfragment editVideoSourceButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  ...videoSourceForm_Vimeo_VimeoVideoSourceFragment\n}\n\nfragment setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  isDefault\n}\n\nfragment videoSourceForm_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  name\n}\n\nfragment videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  ...deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment\n  ...setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment\n  ...editVideoSourceButton_Vimeo_VimeoVideoSourceFragment\n}\n\nfragment videoSourcesTable_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  name\n  isDefault\n  accessToken\n  ...videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment\n}\n"
  }
};
})();

(node as any).hash = "96987c003ebb5f4f84c8cfec5b5a6b6d";

export default node;
