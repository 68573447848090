/**
 * @generated SignedSource<<c99e6e36614aefa7f740e80db83fe785>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type searchableAccountTable_AccountFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "searchableAccountTable_AccountFragment";
};
export type searchableAccountTable_AccountFragment$key = {
  readonly " $data"?: searchableAccountTable_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"searchableAccountTable_AccountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "searchableAccountTable_AccountFragment"
};

(node as any).hash = "34258c0ebe7272c35256b08530111a3e";

export default node;
