/**
 * @generated SignedSource<<9c6fcab3493e18a35d397dcac7b0a4e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messagePagesEditor_Refetch$variables = {
  id: string;
};
export type messagePagesEditor_Refetch$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"messagePagesEditor_PagesFragment">;
  } | null | undefined;
};
export type messagePagesEditor_Refetch = {
  response: messagePagesEditor_Refetch$data;
  variables: messagePagesEditor_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ImageElement",
  "kind": "LinkedField",
  "name": "element",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fileType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "messagePagesEditor_Refetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "messagePagesEditor_PagesFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "messagePagesEditor_Refetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageDefinitionPage",
                "kind": "LinkedField",
                "name": "pages",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "internalTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "type": "ImagePage",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "button",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": (v7/*: any*/),
                                "type": "ConfirmButton",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v7/*: any*/),
                                "type": "TextCopyButton",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                  }
                                ],
                                "type": "LinkButton",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "type": "ImageWithButtonPage",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MessageDefinition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e157b33360b297d0f9383b6cfd937ce3",
    "id": null,
    "metadata": {},
    "name": "messagePagesEditor_Refetch",
    "operationKind": "query",
    "text": "query messagePagesEditor_Refetch(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...messagePagesEditor_PagesFragment\n    id\n  }\n}\n\nfragment messagePageFormDialog_PageFragment on MessageDefinitionPage {\n  internalTitle\n  ...messagePageForm_PageFragment\n}\n\nfragment messagePageForm_PageFragment on MessageDefinitionPage {\n  id\n  internalTitle\n  data {\n    __typename\n    kind\n    ... on ImagePage {\n      element {\n        image {\n          name\n          id\n          fileType\n        }\n      }\n    }\n    ... on ImageWithButtonPage {\n      ...pageButtonForm_ButtonFragment\n      button {\n        __typename\n        kind\n        ... on ConfirmButton {\n          text\n        }\n        ... on TextCopyButton {\n          text\n        }\n        ... on LinkButton {\n          text\n          url\n        }\n      }\n      element {\n        image {\n          name\n          id\n          fileType\n        }\n      }\n    }\n  }\n}\n\nfragment messagePagesEditor_PagesFragment on MessageDefinition {\n  pages {\n    id\n    internalTitle\n    data {\n      __typename\n      kind\n    }\n    ...messagePageFormDialog_PageFragment\n  }\n  id\n}\n\nfragment pageButtonForm_ButtonFragment on ImageWithButtonPage {\n  button {\n    __typename\n    kind\n    ... on ConfirmButton {\n      text\n    }\n    ... on TextCopyButton {\n      text\n    }\n    ... on LinkButton {\n      text\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "db857eee8bdd1fcc309b84e06e218eea";

export default node;
