import {
	DefaultStyledInputNumberComponents,
	type InputBaseProps,
	type StyledInputNumberComponents,
} from "@constellation-academy/framework-react-components";
import {
	InputNumber as PrInputNumber,
	type InputNumberProps as PrInputNumberProps,
} from "primereact/inputnumber";
import styled from "styled-components";
import { DefaultInputStyles } from "@corestyle/component-theme/common/input.styles";

export const tkaAdminInputNumberStyles: StyledInputNumberComponents = {
	InputNumber: {
		...DefaultStyledInputNumberComponents.InputNumber,
		StyledInputNumber: styled(PrInputNumber)<InputBaseProps & PrInputNumberProps>`
			${DefaultInputStyles()};
		`,
	},
};
