import { graphql, useFragment, useMutation } from "react-relay";
import { Card } from "primereact/card";
import React from "react";
import { type Config } from "./Config.interfaces";
import { type ConfigDropdownOptions } from "./ConfigDropdown";
import { ConfigItem } from "./ConfigItem";
import {
	type RestartIfPassedContentConfigEditor_SetCanNotRestartIfPassedContentConfigMutation,
	type SetCanNotRestartIfPassedContentConfigInput,
} from "../../../__generated__/RestartIfPassedContentConfigEditor_SetCanNotRestartIfPassedContentConfigMutation.graphql";
import {
	type RestartIfPassedContentConfigEditor_SetCanRestartIfPassedPassContentConfigMutation,
	type SetCanRestartIfPassedContentConfigInput,
} from "../../../__generated__/RestartIfPassedContentConfigEditor_SetCanRestartIfPassedPassContentConfigMutation.graphql";
import {
	type RestartIfPassedContentConfigEditor_TreeNodeFragment$key,
	type RestartIfPassedContentConfigType,
} from "../../../__generated__/RestartIfPassedContentConfigEditor_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment RestartIfPassedContentConfigEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				restartIfPassedConfig {
					configType
				}
			}
		}
	}
`;

const SET_CAN_RESTART_IF_PASSED_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RestartIfPassedContentConfigEditor_SetCanRestartIfPassedPassContentConfigMutation(
		$input: SetCanRestartIfPassedContentConfigInput!
	) {
		Admin {
			Tree {
				setCanRestartIfPassedContentConfig(input: $input) {
					contentNode {
						...RestartIfPassedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const SET_CANNOT_RESTART_IF_PASSED_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RestartIfPassedContentConfigEditor_SetCanNotRestartIfPassedContentConfigMutation(
		$input: SetCanNotRestartIfPassedContentConfigInput!
	) {
		Admin {
			Tree {
				setCanNotRestartIfPassedContentConfig(input: $input) {
					contentNode {
						...RestartIfPassedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type Props = {
	treeNodeFragmentRef: RestartIfPassedContentConfigEditor_TreeNodeFragment$key;
};

export const RestartIfPassedContentConfigEditor = ({ treeNodeFragmentRef }: Props) => {
	const treeNode = useFragment<RestartIfPassedContentConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [setCanRestartIfPassedContentConfig] =
		useMutation<RestartIfPassedContentConfigEditor_SetCanRestartIfPassedPassContentConfigMutation>(
			SET_CAN_RESTART_IF_PASSED_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);
	const [setCanNotRestartIfPassedContentConfig] =
		useMutation<RestartIfPassedContentConfigEditor_SetCanNotRestartIfPassedContentConfigMutation>(
			SET_CANNOT_RESTART_IF_PASSED_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);

	const configType: RestartIfPassedContentConfigType = treeNode.typeDefinition
		.restartIfPassedConfig?.configType as RestartIfPassedContentConfigType;

	const canRestartIfPassed: Config<
		RestartIfPassedContentConfigType,
		SetCanRestartIfPassedContentConfigInput
	> = {
		configKey: "RestartIfPassedContent_Can",
		addMutation: (input: SetCanRestartIfPassedContentConfigInput) => {
			setCanRestartIfPassedContentConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const canNotRestartIfPAssed: Config<
		RestartIfPassedContentConfigType,
		SetCanNotRestartIfPassedContentConfigInput
	> = {
		configKey: "RestartIfPassedContent_CanNot",
		addMutation: (input: SetCanNotRestartIfPassedContentConfigInput) => {
			setCanNotRestartIfPassedContentConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const configs: Array<Config<RestartIfPassedContentConfigType, any | never>> = [
		canRestartIfPassed,
		canNotRestartIfPAssed,
	];

	const options: Array<ConfigDropdownOptions<RestartIfPassedContentConfigType>> = configs.map(
		(c) => {
			return {
				label: c.configKey,
				value: c.configKey,
			};
		},
	);

	return (
		<Card className="mb-2">
			<h2>Wiederholen falls bereits bestanden</h2>

			<ConfigItem<RestartIfPassedContentConfigType, string>
				isPresentational={false}
				configType={configType}
				canEdit={
					configs.find(
						(c) =>
							c.configKey ===
							treeNode.typeDefinition.restartIfPassedConfig?.configType,
					)?.editable as boolean
				}
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							contentNodeId: treeNode.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
			/>
		</Card>
	);
};
