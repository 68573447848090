/**
 * @generated SignedSource<<7cd7e8375715037d702401840966c7f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Persolog_ClassType = "D" | "G" | "I" | "S";
export type EditPersologElementInput = {
  clientMutationId?: string | null | undefined;
  eLearningContentId: string;
  elementId: string;
  newClasses: ReadonlyArray<Persolog_ClassInput>;
  newTitle: string;
};
export type Persolog_ClassInput = {
  classType: Persolog_ClassType;
  imageId?: string | null | undefined;
  text: string;
  title: string;
};
export type persologElementForm_EditPersologElementMutation$variables = {
  input: EditPersologElementInput;
};
export type persologElementForm_EditPersologElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editPersologElement: {
        readonly persologElement: {
          readonly " $fragmentSpreads": FragmentRefs<"persologElementForm_PersologElementFragment">;
        };
      } | null | undefined;
    };
  };
};
export type persologElementForm_EditPersologElementMutation = {
  response: persologElementForm_EditPersologElementMutation$data;
  variables: persologElementForm_EditPersologElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "classType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "persologElementForm_EditPersologElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditPersologElementPayload",
                "kind": "LinkedField",
                "name": "editPersologElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Persolog_PersologElement",
                    "kind": "LinkedField",
                    "name": "persologElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "persologElementForm_PersologElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "persologElementForm_EditPersologElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditPersologElementPayload",
                "kind": "LinkedField",
                "name": "editPersologElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Persolog_PersologElement",
                    "kind": "LinkedField",
                    "name": "persologElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Persolog_Class",
                        "kind": "LinkedField",
                        "name": "classes",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Persolog_Question",
                        "kind": "LinkedField",
                        "name": "questions",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Persolog_QuestionClass",
                            "kind": "LinkedField",
                            "name": "questionClasses",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2fb342135a4d4db6e3827554ea69a99d",
    "id": null,
    "metadata": {},
    "name": "persologElementForm_EditPersologElementMutation",
    "operationKind": "mutation",
    "text": "mutation persologElementForm_EditPersologElementMutation(\n  $input: EditPersologElementInput!\n) {\n  Admin {\n    ElearningV2 {\n      editPersologElement(input: $input) {\n        persologElement {\n          ...persologElementForm_PersologElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment persologClassForm_PersologClassFragment on Persolog_Class {\n  title\n  text\n  classType\n  image {\n    id\n    name\n  }\n}\n\nfragment persologElementForm_PersologElementFragment on Persolog_PersologElement {\n  id\n  title\n  classes {\n    title\n    text\n    classType\n    image {\n      id\n      name\n    }\n    ...persologClassForm_PersologClassFragment\n  }\n  questions {\n    id\n  }\n  ...persologElementQuestionsEditor_QuestionsFragment\n}\n\nfragment persologElementQuestionClassForm_PersologQuestionClassFragment on Persolog_QuestionClass {\n  text\n  classType\n}\n\nfragment persologElementQuestionFormDialog_PersologElementFragment on Persolog_PersologElement {\n  ...persologElementQuestionForm_PersologElementFragment\n}\n\nfragment persologElementQuestionFormDialog_QuestionFragment on Persolog_Question {\n  title\n  ...persologElementQuestionForm_QuestionFragment\n}\n\nfragment persologElementQuestionForm_PersologElementFragment on Persolog_PersologElement {\n  classes {\n    classType\n    title\n  }\n}\n\nfragment persologElementQuestionForm_QuestionFragment on Persolog_Question {\n  id\n  title\n  questionClasses {\n    text\n    classType\n    ...persologElementQuestionClassForm_PersologQuestionClassFragment\n  }\n}\n\nfragment persologElementQuestionsEditor_QuestionsFragment on Persolog_PersologElement {\n  id\n  ...persologElementQuestionFormDialog_PersologElementFragment\n  questions {\n    id\n    title\n    ...persologElementQuestionFormDialog_QuestionFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e6feded0680db579efce635617d3af5";

export default node;
