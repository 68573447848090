import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const inputWrapperClass = flex({
	mb: "8",
	mt: "4",
	direction: "column",
});

export const labelClass = css({
	fontSize: "10",
});
