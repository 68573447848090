import { graphql } from "react-relay";

export const UNPUBLISHED_LEARNABLE_FRAGMENT = graphql`
	fragment deleteCourseLearnableButton_UnpublishedLearnableFragment on UnpublishedLearnable {
		id
		kind
		... on UnpublishedCourseLearnable {
			kind
			root {
				id
				structureDefinition {
					title
				}
			}
		}
	}
`;

export const DELETE_MUTATION = graphql`
	mutation deleteCourseLearnableButton_DeleteTreeMutation(
		$input: DeleteCourseOrMicroLearningLearnableInput!
		$connections: [ID!]!
	) {
		Admin {
			Tree {
				deleteCourseOrMicroLearningLearnable(input: $input) {
					deletedCourseOrMicroLearningLearnableId @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
