import { EpicIcons, InputText } from "@constellation-academy/epic-ui";
import { useState } from "react";
import { useMutation } from "react-relay";
import { Button } from "@components/button";
import { videoSourceField_GetVideoDataV2Mutation } from "@relay/videoSourceField_GetVideoDataV2Mutation.graphql";
import { P2Span } from "@themes/font-tags";
import { GET_VIDEO_DATA_MUTATION } from "./video-source-field.graphql";
import {
	errorTextClass,
	inputWrapperClass,
	videoDisplayWrapper,
} from "./video-source-field.styles";
import { VideoDataState, VideoSourceFieldProps } from "./video-source-field.types";

export const VideoSourceField = ({
	vimeoId,
	videoSourceId,
	onUpdate,
	onChange,
}: VideoSourceFieldProps) => {
	const [getVideoData, isGettingVideoData] =
		useMutation<videoSourceField_GetVideoDataV2Mutation>(GET_VIDEO_DATA_MUTATION);

	const [videoData, setVideoData] = useState<VideoDataState>();
	const [error, setError] = useState<string>();

	const handleGetVideoDataOnClick = () => {
		getVideoData({
			variables: {
				input: {
					vimeoId: vimeoId ?? "",
					vimeoVideoSourceId: videoSourceId ?? "",
				},
			},
			onCompleted: (response) => {
				const responseVideoData = response.Admin.ElearningV2.getVideoData?.videoData;

				if (!responseVideoData) {
					setError("Video konnte in ausgewählter Video-Quelle nicht gefunden werden.");
					return;
				}

				setVideoData(
					responseVideoData
						? {
								...responseVideoData,
						  }
						: undefined,
				);
			},
		});
	};

	const handleInputOnChange = (input: string | undefined) => {
		onUpdate(input);
		onChange?.();
	};

	return (
		<div>
			<div className={inputWrapperClass}>
				<InputText
					placeholder="Vimeo-ID"
					id="vimeoId"
					value={vimeoId}
					onChange={handleInputOnChange}
				/>
				<Button
					icon={EpicIcons.REFRESH}
					disabled={isGettingVideoData}
					onClick={handleGetVideoDataOnClick}
				/>
			</div>
			{videoData && (
				<div className={videoDisplayWrapper}>
					<div>Name: {videoData.name}</div>
					<div>Dauer: {videoData.duration} Sekunden</div>
					{videoData.thumbnailUrl && (
						<img height={200} alt={videoData.name} src={videoData.thumbnailUrl} />
					)}
				</div>
			)}
			{error && <P2Span className={errorTextClass}>{error}</P2Span>}
		</div>
	);
};
