import { EpicIcons, Icon } from "@constellation-academy/epic-ui";
import { Suspense, useState } from "react";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { formatDateTime } from "@components/DateTimeDisplay";
import type { userRootNodeItem_LearnOpportunityV2Fragment$key } from "@relay/userRootNodeItem_LearnOpportunityV2Fragment.graphql";
import type { userRootNodeItem_RootNodesWithTreeStateTypeFragment$key } from "@relay/userRootNodeItem_RootNodesWithTreeStateTypeFragment.graphql";
import type { userRootNodeItem_TreeStateFragment$key } from "@relay/userRootNodeItem_TreeStateFragment.graphql";
import { error100, info100, success100 } from "@themes/colors";
import { H2Span, P2Span } from "@themes/font-tags";
import {
	TREE_STATE_FRAGMENT,
	LEARN_OPPORTUNITY_V2_FRAGMENT,
	ROOT_NODES_WITH_TREE_STATE_TYPE_FRAGMENT,
} from "./user-root-node-item.graphql";
import {
	contentHeaderClass,
	contentHeaderListClass,
	contentWrapperClass,
	expandButtonClass,
	headerButtonWrapperClass,
	headerClass,
	headerInnerClass,
	headerWrapperClass,
	lockStateClass,
	wrapperClass,
} from "./user-root-node-item.styles";
import { UserRootNodeItemProps } from "./user-root-node-item.types";
import { CreateTreeStateButton } from "../create-tree-state-button";
import { ReenablePaywallButton } from "../reenable-paywall-button";
import { ResetLearnProgressButton } from "../reset-learn-progress-button/reset-learn-progress-button.component";
import { TreeUnlockedStateDisplay } from "../tree-unlocked-state-display";
import { UnlockTreeButton } from "../unlock-tree-button";
import { UserRootNodeItemTree } from "../user-root-node-item-tree";

export const UserRootNodeItem = ({
	userId,
	refetchList,
	rootNodeItemFragmentRef,
}: UserRootNodeItemProps) => {
	const [expanded, setExpanded] = useState(false);

	const item = useFragment<userRootNodeItem_RootNodesWithTreeStateTypeFragment$key>(
		ROOT_NODES_WITH_TREE_STATE_TYPE_FRAGMENT,
		rootNodeItemFragmentRef ?? null,
	);
	const rootNode = useFragment<userRootNodeItem_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		item?.rootNode ?? null,
	);
	const treeState = useFragment<userRootNodeItem_TreeStateFragment$key>(
		TREE_STATE_FRAGMENT,
		item?.treeState ?? null,
	);

	const treeStatus = treeState?.definition?.status;

	const hasTreeState = treeState !== null;
	const treeUnlockedState = treeState?.extension?.treeUnlockedState;
	const treeUnlockKind = treeUnlockedState?.data.kind;
	const paywallLocked = treeUnlockKind === undefined;
	const paywallUnlocked = !paywallLocked;

	const handleExpandOnClick = () => {
		setExpanded((prevExpanded) => !prevExpanded);
	};

	const handleResetLearnProgressDialogOnCompleted = () => {
		refetchList(
			{},
			{
				fetchPolicy: "network-only",
			},
		);
	};

	const handleCreateTreeOnCompleted = () => {
		refetchList(
			{},
			{
				fetchPolicy: "network-only",
			},
		);
	};

	const state = match({
		hasTreeState,
		paywallLocked,
		treeStatus,
	})
		.with(
			{
				hasTreeState: true,
				paywallLocked: true,
				treeStatus: "started",
			},
			() =>
				({
					icon: EpicIcons.LOCK_OPEN,
					variant: "unlocked",
					color: success100,
				}) as const,
		)
		.with(
			{
				hasTreeState: true,
				paywallLocked: false,
				treeStatus: "started",
			},
			() =>
				({
					icon: EpicIcons.LOCK_OPEN,
					variant: "paywallUnlocked",
					color: info100,
				}) as const,
		)
		.with(
			{
				hasTreeState: true,
				paywallLocked: false,
				treeStatus: "finished",
			},
			{
				hasTreeState: true,
				paywallLocked: true,
				treeStatus: "finished",
			},
			() =>
				({
					icon: EpicIcons.CHECK,
					variant: "finished",
					color: info100,
				}) as const,
		)
		.otherwise(
			() =>
				({
					icon: EpicIcons.LOCK,
					variant: "locked",
					color: error100,
				}) as const,
		);

	return (
		<>
			<li className={wrapperClass}>
				<div className={headerWrapperClass}>
					<div className={headerClass}>
						<button
							type="button"
							disabled={!hasTreeState}
							className={expandButtonClass({
								disabled: !hasTreeState,
							})}
							onClick={handleExpandOnClick}
						>
							<Icon icon={expanded ? EpicIcons.ANGLE_DOWN : EpicIcons.ANGLE_RIGHT} />
						</button>
						<div className={headerInnerClass}>
							<div
								className={lockStateClass({
									state: state.variant,
								})}
							>
								<Icon icon={state.icon} color={state.color} />
							</div>
							<H2Span>{rootNode?.structureDefinition.title}</H2Span>
						</div>
					</div>
					<div className={headerButtonWrapperClass}>
						{hasTreeState && (
							<ResetLearnProgressButton
								learnOpportunityV2FragmentRef={item?.rootNode}
								userId={userId}
								onCompleted={handleResetLearnProgressDialogOnCompleted}
							/>
						)}
						{!hasTreeState && (
							<CreateTreeStateButton
								learnOpportunityV2FragmentRef={item?.rootNode}
								userId={userId}
								onCompleted={handleCreateTreeOnCompleted}
							/>
						)}
						{paywallLocked && (
							<UnlockTreeButton
								learnOpportunityV2FragmentRef={item?.rootNode}
								userId={userId}
							/>
						)}
						{paywallUnlocked && (
							<ReenablePaywallButton
								learnOpportunityV2FragmentRef={item?.rootNode}
								userId={userId}
							/>
						)}
					</div>
				</div>
				{expanded && (
					<div className={contentWrapperClass}>
						<div className={contentHeaderClass}>
							<ul className={contentHeaderListClass}>
								<li>
									<P2Span>
										Startdatum:{" "}
										<strong>
											{formatDateTime(treeState?.startedAt ?? "")}
										</strong>
									</P2Span>
								</li>
								<li>
									<P2Span>
										Fortschritt:{" "}
										<strong>{treeState?.progressPercentage}%</strong>
									</P2Span>
								</li>
								<TreeUnlockedStateDisplay
									treeUnlockedStateFragmentRef={treeUnlockedState}
								/>
							</ul>
						</div>
						{rootNode && (
							<Suspense fallback={<div>Lade...</div>}>
								<UserRootNodeItemTree
									rootId={rootNode.id}
									userId={userId}
									hasPaywall={paywallLocked}
									submissions={treeState?.extension.submissions}
									gamificationPoints={treeState?.extension.gamificationPoints}
									currentHeadContentId={treeState?.definition.headContentNode?.id}
									firstContentCoordinatesIndexPath={
										rootNode?.structureDefinition.extension?.unlockInfo
											?.firstContentCoordinatesIndexPath
									}
								/>
							</Suspense>
						)}
					</div>
				)}
			</li>
		</>
	);
};
