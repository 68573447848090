import { graphql } from "react-relay";

export const QUERY = graphql`
	query productsScreen_Query($titleOpt: String, $isHiddenOpt: Boolean, $numProductsPerPage: Int) {
		...productsTable_QueryFragment
			@arguments(
				titleOpt: $titleOpt
				isHiddenOpt: $isHiddenOpt
				first: $numProductsPerPage
				after: null
			)
	}
`;
