import { graphql } from "react-relay";

export const TREE_NODE_FRAGMENT = graphql`
	fragment passContentConfigEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				passConfig {
					configType
				}
			}
		}
	}
`;

export const SET_ALWAYS_PASS_CONTENT_CONFIG_MUTATION = graphql`
	mutation passContentConfigEditor_SetAlwaysPassContentConfigMutation(
		$input: SetAlwaysPassContentConfigInput!
	) {
		Admin {
			Tree {
				setAlwaysPassContentConfig(input: $input) {
					contentNode {
						...passContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const SET_IHK_FLOW_PASS_CONTENT_CONFIG_MUTATION = graphql`
	mutation passContentConfigEditor_SetIHKFlowPassContentConfigMutation(
		$input: SetIHKFlowPassContentConfigInput!
	) {
		Admin {
			Tree {
				setIHKFlowPassContentConfig(input: $input) {
					content {
						...passContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
