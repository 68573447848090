import { memo } from "react";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";
import { match } from "ts-pattern";
import { formatDateTime } from "@components/DateTimeDisplay";
import { Paths } from "@routes/paths";
import { P2Span } from "@themes/font-tags";
import { TREE_UNLOCKED_STATE_FRAGMENT } from "./tree-unlocked-state-display.graphql";
import { linkClass } from "./tree-unlocked-state-display.styles";
import type { TreeUnlockedStateDisplayProps } from "./tree-unlocked-state-display.types";

const TreeUnlockedStateDisplayComponent = ({
	treeUnlockedStateFragmentRef,
}: TreeUnlockedStateDisplayProps) => {
	const treeUnlockedState = useFragment(
		TREE_UNLOCKED_STATE_FRAGMENT,
		treeUnlockedStateFragmentRef ?? null,
	);

	const unlockedByElement = match(treeUnlockedState?.data)
		.with({ kind: "UnlockedByAdmin" }, ({ unlockedByUser }) => (
			<Link to={Paths.users.withId(unlockedByUser?.id ?? "").edit.path} className={linkClass}>
				{unlockedByUser?.name}
			</Link>
		))
		.with({ kind: "UnlockedByLicense" }, () => "einer Lizenz")
		.otherwise(() => null);

	const unlockedAt = treeUnlockedState?.when ?? "";
	const unlockedAtElement = unlockedAt ? formatDateTime(unlockedAt) : null;

	if (!treeUnlockedState) {
		return null;
	}

	return (
		<li>
			<P2Span>
				Paywall entfernt von <strong>{unlockedByElement}</strong> am{" "}
				<strong>{unlockedAtElement}</strong>
			</P2Span>
		</li>
	);
};

export const TreeUnlockedStateDisplay = memo(TreeUnlockedStateDisplayComponent);
