/**
 * @generated SignedSource<<fc69dc03e473bd177b95216853832706>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentKind = "Async" | "ELearning";
import { FragmentRefs } from "relay-runtime";
export type contentConfigEditor_TreeNodeFragment$data = {
  readonly typeDefinition: {
    readonly contentKind?: ContentKind;
  };
  readonly " $fragmentSpreads": FragmentRefs<"RestartIfFailedContentConfigEditor_TreeNodeFragment" | "RestartIfPassedContentConfigEditor_TreeNodeFragment" | "flowAndCurrentElementContentConfigEditor_TreeNodeFragment" | "passContentConfigEditor_TreeNodeFragment" | "startContentConfigEditor_TreeNodeFragment">;
  readonly " $fragmentType": "contentConfigEditor_TreeNodeFragment";
};
export type contentConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: contentConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contentKind",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentConfigEditor_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "AsyncContentTypeDefinition",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "ELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "startContentConfigEditor_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "passContentConfigEditor_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RestartIfFailedContentConfigEditor_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RestartIfPassedContentConfigEditor_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "flowAndCurrentElementContentConfigEditor_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "c54445f912d74096af53157583914f5f";

export default node;
