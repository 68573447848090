import { Button, EpicIcons } from "@constellation-academy/epic-ui";
import { graphql, useMutation } from "react-relay";
import React from "react";
import { type CreateMarkMistakeElementButton_CreateMarkMistakeElementMutation } from "@relay/CreateMarkMistakeElementButton_CreateMarkMistakeElementMutation.graphql";

const CREATE_MARK_MISTAKE_ELEMENT_MUTATION = graphql`
	mutation CreateMarkMistakeElementButton_CreateMarkMistakeElementMutation(
		$input: CreateMarkMistakesElementInput!
	) {
		Admin {
			ElearningV2 {
				createMarkMistakesElement(input: $input) {
					eLearningContent {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type OwnProps = {
	eLearningContentNodeId: string;
};

export const CreateMarkMistakeElementButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [createMarkMistakeElement, isCreatingMarkMistakeElement] =
		useMutation<CreateMarkMistakeElementButton_CreateMarkMistakeElementMutation>(
			CREATE_MARK_MISTAKE_ELEMENT_MUTATION,
		);
	return (
		<Button
			disabled={isCreatingMarkMistakeElement}
			tooltip={`Fehler-Markieren-Baustein anlegen`}
			icon={EpicIcons.CHECK_CIRCLE}
			onClick={() => {
				createMarkMistakeElement({
					variables: {
						input: {
							title: "Neues Element",
							text: "",
							eLearningContentId: eLearningContentNodeId,
						},
					},
				});
			}}
		/>
	);
};
