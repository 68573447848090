/**
 * @generated SignedSource<<df24d43f8387070f70b646467ab8da28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MatrixElementCellKind = "Answer" | "Display";
import { FragmentRefs } from "relay-runtime";
export type matrixFieldElement_MatrixElementFragment$data = {
  readonly cells: ReadonlyArray<{
    readonly id: string;
    readonly kind: MatrixElementCellKind;
    readonly xIdx: number;
    readonly yIdx: number;
    readonly " $fragmentSpreads": FragmentRefs<"answerMatrixElementCellForm_AnswerMatrixElementCellFragment" | "displayMatrixElementCellForm_DisplayMatrixElementCellFragment">;
  }>;
  readonly id: string;
  readonly " $fragmentType": "matrixFieldElement_MatrixElementFragment";
};
export type matrixFieldElement_MatrixElementFragment$key = {
  readonly " $data"?: matrixFieldElement_MatrixElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"matrixFieldElement_MatrixElementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "matrixFieldElement_MatrixElementFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "cells",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xIdx",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "yIdx",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "displayMatrixElementCellForm_DisplayMatrixElementCellFragment"
            }
          ],
          "type": "DisplayMatrixElementCell",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "answerMatrixElementCellForm_AnswerMatrixElementCellFragment"
            }
          ],
          "type": "AnswerMatrixElementCell",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MatrixElement",
  "abstractKey": null
};
})();

(node as any).hash = "693849b419774e264213de99db4a916e";

export default node;
