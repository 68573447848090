/**
 * @generated SignedSource<<bcfbd5c5717adaac14666f972e20b77e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDiscountActionInput = {
  clientMutationId?: string | null | undefined;
  discountActionId: string;
  newDiscountTypeKind: string;
  newPercentage: number;
  newTitle: string;
  newUsageLimitation?: UsageLimitedInput | null | undefined;
  newValidUntil?: string | null | undefined;
};
export type UsageLimitedInput = {
  maxAmountOfUsages?: number | null | undefined;
  maxAmountOfUsagesPerAccount?: number | null | undefined;
  onlyForAccountIds: ReadonlyArray<string>;
  onlyForProductIds: ReadonlyArray<string>;
};
export type discountActionForm_EditDiscountActionMutation$variables = {
  input: EditDiscountActionInput;
};
export type discountActionForm_EditDiscountActionMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly editDiscountAction: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"discountActionForm_DiscountActionFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type discountActionForm_EditDiscountActionMutation = {
  response: discountActionForm_EditDiscountActionMutation$data;
  variables: discountActionForm_EditDiscountActionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "max",
        "storageKey": null
      }
    ],
    "type": "AmountLimited",
    "abstractKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "discountActionForm_EditDiscountActionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditDiscountActionPayload",
                "kind": "LinkedField",
                "name": "editDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "discountActionForm_DiscountActionFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "discountActionForm_EditDiscountActionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditDiscountActionPayload",
                "kind": "LinkedField",
                "name": "editDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percent",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "validUntil",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dateTime",
                                    "storageKey": null
                                  }
                                ],
                                "type": "ValidUntilDateTime",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "discountType",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "usageLimitation",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "maxAmountOfUsages",
                                    "plural": false,
                                    "selections": (v4/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "maxAmountOfUsagesPerAccount",
                                    "plural": false,
                                    "selections": (v4/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "onlyForAccountIds",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "onlyForProductIds",
                                    "storageKey": null
                                  }
                                ],
                                "type": "UsageLimited",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a3de730f2169a49545dc91c9cf8a9469",
    "id": null,
    "metadata": {},
    "name": "discountActionForm_EditDiscountActionMutation",
    "operationKind": "mutation",
    "text": "mutation discountActionForm_EditDiscountActionMutation(\n  $input: EditDiscountActionInput!\n) {\n  Admin {\n    Billing {\n      editDiscountAction(input: $input) {\n        edge {\n          node {\n            ...discountActionForm_DiscountActionFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment discountActionForm_DiscountActionFragment on DiscountAction {\n  id\n  title\n  percent\n  validUntil {\n    __typename\n    kind\n    ... on ValidUntilDateTime {\n      dateTime\n    }\n  }\n  discountType {\n    __typename\n    kind\n  }\n  usageLimitation {\n    __typename\n    kind\n    ... on UsageLimited {\n      kind\n      maxAmountOfUsages {\n        __typename\n        ... on AmountLimited {\n          max\n        }\n      }\n      maxAmountOfUsagesPerAccount {\n        __typename\n        ... on AmountLimited {\n          max\n        }\n      }\n      onlyForAccountIds\n      onlyForProductIds\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "71e409193204f14999b5143ff59b6620";

export default node;
