/**
 * @generated SignedSource<<12b2d6cb8ace9162bcd5eb0d473a4100>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscountActionsTable_DiscountActionFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActionActionColumn_DiscountActionFragment" | "ActionCalcTypeColumn_DiscountActionFragment" | "ActionIdColumn_DiscountActionFragment" | "ActionTitleColumn_DiscountActionFragment" | "ActionTypeColumn_DiscountActionFragment">;
  readonly " $fragmentType": "DiscountActionsTable_DiscountActionFragment";
};
export type DiscountActionsTable_DiscountActionFragment$key = {
  readonly " $data"?: DiscountActionsTable_DiscountActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscountActionsTable_DiscountActionFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "DiscountActionsTable_DiscountActionFragment"
};

(node as any).hash = "f31e69a98c4111c48d33e058179ee9bf";

export default node;
