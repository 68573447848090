import { graphql, useFragment } from "react-relay";
import { NodeConfigurationBadges } from "./NodeConfigurationBadges";
import { type Node_TreeNodeFragment$key } from "../../../__generated__/Node_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment Node_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			title
		}
		typeDefinition {
			definitionType
			... on ContentTypeDefinition {
				contentKind
			}
			... on BranchTypeDefinition {
				childRefs
			}
		}
		...NodeConfigurationBadges_TreeNodeFragment
	}
`;

type OwnProps = {
	treeNodeFragmentRef: Node_TreeNodeFragment$key;
};

export const Node = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<Node_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const branchNodeIsEmpty =
		treeNode.typeDefinition.definitionType === "branch" &&
		(!treeNode.typeDefinition.childRefs || treeNode.typeDefinition.childRefs.length === 0);

	const getContent = () => {
		switch (treeNode.typeDefinition.definitionType) {
			case "content":
				switch (treeNode.typeDefinition.contentKind) {
					case "ELearning":
						return "(E-Learning)";
					default:
						return " ";
				}
			default:
				return " ";
		}
	};

	return treeNode ? (
		<div
			key={treeNode.id}
			className={`flex align-items-center ${branchNodeIsEmpty ? "opacity-50" : ""}`}
		>
			<span className="mr-2">
				{treeNode.structureDefinition.title} {getContent()}
				{branchNodeIsEmpty && "(leer)"}
			</span>
			<NodeConfigurationBadges treeNodeFragmentRef={treeNode} />
		</div>
	) : null;
};
