import { graphql } from "react-relay";

export const USER_FRAGMENT = graphql`
	fragment accountsForUsersTable_UserFragment on User {
		id
		groupAssociations {
			account {
				id
				name
			}
			group {
				id
				name
			}
		}
	}
`;

export const REMOVE_USER_FROM_ACCOUNT_MUTATION = graphql`
	mutation accountsForUsersTable_RemoveUserFromAccountMutation(
		$input: RemoveUserFromAccountInput!
		$connections: [ID!]!
	) {
		Admin {
			Auth {
				removeUserFromAccount(input: $input) {
					removedUserId @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
