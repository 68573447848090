/**
 * @generated SignedSource<<d221bfb61c9adae8e99e1909a4f375af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editVideoSourceButton_Vimeo_VimeoVideoSourceFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"videoSourceForm_Vimeo_VimeoVideoSourceFragment">;
  readonly " $fragmentType": "editVideoSourceButton_Vimeo_VimeoVideoSourceFragment";
};
export type editVideoSourceButton_Vimeo_VimeoVideoSourceFragment$key = {
  readonly " $data"?: editVideoSourceButton_Vimeo_VimeoVideoSourceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editVideoSourceButton_Vimeo_VimeoVideoSourceFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editVideoSourceButton_Vimeo_VimeoVideoSourceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "videoSourceForm_Vimeo_VimeoVideoSourceFragment"
    }
  ],
  "type": "Vimeo_VimeoVideoSource",
  "abstractKey": null
};

(node as any).hash = "8fc507077b51fb7583857764ddd11c4c";

export default node;
