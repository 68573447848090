import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	justify: "space-between",
});

export const formContentWrapperClass = flex({
	direction: "row",
	align: "center",
	gap: "8",
});

export const systemEmailWrapperClass = flex({
	align: "center",
	gap: "4",
});
