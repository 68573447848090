import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment selectLicenseDefinitionField_QueryFragment on Query
	@refetchable(queryName: "selectLicenseDefinitionField_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		nameMatchRegex: { type: "String" }
		dataKinds: { type: "[LicenseDefinitionDataKind!]!" }
	) {
		Admin {
			LicenseDefinition {
				SearchLicenseDefinitions(
					first: $first
					after: $after
					nameMatchRegex: $nameMatchRegex
					dataKinds: $dataKinds
				) @connection(key: "selectLicenseDefinitionField_SearchLicenseDefinitions") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							...selectLicenseDefinitionField_LicenseDefinitionFragment
						}
					}
				}
			}
		}
	}
`;

export const LICENSE_DEFINITION_FRAGMENT = graphql`
	fragment selectLicenseDefinitionField_LicenseDefinitionFragment on LicenseDefinition @inline {
		id
		data {
			name
		}
	}
`;
