import { graphql } from "react-relay";

export const TEXT_WITH_PAGES_ELEMENT_FRAGMENT = graphql`
	fragment textWithPagesElementForm_TextWithPagesElementFragment on TextWithPagesElement {
		id
		title
		task
		...textWithPagesElementPagesEditor_PagesFragment
	}
`;

export const EDIT_TEXT_WITH_PAGES_ELEMENT_MUTATION = graphql`
	mutation textWithPagesElementForm_EditTextWithPagesElementMutation(
		$input: EditTextWithPagesElementInput!
	) {
		Admin {
			ElearningV2 {
				editTextWithPagesElement(input: $input) {
					textWithPagesElement {
						...textWithPagesElementForm_TextWithPagesElementFragment
					}
				}
			}
		}
	}
`;
