import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { NoAccess } from "@components/no-access";
import { LimitedCartDiscountTable } from "@features/cart-discounts/limited-cart-discount-table";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type limitedCartDiscountsScreen_Query } from "@relay/limitedCartDiscountsScreen_Query.graphql";
import { numItemsPerPage } from "./limited-cart-discounts.constants";
import { QUERY } from "./limited-cart-discounts.graphql";

export const LimitedCartDiscountScreen = () => {
	const canRead = useHasPermissions(["UserInAccountPermission_LimitedCartDiscountAdmin_Read"]);

	const limitedCartDiscounts = useLazyLoadQuery<limitedCartDiscountsScreen_Query>(QUERY, {
		numItemsPerPage,
	});

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen title="Warenkorb-Rabatte">
			<Suspense fallback={<ProgressSpinner />}>
				<LimitedCartDiscountTable
					enableFilter={true}
					enableActions={true}
					limitedCartDiscountsPerPage={numItemsPerPage}
					limitedCartDiscountsFragmentRef={limitedCartDiscounts}
				/>
			</Suspense>
		</BaseScreen>
	);
};
