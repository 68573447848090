import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useFragment } from "react-relay";
import { MemoizedRichTextEditor } from "@components/rich-text-editor";
import { ValidatedField } from "@components/ValidatedField";

import { FileSelectionField, FileV2 } from "@features/files/file-selection-field";
import { persologClassForm_PersologClassFragment$key } from "@relay/persologClassForm_PersologClassFragment.graphql";
import { PersologClassFormSchema } from "@screens/educational-offer/parts/persolog-class-form/persolog-class-form.consts";
import { PERSOLOG_CLASS_FRAGMENT } from "@screens/educational-offer/parts/persolog-class-form/persolog-class-form.graphql";
import {
	PersologClassFormProps,
	PersologClassFormRef,
	PersologClassFormState,
} from "@screens/educational-offer/parts/persolog-class-form/persolog-class-form.types";

import { persologClasses } from "@screens/educational-offer/parts/persolog-element-form/persolog-element-form.consts";
import { H2Span } from "@themes/font-tags";
import { RichTextEditorToolbar } from "@components/rich-text-editor/rich-text-editor.const";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";

export const PersologClassForm = forwardRef<PersologClassFormRef, PersologClassFormProps>(
	function PersologClassForm({ persologClassFragmentRef, onChange, onSubmit, classType }, ref) {
		const toast = useRef<Toast>(null);
		const existingClass = useFragment<persologClassForm_PersologClassFragment$key>(
			PERSOLOG_CLASS_FRAGMENT,
			persologClassFragmentRef ?? null,
		);

		const fallbackClass =
			persologClasses.find(
				(persologClass) => persologClass.classType === existingClass?.classType,
			) ?? persologClasses.find((persologClass) => persologClass.classType === classType)!;

		const initialValues: PersologClassFormState = {
			title: existingClass?.title ?? fallbackClass.title,
			text: existingClass?.text ?? fallbackClass.text,
			image: existingClass?.image ?? fallbackClass.image,
			classType: classType,
		};

		const formik = useFormik<PersologClassFormState>({
			initialValues,
			validationSchema: PersologClassFormSchema,
			onSubmit: (values) => {
				onSubmit?.(values);
			},
		});

		useImperativeHandle(ref, () => ({
			persologClass: fallbackClass.title,
			values: formik.values,
			submit: formik.submitForm,
			validate: () =>
				formik.validateForm().then((errors) => Object.keys(errors).length === 0),
		}));

		return (
			<>
				<Toast ref={toast} />
				<H2Span>{fallbackClass.title}</H2Span>
				<ValidatedField<PersologClassFormState, string>
					name={"title"}
					label={"Titel"}
					formikConfig={formik}
					onChange={onChange}
					required
					component={DefaultTextFieldComponent}
				/>
				<ValidatedField<PersologClassFormState, string>
					name={"text"}
					label={"Text"}
					formikConfig={formik}
					onChange={onChange}
					required
					component={(p) => (
						<MemoizedRichTextEditor {...p} toolbar={RichTextEditorToolbar.WithLists} />
					)}
				/>
				<ValidatedField<PersologClassFormState, FileV2>
					name={"image"}
					label={"Bild"}
					component={({ fieldValue, updateField }) => {
						return (
							<FileSelectionField
								name={"image"}
								selectedFile={fieldValue}
								setSelectedFile={updateField}
								filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
								canUploadFiles={true}
								canDeleteFiles={true}
							/>
						);
					}}
					formikConfig={formik}
				/>
			</>
		);
	},
);
