/**
 * @generated SignedSource<<830f593c6f08be472106abb48a397962>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type uploadAsyncElementForm_UploadAsyncElementFragment$data = {
  readonly assignedEvaluator: {
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly taskDescription: string | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "uploadAsyncElementForm_UploadAsyncElementFragment";
};
export type uploadAsyncElementForm_UploadAsyncElementFragment$key = {
  readonly " $data"?: uploadAsyncElementForm_UploadAsyncElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"uploadAsyncElementForm_UploadAsyncElementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "uploadAsyncElementForm_UploadAsyncElementFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taskDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "assignedEvaluator",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "UploadAsyncElement",
  "abstractKey": null
};
})();

(node as any).hash = "57765d35415ce765742e4f2c5497122b";

export default node;
