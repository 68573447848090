/**
 * @generated SignedSource<<33f2d5a25fa55f5e12093b52c981ec16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editRootExtensionForm_RootExtensionImplFragment$data = {
  readonly abbreviation: string | null | undefined;
  readonly diploma: string | null | undefined;
  readonly learnGoalsOpt: string | null | undefined;
  readonly product: {
    readonly id: string;
    readonly title: string;
  } | null | undefined;
  readonly tags: ReadonlyArray<{
    readonly data: {
      readonly name: string;
    };
    readonly id: string;
  }>;
  readonly trailerOpt: string | null | undefined;
  readonly " $fragmentType": "editRootExtensionForm_RootExtensionImplFragment";
};
export type editRootExtensionForm_RootExtensionImplFragment$key = {
  readonly " $data"?: editRootExtensionForm_RootExtensionImplFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editRootExtensionForm_RootExtensionImplFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editRootExtensionForm_RootExtensionImplFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "learnGoalsOpt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trailerOpt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AcademiesTagData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diploma",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abbreviation",
      "storageKey": null
    }
  ],
  "type": "RootExtensionImpl",
  "abstractKey": null
};
})();

(node as any).hash = "559a5460a249eb4363c0c3591ef9f7da";

export default node;
