/**
 * @generated SignedSource<<60e92d98e0dbf82a8157e8a4deb6f8c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditMarkMistakesElementInput = {
  clientMutationId?: string | null | undefined;
  markMistakesElementId: string;
  newText: string;
  newTitle: string;
};
export type MarkMistakeElementForm_EditMarkMistakeElementMutation$variables = {
  input: EditMarkMistakesElementInput;
};
export type MarkMistakeElementForm_EditMarkMistakeElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editMarkMistakesElement: {
        readonly markMistakesElement: {
          readonly " $fragmentSpreads": FragmentRefs<"MarkMistakeElementForm_MarkMistakeElementFragment">;
        };
      } | null | undefined;
    };
  };
};
export type MarkMistakeElementForm_EditMarkMistakeElementMutation = {
  response: MarkMistakeElementForm_EditMarkMistakeElementMutation$data;
  variables: MarkMistakeElementForm_EditMarkMistakeElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkMistakeElementForm_EditMarkMistakeElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditMarkMistakesElementPayload",
                "kind": "LinkedField",
                "name": "editMarkMistakesElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MarkMistakesElement",
                    "kind": "LinkedField",
                    "name": "markMistakesElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "MarkMistakeElementForm_MarkMistakeElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkMistakeElementForm_EditMarkMistakeElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditMarkMistakesElementPayload",
                "kind": "LinkedField",
                "name": "editMarkMistakesElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MarkMistakesElement",
                    "kind": "LinkedField",
                    "name": "markMistakesElement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1a4f57159ce8ffa7e4a508d3c7cd1710",
    "id": null,
    "metadata": {},
    "name": "MarkMistakeElementForm_EditMarkMistakeElementMutation",
    "operationKind": "mutation",
    "text": "mutation MarkMistakeElementForm_EditMarkMistakeElementMutation(\n  $input: EditMarkMistakesElementInput!\n) {\n  Admin {\n    ElearningV2 {\n      editMarkMistakesElement(input: $input) {\n        markMistakesElement {\n          ...MarkMistakeElementForm_MarkMistakeElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment MarkMistakeElementForm_MarkMistakeElementFragment on MarkMistakesElement {\n  id\n  title\n  text\n}\n"
  }
};
})();

(node as any).hash = "069be37e14bd667e37de95619629739c";

export default node;
