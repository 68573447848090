import { graphql } from "react-relay";

export const QUERY = graphql`
	query externalLicenseEditScreen_ExternalLicenseQuery($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on ExternalLicense {
				id
				pool {
					data {
						name
					}
				}
				...editExternalLicenseForm_ExternalLicenseFragment
			}
		}
	}
`;
