/**
 * @generated SignedSource<<a5f31dfd8711634d5d821be985697165>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ButtonKind = "confirm" | "link" | "textCopy";
import { FragmentRefs } from "relay-runtime";
export type pageButtonForm_ButtonFragment$data = {
  readonly button: {
    readonly kind: ButtonKind;
    readonly text?: string;
    readonly url?: string;
  };
  readonly " $fragmentType": "pageButtonForm_ButtonFragment";
};
export type pageButtonForm_ButtonFragment$key = {
  readonly " $data"?: pageButtonForm_ButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"pageButtonForm_ButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "pageButtonForm_ButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "button",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "ConfirmButton",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "TextCopyButton",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "type": "LinkButton",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ImageWithButtonPage",
  "abstractKey": null
};
})();

(node as any).hash = "7069f68891cf88ded2a5582015099776";

export default node;
