import { graphql } from "react-relay";

export const TREE_NODE_FRAGMENT = graphql`
	fragment flowAndCurrentElementContentConfigEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				flowConfig {
					id
					configType
					... on DirectFeedbackFlowELearningContentConfig {
						minNumTriesTillShowAnswer
					}
					...setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment
				}
			}
		}
		...setDirectFeedbackFlowConfigForm_TreeNodeFragment
	}
`;

graphql`
	fragment flowAndCurrentElementContentConfigEditor_Flow_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				flowConfig {
					id
					configType
					... on DirectFeedbackFlowELearningContentConfig {
						minNumTriesTillShowAnswer
					}
					...setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment
				}
			}
		}
		...setDirectFeedbackFlowConfigForm_TreeNodeFragment
	}
`;

graphql`
	fragment flowAndCurrentElementContentConfigEditor_CurrentElement_TreeNodeFragment on TreeNode {
		id
	}
`;

export const SET_SIMPLY_CONTINUE_FLOW_E_LEARNING_CONTENT_CONFIG_MUTATION = graphql`
	mutation flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation(
		$input: SetSimplyContinueFlowELearningContentConfigInput!
	) {
		Admin {
			Tree {
				setSimplyContinueFlowELearningContentConfig(input: $input) {
					content {
						...flowAndCurrentElementContentConfigEditor_Flow_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const SET_DIRECT_FEEDBACK_FLOW_E_LEARNING_CONTENT_CONFIG_MUTATION = graphql`
	mutation flowAndCurrentElementContentConfigEditor_SetDirectFeedbackFlowELearningContentConfigMutation(
		$input: SetDirectFeedbackFlowELearningContentConfigInput!
	) {
		Admin {
			Tree {
				setDirectFeedbackFlowELearningContentConfig(input: $input) {
					content {
						...flowAndCurrentElementContentConfigEditor_Flow_TreeNodeFragment
					}
				}
			}
		}
	}
`;
