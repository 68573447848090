import { flex } from "@styled-system/patterns";

export const ButtonSetClass = flex({
	gap: "8",
	my: "16",
});

export const itemWrapperClass = flex({
	justify: "space-between",
	align: "center",
});

export const buttonWrapperClass = flex({
	gap: "4",
	align: "center",
});
