import { graphql } from "react-relay";

export const CREATE_LICENSE_PRODUCT_MUTATION = graphql`
	mutation createLicenseProductButton_CreateLicenseProductMutation(
		$input: CreateLicenseProductInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createLicenseProduct(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...productsTable_ProductFragment
						}
					}
				}
			}
		}
	}
`;
