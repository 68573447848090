/**
 * @generated SignedSource<<3f8e4ab28eb46763aa938d63cbaa7ae6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateRandomDiscountCodesInput = {
  clientMutationId?: string | null | undefined;
  discountActionId: string;
  number: number;
};
export type discountCodeCreateRandom_CreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateRandomDiscountCodesInput;
};
export type discountCodeCreateRandom_CreateMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly createRandomDiscountCodes: {
        readonly connection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly " $fragmentSpreads": FragmentRefs<"discountCodeTable_DiscountCodeFragment">;
            };
          } | null | undefined> | null | undefined;
        };
      } | null | undefined;
    };
  };
};
export type discountCodeCreateRandom_CreateMutation = {
  response: discountCodeCreateRandom_CreateMutation$data;
  variables: discountCodeCreateRandom_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numUsages",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "discountCodeCreateRandom_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateRandomDiscountCodesPayload",
                "kind": "LinkedField",
                "name": "createRandomDiscountCodes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Connection",
                    "kind": "LinkedField",
                    "name": "connection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodesV2Edge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DiscountCode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineDataFragmentSpread",
                                "name": "discountCodeTable_DiscountCodeFragment",
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "discountCodeDeleteButton_DiscountCodeFragment"
                                  },
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "discountCodeEditButton_DiscountCodeFragment"
                                  }
                                ],
                                "args": null,
                                "argumentDefinitions": []
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "discountCodeCreateRandom_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateRandomDiscountCodesPayload",
                "kind": "LinkedField",
                "name": "createRandomDiscountCodes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Connection",
                    "kind": "LinkedField",
                    "name": "connection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodesV2Edge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DiscountCode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "filters": null,
                        "handle": "prependEdge",
                        "key": "",
                        "kind": "LinkedHandle",
                        "name": "edges",
                        "handleArgs": [
                          {
                            "kind": "Variable",
                            "name": "connections",
                            "variableName": "connections"
                          }
                        ]
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "faafeb09c7d0eeeb4df007681ee13037",
    "id": null,
    "metadata": {},
    "name": "discountCodeCreateRandom_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation discountCodeCreateRandom_CreateMutation(\n  $input: CreateRandomDiscountCodesInput!\n) {\n  Admin {\n    Billing {\n      createRandomDiscountCodes(input: $input) {\n        connection {\n          edges {\n            node {\n              ...discountCodeTable_DiscountCodeFragment\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment discountCodeDeleteButton_DiscountCodeFragment on DiscountCode {\n  id\n}\n\nfragment discountCodeEditButton_DiscountCodeFragment on DiscountCode {\n  id\n}\n\nfragment discountCodeTable_DiscountCodeFragment on DiscountCode {\n  id\n  code\n  createdAt\n  numUsages\n  ...discountCodeDeleteButton_DiscountCodeFragment\n  ...discountCodeEditButton_DiscountCodeFragment\n}\n"
  }
};
})();

(node as any).hash = "50f0b4c028e4c70cdf06b9f7937034f6";

export default node;
