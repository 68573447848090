import { graphql } from "react-relay";

export const ORDER_FRAGMENT = graphql`
	fragment paymentDataForm_OrderFragment on Order {
		allowedPaymentMethods {
			paymentMethods
		}
		selectedPaymentMethod {
			kind
			paymentMethodType
		}
	}
`;
