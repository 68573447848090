/**
 * @generated SignedSource<<77b83ca9f0603cae73b2c4f37261e1fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPodcastWithTimestampInput = {
  clientMutationId?: string | null | undefined;
  elementId: string;
  newPodcastFileId: string;
  newTimestampsWithImage: ReadonlyArray<TimestampWithImageInput>;
  newTitle: string;
};
export type TimestampWithImageInput = {
  end: number;
  imageFileId?: string | null | undefined;
  start: number;
};
export type PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation$variables = {
  input: EditPodcastWithTimestampInput;
};
export type PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editPodcastWithTimestampElement: {
        readonly podcastWithTimestampElement: {
          readonly " $fragmentSpreads": FragmentRefs<"PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment">;
        };
      } | null | undefined;
    };
  };
};
export type PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation = {
  response: PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation$data;
  variables: PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditPodcastWithTimestampPayload",
                "kind": "LinkedField",
                "name": "editPodcastWithTimestampElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PodcastWithTimestamp",
                    "kind": "LinkedField",
                    "name": "podcastWithTimestampElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditPodcastWithTimestampPayload",
                "kind": "LinkedField",
                "name": "editPodcastWithTimestampElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PodcastWithTimestamp",
                    "kind": "LinkedField",
                    "name": "podcastWithTimestampElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "podcastFile",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TimestampWithImage",
                        "kind": "LinkedField",
                        "name": "timestampsWithImage",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "start",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "end",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "imageFile",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fc3cdf513952b859e65431a06eec8858",
    "id": null,
    "metadata": {},
    "name": "PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation",
    "operationKind": "mutation",
    "text": "mutation PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation(\n  $input: EditPodcastWithTimestampInput!\n) {\n  Admin {\n    ElearningV2 {\n      editPodcastWithTimestampElement(input: $input) {\n        podcastWithTimestampElement {\n          ...PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment on PodcastWithTimestamp {\n  id\n  title\n  podcastFile {\n    id\n    name\n  }\n  timestampsWithImage {\n    start\n    end\n    imageFile {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "287c95907580e37ecd022ea25ea1ec56";

export default node;
