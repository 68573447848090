import { EpicIcons } from "@constellation-academy/epic-ui";
import * as Sentry from "@sentry/react";
import { memo, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { Button, ButtonVariant } from "@components/button";
import { ConfirmDialog } from "@components/confirm-dialog";
import type { resetLearnProgressButton_LearnOpportunityV2Fragment$key } from "@relay/resetLearnProgressButton_LearnOpportunityV2Fragment.graphql";
import type { resetLearnProgressButton_ResetLearnProgressMutation } from "@relay/resetLearnProgressButton_ResetLearnProgressMutation.graphql";
import {
	LEARN_OPPORTUNITY_V2_FRAGMENT,
	RESET_LEARN_PROGRESS_MUTATION,
} from "./reset-learn-progress-button.graphql";
import type { ResetLearnProgressButtonProps } from "./reset-learn-progress-button.types";

const ResetLearnProgressButtonComponent = ({
	learnOpportunityV2FragmentRef,
	userId,
	onCompleted,
	onError,
}: ResetLearnProgressButtonProps) => {
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);

	const rootNode = useFragment<resetLearnProgressButton_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef ?? null,
	);

	const [resetLearnProgress, isResettingLearnProgress] =
		useMutation<resetLearnProgressButton_ResetLearnProgressMutation>(
			RESET_LEARN_PROGRESS_MUTATION,
		);

	const handleResetLearnProgressOnClick = () => {
		setShowConfirmDialog(true);
	};

	const handleResetLearnProgressOnCancel = () => {
		setShowConfirmDialog(false);
	};

	const handleResetLearnProgressOnConfirm = () => {
		if (!rootNode) {
			toast.error("Fehler beim Zurücksetzen des Lernfortschritts! RootNode nicht gefunden.");
			Sentry.captureException(
				new Error(
					"resetLearnProgressButton_ResetLearnProgressMutation: RootNode not found",
				),
				{
					extra: {
						userId,
						rootNode,
					},
				},
			);
			return;
		}

		resetLearnProgress({
			variables: {
				input: { rootId: rootNode.id, userId },
			},
			onCompleted: () => {
				toast.success("Lernfortschritt erfolgreich zurückgesetzt!");
				onCompleted?.();
			},
			onError: () => {
				toast.error("Fehler beim Zurücksetzen des Lernfortschritts!");
				onError?.();
			},
		});
	};

	return (
		<>
			<Button
				label="Lernfortschritt zurücksetzen"
				icon={EpicIcons.BACKWARD}
				variant={ButtonVariant.Error}
				onClick={handleResetLearnProgressOnClick}
				disabled={isResettingLearnProgress}
				loading={isResettingLearnProgress}
			/>
			<ConfirmDialog
				title="Lernfortschritt zurücksetzen"
				content={
					<div>
						Möchtest du den Lernfortschritt für den Kurs "
						{rootNode?.structureDefinition.title ?? "Unbekannter Kurs"}" wirklich
						zurücksetzen?
						<br />
						Wenn du diese Aktion ausführst, wird der Fortschritt für den Kurs
						zurückgesetzt und alle Lektionen werden zurückgesetzt auf nicht bearbeitet
						gesetzt.
						<br />
						<strong>Der Lernfortschritt kann nicht wiederhergestellt werden!</strong>
					</div>
				}
				confirmButtonText="Ja, Lernfortschritt wirklich zurücksetzen"
				onConfirm={handleResetLearnProgressOnConfirm}
				onCancel={handleResetLearnProgressOnCancel}
				showDialog={showConfirmDialog}
			/>
		</>
	);
};

export const ResetLearnProgressButton = memo(ResetLearnProgressButtonComponent);
