/**
 * @generated SignedSource<<884c793e52b7b4892bee28addf69c845>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TreeUnlockedStateKind = "UnlockedByAdmin" | "UnlockedByLicense";
import { FragmentRefs } from "relay-runtime";
export type treeUnlockedStateDisplay_TreeUnlockedStateFragment$data = {
  readonly data: {
    readonly kind: TreeUnlockedStateKind;
    readonly license?: {
      readonly creationInfo: {
        readonly licenseDefinition: {
          readonly data: {
            readonly name: string;
          };
          readonly id: string;
        } | null | undefined;
      };
      readonly id: string;
    };
    readonly unlockedByUser?: {
      readonly id: string;
      readonly name: string;
    };
  };
  readonly when: string;
  readonly " $fragmentType": "treeUnlockedStateDisplay_TreeUnlockedStateFragment";
};
export type treeUnlockedStateDisplay_TreeUnlockedStateFragment$key = {
  readonly " $data"?: treeUnlockedStateDisplay_TreeUnlockedStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"treeUnlockedStateDisplay_TreeUnlockedStateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "treeUnlockedStateDisplay_TreeUnlockedStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "when",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "unlockedByUser",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "UnlockedByAdmin",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "License",
              "kind": "LinkedField",
              "name": "license",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "creationInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LicenseDefinition",
                      "kind": "LinkedField",
                      "name": "licenseDefinition",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "data",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "UnlockedByLicense",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeUnlockedState",
  "abstractKey": null
};
})();

(node as any).hash = "0522eb809c5d1571d22f34ba13e8112f";

export default node;
