/**
 * @generated SignedSource<<a5d212e96bf1f64157289acf11ba535a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type OrderStatusType = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient";
export type PaymentMethodType = "Card" | "Giropay" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "Klarna" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort";
export type SelectedPaymentMethodKind = "Monthly" | "OneTime";
import { FragmentRefs } from "relay-runtime";
export type ordersTable_OrderFragment$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly selectedPaymentMethod: {
    readonly kind: SelectedPaymentMethodKind;
    readonly paymentMethodType: PaymentMethodType;
  } | null | undefined;
  readonly status: OrderStatusType;
  readonly " $fragmentSpreads": FragmentRefs<"orderPriceDisplay_OrderFragment" | "paymentProviderLink_OrderFragment">;
  readonly " $fragmentType": "ordersTable_OrderFragment";
};
export type ordersTable_OrderFragment$key = {
  readonly " $data"?: ordersTable_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ordersTable_OrderFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ordersTable_OrderFragment"
};

(node as any).hash = "e942df79bc25dbe893003be5e11c7709";

export default node;
