/**
 * @generated SignedSource<<826e498ef7de5413dea82ce0c535f7a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createEmailTemplateButton_AvailableTemplatesFragment$data = {
  readonly Admin: {
    readonly Email: {
      readonly AvailableSystemTemplates: ReadonlyArray<{
        readonly body: string;
        readonly key: string;
        readonly previewText: string;
        readonly subject: string;
        readonly variables: ReadonlyArray<string>;
      }>;
    };
  };
  readonly " $fragmentType": "createEmailTemplateButton_AvailableTemplatesFragment";
};
export type createEmailTemplateButton_AvailableTemplatesFragment$key = {
  readonly " $data"?: createEmailTemplateButton_AvailableTemplatesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createEmailTemplateButton_AvailableTemplatesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createEmailTemplateButton_AvailableTemplatesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailAdminSchema",
          "kind": "LinkedField",
          "name": "Email",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailTemplate",
              "kind": "LinkedField",
              "name": "AvailableSystemTemplates",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "previewText",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subject",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "body",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "variables",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8494f9dd00cb30e03c520d488b71e86a";

export default node;
