/**
 * @generated SignedSource<<c44a30eebbd69f029a698b974579a814>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type educationalOffer_TreeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"educationalOfferTree_TreeFragment">;
  readonly " $fragmentType": "educationalOffer_TreeFragment";
};
export type educationalOffer_TreeFragment$key = {
  readonly " $data"?: educationalOffer_TreeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"educationalOffer_TreeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "educationalOffer_TreeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "educationalOfferTree_TreeFragment"
    }
  ],
  "type": "Tree",
  "abstractKey": null
};

(node as any).hash = "d2938c904895d219c26124b118c82cfa";

export default node;
