import { graphql } from "react-relay";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment unlockTreeButton_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		structureDefinition {
			title
		}
	}
`;

export const UNLOCK_TREE_BY_ADMIN_MUTATION = graphql`
	mutation unlockTreeButton_UnlockTreeByAdminMutation($input: UnlockTreeByAdminInput!) {
		Admin {
			Tree {
				unlockTreeByAdmin(input: $input) {
					publishedNode {
						...userRootNodeItem_LearnOpportunityV2Fragment
					}
					treeState {
						...userRootNodeItem_TreeStateFragment
					}
				}
			}
		}
	}
`;
