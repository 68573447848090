import { EpicIcons } from "@constellation-academy/epic-ui";
import moment from "moment/moment";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariant } from "@components/button";
import { editMessageDispatchButton_MessageDispatchFragment$key } from "@relay/editMessageDispatchButton_MessageDispatchFragment.graphql";
import { Paths } from "@routes/paths";

import { MESSAGE_DISPATCH_FRAGMENT } from "@screens/message-dispatches/edit-message-dispatch-button/edit-message-dispatch-button.graphql";
import { buttonWrapperClass } from "@screens/message-dispatches/edit-message-dispatch-button/edit-message-dispatch-button.styles";
import { EditMessageDispatchButtonProps } from "@screens/message-dispatches/edit-message-dispatch-button/edit-message-dispatch-button.types";

export const EditMessageDispatchButton = ({
	messageDispatchFragmentRef,
}: EditMessageDispatchButtonProps) => {
	const navigate = useNavigate();

	const messageDispatch = useFragment<editMessageDispatchButton_MessageDispatchFragment$key>(
		MESSAGE_DISPATCH_FRAGMENT,
		messageDispatchFragmentRef ?? null,
	);

	const handleOnEditClick = () => {
		navigate(Paths.messagesDispatches.withId(messageDispatch.id).edit.path);
	};

	const messageAlreadySent = moment(messageDispatch.dispatchedAt?.replace("[UTC]", "")).isBefore(
		moment(),
	);

	return (
		<div className={buttonWrapperClass}>
			<Button
				disabled={messageAlreadySent}
				icon={EpicIcons.PENCIL}
				variant={ButtonVariant.Strong}
				onClick={handleOnEditClick}
			/>
		</div>
	);
};
