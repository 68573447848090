import { graphql } from "react-relay";

export const CREATE_ROOT_TAG_MUTATION = graphql`
	mutation createAcademiesTagButton_CreateRootTagMutation(
		$input: CreateRootTagInput!
		$connections: [ID!]!
	) {
		Admin {
			AcademiesTag {
				createRootTag(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...academiesTagsTable_AcademiesTagFragment
						}
					}
				}
			}
		}
	}
`;
