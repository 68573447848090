/**
 * @generated SignedSource<<6a1cbe9584da99dd5399a835ed1dad03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type RewardKind = "CrmTreeHeadUpdater" | "ExternalLicense" | "GamificationPoints" | "IHKCertificate" | "LearnStreak";
import { FragmentRefs } from "relay-runtime";
export type rewardForm_RewardFragment$data = {
  readonly id: string;
  readonly kind: RewardKind;
  readonly " $fragmentSpreads": FragmentRefs<"externalLicenseRewardForm_ExternalLicenseRewardFragment">;
  readonly " $fragmentType": "rewardForm_RewardFragment";
};
export type rewardForm_RewardFragment$key = {
  readonly " $data"?: rewardForm_RewardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"rewardForm_RewardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "rewardForm_RewardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "externalLicenseRewardForm_ExternalLicenseRewardFragment"
        }
      ],
      "type": "ExternalLicenseReward",
      "abstractKey": null
    }
  ],
  "type": "Reward",
  "abstractKey": "__isReward"
};

(node as any).hash = "19bbdd8143e4e788014ba3ac3c3795d8";

export default node;
