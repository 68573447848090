import { graphql } from "react-relay";

export const UPLOAD_ASYNC_ELEMENT_FRAGMENT = graphql`
	fragment uploadAsyncElementForm_UploadAsyncElementFragment on UploadAsyncElement {
		id
		title
		taskDescription
		assignedEvaluator {
			id
		}
	}
`;

export const EDIT_UPLOAD_ASYNC_ELEMENT_MUTATION = graphql`
	mutation uploadAsyncElementForm_EditUploadAsyncElementMutation(
		$input: EditUploadAsyncElementInput!
	) {
		Admin {
			AsyncContent {
				editUploadAsyncElement(input: $input) {
					uploadAsyncElement {
						...uploadAsyncElementForm_UploadAsyncElementFragment
					}
				}
			}
		}
	}
`;
