import { graphql } from "react-relay";

export const REMOVE_COLUMN_FROM_MATRIX_ELEMENT_MUTATION = graphql`
	mutation removeColumnFromMatrixElementButton_RemoveColumnFromMatrixElementMutation(
		$input: RemoveColumnFromMatrixElementInput!
	) {
		Admin {
			ElearningV2 {
				removeColumnFromMatrixElement(input: $input) {
					matrixElement {
						...matrixElementForm_MatrixElementFragment
					}
				}
			}
		}
	}
`;
