import { Badge } from "primereact/badge";
import { type SeverityType } from "./environment-badge.types";
export const EnvironmentBadge = () => {
	let severity: SeverityType;
	let labelText: string = "";

	switch (process.env.REACT_APP_APP_ENVIRONMENT) {
		case "production":
			severity = "danger";
			labelText = "ECHTSYSTEM";
			break;
		case "staging":
			severity = "warning";
			labelText = "TESTSYSTEM";
			break;
		default:
			severity = "success";
			labelText = "LOKAL";
			break;
	}

	return <Badge severity={severity} value={labelText} />;
};
