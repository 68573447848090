import { EpicIcons } from "@constellation-academy/epic-ui";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/button";
import { Paths } from "@routes/paths";

type OwnProps = {
	productId: string;
};

export const EditProductButton = ({ productId }: OwnProps) => {
	const navigate = useNavigate();

	return (
		<Button
			icon={EpicIcons.PENCIL}
			tooltip="Produkt editieren"
			onClick={() => {
				navigate(Paths.products.withId(productId).edit.path);
			}}
		/>
	);
};
