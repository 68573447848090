import { graphql, useFragment, useMutation } from "react-relay";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { OrderList } from "primereact/orderlist";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
	type OrderElementFormProps,
	type OrderElementFormState,
	type OrderItem,
} from "./order-element-form.interface";
import { type OrderElementForm_EditOrderElementMutation } from "../../../../__generated__/OrderElementForm_EditOrderElementMutation.graphql";
import { type OrderElementForm_MoveOrderItemMutation } from "../../../../__generated__/OrderElementForm_MoveOrderItemMutation.graphql";
import { type OrderElementForm_OrderElementFragment$key } from "../../../../__generated__/OrderElementForm_OrderElementFragment.graphql";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";
import { AddOrderItemButton } from "../add-order-item-button";
import { OrderItemForm } from "../order-item-form";

const ORDER_ELEMENT_FRAGMENT = graphql`
	fragment OrderElementForm_OrderElementFragment on OrderElement {
		id
		title
		answeringTypeText
		orderItems {
			id
			...OrderItemForm_OrderItemFragment
		}
	}
`;

const EDIT_ORDER_MUTATION = graphql`
	mutation OrderElementForm_EditOrderElementMutation($input: EditOrderElementInput!) {
		Admin {
			ElearningV2 {
				editOrderElement(input: $input) {
					orderElement {
						...OrderElementForm_OrderElementFragment
					}
				}
			}
		}
	}
`;

const MOVE_ORDER_ITEM_MUTATION = graphql`
	mutation OrderElementForm_MoveOrderItemMutation($input: MoveOrderItemsInput!) {
		Admin {
			ElearningV2 {
				moveOrderItems(input: $input) {
					orderElement {
						...OrderElementForm_OrderElementFragment
					}
				}
			}
		}
	}
`;

export const OrderElementForm = ({ orderElementFragmentRef, onBack }: OrderElementFormProps) => {
	const orderElement = useFragment<OrderElementForm_OrderElementFragment$key>(
		ORDER_ELEMENT_FRAGMENT,
		orderElementFragmentRef,
	);

	const [editOrderElement, isEdittingOrderElement] =
		useMutation<OrderElementForm_EditOrderElementMutation>(EDIT_ORDER_MUTATION);

	const [moveOrderItem] =
		useMutation<OrderElementForm_MoveOrderItemMutation>(MOVE_ORDER_ITEM_MUTATION);

	const dispatch = useTypedDispatch();
	const formId = "OrderElementForm";
	const formik = useFormik<OrderElementFormState>({
		initialValues: {
			title: orderElement.title,
			answeringTypeText: orderElement.answeringTypeText,
			orderItems: orderElement.orderItems
				? orderElement.orderItems.map((orderItem) => ({
						id: orderItem.id,
						orderItemFragment: orderItem,
				  }))
				: [],
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().min(0, "Der muss mindestens 1 Zeichen enthalten"),
		}),
		enableReinitialize: true,
		onSubmit: (values: OrderElementFormState, { setSubmitting }) => {
			editOrderElement({
				variables: {
					input: {
						orderElementId: orderElement.id,
						newTitle: values.title,
						newAnsweringTypeTextOpt: values.answeringTypeText,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
					setSubmitting(false);
				},
				onError: () => {
					toast.error("Fehler beim Speichern");
					setSubmitting(false);
				},
			});
		},
	});

	// TODO Ron: noch etwas schoener machen
	return (
		<div className="m-4">
			<Card className="mb-4">
				<form onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<OrderElementFormState, string>
						name={"title"}
						label={"Titel"}
						className="mr-2 flex-grow-1 w-auto"
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>
					<ValidatedField<OrderElementFormState, string>
						name={"answeringTypeText"}
						label={"Aufgabenstellung"}
						className="mr-2 flex-grow-1 w-auto"
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>
					<Button
						disabled={
							Object.entries(formik.touched).length === 0 || isEdittingOrderElement
						}
						type="submit"
						label="Speichern"
						className="w-auto p-button-primary p-2 h-2.5rem mb-3"
					/>
					<ValidatedField<OrderElementFormState, OrderItem[]>
						name={"orderItems"}
						formikConfig={formik}
						component={({ fieldName, fieldValue }) => (
							<div>
								<AddOrderItemButton orderElementId={orderElement.id} />
								<OrderList
									id={fieldName}
									value={fieldValue}
									onChange={(e) => {
										moveOrderItem({
											variables: {
												input: {
													orderElementId: orderElement.id,
													orderItemIds: e.value.map(
														(orderItem: OrderItem) => orderItem.id,
													),
												},
											},
										});
									}}
									itemTemplate={(item: OrderItem) => {
										return (
											<OrderItemForm
												orderElementId={orderElement.id}
												orderItemFragmentRef={item.orderItemFragment}
											/>
										);
									}}
								/>
							</div>
						)}
					/>
					<div className="p-fluid">
						<Button
							type="button"
							disabled={!formik.isValid}
							onClick={() => {
								onBack();
							}}
							label="Zurück"
							className="p-button-secondary mt-2"
						/>
					</div>
				</form>
			</Card>
		</div>
	);
};
