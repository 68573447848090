/**
 * @generated SignedSource<<80a71569b902ba3ccd1188bb450c305e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectMultipleRootNodesForm_PublishedRootQuery$variables = Record<PropertyKey, never>;
export type selectMultipleRootNodesForm_PublishedRootQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectMultipleRootNodesField_QueryFragment">;
};
export type selectMultipleRootNodesForm_PublishedRootQuery = {
  response: selectMultipleRootNodesForm_PublishedRootQuery$data;
  variables: selectMultipleRootNodesForm_PublishedRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectMultipleRootNodesForm_PublishedRootQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "selectMultipleRootNodesField_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "selectMultipleRootNodesForm_PublishedRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TreeNodesConnection",
                "kind": "LinkedField",
                "name": "GetRootNodes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNodesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TreeNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "structureDefinition",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": [
                  "filterByTitleOrDescriptionOrShortDescription"
                ],
                "handle": "connection",
                "key": "selectMultipleRootNodesField_GetRootNodes",
                "kind": "LinkedHandle",
                "name": "GetRootNodes"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b28f748b51ff6cc527d0a8a06bd7097",
    "id": null,
    "metadata": {},
    "name": "selectMultipleRootNodesForm_PublishedRootQuery",
    "operationKind": "query",
    "text": "query selectMultipleRootNodesForm_PublishedRootQuery {\n  ...selectMultipleRootNodesField_QueryFragment\n}\n\nfragment selectMultipleRootNodesField_PublishedRootFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    title\n  }\n}\n\nfragment selectMultipleRootNodesField_QueryFragment on Query {\n  Admin {\n    Tree {\n      GetRootNodes {\n        edges {\n          node {\n            ...selectMultipleRootNodesField_PublishedRootFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "44986d87c0f197ce214a593ef443a8fb";

export default node;
