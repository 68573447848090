/**
 * @generated SignedSource<<e40d17d0d3b4620f0aebf9412ec49119>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type videoSourceForm_Vimeo_VimeoVideoSourceFragment$data = {
  readonly name: string;
  readonly " $fragmentType": "videoSourceForm_Vimeo_VimeoVideoSourceFragment";
};
export type videoSourceForm_Vimeo_VimeoVideoSourceFragment$key = {
  readonly " $data"?: videoSourceForm_Vimeo_VimeoVideoSourceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"videoSourceForm_Vimeo_VimeoVideoSourceFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "videoSourceForm_Vimeo_VimeoVideoSourceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Vimeo_VimeoVideoSource",
  "abstractKey": null
};

(node as any).hash = "1906679ed1507434f9e1692e1341300f";

export default node;
