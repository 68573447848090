import { EpicIcons } from "@constellation-academy/epic-ui";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariant } from "@components/button";
import { Paths } from "@routes/paths";

import { buttonWrapperClass } from "@screens/message-definitions/parts/message-definitions-table/edit-message-definition-button/edit-message-definition-button.styles";
import { EditMessageDefinitionButtonProps } from "@screens/message-definitions/parts/message-definitions-table/edit-message-definition-button/edit-message-definition-button.types";

export const EditMessageDefinitionButton = ({
	messageDefinitionId,
}: EditMessageDefinitionButtonProps) => {
	const navigate = useNavigate();
	const handleOnEditClick = () => {
		navigate(Paths.messageDefinitions.withId(messageDefinitionId).edit.path);
	};

	return (
		<div className={buttonWrapperClass}>
			<Button
				icon={EpicIcons.PENCIL}
				variant={ButtonVariant.Strong}
				onClick={handleOnEditClick}
			/>
		</div>
	);
};
