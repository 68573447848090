/**
 * @generated SignedSource<<75b0a78e715d051e8b2cf9690ec75b30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type orderHistoryTable_OrderHistoriesFragment$data = {
  readonly node: {
    readonly history?: {
      readonly " $fragmentSpreads": FragmentRefs<"orderHistoryTable_OrderHistoryFragment">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "orderHistoryTable_OrderHistoriesFragment";
};
export type orderHistoryTable_OrderHistoriesFragment$key = {
  readonly " $data"?: orderHistoryTable_OrderHistoriesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"orderHistoryTable_OrderHistoriesFragment">;
};

import OrderHistoryTable_Refetch_graphql from './OrderHistoryTable_Refetch.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": OrderHistoryTable_Refetch_graphql
    }
  },
  "name": "orderHistoryTable_OrderHistoriesFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderHistory",
              "kind": "LinkedField",
              "name": "history",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "orderHistoryTable_OrderHistoryFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "event",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "kind",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "orderHistoryEvent_OrderHistoryFragment"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Order",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2a9b474bfd102eee93c097343b92e226";

export default node;
