import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";

import { RichTextEditorToolbar } from "@components/rich-text-editor/rich-text-editor.const";
import { textElementForm_EditTextElementMutation } from "@relay/textElementForm_EditTextElementMutation.graphql";
import { textElementForm_TextElementFragment$key } from "@relay/textElementForm_TextElementFragment.graphql";
import {
	EDIT_TEXT_ELEMENT_MUTATION,
	TEXT_ELEMENT_FRAGMENT,
} from "@screens/educational-offer/parts/text-element-form/text-element-form.graphql";
import {
	TextElementFormProps,
	TextElementFormState,
} from "@screens/educational-offer/parts/text-element-form/text-element-form.types";
import { MemoizedRichTextEditor } from "../../../../components/rich-text-editor";
import { ValidatedField } from "../../../../components/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";
import { stripHtml } from "../../../../util/html.utils";

export const TextElementForm = ({ textElementFragmentRef, onBack }: TextElementFormProps) => {
	const element = useFragment<textElementForm_TextElementFragment$key>(
		TEXT_ELEMENT_FRAGMENT,
		textElementFragmentRef,
	);
	const [editTextElement, isEditingTextElement] =
		useMutation<textElementForm_EditTextElementMutation>(EDIT_TEXT_ELEMENT_MUTATION);

	const dispatch = useTypedDispatch();
	const formId = "TextElementForm";
	const formik = useFormik<TextElementFormState>({
		enableReinitialize: false,
		initialValues: {
			title: stripHtml(element.title),
			text: element.text,
			illustration: undefined,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Titel wird benötigt."),
			text: Yup.string().required("Das Feld Text wird benötigt."),
		}),
		onSubmit: (values) => {
			editTextElement({
				variables: {
					input: {
						textElementId: element.id,
						title: values.title,
						text: values.text,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	const handleFormEdited = useCallback(() => {
		dispatch(addEditedFormToEditedFormsArray({ form: formId }));
	}, [dispatch]);

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<TextElementFormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>

			<ValidatedField<TextElementFormState, string>
				name={"text"}
				label={"Text"}
				onChange={handleFormEdited}
				component={(props) => (
					<MemoizedRichTextEditor
						{...props}
						height={200}
						toolbar={RichTextEditorToolbar.WithLists}
					/>
				)}
				formikConfig={formik}
			/>
			<Button
				disabled={isEditingTextElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};
