import { type PathParams } from "@constellation-academy/epic-ui";
import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type limitedCartDiscountEditScreen_LimitedCartDiscountQuery } from "@relay/limitedCartDiscountEditScreen_LimitedCartDiscountQuery.graphql";
import { type LimitedCartDiscountsPath } from "@screens/limited-cart-discounts";
import { QUERY } from "./limited-cart-discount-edit.graphql";
import { LimitedCartDiscountForm } from "./parts/limited-cart-discount-form";
import { Card } from "../../components/card";
import { NoAccess } from "../../components/no-access";

export const EditLimitedCartDiscountScreen = () => {
	const { cartDiscountId } = useParams<PathParams<typeof LimitedCartDiscountsPath>>();

	const query = useLazyLoadQuery<limitedCartDiscountEditScreen_LimitedCartDiscountQuery>(QUERY, {
		id: cartDiscountId!,
		skip: !cartDiscountId,
	});

	const canReadAndModify = useHasPermissions([
		"UserInAccountPermission_LimitedCartDiscountAdmin_Read",
		"UserInAccountPermission_LimitedCartDiscountAdmin_Modify",
	]);

	if (!canReadAndModify) {
		return <NoAccess />;
	}

	return query.node ? (
		<Suspense fallback={<ProgressSpinner />}>
			<div className="p-4">
				<Card>
					<h2 className="mb-3 text-center">Warenkorb-Rabatt: {query.node.title}</h2>
					<LimitedCartDiscountForm limitedCartDiscountFragmentRef={query.node} />
				</Card>
			</div>
		</Suspense>
	) : (
		<h4>Fehler beim Laden des Warenkorb-Rabatts</h4>
	);
};
