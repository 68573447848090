import { graphql, fetchQuery, useRelayEnvironment } from "react-relay";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { type InstructorsMultiSelect_Query } from "../../__generated__/InstructorsMultiSelect_Query.graphql";
import { type RenderConfig } from "../../components/ValidatedField";

const QUERY = graphql`
	query InstructorsMultiSelect_Query($first: Int, $after: String) {
		Admin {
			Instructor {
				InstructorImpls(first: $first, after: $after)
					@connection(key: "InstructorsTable_InstructorImpls") {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							superId
							... on Instructor {
								superId
								name
							}
						}
					}
				}
			}
		}
	}
`;

type InstructorProps = {
	superId?: string;
	name?: string | null;
};

export const InstructorsMultiSelect = ({
	fieldValue,
	updateField,
	onChange,
}: RenderConfig<string[]>) => {
	const environment = useRelayEnvironment();

	const [instructors, setInstructors] = useState<InstructorProps[]>([]);

	useEffect(() => {
		fetchQuery<InstructorsMultiSelect_Query>(environment, QUERY, {})
			.toPromise()
			.then((result) => {
				setInstructors(() =>
					result!.Admin.Instructor.InstructorImpls.edges!.map((e: any) => e!.node!),
				);
			});

		// eslint-disable-next-line
	}, []);

	const instructorTemplate = (option: any) => {
		const instructor = instructors?.find((instructor) => instructor.superId === option.value);

		return (
			<StyledTag className="mr-2 pl-3 pr-3 text-xxs mb-2" value={instructor?.name} rounded />
		);
	};

	return (
		<MultiSelect
			className="m-1"
			value={fieldValue}
			options={instructors.map((p) => ({ label: p.name, value: p.superId }))}
			onChange={(e) => {
				updateField(e.value);
				if (onChange) {
					onChange();
				}
			}}
			filter
			showClear
			showSelectAll={false}
			placeholder="Inhalte auswählen"
			itemTemplate={instructorTemplate}
		/>
	);
};

const StyledTag = styled(Tag)`
	.p-tag-value {
		font-size: 0.65rem;
	}
`;
