/**
 * @generated SignedSource<<118c67411ec4911dc6024b353c4ec715>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditReminderElementInput = {
  clientMutationId?: string | null | undefined;
  reminderElementId: string;
  text: string;
  title: string;
};
export type reminderElementForm_EditReminderElementMutation$variables = {
  input: EditReminderElementInput;
};
export type reminderElementForm_EditReminderElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editReminderElement: {
        readonly reminderElement: {
          readonly " $fragmentSpreads": FragmentRefs<"reminderElementForm_ReminderElementFragment">;
        };
      } | null | undefined;
    };
  };
};
export type reminderElementForm_EditReminderElementMutation = {
  response: reminderElementForm_EditReminderElementMutation$data;
  variables: reminderElementForm_EditReminderElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reminderElementForm_EditReminderElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditReminderElementPayload",
                "kind": "LinkedField",
                "name": "editReminderElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReminderElement",
                    "kind": "LinkedField",
                    "name": "reminderElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "reminderElementForm_ReminderElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reminderElementForm_EditReminderElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditReminderElementPayload",
                "kind": "LinkedField",
                "name": "editReminderElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReminderElement",
                    "kind": "LinkedField",
                    "name": "reminderElement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3fa50a921a7212a56bac4f04934c3afe",
    "id": null,
    "metadata": {},
    "name": "reminderElementForm_EditReminderElementMutation",
    "operationKind": "mutation",
    "text": "mutation reminderElementForm_EditReminderElementMutation(\n  $input: EditReminderElementInput!\n) {\n  Admin {\n    ElearningV2 {\n      editReminderElement(input: $input) {\n        reminderElement {\n          ...reminderElementForm_ReminderElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment reminderElementForm_ReminderElementFragment on ReminderElement {\n  id\n  title\n  text\n}\n"
  }
};
})();

(node as any).hash = "50ed0314366795fa16b7115965a3c2af";

export default node;
