/**
 * @generated SignedSource<<c7e96b05753fe6b22c648b7a7667d5ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment$data = {
  readonly correctAnswer: {
    readonly content: string;
  };
  readonly id: string;
  readonly wrongAnswers: ReadonlyArray<{
    readonly content: string;
    readonly id: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment">;
  readonly " $fragmentType": "editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment";
};
export type editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment$key = {
  readonly " $data"?: editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MatrixElementCellCorrectAnswer",
      "kind": "LinkedField",
      "name": "correctAnswer",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MatrixElementCellWrongAnswer",
      "kind": "LinkedField",
      "name": "wrongAnswers",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment"
    }
  ],
  "type": "AnswerMatrixElementCell",
  "abstractKey": null
};
})();

(node as any).hash = "ddfdd3cba021c94f7a5ab2e0236211ab";

export default node;
