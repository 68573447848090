/**
 * @generated SignedSource<<b17698f3cbc0654b7c936eb30c9d2621>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveRowFromMatrixElementInput = {
  clientMutationId?: string | null | undefined;
  idx: number;
  matrixElementId: string;
};
export type removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation$variables = {
  input: RemoveRowFromMatrixElementInput;
};
export type removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly removeRowFromMatrixElement: {
        readonly matrixElement: {
          readonly " $fragmentSpreads": FragmentRefs<"matrixElementForm_MatrixElementFragment">;
        };
      } | null | undefined;
    };
  };
};
export type removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation = {
  response: removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation$data;
  variables: removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveRowFromMatrixElementPayload",
                "kind": "LinkedField",
                "name": "removeRowFromMatrixElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MatrixElement",
                    "kind": "LinkedField",
                    "name": "matrixElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "matrixElementForm_MatrixElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveRowFromMatrixElementPayload",
                "kind": "LinkedField",
                "name": "removeRowFromMatrixElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MatrixElement",
                    "kind": "LinkedField",
                    "name": "matrixElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "cells",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "xIdx",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "yIdx",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v4/*: any*/),
                            "type": "DisplayMatrixElementCell",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MatrixElementCellCorrectAnswer",
                                "kind": "LinkedField",
                                "name": "correctAnswer",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MatrixElementCellWrongAnswer",
                                "kind": "LinkedField",
                                "name": "wrongAnswers",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "AnswerMatrixElementCell",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d57da60a8c89c7ae2d37c49ea1329e57",
    "id": null,
    "metadata": {},
    "name": "removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation",
    "operationKind": "mutation",
    "text": "mutation removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation(\n  $input: RemoveRowFromMatrixElementInput!\n) {\n  Admin {\n    ElearningV2 {\n      removeRowFromMatrixElement(input: $input) {\n        matrixElement {\n          ...matrixElementForm_MatrixElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment answerMatrixElementCellForm_AnswerMatrixElementCellFragment on AnswerMatrixElementCell {\n  xIdx\n  yIdx\n  correctAnswer {\n    content\n  }\n  wrongAnswers {\n    content\n    id\n  }\n  ...editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment\n}\n\nfragment displayMatrixElementCellForm_DisplayMatrixElementCellFragment on DisplayMatrixElementCell {\n  id\n  content\n  xIdx\n  yIdx\n}\n\nfragment editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment on AnswerMatrixElementCell {\n  id\n  correctAnswer {\n    content\n  }\n  wrongAnswers {\n    content\n    id\n  }\n  ...editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment\n}\n\nfragment editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment on AnswerMatrixElementCell {\n  id\n}\n\nfragment matrixElementForm_MatrixElementFragment on MatrixElement {\n  id\n  title\n  ...matrixFieldElement_MatrixElementFragment\n}\n\nfragment matrixFieldElement_MatrixElementFragment on MatrixElement {\n  id\n  cells {\n    __typename\n    id\n    kind\n    xIdx\n    yIdx\n    ... on DisplayMatrixElementCell {\n      ...displayMatrixElementCellForm_DisplayMatrixElementCellFragment\n    }\n    ... on AnswerMatrixElementCell {\n      ...answerMatrixElementCellForm_AnswerMatrixElementCellFragment\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b232238ada0cd6bd911380be1d230cae";

export default node;
