import { graphql } from "react-relay";

export const ORDER_HISTORIES_FRAGMENT = graphql`
	fragment orderHistoryTable_OrderHistoriesFragment on Query
	@refetchable(queryName: "OrderHistoryTable_Refetch") {
		node(id: $id) {
			... on Order {
				history {
					...orderHistoryTable_OrderHistoryFragment
				}
			}
		}
	}
`;

export const ORDER_HISTORY_FRAGMENT = graphql`
	fragment orderHistoryTable_OrderHistoryFragment on OrderHistory @inline {
		id
		createdAt
		status
		event {
			kind
			...orderHistoryEvent_OrderHistoryFragment
		}
	}
`;
