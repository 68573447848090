import { graphql, useFragment } from "react-relay";
import { Tooltip } from "primereact/tooltip";
import { Fragment } from "react";
import { type NodeConfigurationBadges_TreeNodeFragment$key } from "@relay/NodeConfigurationBadges_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment NodeConfigurationBadges_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			definitionType
		}
		typeDefinition {
			definitionType
			... on ContentTypeDefinition {
				rewards {
					kind
				}
			}
		}
		versioning {
			draftVersion
			releaseVersion
		}
	}
`;

type OwnProps = {
	treeNodeFragmentRef: NodeConfigurationBadges_TreeNodeFragment$key;
};

export const NodeConfigurationBadges = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<NodeConfigurationBadges_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const isUnreleased = treeNode.versioning.draftVersion - treeNode.versioning.releaseVersion > 0;

	if (!isUnreleased) {
		return null;
	}
	const tooltipId = `tooltip-${treeNode.id}`;

	return (
		<Fragment key={treeNode.id}>
			<span className="pi pi-exclamation-triangle text-yellow-300 pr-1" id={tooltipId} />
			<Tooltip target={`#${CSS.escape(tooltipId)}`} content="Noch nicht veröffentlicht" />
		</Fragment>
	);
};
