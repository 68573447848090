/**
 * @generated SignedSource<<78c5e09935ea14f9eec87fad05f77788>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Persolog_ClassType = "D" | "G" | "I" | "S";
import { FragmentRefs } from "relay-runtime";
export type persologElementQuestionForm_PersologElementFragment$data = {
  readonly classes: ReadonlyArray<{
    readonly classType: Persolog_ClassType;
    readonly title: string;
  }>;
  readonly " $fragmentType": "persologElementQuestionForm_PersologElementFragment";
};
export type persologElementQuestionForm_PersologElementFragment$key = {
  readonly " $data"?: persologElementQuestionForm_PersologElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"persologElementQuestionForm_PersologElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "persologElementQuestionForm_PersologElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Persolog_Class",
      "kind": "LinkedField",
      "name": "classes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "classType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Persolog_PersologElement",
  "abstractKey": null
};

(node as any).hash = "6fbee0ccc1461102e36591d90c29ea7f";

export default node;
