/**
 * @generated SignedSource<<5311d55371a86bdb99a4a7db6c79c226>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type productsScreen_Query$variables = {
  isHiddenOpt?: boolean | null | undefined;
  numProductsPerPage?: number | null | undefined;
  titleOpt?: string | null | undefined;
};
export type productsScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"productsTable_QueryFragment">;
};
export type productsScreen_Query = {
  response: productsScreen_Query$data;
  variables: productsScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isHiddenOpt"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numProductsPerPage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "titleOpt"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "numProductsPerPage"
},
v4 = {
  "kind": "Variable",
  "name": "isHiddenOpt",
  "variableName": "isHiddenOpt"
},
v5 = {
  "kind": "Variable",
  "name": "titleOpt",
  "variableName": "titleOpt"
},
v6 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "inKinds",
    "value": [
      "License"
    ]
  },
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "productsScreen_Query",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "productsTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "productsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "ProductsConnection",
                "kind": "LinkedField",
                "name": "SearchProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isHidden",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "netPrice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": [
                  "isHiddenOpt",
                  "titleOpt",
                  "inKinds"
                ],
                "handle": "connection",
                "key": "productsTable_SearchProducts",
                "kind": "LinkedHandle",
                "name": "SearchProducts"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6908a574bd7b22116e7ddcc215c32084",
    "id": null,
    "metadata": {},
    "name": "productsScreen_Query",
    "operationKind": "query",
    "text": "query productsScreen_Query(\n  $titleOpt: String\n  $isHiddenOpt: Boolean\n  $numProductsPerPage: Int\n) {\n  ...productsTable_QueryFragment_1rHEQi\n}\n\nfragment ProductActionColumn_ProductFragment on Product {\n  id\n}\n\nfragment ProductIdColumn_ProductFragment on Product {\n  id\n}\n\nfragment ProductIsHiddenColumn_ProductFragment on Product {\n  isHidden\n}\n\nfragment ProductTitleColumn_ProductFragment on Product {\n  title\n}\n\nfragment productPriceColumn_ProductFragment on Product {\n  netPrice\n}\n\nfragment productsTable_ProductFragment on Product {\n  ...ProductIdColumn_ProductFragment\n  ...ProductTitleColumn_ProductFragment\n  ...ProductIsHiddenColumn_ProductFragment\n  ...productPriceColumn_ProductFragment\n  ...ProductActionColumn_ProductFragment\n}\n\nfragment productsTable_QueryFragment_1rHEQi on Query {\n  Admin {\n    Billing {\n      SearchProducts(isHiddenOpt: $isHiddenOpt, titleOpt: $titleOpt, first: $numProductsPerPage, inKinds: [License]) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          cursor\n          node {\n            ...productsTable_ProductFragment\n            id\n            __typename\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ba4e6d1b5dfda989208832593635449f";

export default node;
