/**
 * @generated SignedSource<<6ff31dc37d96b5ad3935401b355d90e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AsyncElementKind = "Upload";
export type AsyncElementTaskStatus = "Evaluated" | "Fresh";
import { FragmentRefs } from "relay-runtime";
export type tasksTable_TasksFragment$data = {
  readonly Admin: {
    readonly AsyncContent: {
      readonly GetAsyncElementTasks: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly elementKind: AsyncElementKind;
            readonly extension: {
              readonly hierarchy?: {
                readonly asyncContentTitle: string;
                readonly branchTitle: string;
                readonly rootTitle: string;
              } | null | undefined;
            };
            readonly id: string;
            readonly status: AsyncElementTaskStatus;
            readonly submissionInfo: {
              readonly by: {
                readonly name: string;
              } | null | undefined;
            };
          };
        } | null | undefined> | null | undefined;
        readonly pageInfo: {
          readonly endCursor: string | null | undefined;
          readonly hasNextPage: boolean;
          readonly hasPreviousPage: boolean;
          readonly startCursor: string | null | undefined;
        };
      };
    };
  };
  readonly " $fragmentType": "tasksTable_TasksFragment";
};
export type tasksTable_TasksFragment$key = {
  readonly " $data"?: tasksTable_TasksFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"tasksTable_TasksFragment">;
};

import TasksTable_Refetch_graphql from './TasksTable_Refetch.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "AsyncContent",
  "GetAsyncElementTasks"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": TasksTable_Refetch_graphql
    }
  },
  "name": "tasksTable_TasksFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AsyncContentAdminSchema",
          "kind": "LinkedField",
          "name": "AsyncContent",
          "plural": false,
          "selections": [
            {
              "alias": "GetAsyncElementTasks",
              "args": null,
              "concreteType": "AsyncElementTasksConnection",
              "kind": "LinkedField",
              "name": "__TasksTable_GetAsyncElementTasks_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AsyncElementTasksEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "status",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "elementKind",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AsyncElementTaskSubmissionInfo",
                          "kind": "LinkedField",
                          "name": "submissionInfo",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "User",
                              "kind": "LinkedField",
                              "name": "by",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "name",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "extension",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "AsyncElementTaskHierarchy",
                                  "kind": "LinkedField",
                                  "name": "hierarchy",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "asyncContentTitle",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "branchTitle",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "rootTitle",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "AsyncElementTaskExtensionImpl",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a78948eb6d17aa0f4e94e46d51d8227b";

export default node;
