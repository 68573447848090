import { graphql } from "react-relay";

export const SEND_EMAIL_MUTATION = graphql`
	mutation sendEmailButton_SendEmailMutation($input: SendEmailInput!) {
		Admin {
			Email {
				sendEmail(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
