import { graphql } from "react-relay";

export const PERSOLOG_ELEMENT_FRAGMENT = graphql`
	fragment persologElementForm_PersologElementFragment on Persolog_PersologElement {
		id
		title
		classes {
			title
			text
			classType
			image {
				id
				name
			}
			...persologClassForm_PersologClassFragment
		}
		questions {
			id
		}
		...persologElementQuestionsEditor_QuestionsFragment
	}
`;

export const EDIT_PERSOLOG_ELEMENT_MUTATION = graphql`
	mutation persologElementForm_EditPersologElementMutation($input: EditPersologElementInput!) {
		Admin {
			ElearningV2 {
				editPersologElement(input: $input) {
					persologElement {
						...persologElementForm_PersologElementFragment
					}
				}
			}
		}
	}
`;
