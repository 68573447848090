import { graphql } from "react-relay";

export const TREE_NODE_FRAGMENT = graphql`
	fragment startContentConfigEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				startConfigs {
					id
					configType
				}
			}
			... on AsyncContentTypeDefinition {
				startConfigs {
					id
					configType
				}
			}
		}
	}
`;

export const REMOVE_START_CONTENT_CONFIGURATION_MUTATION = graphql`
	mutation startContentConfigEditor_RemoveStartContentConfigMutation(
		$input: RemoveStartContentConfigInput!
	) {
		Admin {
			Tree {
				removeStartContentConfig(input: $input) {
					contentNode {
						...startContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
export const ADD_CAN_AFTER_STARTING_TREE_START_CONTENT_CONFIG_MUTATION = graphql`
	mutation startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation(
		$input: AddCanAfterStartingTreeStartContentConfigInput!
	) {
		Admin {
			Tree {
				addCanAfterStartingTreeStartContentConfig(input: $input) {
					clientMutationId
					content {
						...startContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const ADD_CAN_IF_UNLOCKED_START_CONTENT_CONFIG_MUTATION = graphql`
	mutation startContentConfigEditor_addCanIfUnlockedStartContentConfigAdminMutation(
		$input: AddCanIfUnlockedStartContentConfigInput!
	) {
		Admin {
			Tree {
				addCanIfUnlockedStartContentConfig(input: $input) {
					clientMutationId
					content {
						...startContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
