import { cva } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const treeItemClass = cva({
	base: {
		display: "flex",
		flexDirection: "column",
		marginLeft: "12",
	},
	variants: {
		hasIndentation: {
			true: {
				marginLeft: "12",
			},
			false: {
				marginLeft: "0",
			},
		},
	},
	defaultVariants: {
		hasIndentation: true,
	},
});

export const treeItemTitleClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		gap: "8",
		paddingY: "4",
		paddingX: "8",
		borderRadius: "8",
	},
	variants: {
		isPaywallBarrier: {
			true: {
				border: "1px solid",
				borderColor: "warning.20",
				backgroundColor: "warning.0",
			},
		},
		isCurrentTreeState: {
			true: {
				border: "1px solid",
				borderColor: "success.20",
				backgroundColor: "success.0",
			},
		},
		isContentNode: {
			true: {
				marginLeft: "12",
			},
		},
	},
});

export const treeItemModificationsClass = flex({
	align: "center",
	width: "40",
});

export const setCurrentHeadContentButtonClass = flex({
	align: "center",
	justify: "center",
	backgroundColor: "info.0",
	border: "1px solid",
	borderColor: "info.20",
	paddingX: "8",
	paddingY: "2",
	borderRadius: "8",
});

export const assignPointsButtonClass = flex({
	align: "center",
	justify: "center",
	backgroundColor: "warning.20",
	border: "1px solid",
	borderColor: "warning.100/50",
	paddingX: "8",
	paddingY: "2",
	borderRadius: "8",
});
