/**
 * @generated SignedSource<<89de23b76d6cc477f84f9e8fcaf3adca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateDiscountActionInput = {
  clientMutationId?: string | null | undefined;
  title: string;
};
export type createPercentageDiscountActionButton_CreateDiscountActionMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateDiscountActionInput;
};
export type createPercentageDiscountActionButton_CreateDiscountActionMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly createDiscountAction: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"DiscountActionsTable_DiscountActionFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type createPercentageDiscountActionButton_CreateDiscountActionMutation = {
  response: createPercentageDiscountActionButton_CreateDiscountActionMutation$data;
  variables: createPercentageDiscountActionButton_CreateDiscountActionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createPercentageDiscountActionButton_CreateDiscountActionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateDiscountActionPayload",
                "kind": "LinkedField",
                "name": "createDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "DiscountActionsTable_DiscountActionFragment",
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionIdColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionTitleColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionTypeColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionCalcTypeColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionActionColumn_DiscountActionFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createPercentageDiscountActionButton_CreateDiscountActionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateDiscountActionPayload",
                "kind": "LinkedField",
                "name": "createDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "discountType",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v3/*: any*/),
                                "type": "SystemDiscountType",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v3/*: any*/),
                                "type": "UserDiscountType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percent",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9415d447ce970dceecdf68786f91bb09",
    "id": null,
    "metadata": {},
    "name": "createPercentageDiscountActionButton_CreateDiscountActionMutation",
    "operationKind": "mutation",
    "text": "mutation createPercentageDiscountActionButton_CreateDiscountActionMutation(\n  $input: CreateDiscountActionInput!\n) {\n  Admin {\n    Billing {\n      createDiscountAction(input: $input) {\n        edge {\n          node {\n            ...DiscountActionsTable_DiscountActionFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ActionActionColumn_DiscountActionFragment on DiscountAction {\n  id\n}\n\nfragment ActionCalcTypeColumn_DiscountActionFragment on DiscountAction {\n  percent\n}\n\nfragment ActionIdColumn_DiscountActionFragment on DiscountAction {\n  id\n}\n\nfragment ActionTitleColumn_DiscountActionFragment on DiscountAction {\n  title\n}\n\nfragment ActionTypeColumn_DiscountActionFragment on DiscountAction {\n  discountType {\n    __typename\n    ... on SystemDiscountType {\n      kind\n    }\n    ... on UserDiscountType {\n      kind\n    }\n  }\n}\n\nfragment DiscountActionsTable_DiscountActionFragment on DiscountAction {\n  ...ActionIdColumn_DiscountActionFragment\n  ...ActionTitleColumn_DiscountActionFragment\n  ...ActionTypeColumn_DiscountActionFragment\n  ...ActionCalcTypeColumn_DiscountActionFragment\n  ...ActionActionColumn_DiscountActionFragment\n}\n"
  }
};
})();

(node as any).hash = "17b6b580e5005a592e0128f8a6361129";

export default node;
