import { createContext, PropsWithChildren, useContext, useState, Dispatch } from "react";

export type IDiscountCodeTableContextContext = {
	connectionId: string;
	setConnectionId: Dispatch<string>;
};

export const DiscountCodeTableContext = createContext<IDiscountCodeTableContextContext>({
	connectionId: "",
	setConnectionId: () => {},
});

export const DiscountCodeTableContextProvider = ({ children }: PropsWithChildren) => {
	const [connectionId, setConnectionId] = useState("");

	const value: IDiscountCodeTableContextContext = {
		connectionId,
		setConnectionId,
	};
	return (
		<DiscountCodeTableContext.Provider value={value}>
			{children}
		</DiscountCodeTableContext.Provider>
	);
};

export const useDiscountCodeTableContext = () => useContext(DiscountCodeTableContext);
