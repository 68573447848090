/**
 * @generated SignedSource<<49a43244860e42b42c074e79672ba815>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Persolog_ClassType = "D" | "G" | "I" | "S";
import { FragmentRefs } from "relay-runtime";
export type persologElementQuestionClassForm_PersologQuestionClassFragment$data = {
  readonly classType: Persolog_ClassType;
  readonly text: string;
  readonly " $fragmentType": "persologElementQuestionClassForm_PersologQuestionClassFragment";
};
export type persologElementQuestionClassForm_PersologQuestionClassFragment$key = {
  readonly " $data"?: persologElementQuestionClassForm_PersologQuestionClassFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"persologElementQuestionClassForm_PersologQuestionClassFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "persologElementQuestionClassForm_PersologQuestionClassFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "classType",
      "storageKey": null
    }
  ],
  "type": "Persolog_QuestionClass",
  "abstractKey": null
};

(node as any).hash = "616ab73f8305c2280f66e0f16bb1c5da";

export default node;
