import { RelayPageDataTable } from "@constellation-academy/epic-ui";
import { useContext } from "react";
import { IdDisplayField } from "@components/id-display-field";
import type { levelDefinitionTable_LevelDefinitionInlineFragment$key } from "@relay/levelDefinitionTable_LevelDefinitionInlineFragment.graphql";
import type { levelDefinitionTable_QueryFragment$key } from "@relay/levelDefinitionTable_QueryFragment.graphql";
import type { levelDefinitionTable_Refetch } from "@relay/levelDefinitionTable_Refetch.graphql";
import { LevelDefinitionScreenContext } from "@screens/level-definition/level-definition.context";
import { QUERY_FRAGMENT, LEVEL_DEFINITION_INLINE_FRAGMENT } from "./level-definition-table.graphql";
import { LevelDefinitionTableProps } from "./level-definition-table.types";
import { EditLevelDefinitionButton } from "../edit-level-definition-button";

export const LevelDefinitionTable = ({ queryFragmentRef }: LevelDefinitionTableProps) => {
	const { setConnectionId } = useContext(LevelDefinitionScreenContext);

	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};

	return (
		<RelayPageDataTable<
			levelDefinitionTable_Refetch,
			levelDefinitionTable_QueryFragment$key,
			levelDefinitionTable_LevelDefinitionInlineFragment$key
		>
			queryFragment={QUERY_FRAGMENT}
			parentFragmentRef={queryFragmentRef}
			nodeInlineFragment={LEVEL_DEFINITION_INLINE_FRAGMENT}
			extractConnection={(data) => data.Admin.Gamification.LevelDefinitions}
			rowsCount={20}
			onConnectionIdChange={handleOnConnectionIdChange}
		>
			{(Column) => (
				<>
					<Column
						header="ID"
						style={{ width: "4%" }}
						body={(level) => <IdDisplayField id={level.id} />}
					/>
					<Column header="Name" body={(level) => level?.title ?? ""} />
					<Column
						header="Benötigte Schlüssel"
						body={(level) => level.requiredPoints ?? "0"}
					/>
					<Column
						header="Aktionen"
						style={{ width: "5%" }}
						body={(item) => (
							<EditLevelDefinitionButton levelDefinitionFragmentRef={item} />
						)}
					/>
				</>
			)}
		</RelayPageDataTable>
	);
};
