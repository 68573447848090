import { graphql } from "react-relay";

export const TREE_NODE_FRAGMENT = graphql`
	fragment setDirectFeedbackFlowConfigForm_TreeNodeFragment on TreeNode {
		id
	}
`;

export const EDIT_DIRECT_FEEDBACK_FLOW_E_LEARNING_CONTENT_CONFIG_MUTATION = graphql`
	mutation setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation(
		$input: EditDirectFeedbackFlowELearningContentConfigInput!
	) {
		Admin {
			Tree {
				editDirectFeedbackFlowELearningContentConfig(input: $input) {
					content {
						...flowAndCurrentElementContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const DIRECT_FEEDBACK_FLOW_E_LEARNING_CONTENT_CONFIG_FRAGMENT = graphql`
	fragment setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment on DirectFeedbackFlowELearningContentConfig {
		id
		configType
		minNumTriesTillShowAnswer
	}
`;
