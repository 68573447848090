import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { NoAccess } from "@components/no-access";
import { TasksTable } from "@features/tasks/tasks-table";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type tasksScreen_TasksQuery } from "@relay/tasksScreen_TasksQuery.graphql";
import { TASKS_QUERY } from "./tasks.graphql";

export const TasksScreen = () => {
	const data = useLazyLoadQuery<tasksScreen_TasksQuery>(
		TASKS_QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	const canRead = useHasPermissions(["UserInAccountPermission_AsyncElementTaskAdmin_Read"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen title="Aufgaben">
			<TasksTable tasksFragmentRef={data} />
		</BaseScreen>
	);
};
