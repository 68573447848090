import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { BaseScreen } from "@components/base-screen";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type externalLicensePoolEditScreen_ExternalLicensePoolQuery } from "@relay/externalLicensePoolEditScreen_ExternalLicensePoolQuery.graphql";
import { QUERY } from "./external-license-pool-edit.graphql";
import { EditExternalLicensePoolForm } from "./parts/edit-external-license-pool-form/edit-external-license-pool-form.component";
import { NoAccess } from "../../components/no-access";

export const ExternalLicensePoolEditScreen = () => {
	const { [`externalLicensePoolId`]: externalLicensePoolId } = useParams();

	const query = useLazyLoadQuery<externalLicensePoolEditScreen_ExternalLicensePoolQuery>(QUERY, {
		id: externalLicensePoolId!,
		skip: !externalLicensePoolId,
	});

	const canReadAndModify = useHasPermissions([
		"UserInAccountPermission_ExternalLicenseAdmin_Read",
		"UserInAccountPermission_ExternalLicenseAdmin_Modify",
	]);

	if (!canReadAndModify) {
		return <NoAccess />;
	}

	if (!query.node) {
		return (
			<h4 className="p-4">
				Fehler beim Laden der Pools
				<br />
				<code>Pool-ID: {externalLicensePoolId}</code>
			</h4>
		);
	}

	return (
		<BaseScreen title={`Externen Lizenz-Pool bearbeiten: ${query?.node.data?.name}`}>
			<Suspense fallback={<ProgressSpinner />}>
				<EditExternalLicensePoolForm entityRef={query?.node} />
			</Suspense>
		</BaseScreen>
	);
};
