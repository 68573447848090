/**
 * @generated SignedSource<<49b11a30d1cb7019a4e09de6a66d6a44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment";
};
export type NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment$key = {
  readonly " $data"?: NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "42b39386b9835ec8be6466930a552fe4";

export default node;
