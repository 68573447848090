import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment createLicensesButton_QueryFragment on Query {
		...createLicensesForm_QueryFragment
	}
`;

export const CREATE_LICENSES_MUTATION = graphql`
	mutation createLicensesButton_CreateLicensesMutation(
		$input: CreateLicensesInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createLicenses(input: $input) {
					edges @appendEdge(connections: $connections) {
						node {
							...licensesTable_LicenseFragment
						}
					}
				}
			}
		}
	}
`;
