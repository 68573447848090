import { graphql } from "react-relay";

export const DELETE_MESSAGE_DEFINITION_MUTATION = graphql`
	mutation deleteMessageDefinitionButton_DeleteMessageDefinitionMutation(
		$input: DeleteMessageDefinitionInput!
		$connections: [ID!]!
	) {
		Admin {
			MessageDefinitions {
				deleteMessageDefinition(input: $input) {
					id @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
