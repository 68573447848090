import { Button, EpicIcons } from "@constellation-academy/epic-ui";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { createVideoElementButton_createVideoElementMutation } from "@relay/createVideoElementButton_createVideoElementMutation.graphql";
import { createVideoElementButton_GetDefaultVimeoVideoSourceQuery } from "@relay/createVideoElementButton_GetDefaultVimeoVideoSourceQuery.graphql";
import {
	CREATE_VIDEO_ELEMENT_MUTATION,
	GET_DEFAULT_VIMEO_VIDEO_SOURCE_QUERY,
} from "@screens/educational-offer/parts/create-video-element-button/create-video-element-button.graphql";
import { CreateVideoElementButtonProps } from "@screens/educational-offer/parts/create-video-element-button/create-video-element-button.types";

export const CreateVideoElementButton = ({
	eLearningContentNodeId,
}: CreateVideoElementButtonProps) => {
	const [createVideoElement, isCreatingVideoElement] =
		useMutation<createVideoElementButton_createVideoElementMutation>(
			CREATE_VIDEO_ELEMENT_MUTATION,
		);
	const defaultVideoSourceData =
		useLazyLoadQuery<createVideoElementButton_GetDefaultVimeoVideoSourceQuery>(
			GET_DEFAULT_VIMEO_VIDEO_SOURCE_QUERY,
			{},
		);
	const defaultVideoSourceId =
		defaultVideoSourceData?.Admin?.VimeoVideoSource?.GetDefaultVimeoVideoSource?.id || "";

	const handleCreateOnClick = () => {
		createVideoElement({
			variables: {
				input: {
					title: "Neues Element",
					vimeoId: "",
					vimeoVideoSourceId: defaultVideoSourceId,
					eLearningContentNodeId,
				},
			},
		});
	};

	return (
		<Button
			disabled={isCreatingVideoElement}
			tooltip="Video-Baustein anlegen"
			icon={EpicIcons.VIDEO}
			onClick={handleCreateOnClick}
		/>
	);
};
