import { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { NoAccess } from "@components/no-access";
import { CreateLicenseProductButton } from "@features/products/create-license-product-button";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type productsScreen_Query } from "@relay/productsScreen_Query.graphql";
import { ProductsTableFilters } from "@screens/products/parts/products-table-filters/products-table-filters.component";
import { ProductsScreenContext } from "@screens/products/products.context";
import { ProductsContextProvider } from "@screens/products/products.provider";
import { wrapperClass } from "@screens/products/products.styles";
import { ProductsTable } from "./parts/products-table";
import { QUERY } from "./products.graphql";

export const ProductsScreen = () => {
	const { filters } = useContext(ProductsScreenContext);

	const query = useLazyLoadQuery<productsScreen_Query>(
		QUERY,
		{
			...filters,
		},
		{ fetchPolicy: "network-only" },
	);

	const canRead = useHasPermissions(["UserInAccountPermission_ProductAdmin_Read"]);
	const canModify = useHasPermissions(["UserInAccountPermission_ProductAdmin_Modify"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen title="Produkte">
			<ProductsContextProvider>
				<ProductsTableFilters />
				{canModify && (
					<div className={wrapperClass}>
						<CreateLicenseProductButton className="ml-2 align-self-end" />
					</div>
				)}
				<ProductsTable productsQueryFragment={query} />
			</ProductsContextProvider>
		</BaseScreen>
	);
};
