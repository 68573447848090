import { RelayPageDataTable } from "@constellation-academy/epic-ui";
import React, { useContext } from "react";
import { DateTimeDisplay } from "@components/DateTimeDisplay";
import { messageDispatchesTable_MessageDispatchFragment$key } from "@relay/messageDispatchesTable_MessageDispatchFragment.graphql";
import { messageDispatchesTable_QueryFragment$key } from "@relay/messageDispatchesTable_QueryFragment.graphql";
import { messageDispatchesTable_Refetch } from "@relay/messageDispatchesTable_Refetch.graphql";

import { buttonWrapperClass } from "@screens/message-dispatch-edit/parts/message-dispatches-table/message-dispatches-table.styles";
import { MessageDispatchesTableProps } from "@screens/message-dispatch-edit/parts/message-dispatches-table/message-dispatches-table.types";
import { DeleteMessageDispatchButton } from "@screens/message-dispatches/delete-message-dispatch-button";
import { EditMessageDispatchButton } from "@screens/message-dispatches/edit-message-dispatch-button";
import { MessageDispatchContext } from "@screens/message-dispatches/message-dispatches.context";
import { ROWS_COUNT } from "./message-dispatches-table.const";
import { MESSAGE_DISPATCH_FRAGMENT, QUERY_FRAGMENT } from "./message-dispatches-table.graphql";

export const MessageDispatchesTable = ({
	messageDispatchesFragmentRef,
}: MessageDispatchesTableProps) => {
	const { setConnectionId } = useContext(MessageDispatchContext);

	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};

	return (
		<RelayPageDataTable<
			messageDispatchesTable_Refetch,
			messageDispatchesTable_QueryFragment$key,
			messageDispatchesTable_MessageDispatchFragment$key
		>
			queryFragment={QUERY_FRAGMENT}
			parentFragmentRef={messageDispatchesFragmentRef}
			nodeInlineFragment={MESSAGE_DISPATCH_FRAGMENT}
			extractConnection={(data) => data.Admin.Message.MessageDispatches}
			onConnectionIdChange={handleOnConnectionIdChange}
			rowsCount={ROWS_COUNT}
			emptyMessage={"Keine versendeten Nachrichten"}
		>
			{(Column) => (
				<>
					<Column
						header="Genutzte Nachrichten-Vorlage"
						body={(item) => item.messageDefinition.internalTitle}
					/>
					<Column header="Erstellt von" body={(item) => item.createdBy.name} />
					<Column
						header="Wird versendet am"
						body={(item) => <DateTimeDisplay value={item.sendAt} hideTimezone />}
					/>
					<Column
						header="Versendet am"
						body={(item) => <DateTimeDisplay value={item.dispatchedAt} hideTimezone />}
					/>
					<Column
						header="Aktionen"
						style={{ width: "20%" }}
						body={(item) => (
							<div className={buttonWrapperClass}>
								<EditMessageDispatchButton messageDispatchFragmentRef={item} />
								<DeleteMessageDispatchButton messageDispatchFragmentRef={item} />
							</div>
						)}
					/>
				</>
			)}
		</RelayPageDataTable>
	);
};
