/**
 * @generated SignedSource<<dc2a867963f0e988c1844cca0f7ba2ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveOrderItemInput = {
  clientMutationId?: string | null | undefined;
  orderElementId: string;
  orderItemId: string;
};
export type RemoveOrderItemButton_RemoveOrderItemMutation$variables = {
  input: RemoveOrderItemInput;
};
export type RemoveOrderItemButton_RemoveOrderItemMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly removeOrderItem: {
        readonly orderElement: {
          readonly orderItems: ReadonlyArray<{
            readonly " $fragmentSpreads": FragmentRefs<"OrderItemForm_OrderItemFragment">;
          }>;
        };
      } | null | undefined;
    };
  };
};
export type RemoveOrderItemButton_RemoveOrderItemMutation = {
  response: RemoveOrderItemButton_RemoveOrderItemMutation$data;
  variables: RemoveOrderItemButton_RemoveOrderItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveOrderItemButton_RemoveOrderItemMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveOrderItemPayload",
                "kind": "LinkedField",
                "name": "removeOrderItem",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderElement",
                    "kind": "LinkedField",
                    "name": "orderElement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderItem",
                        "kind": "LinkedField",
                        "name": "orderItems",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "OrderItemForm_OrderItemFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveOrderItemButton_RemoveOrderItemMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveOrderItemPayload",
                "kind": "LinkedField",
                "name": "removeOrderItem",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderElement",
                    "kind": "LinkedField",
                    "name": "orderElement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderItem",
                        "kind": "LinkedField",
                        "name": "orderItems",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c1d55253d6819f397deef395ee9cbdc8",
    "id": null,
    "metadata": {},
    "name": "RemoveOrderItemButton_RemoveOrderItemMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveOrderItemButton_RemoveOrderItemMutation(\n  $input: RemoveOrderItemInput!\n) {\n  Admin {\n    ElearningV2 {\n      removeOrderItem(input: $input) {\n        orderElement {\n          orderItems {\n            ...OrderItemForm_OrderItemFragment\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment EditOrderItemButton_OrderItemFragment on OrderItem {\n  id\n  text\n}\n\nfragment OrderItemForm_OrderItemFragment on OrderItem {\n  id\n  text\n  ...EditOrderItemButton_OrderItemFragment\n  ...RemoveOrderItemButton_OrderItemFragment\n}\n\nfragment RemoveOrderItemButton_OrderItemFragment on OrderItem {\n  id\n}\n"
  }
};
})();

(node as any).hash = "8ee5321aff715d8ef28fe2f59f3dcaaf";

export default node;
