import { Button } from "@components/button";
import { EpicIcons } from "@constellation-academy/epic-ui";
import { useFragment, useMutation } from "react-relay";
import {
	DELETE_DISCOUNT_CODE_MUTATION,
	DISCOUNT_CODE_FRAGMENT,
} from "./discount-code-delete-button.graphql";
import { DiscountCodeDeleteButtonProps } from "./discount-code-delete-button.props";
import { discountCodeDeleteButton_DeleteMutation } from "../../../../__generated__/discountCodeDeleteButton_DeleteMutation.graphql";
import { useDiscountCodeTableContext } from "../discount-code-table/discount-code-table.context";

export const DiscountCodeDeleteButton = ({
	discountCodeFragmentRef,
}: DiscountCodeDeleteButtonProps) => {
	const [deleteCode, isLoading] = useMutation<discountCodeDeleteButton_DeleteMutation>(
		DELETE_DISCOUNT_CODE_MUTATION,
	);
	const { id } = useFragment(DISCOUNT_CODE_FRAGMENT, discountCodeFragmentRef);
	const { connectionId } = useDiscountCodeTableContext();

	const handleOnDeleteClick = () => {
		deleteCode({
			variables: {
				input: {
					discountCodeId: id,
				},
				connections: [connectionId],
			},
		});
	};
	return (
		<Button
			icon={EpicIcons.TRASH}
			tooltip="Aktion löschen"
			loading={isLoading}
			disabled={isLoading}
			onClick={handleOnDeleteClick}
		/>
	);
};
