import {
	DefaultStyledInputColorPickerComponents,
	type InputBaseProps,
	type StyledInputColorPickerComponents,
} from "@constellation-academy/framework-react-components";
import {
	ColorPicker as PrColorPicker,
	type ColorPickerProps as PrColorPickerProps,
} from "primereact/colorpicker";
import styled from "styled-components";
import { DefaultInputStyles } from "@corestyle/component-theme/common/input.styles";

export const tkaAdminInputChipsStyles: StyledInputColorPickerComponents = {
	InputColorPicker: {
		...DefaultStyledInputColorPickerComponents.InputColorPicker,
		StyledColorPicker: styled(PrColorPicker)<InputBaseProps & PrColorPickerProps>`
			${DefaultInputStyles()}
		`,
	},
};
