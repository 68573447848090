import { graphql } from "react-relay";

export const ASYNC_ELEMENT_TASK_FRAGMENT = graphql`
	fragment uploadTaskEditScreen_AsyncElementTaskFragment on AsyncElementTask {
		id
		status
		... on FreshUploadAsyncElementTask {
			file {
				id
				url
				name
			}
		}
		... on EvaluatedUploadAsyncElementTask {
			file {
				id
				url
				name
			}
			evaluation {
				file {
					id
					url
					name
				}
				text
			}
		}
	}
`;

export const EVALUATE_UPLOAD_ASYNC_ELEMENT_MUTATION = graphql`
	mutation uploadTaskEditScreen_EvaluateUploadAsyncElementMutation(
		$input: EvaluateUploadAsyncElementTaskInput!
	) {
		Admin {
			AsyncContent {
				evaluateUploadAsyncElementTask(input: $input) {
					evaluatedTask {
						id
						status
					}
				}
			}
		}
	}
`;
