/**
 * @generated SignedSource<<e2c8716a4d4a67afa97cff8521de6011>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EditMessageDefinitionInput = {
  clientMutationId?: string | null | undefined;
  messageDefinitionId: string;
  newDesktopNotification?: DesktopNotificationInput | null | undefined;
  newInAppNotification?: InAppNotificationInput | null | undefined;
  newInternalTitle: string;
  newShowUntil: string;
};
export type InAppNotificationInput = {
  content: string;
  title: string;
};
export type DesktopNotificationInput = {
  content: string;
  icon: string;
  title: string;
  url: string;
};
export type messageDefinitionForm_EditMessageDefinitionMutation$variables = {
  input: EditMessageDefinitionInput;
};
export type messageDefinitionForm_EditMessageDefinitionMutation$data = {
  readonly Admin: {
    readonly MessageDefinitions: {
      readonly editMessageDefinition: {
        readonly messageDefinition: {
          readonly node: {
            readonly id: string;
          };
        };
      } | null | undefined;
    };
  };
};
export type messageDefinitionForm_EditMessageDefinitionMutation = {
  response: messageDefinitionForm_EditMessageDefinitionMutation$data;
  variables: messageDefinitionForm_EditMessageDefinitionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageDefinitionsAdminMutationSchema",
        "kind": "LinkedField",
        "name": "MessageDefinitions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "EditMessageDefinitionPayload",
            "kind": "LinkedField",
            "name": "editMessageDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageDefinitionsEdge",
                "kind": "LinkedField",
                "name": "messageDefinition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinition",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "messageDefinitionForm_EditMessageDefinitionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "messageDefinitionForm_EditMessageDefinitionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "db82fc22b30e6f2c4edf02ad0c67d684",
    "id": null,
    "metadata": {},
    "name": "messageDefinitionForm_EditMessageDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation messageDefinitionForm_EditMessageDefinitionMutation(\n  $input: EditMessageDefinitionInput!\n) {\n  Admin {\n    MessageDefinitions {\n      editMessageDefinition(input: $input) {\n        messageDefinition {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "05d8654083928897842ab4e631b96217";

export default node;
