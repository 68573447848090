import { graphql } from "react-relay";

export const TREE_NODE_FRAGMENT = graphql`
	fragment contentCard_TreeNodeFragment on TreeNode {
		typeDefinition {
			definitionType
			... on ContentTypeDefinition {
				contentKind
			}
		}
		...elearningContentEditor_TreeNodeFragment
		...asyncContentEditor_TreeNodeFragment
	}
`;
