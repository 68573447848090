import { graphql } from "react-relay";

export const MATRIX_ELEMENT_FRAGMENT = graphql`
	fragment matrixFieldElement_MatrixElementFragment on MatrixElement {
		id
		cells {
			id
			kind
			xIdx
			yIdx
			... on DisplayMatrixElementCell {
				...displayMatrixElementCellForm_DisplayMatrixElementCellFragment
			}
			... on AnswerMatrixElementCell {
				...answerMatrixElementCellForm_AnswerMatrixElementCellFragment
			}
		}
	}
`;
