/**
 * @generated SignedSource<<2fc4ff11212d72ad0e556f877c55e51a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TreeConfigEditor_TreeNodeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StartTreeConfigEditor_TreeNodeFragment" | "visibilityTreeConfigEditor_TreeNodeFragment">;
  readonly " $fragmentType": "TreeConfigEditor_TreeNodeFragment";
};
export type TreeConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: TreeConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TreeConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TreeConfigEditor_TreeNodeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StartTreeConfigEditor_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "visibilityTreeConfigEditor_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "2001355495df075eda36f4f420b57231";

export default node;
