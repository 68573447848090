import { DateTimeDisplay } from "@components/DateTimeDisplay";
import { discountCodeTable_DiscountCodeFragment$key } from "@relay/discountCodeTable_DiscountCodeFragment.graphql";
import { discountCodeTable_QueryFragment$key } from "@relay/discountCodeTable_QueryFragment.graphql";
import { type discountCodeTable_Refetch } from "@relay/discountCodeTable_Refetch.graphql";
import { RelayPageDataTable } from "@constellation-academy/epic-ui";
import { useFragment } from "react-relay";
import { DEFAULT_ROWS_PER_PAGE } from "./discount-code-table-consts";
import { useDiscountCodeTableContext } from "./discount-code-table.context";
import { DISCOUNT_CODE_FRAGMENT, QUERY_FRAGMENT } from "./discount-code-table.graphql";
import { DiscountCodeTableProps } from "./discount-code-table.props";
import { buttonWrapperClass } from "./discount-code-table.styles";
import { CopyTableIdButton } from "../../../../components/copy-table-id-button";
import { DiscountCodeDeleteButton } from "../discount-code-delete-button/discount-code-delete-button.component";
import { DiscountCodeEditButton } from "../discount-code-edit-button/discount-code-edit-button.component";

export const DiscountCodeTable = ({ queryFragmentRef }: DiscountCodeTableProps) => {
	const { setConnectionId } = useDiscountCodeTableContext();
	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};
	const discountActionFragmentRef = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	return (
		<RelayPageDataTable<
			discountCodeTable_Refetch,
			discountCodeTable_QueryFragment$key,
			discountCodeTable_DiscountCodeFragment$key
		>
			queryFragment={QUERY_FRAGMENT}
			parentFragmentRef={queryFragmentRef}
			nodeInlineFragment={DISCOUNT_CODE_FRAGMENT}
			extractConnection={(data) => data.node?.discountCodes}
			rowsCount={DEFAULT_ROWS_PER_PAGE}
			emptyMessage="Keine Codes vorhanden"
			onConnectionIdChange={handleOnConnectionIdChange}
		>
			{(Column) => (
				<>
					<Column header="ID" body={(item) => <CopyTableIdButton id={item.id} />} />
					<Column header="Code" body={(item) => <span>{item.code}</span>} />
					<Column
						header="Verwendungsanzahl"
						body={(item) => <span>{item.numUsages}</span>}
					/>
					<Column
						header="Erstellt am"
						body={(item) => <DateTimeDisplay value={item.createdAt} hideTimezone />}
					/>
					<Column
						header="Aktionen"
						body={(item) => (
							<div className={buttonWrapperClass}>
								<DiscountCodeEditButton
									discountActionFragmentRef={
										discountActionFragmentRef.node ?? null
									}
									discountCodeFragmentRef={item}
								/>
								<DiscountCodeDeleteButton discountCodeFragmentRef={item} />
							</div>
						)}
					/>
				</>
			)}
		</RelayPageDataTable>
	);
};
