/**
 * @generated SignedSource<<7550da8c025397efee652c81ddd6a1c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TextWithPagesPageDataKind = "ImagePageData" | "TextPageData";
import { FragmentRefs } from "relay-runtime";
export type textWithPagesElementPageForm_TextWithPagesPageFragment$data = {
  readonly data: {
    readonly image?: {
      readonly id: string;
      readonly name: string;
    };
    readonly kind: TextWithPagesPageDataKind;
    readonly text?: string;
  };
  readonly internalTitle: string;
  readonly " $fragmentType": "textWithPagesElementPageForm_TextWithPagesPageFragment";
};
export type textWithPagesElementPageForm_TextWithPagesPageFragment$key = {
  readonly " $data"?: textWithPagesElementPageForm_TextWithPagesPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"textWithPagesElementPageForm_TextWithPagesPageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "textWithPagesElementPageForm_TextWithPagesPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "type": "TextWithPagesTextPageData",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "TextWithPagesImagePageData",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TextWithPagesPage",
  "abstractKey": null
};

(node as any).hash = "11cd745df99335c2847cb3e7025c7de3";

export default node;
