/**
 * @generated SignedSource<<b01b3d3120f49f25d0897a77e576b776>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActionTitleColumn_DiscountActionFragment$data = {
  readonly title: string;
  readonly " $fragmentType": "ActionTitleColumn_DiscountActionFragment";
};
export type ActionTitleColumn_DiscountActionFragment$key = {
  readonly " $data"?: ActionTitleColumn_DiscountActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActionTitleColumn_DiscountActionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActionTitleColumn_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};

(node as any).hash = "fc6882ff536852c61f737f0727fece94";

export default node;
