import { graphql } from "react-relay";

export const ORDER_FRAGMENT = graphql`
	fragment orderPriceDisplay_OrderFragment on Order {
		status
		cart {
			totals {
				includingAllDiscounts {
					netPrice
				}
			}
		}
		selectedPaymentMethod {
			... on MonthlyPaymentMethod {
				chosenOption {
					monthlyRate {
						netPrice
					}
				}
			}
		}
		invoiceData {
			...invoiceProviderLink_InvoiceDataFragment
		}
	}
`;
