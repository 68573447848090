/**
 * @generated SignedSource<<d916c8d0951a45893eea9dc719e7768e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsageInvoiceColumn_DiscountCodeUsageFragment$data = {
  readonly order: {
    readonly invoiceData: {
      readonly invoiceFile?: {
        readonly url: string | null | undefined;
      } | null | undefined;
      readonly invoiceNumber?: string;
    };
  } | null | undefined;
  readonly " $fragmentType": "UsageInvoiceColumn_DiscountCodeUsageFragment";
};
export type UsageInvoiceColumn_DiscountCodeUsageFragment$key = {
  readonly " $data"?: UsageInvoiceColumn_DiscountCodeUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsageInvoiceColumn_DiscountCodeUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageInvoiceColumn_DiscountCodeUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "order",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "invoiceData",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoiceNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "invoiceFile",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "CreatedInvoiceData",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DiscountCodeUsage",
  "abstractKey": null
};

(node as any).hash = "cfd458c2ac6dc00aad488bb081e37179";

export default node;
