/**
 * @generated SignedSource<<5064b2920f5cab20135a6aa9359db199>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteExternalLicenseInput = {
  clientMutationId?: string | null | undefined;
  ids: ReadonlyArray<string>;
};
export type deleteExternalLicenseButton_DeleteExternalLicenseMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteExternalLicenseInput;
};
export type deleteExternalLicenseButton_DeleteExternalLicenseMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly deleteExternalLicense: {
        readonly clientMutationId: string | null | undefined;
        readonly deletedIds: ReadonlyArray<string>;
      } | null | undefined;
    };
  };
};
export type deleteExternalLicenseButton_DeleteExternalLicenseMutation = {
  response: deleteExternalLicenseButton_DeleteExternalLicenseMutation$data;
  variables: deleteExternalLicenseButton_DeleteExternalLicenseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteExternalLicenseButton_DeleteExternalLicenseMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteExternalLicensePayload",
                "kind": "LinkedField",
                "name": "deleteExternalLicense",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteExternalLicenseButton_DeleteExternalLicenseMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteExternalLicensePayload",
                "kind": "LinkedField",
                "name": "deleteExternalLicense",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedIds",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90d8c4f3b2517ed5ffe3ce1889b7d82f",
    "id": null,
    "metadata": {},
    "name": "deleteExternalLicenseButton_DeleteExternalLicenseMutation",
    "operationKind": "mutation",
    "text": "mutation deleteExternalLicenseButton_DeleteExternalLicenseMutation(\n  $input: DeleteExternalLicenseInput!\n) {\n  Admin {\n    Billing {\n      deleteExternalLicense(input: $input) {\n        clientMutationId\n        deletedIds\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "59047d7c0125d321714f9ec87b87b30f";

export default node;
