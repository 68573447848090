import { graphql } from "react-relay";

export const CREATE_AND_APPEND_CHILD_TAG_MUTATION = graphql`
	mutation createChildTag_CreateAndAppendChildTagMutation($input: CreateAndAppendChildTagInput!) {
		Admin {
			AcademiesTag {
				createAndAppendChildTag(input: $input) {
					edge {
						node {
							...academiesTagsTable_AcademiesTagFragment
						}
					}
				}
			}
		}
	}
`;
