import { Button } from "primereact/button";
import { readInlineData, useFragment } from "react-relay";
import { type productsTable_LicenseProductFragment$key } from "@relay/productsTable_LicenseProductFragment.graphql";
import { LICENSE_PRODUCT_FRAGMENT, PRODUCTS_CONNECTION_FRAGMENT } from "./products-table.graphql";
import { type ProductsTableProps } from "./products-table.interface";
import { StyledDataTable } from "./products-table.styles";

export const ProductsTable = ({
	productsConnectionFragmentRef,
	hasPrevious,
	hasNext,
	loadNext,
	selectionMode,
	selectedId,
	onProductSelected,
	loadPrevious,
}: ProductsTableProps) => {
	const { edges: treeNodes } = useFragment(
		PRODUCTS_CONNECTION_FRAGMENT,
		productsConnectionFragmentRef,
	);

	const values =
		treeNodes
			?.filter((e) => !!e?.node)
			.map((e) =>
				readInlineData<productsTable_LicenseProductFragment$key>(
					LICENSE_PRODUCT_FRAGMENT,
					e!.node,
				),
			) || [];

	return (
		<>
			<StyledDataTable
				className="mb-3"
				value={values}
				emptyMessage={`Keine passenden Produkte gefunden`}
				selectionMode={selectionMode}
				selection={{ id: selectedId }}
				dataKey="id"
				onSelectionChange={onProductSelected}
			>
				{(Column) => (
					<>
						<Column header="Titel" field="title" />
					</>
				)}
			</StyledDataTable>

			<div className="flex justify-content-center align-items-center">
				<Button
					type="button"
					disabled={!hasPrevious}
					onClick={() => {
						loadPrevious(20);
					}}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					type="button"
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => {
						loadNext(20);
					}}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};
