/**
 * @generated SignedSource<<ea687f81bbd55d6c046835c72260f6ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type externalLicensesTable_ExternalLicenseFragment$data = {
  readonly data: {
    readonly code: string;
    readonly name: string;
  };
  readonly id: string;
  readonly issuingInfo: {
    readonly hasBeenIssued: boolean;
    readonly issuedAt?: string;
    readonly issuedTo?: {
      readonly email: string;
    } | null | undefined;
    readonly validUntil?: string;
  };
  readonly pool: {
    readonly data: {
      readonly usageInformation: string;
    };
  } | null | undefined;
  readonly " $fragmentType": "externalLicensesTable_ExternalLicenseFragment";
};
export type externalLicensesTable_ExternalLicenseFragment$key = {
  readonly " $data"?: externalLicensesTable_ExternalLicenseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"externalLicensesTable_ExternalLicenseFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "externalLicensesTable_ExternalLicenseFragment"
};

(node as any).hash = "6c2f5b6d63914b6d5d390160ff6d6632";

export default node;
