import {
	DefaultStyledTableComponents,
	type StyledTableComponents,
} from "@constellation-academy/framework-react-components";
import {
	DataTable as PrTable,
	type DataTableProps as PrDataTableProps,
} from "primereact/datatable";
import styled from "styled-components";
import tw from "twin.macro";

export const tkaAdminTableStyles: StyledTableComponents = {
	Table: {
		...DefaultStyledTableComponents.Table,
		StyledTable: styled(PrTable)<PrDataTableProps<any>>`
			${tw`tw-flex-grow tw-flex tw-flex-col`};
			max-width: 100%;
			min-height: ${(props) => props.scrollHeight ?? 500};
		`,
		NextPageWrapper: tw.div`tw-ml-auto tw-mr-8`,
		RowsPerPageContainer: tw.div`tw-flex tw-justify-center  tw-bottom-32`,
		RowsPerPageWrapper: tw.div``,
	},
};
