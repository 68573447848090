/**
 * @generated SignedSource<<d89bc9de89dfe3c0ab4a220f42d3fb64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LearnableKind = "Course";
export type offersTable_Refetch$variables = {
  after?: string | null | undefined;
  before?: string | null | undefined;
  first?: number | null | undefined;
  isVisible?: boolean | null | undefined;
  kinds: ReadonlyArray<LearnableKind>;
  last?: number | null | undefined;
  tagIds: ReadonlyArray<string>;
  text?: string | null | undefined;
};
export type offersTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"offersTable_QueryFragment">;
};
export type offersTable_Refetch = {
  response: offersTable_Refetch$data;
  variables: offersTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isVisible"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "kinds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tagIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isVisible",
    "variableName": "isVisible"
  },
  {
    "kind": "Variable",
    "name": "kinds",
    "variableName": "kinds"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "tagIds",
    "variableName": "tagIds"
  },
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "offersTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "offersTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "offersTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnableAdminSchema",
            "kind": "LinkedField",
            "name": "Learnable",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UnpublishedLearnablesConnection",
                "kind": "LinkedField",
                "name": "UnpublishedLearnables",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnpublishedLearnablesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isUnpublishedLearnable"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TreeNode",
                                "kind": "LinkedField",
                                "name": "root",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "structureDefinition",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "UnpublishedCourseLearnable",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "text",
                  "tagIds",
                  "isVisible",
                  "kinds"
                ],
                "handle": "connection",
                "key": "offersTable_UnpublishedLearnables",
                "kind": "LinkedHandle",
                "name": "UnpublishedLearnables"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "052723d7eb1cc5e5a61d591bd8da0e15",
    "id": null,
    "metadata": {},
    "name": "offersTable_Refetch",
    "operationKind": "query",
    "text": "query offersTable_Refetch(\n  $after: String\n  $before: String\n  $first: Int = 20\n  $isVisible: Boolean\n  $kinds: [LearnableKind!]!\n  $last: Int\n  $tagIds: [ID!]!\n  $text: String\n) {\n  ...offersTable_QueryFragment_4peOxi\n}\n\nfragment deleteCourseLearnableButton_UnpublishedLearnableFragment on UnpublishedLearnable {\n  __isUnpublishedLearnable: __typename\n  id\n  kind\n  ... on UnpublishedCourseLearnable {\n    kind\n    root {\n      id\n      structureDefinition {\n        __typename\n        title\n      }\n    }\n  }\n}\n\nfragment editCourseLearnableButton_UnpublishedLearnableFragment on UnpublishedLearnable {\n  __isUnpublishedLearnable: __typename\n  id\n  ... on UnpublishedCourseLearnable {\n    kind\n    root {\n      id\n    }\n  }\n}\n\nfragment offersTable_QueryFragment_4peOxi on Query {\n  Admin {\n    Learnable {\n      UnpublishedLearnables(first: $first, after: $after, last: $last, before: $before, text: $text, tagIds: $tagIds, isVisible: $isVisible, kinds: $kinds) {\n        edges {\n          node {\n            __typename\n            id\n            kind\n            ...offersTable_UnpublishedLearnableFragment\n          }\n          cursor\n        }\n        pageInfo {\n          startCursor\n          endCursor\n          hasNextPage\n          hasPreviousPage\n        }\n      }\n    }\n  }\n}\n\nfragment offersTable_UnpublishedLearnableFragment on UnpublishedLearnable {\n  __isUnpublishedLearnable: __typename\n  id\n  kind\n  ... on UnpublishedCourseLearnable {\n    kind\n    root {\n      id\n      structureDefinition {\n        __typename\n        title\n      }\n    }\n  }\n  ...editCourseLearnableButton_UnpublishedLearnableFragment\n  ...deleteCourseLearnableButton_UnpublishedLearnableFragment\n}\n"
  }
};
})();

(node as any).hash = "7d9cb146c96b59ecbf3dea0d1ef28d29";

export default node;
