import { graphql } from "react-relay";

export const PRODUCTS_CONNECTION_FRAGMENT = graphql`
	fragment productsTable_ProductsConnectionFragment on ProductsConnection {
		edges {
			node {
				... on Product {
					...productsTable_LicenseProductFragment
				}
			}
		}
	}
`;

export const LICENSE_PRODUCT_FRAGMENT = graphql`
	fragment productsTable_LicenseProductFragment on Product @inline {
		id
		title
	}
`;
