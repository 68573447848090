import { graphql } from "react-relay";

export const QUERY = graphql`
	query instructorEditScreen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Instructor {
				...instructorEditScreen_InstructorFragment
			}
		}
	}
`;

export const INSTRUCTOR_FRAGMENT = graphql`
	fragment instructorEditScreen_InstructorFragment on InstructorImpl {
		id
		superId
		name
		position
		shortDescription
		description
		image {
			id
			name
			url
		}
	}
`;

export const CREATE_MUTATION = graphql`
	mutation instructorEditScreen_CreateMutation(
		$input: CreateInstructorImplInput!
		$connections: [ID!]!
	) {
		Admin {
			Instructor {
				createInstructorImpl(input: $input) {
					data @appendEdge(connections: $connections) {
						node {
							...instructorEditScreen_InstructorFragment
						}
					}
				}
			}
		}
	}
`;

export const UPDATE_MUTATION = graphql`
	mutation instructorEditScreen_UpdateMutation($input: UpdateInstructorImplInput!) {
		Admin {
			Instructor {
				updateInstructorImpl(input: $input) {
					data {
						node {
							id
							superId
							name
							...instructorEditScreen_InstructorFragment
						}
					}
				}
			}
		}
	}
`;
