/**
 * @generated SignedSource<<ae53edf5ab9ce2e2ae0daa61823d70fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type persologElementQuestionFormDialog_PersologElementFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"persologElementQuestionForm_PersologElementFragment">;
  readonly " $fragmentType": "persologElementQuestionFormDialog_PersologElementFragment";
};
export type persologElementQuestionFormDialog_PersologElementFragment$key = {
  readonly " $data"?: persologElementQuestionFormDialog_PersologElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"persologElementQuestionFormDialog_PersologElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "persologElementQuestionFormDialog_PersologElementFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "persologElementQuestionForm_PersologElementFragment"
    }
  ],
  "type": "Persolog_PersologElement",
  "abstractKey": null
};

(node as any).hash = "6a15e2900a83e23a6827a37e1aa48584";

export default node;
