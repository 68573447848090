import { type PathParams } from "@constellation-academy/epic-ui";
import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { useHasPermissions } from "@hooks/use-has-permissions";
import { type taskEditScreen_Query } from "@relay/taskEditScreen_Query.graphql";
import { type TasksPath } from "@screens/tasks";
import { QUERY } from "./task-edit.graphql";
import { NoAccess } from "../../components/no-access";
import { UploadTaskEditScreen } from "../upload-task-edit/upload-task-edit.screen";

export const TaskEditScreen = () => {
	const { taskId } = useParams<PathParams<typeof TasksPath>>();

	const query = useLazyLoadQuery<taskEditScreen_Query>(
		QUERY,
		{
			id: taskId || "",
			skip: !taskId,
		},
		{ fetchPolicy: "network-only" },
	);
	const elementKind = query?.node?.elementKind;

	const canReadAndModify = useHasPermissions([
		"UserInAccountPermission_AsyncElementTaskAdmin_Read",
		"UserInAccountPermission_AsyncElementTaskAdmin_Modify",
	]);

	if (!canReadAndModify) return <NoAccess />;

	if (!query?.node) return null;
	switch (elementKind) {
		case "Upload":
			return <UploadTaskEditScreen asyncElementTaskFragmentRef={query?.node} />;
		default:
			return null;
	}
};
