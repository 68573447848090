import { FormDialogButton } from "@constellation-academy/framework-react-components";
import { type FormikProps } from "formik";
import React, { useContext } from "react";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type createFlexLicenseDefinitionButton_CreateFlexLicenseDefinitionMutation } from "@relay/createFlexLicenseDefinitionButton_CreateFlexLicenseDefinitionMutation.graphql";
import { CREATE_FLEX_LICENSE_DEFINITION_MUTATION } from "./create-flex-license-definition-button.graphql";
import { LicenseDefinitionsScreenContext } from "../../../screens/license-definitions/license-defintions.context";
import { FlexLicenseDefinitionForm } from "../flex-license-definition-form";
import { type FlexLicenseDefinitionFormState } from "../flex-license-definition-form/flex-license-definition-form.types";

export const CreateFlexLicenseDefinitionButton = () => {
	const { connectionId } = useContext(LicenseDefinitionsScreenContext);
	const [create, isCreating] =
		useMutation<createFlexLicenseDefinitionButton_CreateFlexLicenseDefinitionMutation>(
			CREATE_FLEX_LICENSE_DEFINITION_MUTATION,
		);

	const createOnSubmitHandler =
		(
			ref: React.MutableRefObject<FormikProps<FlexLicenseDefinitionFormState> | null>,
			onHide: () => void,
		) =>
		(values: FlexLicenseDefinitionFormState) => {
			create({
				variables: {
					input: {
						data: {
							name: values.name,
							includedNodeIds:
								values.includedNodeIds?.map((node) => node.id).filter(Boolean) ??
								[],
							excludedNodeIds:
								values.excludedNodeIds?.map((node) => node.id).filter(Boolean) ??
								[],
						},
					},
					connections: [connectionId],
				},
				onCompleted: () => {
					ref.current?.setSubmitting(false);
					onHide();
					toast.success("Lizenzdefinition erstellt.");
				},
				onError: () => {
					ref.current?.setSubmitting(false);
					toast.error("Fehler beim erstellen der Lizenzdefinition.");
				},
			});
		};

	return (
		<FormDialogButton<FlexLicenseDefinitionFormState>
			disabled={isCreating}
			buttonContent={{
				label: "Lizenzdefinition erstellen",
			}}
			title="Lizenzdefinition erstellen"
		>
			{(ref, onHide) => {
				return (
					<FlexLicenseDefinitionForm
						initialState={{
							name: "",
							includedNodeIds: [],
							excludedNodeIds: [],
						}}
						ref={ref}
						onSubmit={createOnSubmitHandler(ref, onHide)}
					/>
				);
			}}
		</FormDialogButton>
	);
};
