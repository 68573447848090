/**
 * @generated SignedSource<<d5fcb900f73a68a897ec9a5258734715>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActionCalcTypeColumn_DiscountActionFragment$data = {
  readonly percent: number;
  readonly " $fragmentType": "ActionCalcTypeColumn_DiscountActionFragment";
};
export type ActionCalcTypeColumn_DiscountActionFragment$key = {
  readonly " $data"?: ActionCalcTypeColumn_DiscountActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActionCalcTypeColumn_DiscountActionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActionCalcTypeColumn_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percent",
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};

(node as any).hash = "a3f10c33298e2d526c8d547947e17222";

export default node;
