import {
	DefaultStyledDialogComponents,
	type StyledDialogComponents,
} from "@constellation-academy/framework-react-components";
import { createGlobalStyle } from "styled-components";
import tw from "twin.macro";

export const tkaAdminDialogStyles: StyledDialogComponents = {
	Dialog: {
		...DefaultStyledDialogComponents.Dialog,
		DialogGlobal: createGlobalStyle`
          .p-dialog {
            min-width: 600px;
          }
		`,
		DialogHeader: tw.div``,
	},
};
