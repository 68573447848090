import { graphql } from "react-relay";

export const TREE_UNLOCKED_STATE_FRAGMENT = graphql`
	fragment treeUnlockedStateDisplay_TreeUnlockedStateFragment on TreeUnlockedState {
		when
		data {
			kind
			... on UnlockedByAdmin {
				unlockedByUser {
					id
					name
				}
			}
			... on UnlockedByLicense {
				license {
					id
					creationInfo {
						licenseDefinition {
							id
							data {
								name
							}
						}
					}
				}
			}
		}
	}
`;
