/**
 * @generated SignedSource<<b8ff7466e39292f6f763b6f801dd7af3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectExternalLicensePoolField_ExternalLicensePoolFragment$data = {
  readonly data: {
    readonly name: string;
  };
  readonly id: string;
  readonly " $fragmentType": "selectExternalLicensePoolField_ExternalLicensePoolFragment";
};
export type selectExternalLicensePoolField_ExternalLicensePoolFragment$key = {
  readonly " $data"?: selectExternalLicensePoolField_ExternalLicensePoolFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectExternalLicensePoolField_ExternalLicensePoolFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectExternalLicensePoolField_ExternalLicensePoolFragment"
};

(node as any).hash = "ec6ee1a9598bd31eb81fd5320d66f5fe";

export default node;
