/**
 * @generated SignedSource<<bfc10cc89c7df8fa53f0a6239f52e3ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type selectUserField_UsersQuery$variables = {
  accountId?: string | null | undefined;
  nameOrEmail?: string | null | undefined;
};
export type selectUserField_UsersQuery$data = {
  readonly Admin: {
    readonly Auth: {
      readonly SelectUsers: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly activated: boolean;
            readonly email: string;
            readonly id: string;
            readonly name: string;
          };
        } | null | undefined> | null | undefined;
      };
    };
  };
};
export type selectUserField_UsersQuery = {
  response: selectUserField_UsersQuery$data;
  variables: selectUserField_UsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameOrEmail"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthAdminSchema",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filterByAccountId",
                "variableName": "accountId"
              },
              {
                "kind": "Variable",
                "name": "filterByNameOrEmail",
                "variableName": "nameOrEmail"
              }
            ],
            "concreteType": "UserConnection",
            "kind": "LinkedField",
            "name": "SelectUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "activated",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectUserField_UsersQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "selectUserField_UsersQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "25c882eb8afc03e0dd014740c1ecb995",
    "id": null,
    "metadata": {},
    "name": "selectUserField_UsersQuery",
    "operationKind": "query",
    "text": "query selectUserField_UsersQuery(\n  $nameOrEmail: String\n  $accountId: ID\n) {\n  Admin {\n    Auth {\n      SelectUsers(filterByNameOrEmail: $nameOrEmail, filterByAccountId: $accountId) {\n        edges {\n          node {\n            id\n            name\n            email\n            activated\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbffabb7795b6724746e52749a778fa0";

export default node;
