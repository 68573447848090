import { ProgressSpinner } from "primereact/progressspinner";
import React, { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { NoAccess } from "@components/no-access";
import { OrdersTable } from "@features/orders/orders-table/orders-table.component";
import { useHasPermissions } from "@hooks/use-has-permissions";
import {
	type ordersScreen_Query,
	type ordersScreen_Query$data,
} from "@relay/ordersScreen_Query.graphql";
import { ORDERS_QUERY, ordersPerPage } from "./orders.graphql";

export const OrdersScreen = () => {
	const orders: ordersScreen_Query$data = useLazyLoadQuery<ordersScreen_Query>(ORDERS_QUERY, {
		numItemsPerPage: ordersPerPage,
	});

	const canRead = useHasPermissions(["UserInAccountPermission_OrderAdmin_Read"]);
	const canModify = useHasPermissions(["UserInAccountPermission_OrderAdmin_Modify"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen title="Bestellungen">
			<Suspense fallback={<ProgressSpinner />}>
				<OrdersTable
					ordersFragmentRef={orders}
					ordersPerPage={ordersPerPage}
					enableFilter={true}
					canModifyOrders={canModify}
				/>
			</Suspense>
		</BaseScreen>
	);
};
