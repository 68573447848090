import { graphql } from "react-relay";

export const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment discountCodeEditButton_DiscountActionFragment on DiscountAction {
		id
	}
`;
export const DISCOUNT_CODE_FRAGMENT = graphql`
	fragment discountCodeEditButton_DiscountCodeFragment on DiscountCode {
		id
	}
`;
