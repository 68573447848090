/**
 * @generated SignedSource<<de0ebc595c4f3e727b401f0c378c0d3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type textElementForm_TextElementFragment$data = {
  readonly id: string;
  readonly text: string;
  readonly title: string;
  readonly " $fragmentType": "textElementForm_TextElementFragment";
};
export type textElementForm_TextElementFragment$key = {
  readonly " $data"?: textElementForm_TextElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"textElementForm_TextElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "textElementForm_TextElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "type": "TextElementV2",
  "abstractKey": null
};

(node as any).hash = "dd57e08d0f44b73fd36b7003afd58d64";

export default node;
