import { graphql } from "react-relay";

export const QUERY = graphql`
	query accountsTable_Query($first: Int) {
		...accountsTable_UsersListFragment @arguments(first: $first)
	}
`;

export const ACCOUNTS_LIST_FRAGMENT = graphql`
	fragment accountsTable_UsersListFragment on Query
	@refetchable(queryName: "AccountsTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		name: { type: "String" }
	) {
		Admin {
			Auth {
				Accounts(first: $first, after: $after, name: $name)
					@connection(key: "AccountsTable_Accounts") {
					__id
					edges {
						node {
							id
							name
							isDeleted
							groupAssociations {
								group {
									id
									name
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const ANONYMIZE_ACCOUNT_MUTATION = graphql`
	mutation accountsTable_AnonymizeAccountAdminMutation(
		$input: AnonymizeAccountInput!
		$connections: [ID!]!
	) {
		Admin {
			Auth {
				anonymizeAccountAdmin(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
