/**
 * @generated SignedSource<<25a944e3b5c14a321f98d3aa92dc0330>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PaymentMethodType = "Card" | "Giropay" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "Klarna" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort";
export type SelectedPaymentMethodKind = "Monthly" | "OneTime";
import { FragmentRefs } from "relay-runtime";
export type paymentDataForm_OrderFragment$data = {
  readonly allowedPaymentMethods: {
    readonly paymentMethods: ReadonlyArray<PaymentMethodType>;
  };
  readonly selectedPaymentMethod: {
    readonly kind: SelectedPaymentMethodKind;
    readonly paymentMethodType: PaymentMethodType;
  } | null | undefined;
  readonly " $fragmentType": "paymentDataForm_OrderFragment";
};
export type paymentDataForm_OrderFragment$key = {
  readonly " $data"?: paymentDataForm_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"paymentDataForm_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "paymentDataForm_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedPaymentMethods",
      "kind": "LinkedField",
      "name": "allowedPaymentMethods",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethods",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "6f074280593ddfb71207d26aa6d25063";

export default node;
