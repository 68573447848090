/**
 * @generated SignedSource<<78e4f10ad26fc3c29f811872d4f338fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentKind = "Async" | "ELearning";
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "persolog" | "persologEvaluation" | "persologQuestion" | "podcast" | "podcastWithTimestamp" | "reminder" | "text" | "textWithPages" | "video";
export type StructureType = "child" | "root";
export type TreeStateStatus = "finished" | "started";
export type TreeUnlockedStateKind = "UnlockedByAdmin" | "UnlockedByLicense";
export type TypeDefinitionType = "branch" | "content";
import { FragmentRefs } from "relay-runtime";
export type userRootNodeItem_TreeStateFragment$data = {
  readonly definition: {
    readonly headContentNode?: {
      readonly id: string;
    } | null | undefined;
    readonly status: TreeStateStatus;
  };
  readonly extension: {
    readonly gamificationPoints?: ReadonlyArray<{
      readonly contentId: string;
      readonly id: string;
      readonly num: number;
    }>;
    readonly submissions?: ReadonlyArray<{
      readonly id: string;
      readonly lastUpdated: string | null | undefined;
      readonly learnOpportunity: {
        readonly id: string;
      } | null | undefined;
      readonly startedAt: string;
    }>;
    readonly treeUnlockedState?: {
      readonly data: {
        readonly kind: TreeUnlockedStateKind;
      };
      readonly " $fragmentSpreads": FragmentRefs<"treeUnlockedStateDisplay_TreeUnlockedStateFragment">;
    } | null | undefined;
  };
  readonly id: string;
  readonly progressPercentage: number;
  readonly rootNode: {
    readonly parentId: string | null | undefined;
    readonly structureDefinition: {
      readonly coordinates: {
        readonly parentRef: string | null | undefined;
      };
      readonly definitionType: StructureType;
      readonly title: string;
    };
    readonly typeDefinition: {
      readonly childRefs?: ReadonlyArray<string>;
      readonly contentKind?: ContentKind;
      readonly definitionType: TypeDefinitionType;
      readonly elements?: ReadonlyArray<{
        readonly elementType: ElementTypeV2;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"ElementNode_ElementV2Fragment">;
      }>;
    };
  };
  readonly startedAt: string;
  readonly user: {
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "userRootNodeItem_TreeStateFragment";
};
export type userRootNodeItem_TreeStateFragment$key = {
  readonly " $data"?: userRootNodeItem_TreeStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userRootNodeItem_TreeStateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "definitionType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userRootNodeItem_TreeStateFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TreeNode",
              "kind": "LinkedField",
              "name": "headContentNode",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "StartedTreeStateDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "progressPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TreeNode",
      "kind": "LinkedField",
      "name": "rootNode",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CoordinatesV2",
              "kind": "LinkedField",
              "name": "coordinates",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parentRef",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "typeDefinition",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "childRefs",
                  "storageKey": null
                }
              ],
              "type": "BranchTypeDefinition",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "contentKind",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "elements",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "elementType",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "ElementNode_ElementV2Fragment"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "ELearningContentTypeDefinition",
                  "abstractKey": null
                }
              ],
              "type": "ContentTypeDefinition",
              "abstractKey": "__isContentTypeDefinition"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TreeUnlockedState",
              "kind": "LinkedField",
              "name": "treeUnlockedState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "kind",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "treeUnlockedStateDisplay_TreeUnlockedStateFragment"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentSubmission",
              "kind": "LinkedField",
              "name": "submissions",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastUpdated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LearnOpportunityV2",
                  "kind": "LinkedField",
                  "name": "learnOpportunity",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GamificationPointsReceivedByContent",
              "kind": "LinkedField",
              "name": "gamificationPoints",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "contentId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "num",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "TkaTreeStateExtension",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeState",
  "abstractKey": null
};
})();

(node as any).hash = "ac1662d053732df16baa9e65f753184b";

export default node;
