/**
 * @generated SignedSource<<f966427f4ba40ffbcf73ce7d7956ebed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licenseProductForm_LicenseProductFragment$data = {
  readonly data: {
    readonly licenseDefinition?: {
      readonly data: {
        readonly name: string;
      };
      readonly id: string;
    } | null | undefined;
    readonly licenseDefinitionAmount?: number | null | undefined;
  };
  readonly id: string;
  readonly isHidden: boolean;
  readonly isTaxFree: boolean;
  readonly netPrice: number;
  readonly title: string;
  readonly " $fragmentType": "licenseProductForm_LicenseProductFragment";
};
export type licenseProductForm_LicenseProductFragment$key = {
  readonly " $data"?: licenseProductForm_LicenseProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licenseProductForm_LicenseProductFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "licenseProductForm_LicenseProductFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHidden",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTaxFree",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "licenseDefinitionAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LicenseDefinition",
              "kind": "LinkedField",
              "name": "licenseDefinition",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LicenseProductData",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "c4b0f245cbdfd265b61071abc707c9de";

export default node;
