/**
 * @generated SignedSource<<fc5a247d6bec40d6069da55da258652d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type productsTable_LicenseProductFragment$data = {
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "productsTable_LicenseProductFragment";
};
export type productsTable_LicenseProductFragment$key = {
  readonly " $data"?: productsTable_LicenseProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"productsTable_LicenseProductFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "productsTable_LicenseProductFragment"
};

(node as any).hash = "37271f59892d75b1c0473aa504a1f155";

export default node;
