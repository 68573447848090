import { graphql } from "react-relay";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment createTreeStateButton_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		structureDefinition {
			title
		}
	}
`;

export const CREATE_TREE_STATE_FOR_USER_MUTATION = graphql`
	mutation createTreeStateButton_CreateTreeStateForUserMutation(
		$input: CreateTreeStateForUserInput!
	) {
		Admin {
			LearnV2 {
				createTreeStateForUser(input: $input) {
					publishedNode {
						...userRootNodeItem_LearnOpportunityV2Fragment
					}
					treeState {
						...userRootNodeItem_TreeStateFragment
					}
				}
			}
		}
	}
`;
