import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment businessBaseDataForm_BusinessBaseDataFragment on BusinessBaseData {
		__typename
		companyName
		billingOffice
		companyLegalForm
		invoiceEmail
		phoneNumber
		city
		street
		houseNumber
		postalCode
		countryCode
		accountData {
			bic
			iban
		}
		taxData {
			taxNumber
			taxIdentificationNumber
		}
	}
`;
