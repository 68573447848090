/**
 * @generated SignedSource<<ac5ce83f34104170ece15346c6f46392>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createLicensesForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectLicenseDefinitionField_QueryFragment">;
  readonly " $fragmentType": "createLicensesForm_QueryFragment";
};
export type createLicensesForm_QueryFragment$key = {
  readonly " $data"?: createLicensesForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createLicensesForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createLicensesForm_QueryFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "dataKinds",
          "value": [
            "Flex"
          ]
        }
      ],
      "kind": "FragmentSpread",
      "name": "selectLicenseDefinitionField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f34556917697b454c9301da7dfc4789e";

export default node;
