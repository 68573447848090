import { graphql } from "react-relay";

export const CREATE_VIMEO_VIDEO_SOURCE_MUTATION = graphql`
	mutation createVideoSourceButton_CreateVimeoVideoSourceMutation(
		$input: CreateVimeoVideoSourceInput!
		$connections: [ID!]!
	) {
		Admin {
			VimeoVideoSource {
				createVimeoVideoSource(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...videoSourcesTable_Vimeo_VimeoVideoSourceFragment
						}
					}
				}
			}
		}
	}
`;
