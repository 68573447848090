import { graphql } from "react-relay";

export const MESSAGE_DEFINITION_FRAGMENT = graphql`
	fragment createMessageDispatchButton_MessageDefinitionFragment on MessageDefinition {
		id
		internalTitle
	}
`;

export const CREATE_MESSAGE_DISPATCH_MUTATION = graphql`
	mutation createMessageDispatchButton_CreateMessageDispatchMutation(
		$input: CreateMessageDispatchInput!
	) {
		Admin {
			Message {
				createMessageDispatch(input: $input) {
					messageDispatch {
						node {
							id
						}
					}
				}
			}
		}
	}
`;
