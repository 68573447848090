import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type discountActionsScreen_Query } from "@relay/discountActionsScreen_Query.graphql";
import { QUERY } from "./discount-actions.graphql";
import { DiscountActionSearchContainer } from "./parts/DiscountActionSearchContext";
import { DiscountActionsTable } from "./parts/DiscountActionsTable";
import { NoAccess } from "../../components/no-access";

export const DiscountActionsScreen = () => {
	const query = useLazyLoadQuery<discountActionsScreen_Query>(QUERY, { first: 20 });

	const canRead = useHasPermissions(["UserInAccountPermission_DiscountAdmin_Read"]);
	const canModify = useHasPermissions(["UserInAccountPermission_DiscountAdmin_Modify"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen title="Rabattaktionen">
			<DiscountActionSearchContainer>
				<DiscountActionsTable
					discountActionsQueryFragment={query}
					canModifyDiscountActions={canModify}
				/>
			</DiscountActionSearchContainer>
		</BaseScreen>
	);
};
