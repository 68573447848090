import {
	type SelectMultiplePublishedRootFieldProps,
	type SelectMultiplePublishedRootFieldState,
} from "./select-multiple-published-root-field.types";
import { SelectComplexDataField } from "../../../components/select-complex-data-field";
import { SearchablePublishedRootTable } from "../searchable-published-root-table";

export const SelectMultiplePublishedRootField = ({
	...props
}: SelectMultiplePublishedRootFieldProps) => {
	return (
		<>
			<SelectComplexDataField<SelectMultiplePublishedRootFieldState>
				valuePlaceholder="Keine Weiterbildungen ausgewählt"
				actionButtonLabel="Weiterbildungen auswählen"
				headerLabel="Wähle mindestens eine Weiterbildung aus"
				{...props}
			>
				<SearchablePublishedRootTable
					selection={props.fieldValue}
					onChange={props.updateField}
					selectionMode="multiple"
				/>
			</SelectComplexDataField>
		</>
	);
};
