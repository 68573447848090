/**
 * @generated SignedSource<<626b7646eb81d2bcdd17ea2a7a22aa2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type externalLicensePoolScreen_ExternalLicensesQuery$variables = {
  externalLicensePoolId: string;
  numItemsPerPage: number;
};
export type externalLicensePoolScreen_ExternalLicensesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"externalLicensesTable_ExternalLicensesFragment">;
};
export type externalLicensePoolScreen_ExternalLicensesQuery = {
  response: externalLicensePoolScreen_ExternalLicensesQuery$data;
  variables: externalLicensePoolScreen_ExternalLicensesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "externalLicensePoolId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numItemsPerPage"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "numItemsPerPage"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "pool",
    "variableName": "externalLicensePoolId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "externalLicensePoolScreen_ExternalLicensesQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "externalLicensePoolIdOpt",
            "variableName": "externalLicensePoolId"
          },
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "externalLicensesTable_ExternalLicensesFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "externalLicensePoolScreen_ExternalLicensesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "ExternalLicensesConnection",
                "kind": "LinkedField",
                "name": "GetExternalLicenses",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalLicense",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalLicenseData",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "code",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalLicensePool",
                            "kind": "LinkedField",
                            "name": "pool",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ExternalLicensePoolData",
                                "kind": "LinkedField",
                                "name": "data",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "usageInformation",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "issuingInfo",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasBeenIssued",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "issuedAt",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "validUntil",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "issuedTo",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "email",
                                        "storageKey": null
                                      },
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "ExternalLicenseIssuedToUserIssuingInfoType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "pool",
                  "name",
                  "isIssued"
                ],
                "handle": "connection",
                "key": "ExternalLicensesTable_GetExternalLicenses",
                "kind": "LinkedHandle",
                "name": "GetExternalLicenses"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7540326a9b86feaa1a5289d81a821542",
    "id": null,
    "metadata": {},
    "name": "externalLicensePoolScreen_ExternalLicensesQuery",
    "operationKind": "query",
    "text": "query externalLicensePoolScreen_ExternalLicensesQuery(\n  $numItemsPerPage: Int!\n  $externalLicensePoolId: ID!\n) {\n  ...externalLicensesTable_ExternalLicensesFragment_2wvq7q\n}\n\nfragment externalLicensesTable_ExternalLicenseFragment on ExternalLicense {\n  id\n  data {\n    name\n    code\n  }\n  pool {\n    data {\n      usageInformation\n    }\n    id\n  }\n  issuingInfo {\n    __typename\n    hasBeenIssued\n    ... on ExternalLicenseIssuedToUserIssuingInfoType {\n      issuedAt\n      validUntil\n      issuedTo {\n        email\n        id\n      }\n    }\n  }\n}\n\nfragment externalLicensesTable_ExternalLicensesFragment_2wvq7q on Query {\n  Admin {\n    Billing {\n      GetExternalLicenses(pool: $externalLicensePoolId, first: $numItemsPerPage) {\n        edges {\n          node {\n            ...externalLicensesTable_ExternalLicenseFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "749587c0c3a702d6b00f9e904964e125";

export default node;
