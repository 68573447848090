/**
 * @generated SignedSource<<e2c4647ba779536070694506604085b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteLicenseDefinitionInput = {
  clientMutationId?: string | null | undefined;
  licenseDefinitionId: string;
};
export type licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteLicenseDefinitionInput;
};
export type licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation$data = {
  readonly Admin: {
    readonly LicenseDefinition: {
      readonly deleteLicenseDefinition: {
        readonly deletedIds: ReadonlyArray<string>;
      } | null | undefined;
    };
  };
};
export type licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation = {
  response: licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation$data;
  variables: licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminMutationSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteLicenseDefinitionPayload",
                "kind": "LinkedField",
                "name": "deleteLicenseDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminMutationSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteLicenseDefinitionPayload",
                "kind": "LinkedField",
                "name": "deleteLicenseDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedIds",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "86812d30ffafbc7be565f70047fbdf93",
    "id": null,
    "metadata": {},
    "name": "licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation",
    "operationKind": "mutation",
    "text": "mutation licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation(\n  $input: DeleteLicenseDefinitionInput!\n) {\n  Admin {\n    LicenseDefinition {\n      deleteLicenseDefinition(input: $input) {\n        deletedIds\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6af78d0f1b23c81099b731c6e95cd7f2";

export default node;
