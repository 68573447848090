/**
 * @generated SignedSource<<36794b703639822c8116cd7b519a6d03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PageDataKind = "image" | "imageWithButton";
import { FragmentRefs } from "relay-runtime";
export type messagePagesEditor_PagesFragment$data = {
  readonly id: string;
  readonly pages: ReadonlyArray<{
    readonly data: {
      readonly kind: PageDataKind;
    };
    readonly id: string;
    readonly internalTitle: string;
    readonly " $fragmentSpreads": FragmentRefs<"messagePageFormDialog_PageFragment">;
  }>;
  readonly " $fragmentType": "messagePagesEditor_PagesFragment";
};
export type messagePagesEditor_PagesFragment$key = {
  readonly " $data"?: messagePagesEditor_PagesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messagePagesEditor_PagesFragment">;
};

import messagePagesEditor_Refetch_graphql from './messagePagesEditor_Refetch.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": messagePagesEditor_Refetch_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "messagePagesEditor_PagesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageDefinitionPage",
      "kind": "LinkedField",
      "name": "pages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "messagePageFormDialog_PageFragment"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "MessageDefinition",
  "abstractKey": null
};
})();

(node as any).hash = "db857eee8bdd1fcc309b84e06e218eea";

export default node;
