/**
 * @generated SignedSource<<54e25cd9e01356ad84ccf4fe1cb3b7da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messagePageFormDialog_PageFragment$data = {
  readonly internalTitle: string;
  readonly " $fragmentSpreads": FragmentRefs<"messagePageForm_PageFragment">;
  readonly " $fragmentType": "messagePageFormDialog_PageFragment";
};
export type messagePageFormDialog_PageFragment$key = {
  readonly " $data"?: messagePageFormDialog_PageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messagePageFormDialog_PageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "messagePageFormDialog_PageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "messagePageForm_PageFragment"
    }
  ],
  "type": "MessageDefinitionPage",
  "abstractKey": null
};

(node as any).hash = "89a7cf6a28a44e07c66720c963d9b06d";

export default node;
