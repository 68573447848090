/**
 * @generated SignedSource<<9a95f987ba9cf9bc553a7f5061885145>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveAttachmentInput = {
  attachmentId: string;
  clientMutationId?: string | null | undefined;
  nodeId: string;
};
export type AttachmentEditor_RemoveAttachmentV2Mutation$variables = {
  input: RemoveAttachmentInput;
};
export type AttachmentEditor_RemoveAttachmentV2Mutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly removeAttachment: {
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"AttachmentEditor_TreeNodeFragment">;
        };
      } | null | undefined;
    };
  };
};
export type AttachmentEditor_RemoveAttachmentV2Mutation = {
  response: AttachmentEditor_RemoveAttachmentV2Mutation$data;
  variables: AttachmentEditor_RemoveAttachmentV2Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AttachmentEditor_RemoveAttachmentV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveAttachmentPayload",
                "kind": "LinkedField",
                "name": "removeAttachment",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "AttachmentEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AttachmentEditor_RemoveAttachmentV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveAttachmentPayload",
                "kind": "LinkedField",
                "name": "removeAttachment",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachments",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isAttachmentV2"
                          },
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "file",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              }
                            ],
                            "type": "DefaultFileAttachment",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2dd62d2bc7e959bb95dacd9cd77f0ad4",
    "id": null,
    "metadata": {},
    "name": "AttachmentEditor_RemoveAttachmentV2Mutation",
    "operationKind": "mutation",
    "text": "mutation AttachmentEditor_RemoveAttachmentV2Mutation(\n  $input: RemoveAttachmentInput!\n) {\n  Admin {\n    Tree {\n      removeAttachment(input: $input) {\n        node {\n          ...AttachmentEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment AttachmentEditor_TreeNodeFragment on TreeNode {\n  id\n  attachments {\n    __typename\n    kind\n    ... on DefaultFileAttachment {\n      id\n      file {\n        id\n        name\n      }\n      title\n      description\n      kind\n    }\n    ...DefaultFileAttachmentForm_AttachmentV2Fragment\n    id\n  }\n}\n\nfragment DefaultFileAttachmentForm_AttachmentV2Fragment on AttachmentV2 {\n  __isAttachmentV2: __typename\n  ... on DefaultFileAttachment {\n    file {\n      id\n      name\n    }\n    title\n    description\n  }\n  kind\n}\n"
  }
};
})();

(node as any).hash = "37878c65c97afc99d3934c1d53114f95";

export default node;
