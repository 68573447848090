import { Button, EpicIcons } from "@constellation-academy/epic-ui";
import { useMutation } from "react-relay";
import { type createMatrixElementButton_CreateMatrixElementMutation } from "@relay/createMatrixElementButton_CreateMatrixElementMutation.graphql";
import { CREATE_MATRIX_ELEMENT_MUTATION } from "./create-matrix-element-button.graphql";
import { type CreateMatrixElementButtonProps } from "./create-matrix-element-button.types";

export const CreateMatrixElementButton = ({
	eLearningContentNodeId,
}: CreateMatrixElementButtonProps) => {
	const [createMatrixElement, isCreatingMatrixElement] =
		useMutation<createMatrixElementButton_CreateMatrixElementMutation>(
			CREATE_MATRIX_ELEMENT_MUTATION,
		);

	return (
		<Button
			disabled={isCreatingMatrixElement}
			tooltip={`Matrix-Baustein anlegen`}
			icon={EpicIcons.TH_LARGE}
			onClick={() => {
				createMatrixElement({
					variables: {
						input: {
							title: "Neues Element",
							correctAnswer: "korrekte Antwort",
							firstColumnTitle: "Neue Spalte",
							firstRowTitle: "Neue Zeile",
							eLearningContentId: eLearningContentNodeId,
						},
					},
				});
			}}
		/>
	);
};
