import { graphql } from "react-relay";

export const EXTERNAL_LICENSE_POOL_QUERY = graphql`
	query externalLicensePoolScreen_ExternalLicensePoolQuery($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on ExternalLicense {
				id
				data {
					name
				}
			}
		}
	}
`;

export const EXTERNAL_LICENSES_QUERY = graphql`
	query externalLicensePoolScreen_ExternalLicensesQuery(
		$numItemsPerPage: Int!
		$externalLicensePoolId: ID!
	) {
		...externalLicensesTable_ExternalLicensesFragment
			@arguments(
				externalLicensePoolIdOpt: $externalLicensePoolId
				first: $numItemsPerPage
				after: null
			)
	}
`;
