/**
 * @generated SignedSource<<a0f7d07432c6b64621d23ef47c8da428>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type LearnableKind = "Course";
import { FragmentRefs } from "relay-runtime";
export type offersTable_UnpublishedLearnableFragment$data = {
  readonly id: string;
  readonly kind: LearnableKind;
  readonly root?: {
    readonly id: string;
    readonly structureDefinition: {
      readonly title: string;
    };
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"deleteCourseLearnableButton_UnpublishedLearnableFragment" | "editCourseLearnableButton_UnpublishedLearnableFragment">;
  readonly " $fragmentType": "offersTable_UnpublishedLearnableFragment";
};
export type offersTable_UnpublishedLearnableFragment$key = {
  readonly " $data"?: offersTable_UnpublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"offersTable_UnpublishedLearnableFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "offersTable_UnpublishedLearnableFragment"
};

(node as any).hash = "0ec59cc6b5d2b6e2e1e85a5c1358a1df";

export default node;
